import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Row from 'react-bootstrap/Row'
import * as formik from 'formik'
import * as yup from 'yup'
import { LanguageTranslate } from '../../../Infraestructure/LanguageTranslate'

interface Props {
  textLength?: number
  handleSearch?: (text: string) => void
  handleChange?: (text: string) => void
}

export function InputSearch(props: Props) {

  const { textLength = 2, handleSearch, handleChange } = props
  const { Formik } = formik
  const languageTranslate = LanguageTranslate()

  const schema = yup.object().shape({
    text: yup.string().min(textLength, `El texto debe tener al menos ${textLength} caracteres.`),
  })

  const handleChangeAux = (e: any) => {
    if (handleChange) handleChange(e.target.value)
  }

  return (
    <Formik
      validationSchema={schema}
      onSubmit={({ text }) => {
        if (text.length === 0) return
        if (handleSearch) handleSearch(text)
      }}
      initialValues={{
        text: '',
      }}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row className='mb-3'>
            <Form.Group as={Col} md='4' controlId='validationFormiktext'>
              <InputGroup hasValidation>
                <InputGroup.Text id='inputGroupPrepend'>🔎</InputGroup.Text>
                <Form.Control
                  type='search'
                  placeholder={languageTranslate.general.Buscar}
                  aria-describedby='inputGroupPrepend'
                  name='text'
                  value={values.text}
                  onChange={(e) => {
                    handleChange(e)
                    handleChangeAux(e)
                  }}
                  isInvalid={!!errors.text}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.text}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
        </Form>
      )}
    </Formik>
  )
}