import { DtoResponseSelectStockPersonal } from "../../../Logistica/DetalleStockPersonalTotal/Domain/DtoResponseSelectStockPersonal";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import './GridDetalleStockPersonal.scss';

interface IGridDetalleStockPersonal {
    item: DtoResponseSelectStockPersonal
}

const GridDetalleStockPersonal = (props: IGridDetalleStockPersonal) => {

    const languageTranslate = LanguageTranslate();

    return (
        <div className="GridDetalleStockPersonal mb-3 shadow rounded">
            <table className="table table-sm table-striped rowColor">
                <tbody>
                    <tr>
                        <th scope="row">{languageTranslate.moduloLogistica.stockPersonalTotal.detalleStockPersonalTotal.content.textCodigo}</th>
                        <td>{props.item.Item?.Codigo}</td>    
                    </tr>
                    <tr>
                        <th scope="row">{languageTranslate.moduloLogistica.stockPersonalTotal.detalleStockPersonalTotal.content.textLote}</th>
                        <td>{props.item.Lote}</td>  
                    </tr>
                    <tr>
                        <th scope="row">{languageTranslate.moduloLogistica.stockPersonalTotal.detalleStockPersonalTotal.content.textEmpresa}</th>    
                        <td>{props.item.Empresa?.RazonSocial}</td>  
                    </tr>
                    <tr>
                        <th scope="row">{languageTranslate.moduloLogistica.stockPersonalTotal.detalleStockPersonalTotal.content.textBobina}</th>
                        <td>{props.item.Bobina}</td> 
                    </tr>
                    <tr className="">
                        <th scope="row">{languageTranslate.moduloLogistica.stockPersonalTotal.detalleStockPersonalTotal.content.textDescripcion}</th>    
                        <td>{props.item.Item?.Nombre}</td> 
                    </tr>
                    <tr>
                        <th scope="row">{languageTranslate.moduloLogistica.stockPersonalTotal.detalleStockPersonalTotal.content.textUM}</th>
                        <td>{props.item.Item?.UnidadMedida.Nombre}</td> 
                    </tr>
                    <tr>
                        <th scope="row">{languageTranslate.moduloLogistica.stockPersonalTotal.detalleStockPersonalTotal.content.text$PU}</th>    
                        <td>{props.item.Precio}</td> 
                    </tr>
                    <tr>
                        <th scope="row">{languageTranslate.moduloLogistica.stockPersonalTotal.detalleStockPersonalTotal.content.textTipo}</th>
                        <td>{props.item.Tipo}</td> 
                    </tr>
                    <tr className="">
                        <th scope="row">{languageTranslate.moduloLogistica.stockPersonalTotal.detalleStockPersonalTotal.content.textSB}</th>    
                        <td>{props.item.Item?.Ind_SB}</td>
                    </tr>
                    <tr className="">
                        <th scope="row">{languageTranslate.moduloLogistica.stockPersonalTotal.detalleStockPersonalTotal.content.textTotalDespacho}</th>    
                        <td>{props.item.TotalDespacho}</td>
                    </tr>
                    <tr className="">
                        <th scope="row">{languageTranslate.moduloLogistica.stockPersonalTotal.detalleStockPersonalTotal.content.textTotalDevolucion}</th>
                        <td>{props.item.TotalDevolucion}</td>
                    </tr>
                    <tr>
                        <th scope="row">{languageTranslate.moduloLogistica.stockPersonalTotal.detalleStockPersonalTotal.content.textTotalLiquidacion}</th>
                        <td>{props.item.TotalLiquidacion}</td>
                    </tr>
                    <tr>
                        <th scope="row">{languageTranslate.moduloLogistica.stockPersonalTotal.detalleStockPersonalTotal.content.textTotalStock}</th>    
                        <td>{props.item.TotalStock}</td>
                    </tr>
                    <tr>
                        <th scope="row">{languageTranslate.moduloLogistica.stockPersonalTotal.detalleStockPersonalTotal.content.textTipoStock}</th>
                        <td>{props.item.TipoStock?.Nombre}</td>
                    </tr>
                    <tr>
                        <th scope="row">{languageTranslate.moduloLogistica.stockPersonalTotal.detalleStockPersonalTotal.content.textEstado}</th>    
                        <td>{props.item.Estado?.Nombre}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default GridDetalleStockPersonal;