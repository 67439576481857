import { AdapterConfigure } from './AdapterConfigure';
import { RepositoryImplMain } from './RepositoryImplMain';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { addLoading, hideIconMenu, removeLoading } from '../../../shared/Infraestructure/SliceGenerico';
import { useState } from 'react';
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { DtoResponseSelectStockPersonal } from '../../../Master/Home/Domain/DtoResponseSelectStockPersonal';
import { UseCaseSelectStockPersonalLocal } from '../Application/UseCaseSelectStockPersonalLocal';

export const Controller = () => {
  const dispatch: Dispatch = useDispatch();
  const [detalleStockPersonal, setDetalleStockPersonal] = useState<DtoResponseSelectStockPersonal>({} as DtoResponseSelectStockPersonal);
  const navigate: NavigateFunction = useNavigate();
  const params = useParams<{ id: string, trabajo: string }>();
  const { websocket, dbLocal } = useSelector((state: RootState) => state.generico);
  const { user } = useSelector((state: RootState) => state.auth);
  const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

  const init = async () => {
    try {
      dispatch(hideIconMenu());
      await loadData();
    } catch (error) {
      console.error(error);
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false);
      navigate(AdapterConfigure.REDIRECT_STOCK_PERSONAL_OBRA, { replace: true });
    } finally {
      dispatch(removeLoading());
    }
  };

  const loadData = async () => {
    dispatch(addLoading({ textLoading: 'Cargando Detallle Stock Personal...' }));
    let respDetalleStockPersonal = await new UseCaseSelectStockPersonalLocal(repository).exec(user, Number(params.id));
    dispatch(removeLoading());
    setDetalleStockPersonal(respDetalleStockPersonal);
  };

  return {
    init,
    user,
    detalleStockPersonal,
    params
  };
}