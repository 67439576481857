const SCHEMA: string = process.env.REACT_APP_MASTER_LOGIN_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MASTER_LOGIN_ENTITY || '';

const LOGIN: string = '/SigoPwa/Usuarios/Login';
const V1_INFO_USER: string = '/procesos/usuarios/v1/generateuserinformation';

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    LOGIN,
    V1_INFO_USER
};