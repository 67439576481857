import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { DtoRequestRecuperarPassword } from "../Domain/DtoRequestRecuperarPassword";
import { DtoResponseLogin } from "../../Login/Domain/DtoResponseLogin";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { AdapterConfigure } from "./AdapterConfigure";
import { removeLoading } from "../../../shared/Infraestructure/SliceGenerico";

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {
    public async recuperarPassword(params: DtoRequestRecuperarPassword): Promise<boolean> {
        return this._recuperarPassword(params);
    }

    private async _recuperarPassword(params: DtoRequestRecuperarPassword): Promise<any> {
        try {
            if (!navigator.onLine) {
                this.dispatch(removeLoading());
                AdapterGenerico.createMessage('Sin Conexión', 'No posee conexión a internet', 'warning');
                return;
            }

            let url: string = `${this.urlBase}${AdapterConfigure.RECOVERYPASSWORD}`;
            let response: boolean = await this.service.call<any>('POST', url, JSON.stringify(params), 'basic', 'json', 'json', {});
            response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response;
            return response;

        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}