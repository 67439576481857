import { RepositoryMain } from '../Domain/RepositoryMain'
import { EntityDataUsuario } from '../../../shared/Domain/EntityDataUsuario'
import { DtoRequestSelectConsumoMaterial } from '../Domain/DtoRequestSelectConsumoMaterial'
import { DtoConsumoMaterial } from '../Domain/DtoConsumoMaterial'

export class UseCaseSelectConsumoMaterial {
    private repository: RepositoryMain

    constructor(_repository: RepositoryMain) {
        this.repository = _repository
    }

    public async exec(user: EntityDataUsuario, idsTrabajo: number[]): Promise<DtoConsumoMaterial[]> {
        return await this._exec(user, idsTrabajo)
    }

    private async _exec(user: EntityDataUsuario, idsTrabajo: number[]): Promise<DtoConsumoMaterial[]> {
        let params: DtoRequestSelectConsumoMaterial = {
            datos: {
                Codigo: user.Delegacion.Codigo,
                Nombre: user.Pais.Nombre,
            },
            Filtros: [
                {
                    $match: {
                        // ID_Trabajo: { $in: idsTrabajo },
                        'Almacen.Codigo': user.AlmacenEmpresa.Codigo,
                        'Pais.Codigo': user.Pais.Codigo,
                        'Delegacion.Codigo': user.Delegacion.Codigo,
                        'Estado.ID_Estado': 1,
                    }
                },
                {
                    $project: {
                        Delegacion: 0,
                        Estado: 0,
                        Pais: 0
                    },
                }
            ],
        }
        if (user.Pais.Codigo === '004')
            return await this.repository.selectConsumoMaterial(params, user)
        return []
    }
}