import { AdapterConfigure } from './AdapterConfigure';
import { RepositoryImplMain } from './RepositoryImplMain';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { addLoading, hideIconMenu, removeLoading } from '../../../shared/Infraestructure/SliceGenerico';
import { useEffect, useState } from 'react';
import { EntitySalidaAlmacen } from "../../FirmaAlbaran/Domain/EntitySalidaAlmacen";
import { UseCaseSelectSalidaAlmacen } from '../Application/UseCaseSelectSalidaAlmacen';
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { ErrorCostume } from '../../../shared/Domain/ErrorCostume';
import { UseCaseEnviarFirma } from '../Application/UseCaseEnviarFirma';
import { changeShowBtnFirmar } from '../Domain/SliceDespachoAlmacen';

export const Controller = () => {

  const { websocket, dbLocal } = useSelector((state: RootState) => state.generico);
  const { user } = useSelector((state: RootState) => state.auth);
  const { showBtnFirmar } = useSelector((state: RootState) => state.despachoAlmacen);

  const [salidaAlmacen, selectSalidaAlmacen] = useState<EntitySalidaAlmacen>({} as EntitySalidaAlmacen);
  const [showModal, setModal] = useState<boolean>(false);
  const dispatch: Dispatch = useDispatch();
  const navigate: NavigateFunction = useNavigate();
  const params = useParams();
  useEffect(() => {
    return () => {
      dispatch(changeShowBtnFirmar(false));
    };
  }, [])

  const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

  const init = async () => {
    try {
      dispatch(hideIconMenu());
      await loadData();
    } catch (error) {
      console.error(error);
      let err: ErrorCostume = new ErrorCostume((error as Error).message);
      AdapterGenerico.createMessage('Alerta', err.message, 'warning', false);
      navigate(AdapterConfigure.REDIRECT_FIRMARALBARAN, { replace: true });
    } finally {
      dispatch(removeLoading());
    }
  };

  const loadData = async () => {
    dispatch(addLoading({ textLoading: 'Cargando Salida Almacen...' }));
    let respSalidaAlmacen = await new UseCaseSelectSalidaAlmacen(repository).exec(user, Number(params.id));
    dispatch(removeLoading());
    if (respSalidaAlmacen.length !== 1) throw Error(`No se encontró salida de almacen con ID_DespachoAlmacen: ${params.id}`);
    selectSalidaAlmacen(respSalidaAlmacen[0]);
  };

  const onSendFirm = async (base64String: string | undefined) => {
    try {
      if (base64String === undefined) throw Error(`No se ha firmado el albarán.`);
      await new UseCaseEnviarFirma(repository).exec({
        imagen: base64String,
        Identificacion: salidaAlmacen.Personal.Identificacion,
        NroDocumento: salidaAlmacen.NroDocumento,
        Firma: salidaAlmacen.Personal.Firma
      });
      setModal(false);
      await AdapterGenerico.createMessage('¡Éxito!', 'Se firmó correctamente.', 'success', false);
    } catch (error) {
      console.error(error);
      let err: ErrorCostume = new ErrorCostume((error as Error).message);
      AdapterGenerico.createMessage('Alerta', err.message, 'warning', false);
    } finally {
      setModal(false);
      navigate(AdapterConfigure.REDIRECT_FIRMARALBARAN, { replace: false });
    }
  }

  const openModal = async () => {
    try {
      setModal(true);
    } catch (error) {
      console.error(error);
      let err: ErrorCostume = new ErrorCostume((error as Error).message);
      AdapterGenerico.createMessage('Alerta', err.message, 'warning', false);
    }
  }

  return {
    init,
    salidaAlmacen,
    onSendFirm,
    setModal,
    showModal,
    openModal,
    showBtnFirmar,
  };
}