import React from 'react'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import ElementStepperContent from '../../../../shared/Components/ElementStepperContent'
import { IManoObraEditados } from '../Domain/IEditados'
import { DtoAmap } from '../../../Home/Domain/DtoAmap'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { InputSelect } from '../../../../shared/Components/ElementInputsCostume'
import { DtoManoObraGlobal } from '../../../../../app/Domain/DtoManoObraGlobal'
import { DtoGeneralCodigoNombre } from '../../../../shared/Domain/Dto/DtoGeneralCodigoNombre'

interface PropsReplanteo {
    keyTab: number
    setKeyTab: React.Dispatch<React.SetStateAction<number>>
    manoObraEditados: IManoObraEditados
    setManoObraEditados: React.Dispatch<React.SetStateAction<IManoObraEditados>>
    datosManoObra: DtoAmap[]
    onChange: (name: string, value: any) => void
    dataManoObraGlobal: DtoManoObraGlobal[]
    dataEspecialidad: DtoGeneralCodigoNombre[]
    selectManoObraGlobal: DtoManoObraGlobal | null
    onSave: () => void
}

export const Step2 = ({ keyTab, setKeyTab, manoObraEditados, setManoObraEditados, datosManoObra, onChange, dataManoObraGlobal, dataEspecialidad, selectManoObraGlobal, onSave }: PropsReplanteo): React.ReactElement => {

    const languageTranslate = LanguageTranslate()
    const handleEdit = (codigo: string, value: string) => {
        setManoObraEditados((prevEditados) => ({
            ...prevEditados,
            [codigo]: value,
        }))
    }

    return (
        <ElementStepperContent
            step={2}
            currentStep={keyTab}
            title={languageTranslate.general.Material}
            onSubmit={(step) => {
                if ((Object.keys(manoObraEditados).find((clave) => !manoObraEditados[clave].length)) && step === 3)
                    return AdapterGenerico.createMessage('Alerta', 'complete todas las cantidades', 'warning', false)
                // setKeyTab(step)
                onSave()
            }}
            // activateBackButton
            isLastStep
        >
            <InputSelect
                label={languageTranslate.general.ManoDeObra}
                name='ManoObraGlobal'
                onChange={onChange}
                values={datosManoObra}
                isRequired
                options={
                    dataManoObraGlobal.map(row => ({ label: `${row.Codigo} - ${row.Actividad}`, value: row.Codigo, dataComplete: row }))
                        .filter(e => !datosManoObra.map(e => e.Codigo).includes(e.value))
                }
                loading={false}
                disabled={false}
                disabledVirtualized
            />
            {
                selectManoObraGlobal &&
                <InputSelect
                    label={languageTranslate.general.SeleccioneEspecialidad}
                    name='Especialidad'
                    onChange={onChange}
                    values={datosManoObra}
                    isRequired
                    options={dataEspecialidad}
                    loading={false}
                    disabled={false}
                    disabledVirtualized
                />
            }
            <table className='table-responsive table-sm'>
                <thead>
                    <tr>
                        <th className='text-center'>OPC</th>
                        <th>{languageTranslate.general.Codigo}</th>
                        <th>{languageTranslate.general.Nombre}</th>
                        <th className='text-center'>{languageTranslate.general.Reserva}</th>
                        <th className='text-center'>{languageTranslate.general.Cantidad}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        datosManoObra.map((row, i) => (
                            <tr key={i}>
                                <td onClick={() => onChange('deleteManoObra', row.DetalleChile.ManoObra.Codigo)} style={{ verticalAlign: 'middle' }}  >
                                    <i className='fa-solid fa-trash' style={{ fontSize: 15, color: 'red' }} />
                                </td>
                                <td>{row.Codigo}</td>
                                <td>{row.Descripcion}</td>
                                <td className='align-middle text-center'>{row.Cantidad}</td>
                                <td className='align-middle'>
                                    <input
                                        className='form-control'
                                        type='number'
                                        name={row.Codigo}
                                        value={manoObraEditados[row.Codigo] ? manoObraEditados[row.Codigo] : ''}
                                        onChange={(e) => handleEdit(row.Codigo, e.target.value)}
                                    />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </ElementStepperContent>
    )
}
