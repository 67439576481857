import ElementModalCostume from "../../../shared/Components/ElementModalCostume";
import { DoughnutChart } from "../../../shared/Components/DoughnutChart";
import { ElementVerticalBarChart } from "../../../shared/Components/ElementVerticalBarChart/ElementVerticalBarChart";
import { EntityMenu } from "../../../shared/Domain/EntityMenu";
import { EntityNotificacionTerminos } from "../../../shared/Domain/EntityNotificacionTerminos";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import ElementContenedorGenerico from "../../../shared/Components/Contenedor/ElementContenedorGenerico";
import ElementListDashboardStockPersonalTotal from "../../../shared/Components/ElementListDashboardStockPersonalTotal/ElementListDashboardStockPersonalTotal";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./Style.scss";
import { ValorizacionDashboard } from "../Infraestructure/Controller";
import { DtoTrabajos } from "../../Home/Domain/DtoTrabajos";
import InfiniteScroll from "react-infinite-scroll-component";
import "./Style.scss";
import { DtoResponseSelectStockPersonal } from "../../../Logistica/StockPersonalTotal/Domain/DtoResponseSelectStockPersonal";
import { useEffect, useState } from "react";
import {
    CardBody,
    CardContainer,
    CardHeader,
} from "../../../shared/Components/CardComponents";
import { useSelector } from "react-redux";
import { RootState } from "../../../shared/Infraestructure/AdapterStore";
//import { DtoTrabajos } from "../../../Programados/Trabajos/Trabajos/Domain/DtoTrabajos";

interface PropsView {
    menu: Array<EntityMenu>;
    onClickAyuda: Function;
    onClickReport: Function;
    onClickMenu: Function;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    Notifications: EntityNotificacionTerminos[];
    getDashboardAmounts(): number[];
    getDashboardLabels(): string[];
    trabajos: DtoTrabajos[];
    stockPersonalAux: DtoResponseSelectStockPersonal[];
}

type StockPersonalProps = {
    data: DtoResponseSelectStockPersonal[];
};

const StockPersonal: React.FC<StockPersonalProps> = ({ data }) => {
    const languageTranslate = LanguageTranslate();
    const [stocksRecords, setStocksRecors] = useState(data.slice(0, 4))
    const loadMoreData = () => setStocksRecors(data.slice(0, stocksRecords.length + 4));
    return (
        <CardContainer>
            <CardHeader>
                <label className="d-flex h-auto text-muted">
                    {`${languageTranslate.menu["053"].titulo} : ${data.length} Items`}
                </label>
            </CardHeader>
            <CardBody
                id="scrollableDiv"
                style={{ padding: 0, height: "300px", overflow: "scroll" }}
            >
                <InfiniteScroll
                    dataLength={stocksRecords.length} // Número total de elementos que tienes
                    next={loadMoreData} // Función que se llamará cada vez que necesites cargar más datos
                    hasMore={stocksRecords.length < data.length} // Indica si hay más elementos para cargar o no
                    loader={<h4>Cargando...</h4>} // Componente a mostrar mientras se cargan los datos
                    scrollableTarget="scrollableDiv"
                >
                    {stocksRecords.map((row) => (
                        <ElementListDashboardStockPersonalTotal
                            key={row.ID_StockPersonal}
                            dataCant={row.Despacho - row.Devolucion - row.Liquidacion + row.DespachoTR - row.DevolucionTR}
                            dataCodDescrip={`${row.Item.Codigo} - ${row.Item.Nombre}`}
                            to={`/main/stockPersonalTotal/${row.CodigoLlave}`}
                        />
                    ))}
                </InfiniteScroll>
            </CardBody>
        </CardContainer>
    );
};

export const ViewMain = (props: PropsView) => {
    const languageTranslate = LanguageTranslate();
    const { loading } = useSelector((state: RootState) => state.generico);

    return (
        <div className="ViewMasterHome" style={{ overflow: "scroll" }}>
            {props.trabajos.length > 0 && !loading && (
                <div style={{ margin: "25px" }}>
                    <CardContainer>
                        <CardHeader>
                            <label className="d-flex h-auto text-muted">
                                {languageTranslate.menu["002"].titulo}
                            </label>
                        </CardHeader>
                        <CardBody>
                            <div className="dougnut-container" >
                                <DoughnutChart
                                    labels={props.getDashboardLabels()}
                                    dataGroups={props.getDashboardAmounts()}
                                />

                            </div>
                        </CardBody>
                    </CardContainer>
                </div>
            )}
            <div>
                {props.trabajos.length > 0 && !loading && (
                    <Swiper
                        pagination={{
                            type: "fraction",
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                        spaceBetween={10}
                    >
                        {props.trabajos.slice(0, 10).map((trabajo) => {
                            const dash = new ValorizacionDashboard(
                                trabajo.Ultima_PreLiquidacion.Valorizacion
                            );
                            return (
                                <SwiperSlide key={trabajo.ID_Trabajo}>
                                    <div style={{ padding: "0 25px" }}>
                                        <ElementContenedorGenerico
                                            title={
                                                languageTranslate.moduloProgramados.trabajos
                                                    .moduloPreLiquidar.stepper.stepper1.content
                                                    .valorizacion
                                            }
                                        >
                                            <ElementVerticalBarChart
                                                task={`${trabajo.ColeccionObras[0].OrdenTrabajo} / ${trabajo.ColeccionObras[0].Proyecto}`} // NOMBRE DEL TRABAJO
                                                labels={ValorizacionDashboard.LABELS}
                                                dataGroups={dash.getAmounts()}
                                            />
                                        </ElementContenedorGenerico>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                )}
                {props.stockPersonalAux.length > 0 && !loading && (
                    <div style={{ margin: "25px" }}>
                        <StockPersonal data={props.stockPersonalAux} />
                    </div>
                )}

                <div
                    className="f"
                    style={{ position: "absolute", zIndex: 0, marginTop: "-24px" }}
                >
                    <div
                        className="container"
                        style={{ position: "relative", zIndex: -1 }}
                    >
                        {props.menu.map((row) => (
                            <div className="row pb-3" key={row.id}>
                                <div className="col-12">
                                    <div
                                        className="shadow-lg"
                                        style={{
                                            width: "100%",
                                            padding: 5,
                                            margin: 0,
                                            borderRadius: "10px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => props.onClickMenu(row)}
                                    >
                                        <div className="row">
                                            <div className="col-2">
                                                <button
                                                    className="btn btn-secondary btn-icon-first"
                                                    style={{ marginTop: "5px" }}
                                                >
                                                    <i className={`text-white ${row.icono}`} />
                                                </button>
                                            </div>
                                            <div className="col-8">
                                                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                                                    {
                                                        languageTranslate.menu[
                                                            row.codigo as keyof typeof languageTranslate.menu
                                                        ].titulo
                                                    }
                                                </span>
                                                <br />
                                                <span style={{ fontSize: "10px" }}>
                                                    {
                                                        languageTranslate.menu[
                                                            row.codigo as keyof typeof languageTranslate.menu
                                                        ].descripcion
                                                    }
                                                </span>
                                            </div>
                                            <div className="col-2 position-relative">
                                                <button className="btn btn-lg btn-secondary text-white text-center">
                                                    {">"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <ElementModalCostume
                        closeModal={() => props.setShowModal(false)}
                        show={props.showModal}
                        title={`Notificación (${props.Notifications.length})`}
                        disabledButtonClose={
                            props.Notifications[0]?.TipoNotificacion.IdTipoNotificacion === 1
                        }
                    >
                        {props.Notifications.map((row) => (
                            <div key={row.IdNotificacion}>
                                <label className="mb-4">
                                    {" "}
                                    <strong> {row.TituloNotificacion} </strong>{" "}
                                </label>
                                <div
                                    className="mb-3"
                                    dangerouslySetInnerHTML={{ __html: row.Contenido.Cuerpo }}
                                />
                                {row.Contenido.Opciones.map((opcion) => (
                                    <div
                                        key={opcion}
                                        className="mb-3"
                                        style={{ fontWeight: "bolder" }}
                                        dangerouslySetInnerHTML={{ __html: opcion }}
                                    />
                                ))}
                                <div
                                    className="mb-4"
                                    dangerouslySetInnerHTML={{ __html: row.Contenido.PiePagina }}
                                />
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    style={{ width: "100%", backgroundColor: "#01397D" }}
                                //   onClick={props.onAcceptTerms}
                                >
                                    {row.Opciones[0].InnerHTML}
                                </button>
                            </div>
                        ))}
                    </ElementModalCostume>
                </div>
            </div>
        </div>
    );
};
