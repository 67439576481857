import { Filtros } from '../../Domain/EntityParams';

export const FiltroDocumentacionPEX: Filtros = {
    Filtros: [
        {
            $match: {
                'Estado.ID_Estado': 1
            }
        },
        {
            $project: {
                _id: 0,
                IsDebug: 0,
                'Pais.IsDebug': 0,
                'Delegacion.IsDebug': 0,
                'Nodos.IsDebug': 0,
                'Nodos.Size.IsDebug': 0,
                'Nodos.Estado.IsDebug': 0,
                Bitacora: 0,
            }
        }
    ]
}