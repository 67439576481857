import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ViewMain } from "./Components/View";


const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
        controller.init();
        return () => { }
        // eslint-disable-next-line
    }, []);

    return (<ViewMain
        trabajos={controller.trabajosAux}
        onChange={controller.onChange}
        formSearch={controller.formSearch}
        showModal={controller.showModal}
        onSearchSubmit={controller.onSearchSubmit}
        formSearchDB={controller.formSearchDB}
        setModal={controller.setModal}
    />);
};

export default ManagerEntity;