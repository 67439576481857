import { RepositoryMain } from '../Domain/RepositoryMain'

export class UseCaseDownloadFiles {
    private repository: RepositoryMain

    constructor(_repository: RepositoryMain) {
        this.repository = _repository
    }

    public async exec(fileNames: string[]): Promise<File[]> {
        return await this._exec(fileNames)
    }

    private async _exec(fileNames: string[]): Promise<File[]> {
        return await this.repository.downloadFiles(fileNames)
    }
}