const {
    REACT_APP_ROUTE_PATH_MAIN,
    REACT_APP_ROUTE_PATH_MAIN_LOGISTICA,
    REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_TRABAJOS,
    REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_STOCKPERSONAL,
} = process.env;
const SCHEMA: string = process.env.REACT_APP_MAESTRO_SEDE_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MAESTRO_SEDE_ENTITY || '';
const REDIRECT_STOCK_PERSONAL: string = `/${REACT_APP_ROUTE_PATH_MAIN}/${REACT_APP_ROUTE_PATH_MAIN_LOGISTICA}/${REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_TRABAJOS}/${REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_STOCKPERSONAL}` || '';
const REDIRECT_LOGISTICA: string = `/${REACT_APP_ROUTE_PATH_MAIN}/${REACT_APP_ROUTE_PATH_MAIN_LOGISTICA}` || '';
const SELECT_STOCK_PERSONAL: string = '/SigoPwa/Logistica/Reportes/StockPersonal/Select';

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    REDIRECT_LOGISTICA,
    SELECT_STOCK_PERSONAL,
    REDIRECT_STOCK_PERSONAL,
};