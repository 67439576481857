import { DtoEmpresa } from "../../../shared/Domain/Dto/DtoEmpresa";
import { DtoUnidadMedida } from "../../../shared/Domain/Dto/DtoUnidadMedida";
import { DtoEstado } from "../../../shared/Domain/Dto/DtoEstado";
import { DtoWBE } from "./DtoWBE";

export class DtoItem {
    ID_Item: number = 0;
    Empresa: DtoEmpresa = new DtoEmpresa();
    Codigo: string = '';
    Nombre: string = '';
    Descripcion: string = '';
    Variante: DtoVariante = new DtoVariante();
    UnidadMedida: DtoUnidadMedida = new DtoUnidadMedida();
    Ind_SB: string = '';
    Lote: string = '';
    Cantidad: number = 0;
    CantidadDespacho: number = 0;
    CantidadUsados: number = 0;
    CantidadDevolucion: number = 0;
    CantidadAprobada: number = 0;
    POS: number = 0;
    Valor: number = 0;
    WBE: DtoWBE = new DtoWBE();
    Total: number = 0;
    Moneda: string = '';
    Simbolo: string = '';
    Decimales: number = 0;
    Cantidades: DtoItemCantidades[] = [];
    DatosCatalogoItem: DtoDataCatalogoItem = new DtoDataCatalogoItem();
}

export class DtoDataCatalogoItem {
    DatosPrecioMaterial: DtoDatosPrecioMaterial = new DtoDatosPrecioMaterial();
    DatosItem: DtoDatosItem = new DtoDatosItem();
}

class DtoDatosItem {
    Tipo: string = '';
    Valor: number = 0;
    Ind_SB: string = '';
    Propio: boolean = true;
}

class DtoDatosPrecioMaterial {
    Precio: number = 0;
    Moneda: string = '';
    Simbolo: string = '';
    Decimales: number = 0;
    PreciosVigentes: DtoPreciosVigentes[] = [];
    Vigencia: DtoVigencia = new DtoVigencia();
}

class DtoPreciosVigentes {
    ID_PreciosVigentes: number = 0;
    Precio: number = 0;
    Vigencia: DtoVigencia = new DtoVigencia();
    Estado: DtoEstado = new DtoEstado();
}

class DtoVigencia {
    FechaInicio: Date = new Date();
    FechaFin: Date = new Date();
}

class DtoItemCantidades {
    ID_ItemCantidades: number = 0;
    Cantidad: number = 0;
    Fecha: Date = new Date();
}

class DtoVariante {
    ID_Variante: number = 0;
    Descripcion: string = '';
}