import "./ElementListDashboardStockPersonalTotal.scss";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

// props: IElementListDashboardStockPersonalTotal
type ElementListDashboardStockPersonalTotalProps = {
  dataCant: number;
  dataCodDescrip: string;
  to: string
};
const ElementListDashboardStockPersonalTotal = (
  props: ElementListDashboardStockPersonalTotalProps
) => {
  const { dataCant, dataCodDescrip } = props;
  return (
    <div className="ElementListDashboardStockPersonalTotal">
      <div className="Content">
        <div className="position-relative">
          <div className="LabelContainer">
            <span className="LabelContent">{dataCant}</span>
          </div>
        </div>
        <div className="StockTextContainer">
          <span style={{ fontSize: "12px", fontWeight: "bold", color: "#fff" }}>
            {dataCodDescrip}
          </span>
        </div>
        <div className="btn btn-lg btn-secondary text-white text-center ArrowContainer">
          <Link to={props.to} className="text-white text-center">
            <FaArrowRight />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ElementListDashboardStockPersonalTotal;
