const SCHEMA: string = process.env.REACT_APP_MASTER_MAIN_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MASTER_MAIN_ENTITY || '';
const SELECT_PAIS: string = '/SigoPwa/Configuracion/MantenimientosGlobales/Pais/Select';
const SELECT_PERSONAL: string = '/SigoPwa/Configuracion/MantenimientosGlobales/Personal/Select';
const SELECT_ESTADOINTERNO: string = '/SigoPwa/Programados/Mantenimientos/EstadoInterno/Select';
const SELECT_PRECIO_MATERIAL: string = '/SigoConfiguracion/MatenimientosGlobales/PrecioMaterial/Select';
const SELECT_MANO_OBRA_GLOBAL: string = '/SigoConfiguracion/MatenimientosGlobales/ManoObraGlobal/Select';
const SELECT_CUADRILLAS: string = '/SigoPwa/Configuracion/MantenimientosGlobales/Cuadrillas/Select';
const SELECT_ITEMS: string = '/SigoPwa/Configuracion/MantenimientosGlobales/Items/Select';
const SELECT_DOCUMENTACIONBAREMOPEX: string = '/SigoProgramados/Mantenimentos/DocumentacionBaremoPEX/Select';
const SELECT_DOCUMENTACIONPEX: string = '/SigoProgramados/Mantenimentos/DocumentacionPEX/Select';
const SELECT_PRECIO_ESPECIALIDAD: string = '/SigoConfiguracion/MatenimientosGlobales/PrecioEspecialidad/Select';
const SELECT_CONTRATO_OT: string = '/SigoConfiguracion/MatenimientosGlobales/ContratoOT/Select';
const UPDATE_ADDRESSLIST: string = '/SigoPwa/Programados/Gestion/addresslist/update'
const UPDATE_OBRA: string = '/SigoPwa/Programados/Gestion/obras/update'

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    SELECT_PERSONAL,
    SELECT_PAIS,
    SELECT_ESTADOINTERNO,
    SELECT_PRECIO_MATERIAL,
    SELECT_MANO_OBRA_GLOBAL,
    SELECT_CUADRILLAS,
    SELECT_ITEMS,
    SELECT_DOCUMENTACIONBAREMOPEX,
    SELECT_PRECIO_ESPECIALIDAD,
    SELECT_CONTRATO_OT,
    SELECT_DOCUMENTACIONPEX,
    UPDATE_ADDRESSLIST,
    UPDATE_OBRA,
};