import { useSelector } from 'react-redux'
import { Container } from '../../../../shared/Components/Container/Container'
import { ElementBreadCrumb } from '../../../../shared/Components/ElementBreadCrumb'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import { RootState } from '../../../../shared/Infraestructure/AdapterStore'
import { Children, FC } from 'react'
import { Card } from './card'
import ButtonFloating from '../../../../shared/Components/ButtonFloating/ButtonFloating'

interface iProps {
  handleSave: () => Promise<void>
}

export const View: FC<iProps> = ({ handleSave }) => {

  const langTrans = LanguageTranslate()
  const { dataAutoInventario } = useSelector((state: RootState) => state.autoInventario)
  
  const { autoInventario } = useSelector((state: RootState) => state.generico)

  return (
    <Container>
      <ElementBreadCrumb list={[
        { text: langTrans.moduloMain.textoLogistica, navigate: true, path: '/main/logistica' },
        { text: langTrans.menu['054'].titulo, navigate: false, path: '' }
      ]} />
      <div className='d-flex flex-column gap-3'>
        {
          Children.toArray(
            dataAutoInventario.map(e => <Card {...e} />)
          )
        }
      </div>
      {
        !autoInventario &&
        <ButtonFloating
          onClick={handleSave}
          icono='fa-solid fa-floppy-disk'
          colorIcono='white'
          backgroundColor='#049bed'
          right={20}
        />
      }
    </Container>
  )
}
