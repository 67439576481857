
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { Container } from '../../Container/Container'

interface props {
  children: ReactNode
  header?: boolean
  titleHeader?: string
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
  height?: 10 | 15 | 20 | 25 | 30 | 35 | 40 | 45 | 50 | 55 | 60 | 65 | 70 | 75 | 80 | 82 | 85 | 90 | 95 | 100
}

export function OffCanvasBottom({ children, show, setShow, header, height = 30, titleHeader, ...props }: props) {

  const [clientY1, setClientY1] = useState<number>(0)
  const [clientY2, setClientY2] = useState<number>(0)

  useEffect(() => {
    if (clientY1 < clientY2) {
      setShow(false)
    }
    setClientY1(0)
    setClientY2(0)
  }, [clientY2])

  return (
    <Offcanvas
      style={{ borderRadius: '15px 15px 0px 0px', height: `${height}%` }}
      show={show}
      onHide={() => setShow(!show)}
      placement='bottom' {...props}
    >
      <div
        onTouchStart={(e) => setClientY1(e.touches[0].clientY)}
        onTouchEnd={(e) => setClientY2(e.changedTouches[0].clientY)}
        className='d-flex justify-content-center align-items-center'
        style={{ height: 20 }}
      >
        <div style={{ border: '3px solid #aaaaaa', width: '20%', height: 3, borderRadius: 3 }} />
      </div>
      {
        header &&
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{titleHeader}</Offcanvas.Title>
        </Offcanvas.Header>
      }
      <Offcanvas.Body className='mb-1'>
        <Container className='h-100'>
          {children}
        </Container>
      </Offcanvas.Body>
    </Offcanvas>
  )
}