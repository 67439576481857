import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { EntityMenu } from '../../../shared/Domain/EntityMenu';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';
import { changeSaludo } from '../../../shared/Infraestructure/SliceGenerico';
import { AdapterConfigure } from './AdapterConfigure';
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';

export const Controller = () => {
  let { menu } = useSelector((state: RootState) => state.auth)
  const { autoInventario } = useSelector((state: RootState) => state.generico)
  const dispatch: Dispatch = useDispatch();
  const navigate = useNavigate();
  const { ROUTE_AUTO_INVENTARIO } = AdapterConfigure

  menu = menu.filter(row => !row.esOpcion && row.sigla === 'SIGOAPP_PEX' && row.estado)

  const init = async () => {
    dispatch(changeSaludo(false))
    // if (!autoInventario) {
    //   await AdapterGenerico.createMessage('Información', 'Por favor, reporte autoinventario', 'info').then(() => navigate(ROUTE_AUTO_INVENTARIO))
    // }
  }

  const onClickMenu = (element: EntityMenu) => {
    navigate(`/${element.ruta}`, { replace: true });
  };

  return {
    init,
    menu,
    onClickMenu,
  };
}