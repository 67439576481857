import React from 'react'
import { InputTextSearch } from '../../../../shared/Components/ElementInputSearch'
import { useFormik } from 'formik'
import { FormSearchValues } from '../Domain/IForms'
import { DtoObras } from '../Domain/DtoObras'
import { ElementItemCardGenerico } from '../../../../shared/Components/Programados/ElementItemCardGenerico'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'

interface PropsBuscarObra {
    selectObra: (value: number) => void
    formSearch: ReturnType<typeof useFormik<FormSearchValues>>
    obras: DtoObras[]
}

export const BuscarObra = ({ formSearch, obras, selectObra }: PropsBuscarObra): React.ReactElement => {

    const languageTranslate = LanguageTranslate()

    return (
        <>
            <form onSubmit={formSearch.handleSubmit}>
                <InputTextSearch
                    name='textSearch'
                    onChange={(name, value) => formSearch.handleChange(name)(value)}
                    values={formSearch.values}
                />
                {
                    formSearch.touched.textSearch && formSearch.errors.textSearch ? (
                        <label className='text-danger'>{formSearch.errors.textSearch}</label>
                    ) : null
                }
            </form>
            {
                obras.map((obra, i) =>
                    <ElementItemCardGenerico
                        key={i}
                        icono
                        onClick={() => selectObra(obra.ID_incidencia)}
                        text1={languageTranslate.general.OrdenTrabajo}
                        data1={obra.OrdenTrabajo}
                        text2={languageTranslate.general.Contrato}
                        data2={obra.Contrato}
                        text3={languageTranslate.general.EstadoInterno}
                        data3={obra.Ultimo_Estado_Interno.Descripcion}
                    />
                )
            }
        </>
    )
}
