import { createSlice } from '@reduxjs/toolkit'

interface Slice {
  textSearch: string
}

const initialState: Slice = {
  textSearch: '',
}

const Slice = createSlice({
  name: 'programadosDESlice',
  initialState,
  reducers: {
    changeTextSearch: (state, action) => {
      return {
        ...state,
        textSearch: action.payload,
      }
    },
  },
})

export const {
  changeTextSearch,
} = Slice.actions

export default Slice.reducer