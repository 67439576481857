import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../../shared/Infraestructure/RepositoryImplGenerico";
import { AdapterConfigure } from "./AdapterConfigure";
import { AdapterGenerico } from "../../../../shared/Infraestructure/AdapterGenerico";
import { DtoTrabajos } from "../../../../Master/Home/Domain/DtoTrabajos";
import { EntityDataUsuario } from "../../../../shared/Domain/EntityDataUsuario";
import { DtoReqSelectTrabajo } from "../../../Home/Domain/DtoReqSelectTrabajo";

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

    public async selectTrabajo(params: DtoReqSelectTrabajo): Promise<DtoTrabajos[]> {

        // params.Contrasenia = AdapterEncrypt.encrypt(params.Contrasenia, this.websocket.key, false);

        let url: string = `${this.urlBase}${AdapterConfigure.SELECT_TRABAJOS}`;

        let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'text', {});
        response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response;
        return response;
    }

    public async selectTrabajoLocal(user: EntityDataUsuario, idTrabajo: number): Promise<DtoTrabajos> {
        return await this.dbLocal.selectByIndexStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_Trabajos`, value: idTrabajo });
    }
    
}