import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ViewMain } from "./Components/View";

const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
        controller.init();
        return () => {
        }
        // eslint-disable-next-line
    }, []);

    return (<ViewMain
        stockPersonalAux={controller.stockPersonalAux}
        onChange={controller.onChange}
        formSearch={controller.formSearch}
    />);
};

export default ManagerEntity;