import './PageNotAvailable.scss';
import logo from '../../../assets/img/logo.png';
import notAvailable from '../../../assets/img/imageNotAvailable.png';
import { LanguageTranslate } from '../Infraestructure/LanguageTranslate';

export const PageNotAvailable = () => {
    const languageTranslate = LanguageTranslate();

    return <div className="pageNotAvailable">
        <div className="container">
            <div className="row pt-5">
                <div className="col-12 text-center">
                    <img src={logo} alt="Logo" className='img-fluid fondoBlanco' width={200} />
                </div>
                <div className="col-12 text-center pt-3">
                    <h1>{languageTranslate.moduloNotAvailable.textoCabecera}</h1>
                    <h6>{languageTranslate.moduloNotAvailable.textoDescripcion}</h6>
                </div>
                <div className="col-12 text-center pt-3">
                    <img src={notAvailable} alt="Logo" className='img-fluid' width={200} />
                </div>
            </div>
        </div>
    </div>
}