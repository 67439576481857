import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { DtoResponseCuadrillas } from '../../../../app/Domain/DtoResponseCuadrillas'
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate'
import { InputSelect } from '../../../shared/Components/ElementInputsCostume'
import { DtoFormSelectCuadrilla } from '../Domain/DtoFormSelectCuadrilla'
import { DtoValueOnSelect } from '../../../shared/Domain'

interface props {
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
  dataCuadrillas: DtoResponseCuadrillas[]
  onSubmitForm: (cuadrilla: DtoResponseCuadrillas) => void | Promise<any>
}

export const SelectCuadrilla = ({ show, setShow, dataCuadrillas, onSubmitForm, }: props) => {

  const languageTranslate = LanguageTranslate()

  const schema = yup.object().shape({
    cuadrilla: yup
      .object()
      .shape({ value: yup.string().min(1, 'Seleccione una opción') }),
  })

  const initialValues: DtoFormSelectCuadrilla = {
    cuadrilla: new DtoValueOnSelect(),
  }

  useEffect(() => {

  }, [show])

  return (
    <Modal show={show} onHide={() => setShow(!show)}>
      <Modal.Header closeButton>
        <Modal.Title className='text-white'>{languageTranslate.general.Seleccione_Una_Cuadrilla}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={(values) => onSubmitForm(values.cuadrilla.dataComplete)}
        >
          {
            ({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form className='h-100 d-flex flex-column justify-content-between' noValidate onSubmit={handleSubmit}>
                <div>
                  <Row>
                    <InputSelect
                      label={languageTranslate.general.Cuadrilla}
                      name='cuadrilla'
                      onChange={(name, value) => handleChange({ target: { value, name } })}
                      values={values}
                      isRequired
                      options={dataCuadrillas
                        .map(e => ({
                          dataComplete: e,
                          label: `${e.Codigo} - ${e.Descripcion}`,
                          value: e.Codigo
                        }))
                      }
                      loading={false}
                      disabled={false}
                      disabledVirtualized
                      errors={errors}
                    />
                  </Row>
                </div>
                <Row>
                  <Col>
                    <Button className='w-100' type='submit'>{languageTranslate.btnSiguiente}</Button>
                  </Col>
                </Row>
              </Form>
            )
          }
        </Formik>
      </Modal.Body>
    </Modal>
  )
}
