import { createSlice } from '@reduxjs/toolkit'
import { DtoObras } from './DtoObras'

interface ISliceReplanteo {
    textSeach: string
    obras: DtoObras[]
    obra: DtoObras | null
}

const initialState: ISliceReplanteo = {
    textSeach: '',
    obras: [],
    obra: null
}

const replanteoSlice = createSlice({
    name: 'replanteoSlice',
    initialState,
    reducers: {
        changeTextSeach: (state, action) => {
            return {
                ...state,
                textSeach: action.payload,
            }
        },
        changeObras: (state, action) => {
            return {
                ...state,
                obras: action.payload
            }
        },
        changeObra: (state, action) => {
            return {
                ...state,
                obra: action.payload
            }
        }
    },
})

export const {
    changeTextSeach,
    changeObras,
    changeObra,
} = replanteoSlice.actions

export default replanteoSlice.reducer