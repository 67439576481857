import { Children, useEffect, useState } from 'react'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import { DtoTrabajosDE } from '../../Trabajos/Domain/DtoTrabajosDE'
import { DtoValorizacion } from '../../../Trabajos/Asignacion/Domain/DtoValorizacion'
import { EmptyData } from '../../../../shared/Components/ReactBootstrap/EmptyData/EmptyData'
import { Button } from 'react-bootstrap'
import { typeEstadosAddressID } from '../../Shared/Domain'

interface iProps {
  trabajo: DtoTrabajosDE
  cambiarEstadoHPList: (campo: typeEstadosAddressID) => Promise<void>
}

export const ValorizacionesTroncal = (props: iProps) => {

  const { trabajo, cambiarEstadoHPList } = props
  const langTranslate = LanguageTranslate()

  const [data, setData] = useState<DtoValorizacion[]>([])
  const [disabled_oc, setDisabled_oc] = useState<boolean>(true)
  const [disabled_sop, setDisabled_sop] = useState<boolean>(true)
  const [disabled_asph, setDisabled_asph] = useState<boolean>(true)

  useEffect(() => {
    const data = trabajo.Ultima_PreLiquidacion.Valorizacion
      .filter((curr) =>
        curr.HomePass[0].ClientType === 'Troncal' &&
        [
          'PRODUCCION', 'CERTIFICADA', 'PRELIQUIDADA', 'LIQUIDADA', 'RECHAZADA POR DOCUMENTACION'
        ].includes(curr.Ultimo_Estado_Interno.Descripcion)
      )
    setData(data)
    setDisabled_oc(trabajo.Ultima_PreLiquidacion.OC?.Estado ?? true)
    setDisabled_sop(trabajo.Ultima_PreLiquidacion.Soplado?.Estado ?? true)
    setDisabled_asph(trabajo.Ultima_PreLiquidacion.Asfaltado?.Estado ?? true)
  }, [trabajo])

  if (!data.length) return <EmptyData />

  return (
    <div className='d-flex flex-column gap-3'>
      <div className='d-flex gap-2'>
        <Button
          key='OC'
          disabled={disabled_oc}
          onClick={async () => await cambiarEstadoHPList('OC')}
        >
          OC
        </Button>
        <Button
          key='SOP'
          disabled={disabled_sop}
          onClick={async () => await cambiarEstadoHPList('SOP')}
        >
          SOP
        </Button>
        <Button
          key='ASPH'
          disabled={disabled_asph}
          onClick={async () => await cambiarEstadoHPList('ASPH')}
        >
          ASPH
        </Button>
      </div>
      <table className='table table-striped' style={{ backgroundColor: 'gray' }}>
        <thead>
          <tr>
            <th scope='col'>{langTranslate.general.Codigo}</th>
            <th scope='col'>{langTranslate.general.Descripcion}</th>
            <th scope='col' className='text-center'>{langTranslate.general.Cantidad}</th>
            <th scope='col' className='text-center'>{langTranslate.general.Estado}</th>
          </tr>
        </thead>
        <tbody>
          {
            Children.toArray(
              data.map(e =>
                <tr>
                  <td>{e.DetalleChile.ManoObra.Codigo}</td>
                  <td>{e.DetalleChile.ManoObra.Nombre}</td>
                  <td className='text-center'>{e.Cantidad.toFixed(2)}</td>
                  <td className='text-center' style={{ fontSize: 9 }}>{e.Ultimo_Estado_Interno.Descripcion}</td>
                </tr>
              )
            )
          }
        </tbody>
      </table>
    </div>
  )
}
