import { RepositoryMain } from '../Domain/RepositoryMain'
import { RepositoryImplGenerico } from '../../../../shared/Infraestructure/RepositoryImplGenerico'
import { AdapterConfigure } from './AdapterConfigure'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { DtoAddressList, DtoObrasDE, DtoTrabajosDE } from '../../Trabajos/Domain/DtoTrabajosDE'
import { DtoReqSelectTrabajoDE } from '../../Trabajos/Domain/DtoReqSelectTrabajoDE'
import { DtoReqUpdateAddressList } from '../../../../shared/Domain/Dto/DtoReqUpdateAddressList'
import { FTPService } from '../../../../shared/Infraestructure/AdapterFileSystem'
import { DtoDocumentacionPEX } from '../../../../../app/Domain/DtoDocumentacionPEX'
import { DtoReqUpdateObraDE } from '../../../../shared/Domain/Dto/DtoReqUpdateObraDE'
import { addCountProcess } from '../../../../shared/Infraestructure/SliceGenerico'
import { DtoResponseSelectStockPersonal } from '../../../../Master/Home/Domain/DtoResponseSelectStockPersonal'
import { DtoDocumentacionBaremoPEX } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { DtoItems } from '../../../../../app/Domain/DtoItems'
import { DtoManoObraGlobal } from '../../../../../app/Domain/DtoManoObraGlobal'
import { DtoStockAlmacen } from '../../../../Master/Home/Domain/DtoStockAlmacen'
import { DtoConsumoMaterial } from '../../../../Master/Home/Domain/DtoConsumoMaterial'

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

  async selectTrabajo(params: DtoReqSelectTrabajoDE, user: EntityDataUsuario, ID_Trabajo: number): Promise<DtoTrabajosDE[]> {

    const url: string = `${this.urlBase}${AdapterConfigure.SELECT_TRABAJOS}`

    let response: Array<any> = navigator.onLine
      ? await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'text', {})
      : await this.selectTrabajoLocal(user, ID_Trabajo)
    response = typeof response === 'string'
      ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response
      : response
    response = Array.isArray(response) ? response : [response]
    return response
  }

  async selectTrabajoLocal(user: EntityDataUsuario, ID_Trabajo: number): Promise<DtoTrabajosDE> {
    const trabajo = await this.dbLocal.selectByIndexStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_Trabajos`, value: ID_Trabajo })
    return trabajo
  }

  async updateAddressState(params: DtoReqUpdateAddressList): Promise<DtoAddressList[]> {

    const url: string = `${this.urlBase}${AdapterConfigure.UPDATE_ADDRESSLIST}`

    const response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'json', {})
    return typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response

  }

  async updateAddressList(params: DtoReqUpdateAddressList, files: File[], usuario: EntityDataUsuario, trabajo: DtoTrabajosDE): Promise<DtoAddressList[]> {

    const url: string = `${this.urlBase}${AdapterConfigure.UPDATE_ADDRESSLIST}`

    const response: Array<any> = navigator.onLine ?
      await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'json', {}) :
      await this._saveLocalAddressList(params, files, usuario, trabajo)
    return typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response

  }

  private async _saveLocalAddressList(params: DtoReqUpdateAddressList, files: File[], usuario: EntityDataUsuario, trabajo: DtoTrabajosDE): Promise<any[]> {

    if (!Array.isArray(params.Body)) return []

    const nameStore = `${usuario.Pais.Nombre}_${usuario.Delegacion.Codigo}_AddressList`
    const nameStoreTrabajo = `${usuario.Pais.Nombre}_${usuario.Delegacion.Codigo}_Trabajos`

    const addressList = await this.dbLocal.selectByIndexStore({ nameStore, value: params.Body[0].ID_AddressList })

    const data = {
      ...params.Body[0],
      StatusOffline: { Estado: 'No Enviado' },
      dataSend: { params, archivos: addressList ? [...files, ...addressList.dataSend.archivos] : files }
    }

    const response = await this.dbLocal.insertDataStore({ nameStore, data })

    if (!addressList) this.dispatch(addCountProcess())


    const indexToUpdate = trabajo.ColeccionAddressList.findIndex(al => al.ID_AddressList === response.ID_AddressList)
    if (indexToUpdate !== -1) trabajo.ColeccionAddressList.splice(indexToUpdate, 1, response)

    await this.dbLocal.insertDataStore({ nameStore: nameStoreTrabajo, data: trabajo })

    return [response]

  }

  async updateObra(params: DtoReqUpdateObraDE, files: File[], usuario: EntityDataUsuario, trabajo: DtoTrabajosDE): Promise<DtoObrasDE[]> {

    const url: string = `${this.urlBase}${AdapterConfigure.UPDATE_OBRA}`

    const response: Array<any> = navigator.onLine ?
      await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'json', {}) :
      await this._saveLocalObra(params, files, usuario, trabajo)
    return typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response

  }

  private async _saveLocalObra(params: DtoReqUpdateObraDE, files: File[], usuario: EntityDataUsuario, trabajo: DtoTrabajosDE): Promise<any[]> {

    if (Array.isArray(params.Body)) return []

    const nameStore = `${usuario.Pais.Nombre}_${usuario.Delegacion.Codigo}_Obras`
    const nameStoreTrabajo = `${usuario.Pais.Nombre}_${usuario.Delegacion.Codigo}_Trabajos`

    const obra: DtoObrasDE = await this.dbLocal.selectByIndexStore({ nameStore, value: params.Body.ID_incidencia })

    const data = {
      ...params.Body,
      StatusOffline: { Estado: 'No Enviado' },
      dataSend: { params, archivos: obra ? [...files, ...obra.dataSend.archivos] : files }
    }

    const response = await this.dbLocal.insertDataStore({ nameStore, data: data })

    if (!obra) this.dispatch(addCountProcess())

    const indexToUpdate = trabajo.ColeccionObras.findIndex(al => al.ID_incidencia === response.ID_incidencia)
    if (indexToUpdate !== -1) trabajo.ColeccionObras.splice(indexToUpdate, 1, response)

    await this.dbLocal.insertDataStore({ nameStore: nameStoreTrabajo, data: trabajo })

    return [response]

  }

  async updateTrabajoLocal(trabajoUpdated: DtoTrabajosDE, user: EntityDataUsuario): Promise<DtoTrabajosDE> {
    const nameStore = `${user.Pais.Nombre}_${user.Delegacion.Codigo}_Trabajos`
    await this.dbLocal.updateByIndexStore({ nameStore, value: trabajoUpdated })
    const trabajo = await this.dbLocal.selectByIndexStore({ nameStore, value: trabajoUpdated.ID_Trabajo })
    if (!trabajo) throw Error(`Error al obtener Trabajo`)
    return trabajo
  }

  async uploadFiles(files: File[]) {
    console.info(`¡Se subirán ${files.length} archivos!`)
    if (navigator.onLine) {
      for await (const file of files) {
        await FTPService.setFileToServer(file.name, file)
      }
      // await Promise.all(files.map(e => FTPService.setFileToServer(e.name, e)))
    }
  }

  async selectDocumentacionPEX(): Promise<DtoDocumentacionPEX[]> {
    return await this.dbLocal.selectAllStore('DocumentacionPEX')
  }

  public async selectStockPersonal(user: EntityDataUsuario): Promise<DtoResponseSelectStockPersonal[]> {
    const result: DtoResponseSelectStockPersonal[] = await this.dbLocal.selectAllStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockPersonal`)
    return result.filter(e => e.FlagPex === 1)
  }

  public async selectConsumoMaterial(user: EntityDataUsuario): Promise<DtoConsumoMaterial[]> {
    return await this.dbLocal.selectAllStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_ConsumoMaterial`)
  }

  public async selectItems(): Promise<DtoItems[]> {
    return await this.dbLocal.selectAllStore('Items')
  }

  public async selectManoObraGlobal(): Promise<DtoManoObraGlobal[]> {
    return await this.dbLocal.selectAllStore('ManoObraGlobal')
  }

  public async selectDocumentacionBaremoPEX(): Promise<DtoDocumentacionBaremoPEX[]> {
    return await this.dbLocal.selectAllStore('DocumentacionBaremoPEX')
  }

  public async selectEstadoInterno(): Promise<any[]> {
    return await this.dbLocal.selectAllStore('EstadoInterno')
  }

  public async selectPrecioEspecialidad(): Promise<any[]> {
    return await this.dbLocal.selectAllStore('PrecioEspecialidad')
  }

  async selectPrecioMaterial(): Promise<any[]> {
    return await this.dbLocal.selectAllStore('PrecioMaterial')
  }

  public async selectStockAlmacen(user: EntityDataUsuario): Promise<DtoStockAlmacen[]> {
    return await this.dbLocal.selectAllStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockAlmacen`)
  }


}