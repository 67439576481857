import { DtoDocumentacionBaremoPEX } from '../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { EntityParams } from '../Domain/EntityParams'
import { RepositoryMain } from '../Domain/RepositoryMain'

export class UseCaseSelectDocumentacionBaremoPEX {
    private repository: RepositoryMain

    constructor(_repository: RepositoryMain) {
        this.repository = _repository
    }

    public async exec(params: EntityParams): Promise<DtoDocumentacionBaremoPEX[]> {
        return await this._exec(params)
    }

    private async _exec(params: EntityParams): Promise<DtoDocumentacionBaremoPEX[]> {
        return await this.repository.selectDocumentacionBaremoPEX(params)
    }
}