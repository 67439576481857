import { Children } from 'react'
import { Container } from '../../../../shared/Components/Container/Container'
import { ElementBreadCrumb } from '../../../../shared/Components/ElementBreadCrumb'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import { DtoTrabajosDE } from '../../Trabajos/Domain/DtoTrabajosDE'
import { DtoData } from '../Domain/DtoData'
import { AdapterConfigure } from '../Infraestructure/AdapterConfigure'

interface props {
  trabajo: DtoTrabajosDE | null
  data: DtoData[]
}

export const ViewMain = ({ trabajo, data }: props) => {

  const languageTranslate = LanguageTranslate()

  return (
    <Container>
      <ElementBreadCrumb
        list={[
          {
            text: languageTranslate.moduloMain.textoProgramados,
            navigate: true,
            path: `${AdapterConfigure.ROUTE_PROGRAMADOS}`,
          },
          {
            text: languageTranslate.menu['004'].titulo,
            navigate: true,
            path: `${AdapterConfigure.ROUTE_TRABAJOS}`,
          },
          {
            text: trabajo?.ColeccionObras[0].OrdenTrabajo || '',
            navigate: false,
            path: '',
          },
        ]}
      />
      <h6 className='w-100 d-flex justify-content-center'>{languageTranslate.general.Presupuesto_Mano_de_Obra}</h6>
      <div className='table-responsive'>
        <table className='table table-striped'>
          <thead>
            <tr>
              <th scope='col'>{languageTranslate.general.Codigo}</th>
              <th scope='col'>{languageTranslate.general.Descripcion}</th>
              <th scope='col'>{languageTranslate.general.Cantidad}</th>
              <th scope='col'>{languageTranslate.general.Usado}</th>
            </tr>
          </thead>
          <tbody>
            {
              Children.toArray(data.map(item =>
                <tr>
                  <td>{item.ManoObra.Codigo}</td>
                  <td>{item.ManoObra.Nombre}</td>
                  <td style={{ textAlign: 'center' }}>{item.CantidadMO.toFixed(2)}</td>
                  <td style={{ textAlign: 'center' }}>{item.CantidadUsado.toFixed(2)}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </Container>
  )
}
