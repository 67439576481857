import { AdapterConfigureGenerico } from '../AdapterConfigureGenerico'
import createApiClient from './apiClient'
import { toBase64 } from './utils'
import imageCompression from 'browser-image-compression'

const setFileToServer = async (FileName: string, File: File): Promise<void> => {

    const lastIndex = FileName.indexOf('-')
    const directory = FileName.substring(0, lastIndex).split('_').filter((elem) => elem !== '').join(',')

    const fileToUpload = await handleImageUpload(File)

    const formData = new FormData()
    formData.append('directory', directory)
    formData.append('fileName', FileName)
    formData.append('user', AdapterConfigureGenerico.user_API_FILE as string)
    formData.append('File', fileToUpload)

    const headers = {
        'Authorization': 'Basic ' + toBase64(`${process.env.REACT_APP_User_Auth_FILE}:${process.env.REACT_APP_Pass_Auth_FILE}`),
        'request-decrypt-response': 'yes',
        'Content-Type': 'multipart/form-data',
    }

    const apiClient = createApiClient(AdapterConfigureGenerico.PATH_API_FILE as string, headers)
    try {
        return await apiClient.post('upload', formData)
    } catch (error) {
        throw error
    }
}

async function handleImageUpload(imageFile: File): Promise<File> {
    if (imageFile.size <= 400 * 1024) {
        return imageFile
    } else {
        const options = {
            maxSizeMB: 0.2,
            maxWidthOrHeight: 1680,
            useWebWorker: true,
        }
        return imageCompression(imageFile, options)
            .then(compressedFile => {
                return compressedFile
            })
            .catch(error => {
                console.error('Error al comprimir la imagen:', error)
                throw error
            })
    }
}

export { setFileToServer }