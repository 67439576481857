import { DtoPrecioMaterial } from "../../../../app/Domain/DtoPrecioMaterial";
import { EntityParams } from "../Domain/EntityParams";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseSelectPrecioMaterial {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: EntityParams): Promise<DtoPrecioMaterial[]> {
        return await this._exec(params);
    }

    private async _exec(params: EntityParams): Promise<DtoPrecioMaterial[]> {
        return await this.repository.selectPrecioMaterial(params);
    }
}