import { DtoDocumentacionPEX } from '../../../../../../app/Domain/DtoDocumentacionPEX'
import { DtoTrabajosDE } from '../../../Trabajos/Domain/DtoTrabajosDE'
import { generarModeloDocZanja } from './Processes'

class UseCaseProcessesDoc {

  generarModeloDocZanja(documentacion: DtoDocumentacionPEX, trabajo: DtoTrabajosDE) { return generarModeloDocZanja(documentacion, trabajo) }

}

export const UseCaseProcesses = new UseCaseProcessesDoc()