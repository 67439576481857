import { ElementBreadCrumb } from '../../../shared/Components/ElementBreadCrumb';
import { InputTextSearch } from '../../../shared/Components/ElementInputSearch';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import { DtoResponseSelectStockPersonal } from '../Domain/DtoResponseSelectStockPersonal';
import { ElementStockGenerico } from '../../../shared/Components/ElementStockGenerico/ElementStockGenerico';
import './Style.scss';
import { AdapterConfigure } from '../Infraestructure/AdapterConfigure';

interface PropsViewMain {
    stockPersonalAux: DtoResponseSelectStockPersonal[];
    onChange: (name: string, value: any) => void;
    formSearch: {
        values: any;
        touched: any;
        errors: any;
        handleBlur: Function;
    };
    params: { trabajo?: string }
};

// const animatedComponents = makeAnimated();
export const ViewMain = (props: PropsViewMain) => {
    const {
        onChange,
        formSearch,
        stockPersonalAux,
        params
    } = props;

    const languageTranslate = LanguageTranslate();

    return (

        <div className='ViewProfile'>
            <div className="container-fluid">
                {/** BLOQUE DE BREADCRUMB */}
                <ElementBreadCrumb list={
                    [
                        { text: languageTranslate.moduloMain.textoLogistica, navigate: true, path: '/main/logistica' },
                        { text: languageTranslate.general.Trabajos, navigate: true, path: '/main/logistica/trabajos' },
                        { text: `${languageTranslate.general.StockPersonal}`, navigate: false, path: `` }
                    ]
                } />

                <InputTextSearch
                    name='textSearch'
                    onChange={onChange}
                    values={formSearch.values}
                />

                {stockPersonalAux.map((row, index) => (
                    <ElementStockGenerico
                        key={index}
                        data={row.Item.Nombre}
                        data1={row.Item.Codigo}
                        data2={row.OrdenTrabajo}
                        data3={row.Lote === '' ? '-' : row.Lote}
                        data4={row.Stock}
                        text1={languageTranslate.moduloLogistica.stockPersonal.textCodigo}
                        text2={languageTranslate.moduloLogistica.stockPersonal.textObra}
                        text3={languageTranslate.moduloLogistica.stockPersonalTotal.textLote}
                        linkIcono1={`/${AdapterConfigure.ROUTE_STOCK}${params.trabajo}/${row.ID_StockPersonal}`}
                    />
                ))}
            </div>
        </div >
    )
};