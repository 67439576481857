import { Children, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { InputSelect } from '../../../../shared/Components/ElementInputsCostume'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import { typeMaterial } from '../Domain/types'
import { DtoFormAddMaterial } from '../Domain/DtoFormAddMaterial'
import { DtoMaterialesUtiRet } from '../../../Trabajos/Asignacion/Domain/DtoMaterialesUtiRet'
import { Button, Col, Row } from 'react-bootstrap'
import { InputNumber } from '../../../../shared/Components/ReactBootstrap/InputNumber/InputNumber'
import { Formik } from 'formik'
import * as yup from 'yup'
import { DtoValueOnSelect } from '../Domain/DtoValueOnSelect'
import { ShowAvailable } from './ShowAvailable'
import { DtoInitialValues, codMOPublico } from '../../Shared'
import { typeHPList } from '../../DocHP/Domain/typeHPList'
import {
  CodsMapeoMOMaterial,
  CodsMapeoMOMaterialSopladoClientes,
  CodsMapeoMOMaterialSopladoTroncal,
  codMOClientes_ReglaLiquidacion1,
  codMONoClientes_ReglaLiquidacion1,
  codMOTroncal_ReglaLiquidacion1,
  CodsMapeoMO_Material_Arquertas,
} from 'sigo-package'
import { DtoDataMateriales } from '../Domain/DtoDataMateriales'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
interface props {
  dataMateriales: DtoDataMateriales[]
  onChange: (name: string, value: any, materialesInstalado: DtoMaterialesUtiRet[]) => { dataAlmacen: DtoDataMateriales[], dataMateriales: DtoDataMateriales[] } | undefined
  materialesInstalado: DtoMaterialesUtiRet[]
  materialesRetirado: DtoMaterialesUtiRet[]
  setMaterialesRetirado: Dispatch<SetStateAction<DtoMaterialesUtiRet[]>>
  setMaterialesInstalado: Dispatch<SetStateAction<DtoMaterialesUtiRet[]>>
  valuesAux: DtoInitialValues,
  address?: {
    ID_AddressList: number
    Home_ID: string
    typeHPList: typeHPList
  }
  dataMaterialesAlmacen: DtoDataMateriales[]
}

export const AddedMaterial = ({
  dataMateriales,
  onChange,
  setMaterialesInstalado,
  setMaterialesRetirado,
  materialesInstalado,
  materialesRetirado,
  valuesAux,
  address,
  dataMaterialesAlmacen
}: props) => {

  const langTranslate = LanguageTranslate()
  const dataTipoMaterial: typeMaterial[] = ['Instalado', 'Retirado']
  const [reglaLiquidacion1, setReglaLiquidacion1] = useState<boolean>(false)
  useEffect(() => {
    //#region Troncal
    if (
      valuesAux.asignacion.value &&
      !address &&
      codMOTroncal_ReglaLiquidacion1.includes(valuesAux.asignacion.dataComplete.ManoObra.Codigo.trim())
    ) {
      setReglaLiquidacion1(true)
    } else {
      setReglaLiquidacion1(false)
    }
    //#endregion
  }, [valuesAux])

  const initialValues: DtoFormAddMaterial = {
    tipoMaterial: new DtoValueOnSelect(),
    material: new DtoValueOnSelect(),
    materialConector: new DtoValueOnSelect(),
    cantidad: 1,
    cantidadEmpalmes: 0
  }

  const schema = yup.object().shape({
    tipoMaterial: yup
      .object()
      .shape({ value: yup.string().min(1, 'Seleccione una opción') }),
    material: yup
      .object()
      .shape({ value: yup.string().min(1, 'Seleccione una opción') }),
    cantidad: yup.number().min(0.1, 'Ingrese una cantidad mayor a 0')
      .required('Campo es requerido')
      .test({
        test(value, ctx) {
          if (this.parent.tipoMaterial.dataComplete === 'Retirado') return true
          if (valuesAux.cantidad <= 0)
            return ctx.createError({ message: `Ingrese una cantidad mayor a 0 de la tarea` })
          if (value !== undefined) {
            const disponible = this.parent.material.dataComplete.CantidadDisponible
            const cantidad = value * (reglaLiquidacion1
              ? valuesAux.cantidad
              : 1)
            if (cantidad > disponible)
              return ctx.createError({ message: `Cantidad supera lo Disponible` })
          }
          return true
        }
      }),
    materialConector: yup
      .object()
      .shape({
        value: yup
          .string()
          .test({
            test(value, ctx) {
              if (value === undefined) return true
              if (this.options.context?.tipoMaterial.dataComplete === 'Retirado') return true
              if (!CodsMapeoMOMaterial
                .map(e => e.CodigoMaterial.trim())
                .includes(this.options.context?.material.dataComplete?.Codigo?.trim())) return true
              if (!reglaLiquidacion1) return true
              if (value === '')
                return ctx.createError({ message: `No se encontró material Conector/Empalme ${this.parent.dataComplete.Descripcion} asociado a Ducto o no dispone en su stock Almacén` })

              const mat = CodsMapeoMOMaterial.filter(e => e.CodigoMaterial === this.options.context?.material.dataComplete?.Codigo?.trim())
              if (mat.length !== 1) {
                return ctx.createError({ message: `No se encontró material Conector/Empalme ${this.parent.dataComplete.Descripcion} asociado a Ducto ${this.options.context?.material?.dataComplete?.Codigo}` })
              }
              const cantInformada = this.options.context?.cantidadEmpalmes * mat[0].CantidadPorDucto
              if (cantInformada > this.parent?.dataComplete?.CantidadDisponible)
                return ctx.createError({ message: `Cantidad supera lo Disponible` })
              return true
            }
          }),
      })
  })

  const deleteMaterial = (tipoMaterial: string, CodigoLlave: string) => {
    switch (tipoMaterial) {
      case 'Instalado':
        if (
          (address && address.typeHPList === 'Business' && codMONoClientes_ReglaLiquidacion1.includes(valuesAux.asignacion.dataComplete.ManoObra.Codigo.trim())) ||
          (address && address.typeHPList === 'Business' && codMOPublico.includes(valuesAux.asignacion.dataComplete.ManoObra.Codigo.trim())) ||
          (address && address.typeHPList === 'Residential' && codMOPublico.includes(valuesAux.asignacion.dataComplete.ManoObra.Codigo.trim())) ||
          (address && address.typeHPList === 'Residential' && codMOClientes_ReglaLiquidacion1.includes(valuesAux.asignacion.dataComplete.ManoObra.Codigo.trim())) ||
          (address && address.typeHPList === 'Residential' && CodsMapeoMOMaterialSopladoClientes.map(e => e.CodigoMO).includes(valuesAux.asignacion.dataComplete.ManoObra.Codigo.trim())) ||
          (
            !address &&
            CodsMapeoMOMaterialSopladoTroncal
              .filter(e => !e.ConsumoManual)
              .map(e => e.CodigoMO)
              .includes(valuesAux.asignacion.dataComplete.ManoObra.Codigo.trim())
          ) ||
          (!address && CodsMapeoMO_Material_Arquertas.map(e => e.CodigoMO).includes(valuesAux.asignacion.dataComplete.ManoObra.Codigo.trim()))
        ) {
          return
        }
        setMaterialesInstalado((prev) => prev.filter(e => e.CodigoLlave !== CodigoLlave))
        break
      case 'Retirado':
        setMaterialesRetirado((prev) => prev.filter(e => e.CodigoLlave !== CodigoLlave))
        break
    }
  }

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          try {
            values.material.dataComplete.Cantidad = values.cantidad
            values.material.dataComplete.CantidadInformada = values.cantidad
            delete (values.material.dataComplete as any).CantidadDisponible
            delete (values.material.dataComplete as any).Lote
            delete (values.material.dataComplete as any).Bobina
            delete (values.materialConector.dataComplete as any).CantidadDisponible
            delete (values.materialConector.dataComplete as any).Lote
            delete (values.materialConector.dataComplete as any).Bobina
            switch (values.tipoMaterial.dataComplete) {
              case 'Instalado':
                if (reglaLiquidacion1)
                  values.material.dataComplete.Cantidad = values.cantidad * valuesAux.cantidad
                if (values.materialConector.value) {
                  const mat = CodsMapeoMOMaterial.filter(e => e.CodigoMaterial === values.material.dataComplete.Codigo.trim())
                  if (mat.length !== 1) throw Error(`¡No se pudo obtener ducto ${values.material.dataComplete.Codigo}`)
                  values.materialConector.dataComplete.Cantidad = values.cantidadEmpalmes * mat[0].CantidadPorDucto
                  values.materialConector.dataComplete.Motivo.Codigo = values.material.value.toString().trim()
                  values.materialConector.dataComplete.Motivo.Descripcion = 'CONSUMO_MATERIAL_AUTOMATICO_DUCTOS'
                }
                setMaterialesInstalado((prev) => {
                  return values.materialConector.value && values.cantidadEmpalmes > 0
                    ? [...prev, values.material.dataComplete, values.materialConector.dataComplete]
                    : [...prev, values.material.dataComplete]
                })
                break
              case 'Retirado':
                setMaterialesRetirado((prev) => [...prev, values.material.dataComplete])
                break
              default:
                break
            }
            resetForm()
          } catch (error) {
            console.error(error)
            AdapterGenerico.createMessage('¡Alerta!', (error as Error).message, 'error')
          }
        }}
      >
        {
          ({ values, handleChange, errors, handleSubmit, touched, setFieldValue }) => (
            <div>
              {
                (
                  !(
                    (address && address.typeHPList === 'Business' && codMONoClientes_ReglaLiquidacion1.includes(valuesAux.asignacion.dataComplete.ManoObra.Codigo.trim())) ||
                    (address && address.typeHPList === 'Business' && codMOPublico.includes(valuesAux.asignacion.dataComplete.ManoObra.Codigo.trim())) ||
                    (address && address.typeHPList === 'Residential' && codMOPublico.includes(valuesAux.asignacion.dataComplete.ManoObra.Codigo.trim())) || // Clientes
                    (address && address.typeHPList === 'Residential' && codMOClientes_ReglaLiquidacion1.includes(valuesAux.asignacion.dataComplete.ManoObra.Codigo.trim())) || // Clientes
                    (address && address.typeHPList === 'Residential' && CodsMapeoMOMaterialSopladoClientes.map(e => e.CodigoMO).includes(valuesAux.asignacion.dataComplete.ManoObra.Codigo.trim())) || // Clientes
                    (
                      !address &&
                      CodsMapeoMOMaterialSopladoTroncal
                        // .filter(e => !e.ConsumoManual)
                        .map(e => e.CodigoMO)
                        .includes(valuesAux.asignacion.dataComplete.ManoObra.Codigo.trim())
                    ) ||
                    (!address && CodsMapeoMO_Material_Arquertas.map(e => e.CodigoMO).includes(valuesAux.asignacion.dataComplete.ManoObra.Codigo.trim()))
                  )
                ) &&
                <>
                  <InputSelect
                    label={langTranslate.general.TipoMaterial}
                    name='tipoMaterial'
                    onChange={(name, value) => {
                      handleChange({ target: { name, value } })
                      onChange(name, value, materialesInstalado)
                    }}
                    values={values}
                    isRequired
                    options={dataTipoMaterial.map(e => ({
                      label: e === 'Retirado' ? 'Chatarra' : e,
                      value: e,
                      dataComplete: e
                    }))}
                    errors={errors}
                    touched={touched}
                  />
                  {
                    values.tipoMaterial.value &&
                    <Row>
                      <InputSelect
                        label={reglaLiquidacion1 &&
                          values.tipoMaterial.dataComplete === 'Instalado' &&
                          CodsMapeoMOMaterial.find(e => e.CodigoMaterial.trim() === values.material.dataComplete?.Codigo?.trim())
                          ? langTranslate.general.Ducto : langTranslate.general.Material}
                        name='material'
                        onChange={async (name, value) => {
                          if (reglaLiquidacion1 && values.tipoMaterial.dataComplete === 'Instalado') {
                            const mat = CodsMapeoMOMaterial.find(e => e.CodigoMaterial === value.dataComplete.Codigo.trim())
                            if (mat) {
                              const matAux = dataMaterialesAlmacen.find(e => e.Codigo === mat.CodMatConector)
                              if (matAux) {
                                await setFieldValue('materialConector', {
                                  label: `${matAux.Codigo} ${matAux.Descripcion} ${matAux.Lote ? `- Lote: ${matAux.Lote}` : ''} ${matAux.Bobina ? `- Bobina: ${matAux.Bobina}` : ''}`,
                                  value: matAux.CodigoLlave.trim(),
                                  dataComplete: matAux
                                })
                              } else {
                                const item: DtoValueOnSelect<DtoDataMateriales> = new DtoValueOnSelect()
                                item.dataComplete.Descripcion = mat.CodMatConector
                                await setFieldValue('materialConector', item)
                              }
                            } else {
                              const item: DtoValueOnSelect<DtoDataMateriales> = new DtoValueOnSelect()
                              await setFieldValue('materialConector', item)
                            }
                          }
                          handleChange({ target: { name, value } })
                        }}
                        values={values}
                        isRequired
                        options={dataMateriales
                          .map(e => ({
                            label: `${e.Codigo} - ${e.Descripcion} ${e.Lote ? `- Lote: ${e.Lote}` : ''} ${e.Bobina ? `- Bobina: ${e.Bobina}` : ''}`.trim(),
                            value: e.CodigoLlave,
                            dataComplete: e
                          }))
                          .filter(e => !new Set([
                            ...materialesInstalado.map(e => e.CodigoLlave),
                            ...materialesRetirado.map(e => e.CodigoLlave)
                          ]).has(e.value))
                        }
                        errors={errors}
                        touched={touched}
                      />
                    </Row>
                  }
                  {
                    (values.tipoMaterial.dataComplete === 'Instalado' && values.material.value) &&
                    <ShowAvailable cantidadDisponible={values.material.dataComplete.CantidadDisponible} />
                  }
                  {
                    (reglaLiquidacion1
                      && CodsMapeoMOMaterial.find(e => e.CodigoMaterial.trim() === values.material?.dataComplete?.Codigo?.trim())
                      && values.tipoMaterial.dataComplete === 'Instalado'
                    ) &&
                    <InputSelect
                      label={langTranslate.general.Conectores__Empalmes}
                      name='materialConector'
                      onChange={async (name, value) => { handleChange({ target: { name, value } }) }}
                      values={values}
                      isRequired
                      options={dataMaterialesAlmacen
                        .map(e => ({
                          label: `${e.Codigo} - ${e.Descripcion} ${e.Lote ? `- Lote: ${e.Lote}` : ''} ${e.Bobina ? `- Bobina: ${e.Bobina}` : ''}`,
                          value: e.CodigoLlave,
                          dataComplete: e
                        }))
                      }
                      errors={errors}
                      touched={touched}
                      disabled
                    />
                  }
                  {
                    (reglaLiquidacion1 && values.tipoMaterial.dataComplete === 'Instalado' && values.materialConector.value) &&
                    <ShowAvailable cantidadDisponible={values.materialConector.dataComplete.CantidadDisponible} />
                  }
                  {
                    values.tipoMaterial.value &&
                    <>
                      <Row className='mb-2'>
                        <Col xs='6'>
                          <InputNumber
                            label={
                              values.tipoMaterial.dataComplete === 'Instalado'
                                && reglaLiquidacion1 && values.materialConector.value
                                ? langTranslate.general.Cantidad_Ductos
                                : langTranslate.general.Cantidad
                            }
                            name='cantidad'
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                            type='number'
                          />
                        </Col>
                        <Col xs='6'>
                          {
                            (values.tipoMaterial.dataComplete === 'Instalado'
                              && reglaLiquidacion1 && values.materialConector.value) &&
                            <InputNumber
                              label={langTranslate.general.Empalmes}
                              name='cantidadEmpalmes'
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}
                              type='number'
                            />
                          }
                        </Col>
                      </Row>
                      <Row>
                        <Col xs='12' className='d-flex flex-column justify-content-center'>
                          <Button onClick={(e) => handleSubmit()} className='btn-success text-white'>
                            <i className='fa-solid fa-plus'></i>
                          </Button>
                        </Col>
                      </Row>
                    </>
                  }
                </>
              }
              {
                (materialesInstalado.length || materialesRetirado.length) ?
                  <Row>
                    <table className='table table-striped' style={{ backgroundColor: 'gray' }}>
                      <thead>
                        <tr>
                          <th scope='col'>{langTranslate.general.Cod}</th>
                          <th scope='col'>{langTranslate.general.Desc}</th>
                          <th scope='col'>{langTranslate.general.Cant}</th>
                          <th scope='col'>{langTranslate.general.TipoMaterial}</th>
                          <th scope='col' style={{ textAlign: 'center' }}>x</th>
                        </tr>
                      </thead>
                      <tbody>
                        {

                          Children.toArray(
                            [
                              ...materialesInstalado.map(e => ({ ...e, Tipo: 'Instalado' })),
                              ...materialesRetirado.map(e => ({ ...e, Tipo: 'Retirado' })),
                            ].map(e =>
                              <tr>
                                <td>{e.Codigo}</td>
                                <td>{e.Descripcion}</td>
                                <td>{e.Cantidad}</td>
                                <td>{e.Tipo}</td>
                                <td
                                  scope='row'
                                  onClick={() => deleteMaterial(e.Tipo, e.CodigoLlave)}
                                  style={{ verticalAlign: 'middle', textAlign: 'center' }}
                                >
                                  <i className='fa-solid fa-trash' style={{ fontSize: 15, color: 'red' }} />
                                </td>
                              </tr>
                            )
                          )
                        }
                      </tbody>
                    </table>
                  </Row>
                  : null
              }
            </div>
          )
        }
      </Formik>
    </>
  )
}
