import { ElementBreadCrumb } from '../../../shared/Components/ElementBreadCrumb';
import ElementContenedorGenerico from "../../../shared/Components/Contenedor/ElementContenedorGenerico";
import GridDetalleStockPersonal from "../Components/GridDetalleStockPersonal";
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import { DtoResponseSelectStockPersonal } from '../Domain/DtoResponseSelectStockPersonal';
import './Style.scss';

interface PropsViewMain {
  detalleStockPersonal: DtoResponseSelectStockPersonal;
  codigoLlave: string;
};

// const animatedComponents = makeAnimated();
export const ViewMain = (props: PropsViewMain) => {
  const {
    detalleStockPersonal,
  } = props;

  const languageTranslate = LanguageTranslate();

  return (
    <div className='ViewProfile'>
      <div className="container-fluid">
        {/** BLOQUE DE BREADCRUMB */}
        <ElementBreadCrumb list={[
          { text: languageTranslate.moduloMain.textoLogistica, navigate: true, path: '/main/logistica' },
          { text: languageTranslate.menu['053'].titulo, navigate: true, path: '/main/logistica/stockPersonalTotal' },
          { text: props.codigoLlave, navigate: false, path: '' }
        ]} />
        <ElementContenedorGenerico
          title={languageTranslate.moduloLogistica.stockPersonalTotal.detalleStockPersonalTotal.titulo}
        >
          <GridDetalleStockPersonal
            item={detalleStockPersonal}
          />
        </ElementContenedorGenerico>
      </div>
    </div >
  )
};