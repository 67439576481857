import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ViewMain } from "./Components/View";
import ViewModal from './Components/ViewModal';
import ImageViewer from '../../../shared/Components/ImageViewer/ImageViewer';
// import ViewModal from '../../../shared/Components/ViewModal/ViewModal';

const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
        controller.init();
        return () => { }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <ViewMain
                trabajo={controller.trabajo}
                setModalData={controller.setModalData}
            />
            <ViewModal
                modalData={controller.modalData}
                setModalData={controller.setModalData}
                disabledButton={false}
                documentacionBaremoPEX={controller.documentacionBaremoPEX}
                setShowImageViewer={controller.setShowImageViewer}
                setFileImageViewer={controller.setFileImageViewer}
                setNewFilesUpload={controller.setNewFilesUpload}
                newFilesUpload={controller.newFilesUpload}
                trabajo={controller.trabajo}
                saveFiles={controller.saveFiles}
                showButtonSave={controller.showButtonSave}
                uploadFile={controller.uploadFile}
            />
            {
                controller.fileImageViewer &&
                <ImageViewer
                    show={controller.showImageViewer}
                    file={controller.fileImageViewer}
                    setShow={controller.setShowImageViewer}
                    setFile={controller.setFileImageViewer}
                />
            }
        </>
    );
};

export default ManagerEntity;