import { AdapterConfigure } from './AdapterConfigure';
import { RepositoryImplMain } from './RepositoryImplMain';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { addLoading, hideIconMenu, removeLoading } from '../../../shared/Infraestructure/SliceGenerico';
import { AdapterStorage } from '../../../shared/Infraestructure/AdapterStorage';
import { EntityInformationDataInitial } from '../../../shared/Domain/EntityInformationDataInitial';
import { EntityParams } from '../Domain/EntityParams';
import { useState } from 'react';
import { UseCaseSelectPais } from '../Application/UseCaseSelectPais';
import { UseCaseSelectPersonal } from '../Application/UseCaseSelectPersonal';
import { UseCaseSelectEstadoInterno } from '../Application/UseCaseSelectEstadoInterno';
import { UseCaseSelectCuadrillas } from '../Application/UseCaseSelectCuadrillas';
import * as Pipelines from '../../../shared/Infraestructure/Pipelines';
import { UseCaseSelectItems } from '../Application/UseCaseSelectItems';
import { UseCaseSelectDocumentacionBaremoPEX } from '../Application/UseCaseSelectDocumentacionBaremoPEX';
import { UseCaseSelectPrecioMaterial } from '../Application/UseCaseSelectPrecioMaterial';
import { UseCaseSelectManoObraGlobal } from '../Application/UseCaseSelectManoObraGlobal';
import { UseCaseSelectPrecioEspecialidad } from '../Application/UseCaseSelectPrecioEspecialidad';
import { UseCaseSelectContratoOT } from '../Application/UseCaseSelectContratoOT';
import { UseCaseSelectDocumentacionPEX } from '../Application/UseCaseSelectDocumentacionPEX';

export const Controller = () => {
    const { websocket, dbLocal } = useSelector((state: RootState) => state.generico);
    const { user } = useSelector((state: RootState) => state.auth);
    const dispatch: Dispatch = useDispatch();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    let { servicesCalleds }: { servicesCalleds: EntityInformationDataInitial | undefined } = AdapterStorage.get('servicesCalleds');
    let dataInitial = !servicesCalleds ? {
        Pais: { called: false, count: 0, date: null },
        Personal: { called: false, count: 0, date: null },
        EstadoInterno: { called: false, count: 0, date: null },
        Cuadrillas: { called: false, count: 0, date: null },
        Items: { called: false, count: 0, date: null },
        DocumentacionBaremoPEX: { called: false, count: 0, date: null },
        DocumentacionPEX: { called: false, count: 0, date: null },
        ManoObraGlobal: { called: false, count: 0, date: null },
        PrecioMaterial: { called: false, count: 0, date: null },
        PrecioEspecialidad: { called: false, count: 0, date: null },
        ContratoOT: { called: false, count: 0, date: null },
    } as EntityInformationDataInitial : servicesCalleds;

    dataInitial.Pais.date = dataInitial.Pais.called ? new Date(dataInitial.Pais.date as string) : dataInitial.Pais.date;
    dataInitial.Personal.date = dataInitial.Personal.called ? new Date(dataInitial.Personal.date as string) : dataInitial.Personal.date;
    dataInitial.EstadoInterno.date = dataInitial.EstadoInterno.called ? new Date(dataInitial.EstadoInterno.date as string) : dataInitial.EstadoInterno.date;
    dataInitial.Cuadrillas.date = dataInitial.Cuadrillas.called ? new Date(dataInitial.Cuadrillas.date as string) : dataInitial.Cuadrillas.date;
    dataInitial.Items.date = dataInitial.Items.called ? new Date(dataInitial.Items.date as string) : dataInitial.Items.date;
    dataInitial.DocumentacionBaremoPEX.date = dataInitial.DocumentacionBaremoPEX.called ? new Date(dataInitial.DocumentacionBaremoPEX.date as string) : dataInitial.DocumentacionBaremoPEX.date;
    dataInitial.DocumentacionPEX.date = dataInitial.DocumentacionPEX.called ? new Date(dataInitial.DocumentacionPEX.date as string) : dataInitial.DocumentacionPEX.date;
    dataInitial.PrecioMaterial.date = dataInitial.PrecioMaterial.called ? new Date(dataInitial.PrecioMaterial.date as string) : dataInitial.PrecioMaterial.date;
    dataInitial.ManoObraGlobal.date = dataInitial.ManoObraGlobal.called ? new Date(dataInitial.ManoObraGlobal.date as string) : dataInitial.ManoObraGlobal.date;
    dataInitial.PrecioEspecialidad.date = dataInitial.PrecioEspecialidad.called ? new Date(dataInitial.PrecioEspecialidad.date as string) : dataInitial.PrecioEspecialidad.date;
    dataInitial.ContratoOT.date = dataInitial.ContratoOT.called ? new Date(dataInitial.ContratoOT.date as string) : dataInitial.ContratoOT.date;

    const [informationDataInitial, setInformationDataInitial] = useState<EntityInformationDataInitial>(dataInitial);

    const init = async () => {
        dispatch(hideIconMenu());
    };

    const onClickLoadCatalogo = async (name: string) => {
        try {
            dispatch(addLoading({ textLoading: 'Cargando...' }));

            let params: EntityParams = {
                Pais: Pipelines.FiltroPais,
                Cuadrillas: Pipelines.FiltroCuadrillas,
                Personal: Pipelines.FiltroPersonal,
                EstadoInterno: Pipelines.FiltroEstadoInterno,
                PrecioMaterial: Pipelines.FiltroPrecioMaterial,
                ManoObraGlobal: Pipelines.FiltroManoObraGlobal,
                Items: Pipelines.FiltroItems,
                DocumentacionBaremoPEX: Pipelines.FiltroDocumentacionBaremoPEX,
                DocumentacionPEX: Pipelines.FiltroDocumentacionPEX,
                PrecioEspecialidad: Pipelines.FiltroPrecioEspecialidad,
                ContratoOT: Pipelines.FiltroPrecioEspecialidad,
            };

            let data: Array<any> = [];
            let newData = { ...informationDataInitial };

            let match: any;
            let count: number = 0;

            switch (name) {
                case 'Pais':
                    count = await dbLocal.countStore(name);
                    if (informationDataInitial[name].called) {
                        let fecha: Date = informationDataInitial[name].date === null || count === 0
                            ? new Date('1900-01-01')
                            : typeof informationDataInitial[name].date === 'string'
                                ? new Date(informationDataInitial[name].date as string)
                                : informationDataInitial['Pais'].date as Date;

                        // match = { $match: { $or: [{ "Procesos.Registrar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Modificar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Eliminar.Fecha": { $gt: fecha.toISOString() } }] } };
                        // params[name].Filtros.splice(0, 0, match);
                    }
                    data = await new UseCaseSelectPais(repository).exec(params);
                    count += data.length;
                    newData = { ...newData, Pais: { called: true, count, date: new Date() } };
                    break;
                case 'Personal':
                    count = await dbLocal.countStore(name);
                    if (informationDataInitial[name].called) {
                        let fecha: Date = informationDataInitial[name].date === null || count === 0
                            ? new Date('1900-01-01')
                            : typeof informationDataInitial[name].date === 'string'
                                ? new Date(informationDataInitial[name].date as string)
                                : informationDataInitial['Pais'].date as Date;
                    }
                    data = await new UseCaseSelectPersonal(repository).exec(params);
                    count += data.length;
                    newData = { ...newData, Personal: { called: true, count, date: new Date() } };
                    break;
                case 'EstadoInterno':
                    count = await dbLocal.countStore(name);
                    if (informationDataInitial[name].called) {
                        let fecha: Date = informationDataInitial[name].date === null || count === 0
                            ? new Date('1900-01-01')
                            : typeof informationDataInitial[name].date === 'string'
                                ? new Date(informationDataInitial[name].date as string)
                                : informationDataInitial['Pais'].date as Date;
                    }
                    data = await new UseCaseSelectEstadoInterno(repository).exec(params);
                    count += data.length;
                    newData = { ...newData, EstadoInterno: { called: true, count, date: new Date() } };
                    break;
                case 'Cuadrillas':
                    count = await dbLocal.countStore(name);
                    if (informationDataInitial[name].called) {
                        let fecha: Date = informationDataInitial[name].date === null || count === 0
                            ? new Date('1900-01-01')
                            : typeof informationDataInitial[name].date === 'string'
                                ? new Date(informationDataInitial[name].date as string)
                                : informationDataInitial['Pais'].date as Date;
                    }
                    data = await new UseCaseSelectCuadrillas(repository).exec(params);
                    count += data.length;
                    newData = { ...newData, Cuadrillas: { called: true, count, date: new Date() } };
                    break;
                case 'Items':
                    count = await dbLocal.countStore(name);
                    if (informationDataInitial[name].called) {
                        let fecha: Date = informationDataInitial[name].date === null || count === 0
                            ? new Date('1900-01-01')
                            : typeof informationDataInitial[name].date === 'string'
                                ? new Date(informationDataInitial[name].date as string)
                                : informationDataInitial['Pais'].date as Date;
                    }
                    data = await new UseCaseSelectItems(repository).exec(params);
                    count += data.length;
                    newData = { ...newData, Items: { called: true, count, date: new Date() } };
                    break;
                case 'ContratoOT':
                    count = await dbLocal.countStore(name);
                    if (informationDataInitial[name].called) {
                        let fecha: Date = informationDataInitial[name].date === null || count === 0
                            ? new Date('1900-01-01')
                            : typeof informationDataInitial[name].date === 'string'
                                ? new Date(informationDataInitial[name].date as string)
                                : informationDataInitial['Pais'].date as Date;
                    }
                    data = await new UseCaseSelectContratoOT(repository).exec(params);
                    count += data.length;
                    newData = { ...newData, ContratoOT: { called: true, count, date: new Date() } };
                    break;
                case 'PrecioMaterial':
                    count = await dbLocal.countStore(name);
                    if (informationDataInitial[name].called) {
                        let fecha: Date = informationDataInitial[name].date === null || count === 0
                            ? new Date('1900-01-01')
                            : typeof informationDataInitial[name].date === 'string'
                                ? new Date(informationDataInitial[name].date as string)
                                : informationDataInitial['Pais'].date as Date;
                    }
                    data = await new UseCaseSelectPrecioMaterial(repository).exec(params);
                    count += data.length;
                    newData = { ...newData, PrecioMaterial: { called: true, count, date: new Date() } };
                    break;
                case 'ManoObraGlobal':
                    count = await dbLocal.countStore(name);
                    if (informationDataInitial[name].called) {
                        let fecha: Date = informationDataInitial[name].date === null || count === 0
                            ? new Date('1900-01-01')
                            : typeof informationDataInitial[name].date === 'string'
                                ? new Date(informationDataInitial[name].date as string)
                                : informationDataInitial['Pais'].date as Date;
                    }
                    data = await new UseCaseSelectManoObraGlobal(repository).exec(params);
                    count += data.length;
                    newData = { ...newData, ManoObraGlobal: { called: true, count, date: new Date() } };
                    break;
                case 'PrecioEspecialidad':
                    count = await dbLocal.countStore(name);
                    if (informationDataInitial[name].called) {
                        let fecha: Date = informationDataInitial[name].date === null || count === 0
                            ? new Date('1900-01-01')
                            : typeof informationDataInitial[name].date === 'string'
                                ? new Date(informationDataInitial[name].date as string)
                                : informationDataInitial['Pais'].date as Date;
                    }
                    data = await new UseCaseSelectPrecioEspecialidad(repository).exec(params);
                    count += data.length;
                    newData = { ...newData, PrecioEspecialidad: { called: true, count, date: new Date() } };
                    break;
                case 'DocumentacionBaremoPEX':
                    count = await dbLocal.countStore(name)
                    if (informationDataInitial[name].called) {
                        let fecha: Date = informationDataInitial[name].date === null || count === 0
                            ? new Date('1900-01-01')
                            : typeof informationDataInitial[name].date === 'string'
                                ? new Date(informationDataInitial[name].date as string)
                                : informationDataInitial['Pais'].date as Date
                    }
                    data = await new UseCaseSelectDocumentacionBaremoPEX(repository).exec(params)
                    count += data.length
                    newData = { ...newData, DocumentacionBaremoPEX: { called: true, count, date: new Date() } }
                    break;
                case 'DocumentacionPEX':
                    count = await dbLocal.countStore(name)
                    if (informationDataInitial[name].called) {
                        let fecha: Date = informationDataInitial[name].date === null || count === 0
                            ? new Date('1900-01-01')
                            : typeof informationDataInitial[name].date === 'string'
                                ? new Date(informationDataInitial[name].date as string)
                                : informationDataInitial['Pais'].date as Date
                    }
                    data = await new UseCaseSelectDocumentacionPEX(repository).exec(params)
                    count += data.length
                    newData = { ...newData, DocumentacionPEX: { called: true, count, date: new Date() } }
                    break;
                default:
                    break;
            }

            await dbLocal.clearStore(name)
            await dbLocal.insertDataStore({ nameStore: name, data });
            setInformationDataInitial(newData);
            AdapterStorage.set('servicesCalleds', newData);
            dispatch(removeLoading());
        } catch (error) {
            dispatch(removeLoading());
        }
    }

    const onClickLoadAll = async () => {
        try {
            // dispatch(addLoading({ textLoading: 'Cargando...' }));

            // let params: EntityParams = {
            //     Pais: { Filtros: [{ $match: { 'Estado.ID_Estado': 1 } }] },
            //     Personal: { Filtros: [{ $match: { 'Estado.ID_Estado': 1 } }] },
            //     EstadoInterno: { Filtros: [{ $match: { 'Estado.ID_Estado': 1 } }] },
            // };

            // let newData = { ...informationDataInitial };

            // let match: any;

            // if (informationDataInitial['Personal'].called) {
            //     let fecha: Date = informationDataInitial['Personal'].date === null ? new Date('1900-01-01') : typeof informationDataInitial['Personal'].date === 'string' ? new Date(informationDataInitial['Personal'].date) : informationDataInitial['Personal'].date;

            //     match = { $match: { $or: [{ "Procesos.Registrar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Modificar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Eliminar.Fecha": { $gt: fecha.toISOString() } }] } };
            //     params['Personal'].Filtros.splice(0, 0, match);
            // }

            // if (informationDataInitial['EstadoInterno'].called) {
            //     let fecha: Date = informationDataInitial['EstadoInterno'].date === null ? new Date('1900-01-01') : typeof informationDataInitial['EstadoInterno'].date === 'string' ? new Date(informationDataInitial['EstadoInterno'].date) : informationDataInitial['EstadoInterno'].date;

            //     match = { $match: { $or: [{ "Procesos.Registrar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Modificar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Eliminar.Fecha": { $gt: fecha.toISOString() } }] } };
            //     params['EstadoInterno'].Filtros.splice(0, 0, match);
            // }

            // const [pais, personal, estadoInterno] = await Promise.all([
            //     new UseCaseSelectPais(repository).exec(params),
            //     new UseCaseSelectPersonal(repository).exec(params),
            //     new UseCaseSelectEstadoInterno(repository).exec(params),
            // ]);

            // newData = {
            //     Pais: { called: true, count: pais.length, date: new Date() },
            //     Personal: { called: true, count: personal.length, date: new Date() },
            //     EstadoInterno: { called: true, count: estadoInterno.length, date: new Date() },
            //     Cuadrillas: { called: true, count: estadoInterno.length, date: new Date() },
            // };

            // // await dbLocal.clearStore(name);
            // await dbLocal.insertDataStore([
            //     { nameStore: 'Pais', data: pais },
            //     { nameStore: 'Personal', data: personal },
            //     { nameStore: 'Actividad', data: estadoInterno },
            // ]);
            // setInformationDataInitial(newData);
            // AdapterStorage.set('servicesCalleds', newData);
            // dispatch(removeLoading());
        } catch (error) {
            dispatch(removeLoading());
        }
    }

    return {
        init,

        informationDataInitial,

        onClickLoadCatalogo,
        onClickLoadAll
    };
}