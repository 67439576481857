import { DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'

export const nodos: DtoNodoDoc[] = [
  {
    CantidadMax: 0,
    CantidadMin: 0,
    Children: [],
    Codigo: 'cod001',
    Descripcion: 'Anexos',
    Estado: { ID_Estado: 1, Nombre: 'ACTIVO' },
    Extension: ['ALL'],
    Files: [],
    Requerido: false,
    Size: { Size: 10, UM: 'MB' },
    Titulo: 'Anexos'
  }
]