import {
  CodsMapeoMOMaterial,
  CodsMapeoMOMaterialSopladoTroncal,
  CodsMapeoMO_Material_Arquertas,
  ObtenerPrecioContratistaVigente,
  codMOClientes_ReglaLiquidacion1,
  codMOTroncal_ReglaLiquidacion1
} from 'sigo-package'
import { DtoResponseEstadoInterno } from '../../../../../app/Domain/DtoResponseEstadoInterno'
import { EntityEstadoInterno } from '../../../../shared/Domain/Entity/EntityEstadoInterno'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { DtoCertificacion } from '../../../Trabajos/Asignacion/Domain/DtoCertificacion'
import { DtoContrata } from '../../../Trabajos/Asignacion/Domain/DtoContrata'
import { DtoProduccion } from '../../../Trabajos/Asignacion/Domain/DtoProduccion'
import { DtoValorizacion } from '../../../Trabajos/Asignacion/Domain/DtoValorizacion'
import { DtoDataAddedValorizaciones } from '../../Shared/Preliquidar/Interfaces/DtoDataAddedValorizaciones'
import { DtoRequestUpdateTrabajo } from '../Domain/DtoRequestUpdateTrabajo'
import { RepositoryMain } from '../Domain/RepositoryMain'
import { DtoAmap } from '../../../Home/Domain/DtoAmap'
import { DtoDetalleChileReservaMO } from '../../../../shared/Domain/Dto/DtoDetalleChileReservaMO'
import { DtoTrabajosDE } from '../../Trabajos/Domain/DtoTrabajosDE'
import { DtoManoObraGlobal } from '../../../../../app/Domain/DtoManoObraGlobal'
import { addCodigosSoplado } from '../Shared/Utils/AddCodigosSoplado';
import { DtoMaterialesUtiRet } from '../../../Trabajos/Asignacion/Domain/DtoMaterialesUtiRet'
import { typeHPList } from '../../DocHP/Domain/typeHPList'
import { codMOPublico } from '../../Shared'

export class UseCasePreliquidar {
  private repository: RepositoryMain
  private trabajo: DtoTrabajosDE
  private user: EntityDataUsuario
  private userGestor: EntityDataUsuario
  private maxID_Valorizacion: number
  private maxID_AsignacionValorizacion: number
  private maxID_Amap: number
  private dataEstadoInterno: DtoResponseEstadoInterno[]
  private uploadFiles: File[]
  private address = false
  private reglaLiquidacion1 = false
  private typeHPList: typeHPList = 'Troncal'
  private valRechazadaXMetraje: number | null = null

  constructor(_repository: RepositoryMain, address?: boolean) {
    this.repository = _repository
    this.trabajo = {} as DtoTrabajosDE
    this.user = {} as EntityDataUsuario
    this.userGestor = {} as EntityDataUsuario
    this.maxID_Valorizacion = 0
    this.maxID_AsignacionValorizacion = 0
    this.maxID_Amap = 0
    this.dataEstadoInterno = []
    this.uploadFiles = []
    this.address = !!address
  }

  async exec(
    user: EntityDataUsuario,
    userGestor: EntityDataUsuario,
    trabajo: DtoTrabajosDE,
    data: DtoDataAddedValorizaciones[],
    dataEstadoInterno: DtoResponseEstadoInterno[],
    dataManoObraGlobal: Array<DtoManoObraGlobal>,
    typeHPList: typeHPList,
    valRechazadaXMetraje: number | null
  ) {
    this.trabajo = trabajo
    this.user = user
    this.userGestor = userGestor
    this.dataEstadoInterno = dataEstadoInterno
    // this.dataManoObraGlobal = dataManoObraGlobal
    this.typeHPList = typeHPList
    this.valRechazadaXMetraje = valRechazadaXMetraje
    await this._exec(data)
  }

  private async _exec(data: DtoDataAddedValorizaciones[]) {
    const newValorizaciones = this.generarValorizaciones(data)
    this.inactivarValorizacionesRechazadasPorMetraje()
    this.trabajo.Ultima_PreLiquidacion.Valorizacion = this.trabajo.Ultima_PreLiquidacion.Valorizacion.concat(newValorizaciones)
    this.updateUltimaAsignacionValorizaciones(data)
    this.updateValorizacionesManoObra(data)
    const params: DtoRequestUpdateTrabajo = {
      Header: {
        Coleccion: {
          Codigo: this.user.Delegacion.Codigo,
          Nombre: this.user.Pais.Nombre
        }
      },
      Body: this.trabajo
    }
    // console.log(newValorizaciones); return []
    await this.repository.updateTrabajos(params, this.user)
    await this.repository.uploadFiles(this.uploadFiles, this.user, this.trabajo.ID_Trabajo)
  }

  private generarValorizaciones(data: DtoDataAddedValorizaciones[]) {
    this.obtnerMaxID_Valorizacion()
    return data.reduce((acc: DtoValorizacion[], el) => [...acc, ...this.generarDtoValorizacion(el)], [])
  }

  private generarDtoValorizacion(el: DtoDataAddedValorizaciones): DtoValorizacion[] {

    this.reglaLiquidacion1 = codMOTroncal_ReglaLiquidacion1
      .filter(e => ![
        ...CodsMapeoMO_Material_Arquertas,
        ...CodsMapeoMOMaterialSopladoTroncal
      ].map(el => el.CodigoMO).includes(e))
      .includes(el.ManoObra.Codigo.trim()) && !this.address
    const newDtos: DtoValorizacion[] = []

    const MO = this.obtenerManoObra(el)
    const Anexos = this.obtenerAnexos(el, this.maxID_Valorizacion)
    const estadoInterno = this.obtenerEstadoInterno()
    const { Precio, PrecioContrata } = this.obtenerPrecioEspecialidad(el.Especialidad.Codigo, el.Actividad.Codigo, MO, estadoInterno)
    const DetalleChile = this.obtenerDetalleChile(MO, el)

    const subMOClientesYNoClientes = this.subMOClientesYNoClientes(el)
    const subMOClientes = this.subMOClientes(el)
    const { MaterialesUtilizados, newValorizacionesSubMO } = this.generarMaterialesUtilizados(el)

    const newDto: DtoValorizacion = {
      ID_Valorizacion: this.maxID_Valorizacion,
      Amap: MO.Amap,
      Cantidad: el.CantidadPreLiquidado > el.CantidadMODisponible && el.CantidadMODisponible > 0 ? el.CantidadMODisponible : el.CantidadPreLiquidado,
      CantidadUO: el.CantidadPreLiquidado,
      Codigo: MO.Codigo,
      Descripcion: MO.Descripcion,
      Contrata: new DtoContrata(),
      Certificacion: new DtoCertificacion(),
      Produccion: new DtoProduccion(),
      Ultimo_Estado_Interno: estadoInterno,
      Estados: [estadoInterno],
      FechaLiquidacion: new Date(),
      Semana: 0,
      Calle: '',
      Numero: '',
      Anexos: Anexos,
      Fotos: [],
      Moneda: MO.Moneda,
      Simbolo: MO.Simbolo,
      Decimales: MO.Decimales,
      Observaciones: el.ID_AsignacionValorizacion === -100 ? 'PWA (MO NO RESERVADA)' : 'PWA',
      Precio,
      Puntos: 0,
      Total: el.CantidadPreLiquidado * Precio,
      Unidad: MO.Unidad,
      MaterialesUtilizados,
      MaterialesRetirados: el.materialesRetirado,
      DetalleChile,
      HomePass: el.homePassMO,
      SubManoObra: [],
      PrecioContrata,
      TotalContrata: PrecioContrata * el.CantidadPreLiquidado,
    }
    this.maxID_Valorizacion++
    newDtos.push(newDto)

    const especialidad = addCodigosSoplado()

    if (especialidad.includes(newDto.DetalleChile.ManoObra.Codigo.trim()) && this.address) {
      if (el.CantidadPreLiquidado > el.CantidadMODisponible) {
        for (const hp of newDto.HomePass) {
          hp.Cantidad = el.CantidadMODisponible > 0 ? el.CantidadMODisponible : newDto.Cantidad
        }
      } else {
        for (const hp of newDto.HomePass) {
          hp.Cantidad = newDto.Cantidad
        }
      }
    }

    if (el.CantidadPreLiquidado > el.CantidadMODisponible && el.CantidadMODisponible > 0) {
      const newDtoAux = structuredClone(newDto)
      newDtoAux.ID_Valorizacion = this.maxID_Valorizacion
      this.maxID_Valorizacion++
      newDtoAux.Cantidad = newDto.CantidadUO - newDto.Cantidad
      newDtoAux.Numero = `ID#${this.maxID_Valorizacion}__LineaCodigoCub#${newDtoAux.DetalleChile.LineaCodigoCub}`
      newDtoAux.Semana = newDto.ID_Valorizacion
      if (especialidad.includes(newDtoAux.DetalleChile.ManoObra.Codigo.trim()) && this.address) {
        for (const hp of newDtoAux.HomePass) {
          hp.Cantidad = newDtoAux.Cantidad
        }
      }
      newDtos.push(newDtoAux)
    }

    for (const val of newValorizacionesSubMO) {
      val.ID_Valorizacion = this.maxID_Valorizacion
      this.maxID_Valorizacion++
      val.Semana = newDto.ID_Valorizacion
      newDtos.push(val)
    }

    if (subMOClientesYNoClientes) {
      subMOClientesYNoClientes.ID_Valorizacion = this.maxID_Valorizacion
      this.maxID_Valorizacion++
      subMOClientesYNoClientes.Semana = newDto.ID_Valorizacion
      newDtos.push(subMOClientesYNoClientes)
    }

    if (subMOClientes) {
      subMOClientes.ID_Valorizacion = this.maxID_Valorizacion
      this.maxID_Valorizacion++
      subMOClientes.Semana = newDto.ID_Valorizacion
      newDtos.push(subMOClientes)
    }

    return newDtos
  }

  private obtenerManoObra(el: DtoDataAddedValorizaciones) {
    const mo = this.trabajo.ColeccionManoObra[0].Valorizacion.filter(e => e.DetalleChile.LineaCodigoCub.trim() === el.LineaCodigoCub.trim())
    if (mo.length !== 1) throw Error(`¡No se pudo obtener Mano Obra! Comunícate con Soporte`)
    return mo[0]
  }

  private obtnerMaxID_Valorizacion = () => {
    this.maxID_Valorizacion = this.trabajo.Ultima_PreLiquidacion.Valorizacion.reduce((max, val) => max < val.ID_Valorizacion ? val.ID_Valorizacion : max, 0) + 1
  }

  private obtnerMaxID_AsignacionValorizacion = () => {
    this.maxID_AsignacionValorizacion = this.trabajo.Ultima_asignacion.Valorizaciones.reduce((max, val) => max < val.ID_AsignacionValorizacion ? val.ID_AsignacionValorizacion : max, 0) + 1
  }

  private obtnerMaxID_Amap = () => {
    this.maxID_Amap = this.trabajo.ColeccionManoObra[0].Valorizacion.reduce((max, val) => max < val.ID_Amap ? val.ID_Amap : max, 0) + 1
  }

  private obtenerEstadoInterno(Estado: string = 'PRELIQUIDADA') {
    const estado = this.dataEstadoInterno.filter(e => e.Descripcion.trim().toUpperCase() === Estado)
    if (estado.length !== 1) throw Error(`¡No se pudo obtener Estado Interno! Comúnicate con Soporte`)
    const newEstadoInterno = new EntityEstadoInterno()
    newEstadoInterno.ID_EstadoInterno = estado[0].ID_EstadoInterno
    newEstadoInterno.Descripcion = estado[0].Descripcion
    newEstadoInterno.Icono = estado[0].Icono
    newEstadoInterno.Color = estado[0].Color
    newEstadoInterno.Orden = estado[0].Orden
    newEstadoInterno.ColorFondo = estado[0].ColorFondo
    newEstadoInterno.Tipo = estado[0].Tipo
    newEstadoInterno.Observacion = estado[0].Observacion
    newEstadoInterno.Sistema = estado[0].Sistema
    newEstadoInterno.Anexos = estado[0].Anexos
    newEstadoInterno.Pais = estado[0].Pais
    newEstadoInterno.UsuarioEdicion.Identificacion = this.userGestor.Identificacion
    newEstadoInterno.UsuarioEdicion.User = this.userGestor.User
    newEstadoInterno.UsuarioEdicion.Cuadrilla = 'GESTOR'
    return newEstadoInterno
  }

  private obtenerAnexos(el: DtoDataAddedValorizaciones, ID_Valorizacion: number) {
    const anexos = []
    for (const anexo of el.Adjuntos) {
      for (const file of anexo.Files) {
        const ruta: string = `OBRA_${this.trabajo.ColeccionObras[0].ID_incidencia}_${this.trabajo.ID_GOM}_${this.trabajo.ID_Trabajo}_${ID_Valorizacion}_Fotos_Anexos_`
        file.Ruta = ruta
        if (file.File) {
          file.Filename = `${ruta}${file.File.name}`
          const newFile = new File([file.File], `${this.trabajo.Pais.Nombre}_${this.trabajo.Delegacion.Codigo}_${ruta}-${file.Filename}`, {
            type: file.File.type,
            lastModified: file.File.lastModified
          })
          this.uploadFiles.push(newFile)
          delete file.File
          file.Ultimo_Flujo.Usuario.Identificacion = this.userGestor.Identificacion
          file.Ultimo_Flujo.Usuario.Apellidos = `${this.userGestor.ApellidoPaterno} ${this.userGestor.ApellidoMaterno}`
          file.Ultimo_Flujo.Usuario.Nombres = this.userGestor.Nombres
          anexos.push(file)
        }
      }
    }
    return anexos
  }

  private obtenerPrecioEspecialidad(codigoEspecialidad: string, codigoActividad: string, val: DtoAmap, estadoInterno: EntityEstadoInterno): { Precio: number, PrecioContrata: number } {

    const { DatosCatalogoMO: { DatosPrecioEspecialidad: { Agencias, Contratistas } }, DetalleChile: { Linea } } = val
    if (this.trabajo.Ultima_asignacion.Empresa.Propia) {
      const agencia = Agencias.filter(e => e.Codigo.trim() === this.trabajo.ColeccionObras[0].DetalleChile.agencia.trim())
      if (agencia.length !== 1) throw Error(`No se encontró Agencia con Codigo: ${this.trabajo.ColeccionObras[0].DetalleChile.agencia} para obra: ${this.trabajo.ColeccionObras[0].OrdenTrabajo}`)
      return { Precio: Agencias[0].Ultimo_Precio.Precio, PrecioContrata: 0 }
    }

    const contratista = Contratistas.filter((el) => el.Empresa.Codigo.trim() === this.trabajo?.Ultima_asignacion.Empresa.Codigo.trim() && el.Estado.ID_Estado === 1)
    const msgErrContratista = `Contratista (Código): ${this.trabajo?.Ultima_asignacion.Empresa.Codigo} en PrecioEspecialidad (Código): ${codigoEspecialidad} con ContratoOT (Código): ${this.trabajo.ColeccionObras[0].ContratoOT.Codigo}, (${val.DetalleChile.ManoObra.Codigo})`
    if (contratista.length !== 1)
      throw Error(`¡No se pudo encontrar ${msgErrContratista}!`)

    const zonaContratista = contratista[0].Zonas.filter((el) => el.Descripcion.trim() === this.trabajo.ColeccionObras[0].ZonaAsignada.trim() && el.Estado.ID_Estado === 1)
    const msgErrZona = `Zona: ${this.trabajo.ColeccionObras[0].ZonaAsignada} para ${msgErrContratista}`
    if (zonaContratista.length !== 1)
      throw Error(`¡No se pudo encontrar ${msgErrZona}!`)

    const actividadZonaContratista = zonaContratista[0].Actividades.filter((el) => el.Codigo.trim() === codigoActividad.trim() && el.Estado.ID_Estado === 1)
    const msgErrActividad = `Actividad (Código): ${codigoActividad} para ${msgErrZona}`
    if (actividadZonaContratista.length !== 1)
      throw Error(`¡No se pudo encontrar ${msgErrActividad}!`)

    const Precio = ObtenerPrecioContratistaVigente({ actividad: actividadZonaContratista[0], FechaVerificar: estadoInterno.Fecha })
    if (!Precio) throw Error(`¡No se encontró Precio Vigente en la Línea: <span style="color: red">${Linea}</span>, para ${msgErrActividad}!`)

    return { Precio: Agencias[0].Ultimo_Precio.Precio, PrecioContrata: Precio.Precio }
  }

  private obtenerDetalleChile(MO: DtoAmap, el?: DtoDataAddedValorizaciones) {
    const newDto: DtoDetalleChileReservaMO = {
      Codigo: MO.DetalleChile.Codigo,
      Linea: MO.DetalleChile.Linea,
      DirDesde: MO.DetalleChile.DirDesde,
      AltDesde: MO.DetalleChile.AltDesde,
      DirHasta: MO.DetalleChile.DirHasta,
      AltHasta: MO.DetalleChile.AltHasta,
      Plano: MO.DetalleChile.Plano,
      Especialidad: MO.DetalleChile.Especialidad,
      Actividad: MO.DetalleChile.Actividad,
      Clave: MO.DetalleChile.Clave,
      Tarea: MO.DetalleChile.Tarea,
      ManoObra: MO.DetalleChile.ManoObra,
      UnidadObra: MO.DetalleChile.UnidadObra,
      HomePass: MO.DetalleChile.HomePass,
      LineaCodigoCub: MO.DetalleChile.LineaCodigoCub,
      CodigoCub: MO.DetalleChile.CodigoCub,
      CanMOCub: MO.DetalleChile.CanMOCub,//
      CanMOInf: MO.DetalleChile.CanMOInf,
      CanMOApr: MO.DetalleChile.CanMOApr,
      CanUOCub: MO.DetalleChile.CanUOCub,
      CanUOInf: MO.DetalleChile.CanUOInf,
      CanUOApr: MO.DetalleChile.CanUOApr,
      TotalUO: 0,
      Origen: MO.DetalleChile.Origen,
      CodigoBarra: el ? el.tipoBaremoSubcontrata : MO.DetalleChile.CodigoBarra, // se guarda el tipo baremo subcontrata (Trenching, Asphalt)
      PuntoBaremo: MO.DetalleChile.PuntoBaremo,
    }
    return newDto
  }

  private updateUltimaAsignacionValorizaciones(data: DtoDataAddedValorizaciones[]) {
    this.trabajo.Ultima_asignacion.Valorizaciones = this.trabajo.Ultima_asignacion.Valorizaciones
      .filter(e => e.ID_AsignacionValorizacion !== -100 || (e.ID_AsignacionValorizacion === -100
        && data.map(e => e.LineaCodigoCub).includes(e.LineaCodigoCub.trim())))
    this.obtnerMaxID_AsignacionValorizacion()
    let count = 0
    for (const val of this.trabajo.Ultima_asignacion.Valorizaciones) {
      if (val.ID_AsignacionValorizacion === -100) {
        val.ID_AsignacionValorizacion = this.maxID_AsignacionValorizacion + count
        count++
      }
    }
  }

  private updateValorizacionesManoObra(data: DtoDataAddedValorizaciones[]) {
    this.trabajo.ColeccionManoObra[0].Valorizacion = this.trabajo.ColeccionManoObra[0].Valorizacion
      .filter(e =>
        e.ID_Amap !== -100 ||
        (e.ID_Amap === -100 && data.map(e => e.LineaCodigoCub).includes(e.DetalleChile.LineaCodigoCub.trim())))
    this.obtnerMaxID_Amap()
    let count = 0
    for (const val of this.trabajo.ColeccionManoObra[0].Valorizacion) {
      if (val.ID_Amap === -100) {
        val.ID_Amap = this.maxID_Amap + count
        count++
        const asignacion = this.trabajo.Ultima_asignacion.Valorizaciones.find(e => e.LineaCodigoCub.trim() === val.DetalleChile.LineaCodigoCub.trim())
        if (asignacion) {
          asignacion.ID_Amap = val.ID_Amap
        }
      }
    }
  }

  //#region SubMano Obra Regla Liquidacion Troncal

  private generarMaterialesUtilizados(data: DtoDataAddedValorizaciones): { MaterialesUtilizados: DtoMaterialesUtiRet[], newValorizacionesSubMO: DtoValorizacion[] } {
    let MaterialesUtilizados = this.reglaLiquidacion1 ? [] : data.materialesInstalado
    const newValorizacionesSubMO: DtoValorizacion[] = []
    if (this.reglaLiquidacion1) {

      for (const materialInstalado of data.materialesInstalado) {

        let matHijo: DtoMaterialesUtiRet[] = []

        if (materialInstalado.Motivo.Descripcion === 'CONSUMO_MATERIAL_AUTOMATICO_DUCTOS') continue //Quiere decir que es Hijo

        const exist = CodsMapeoMOMaterial.find(e => e.CodigoMaterial.trim() === materialInstalado.Codigo.trim())

        if (exist) {
          matHijo = data.materialesInstalado.filter(e => e.Codigo.trim() === exist.CodMatConector.trim()
            && e.Motivo.Codigo.trim() === materialInstalado.CodigoLlave.trim()
            && e.Motivo.Descripcion === 'CONSUMO_MATERIAL_AUTOMATICO_DUCTOS'
          )
          if (matHijo.length > 1) throw Error(`Error al Preliquidar`)
        }


        if (['03DUC-109-205', '03DUC-109-210'].includes(materialInstalado.Codigo.trim())) {// 03DUC-109-205 no genera MO pero si material 03DUC-110-201
          if (exist) {
            MaterialesUtilizados = [...MaterialesUtilizados, materialInstalado, ...matHijo]
          }
          continue
        }

        if (exist) {
          const MO = this.buscarMO(exist.CodigoMO)
          const newVal = this.generarDtoValorizacionSubMO(MO, [materialInstalado, ...matHijo], materialInstalado.Cantidad, data)
          newValorizacionesSubMO.push(newVal)
        }

      }

    }
    return { MaterialesUtilizados, newValorizacionesSubMO }
  }

  private buscarMO(CodigoMO: string) {
    const existInAsignacion = this.trabajo.Ultima_asignacion.Valorizaciones.filter(e => e.ManoObra.Codigo.trim() === CodigoMO.trim() && e.Estado.ID_Estado === 1)
    if (!existInAsignacion.length) throw Error(`¡No tiene asignado Mano de Obra con código ${CodigoMO}!`)
    if (existInAsignacion.length > 1) throw Error(`¡No se pudo Preliquidar!`)
    const mo = this.trabajo.ColeccionManoObra[0].Valorizacion.filter(e => e.DetalleChile.ManoObra.Codigo.trim() === CodigoMO.trim())
    if (mo.length !== 1) throw Error(`¡Error al Preliquidar!`)
    return mo[0]
  }

  private generarDtoValorizacionSubMO(MO: DtoAmap, mat: DtoMaterialesUtiRet[], Cantidad: number, data: DtoDataAddedValorizaciones) {
    const estadoInterno = this.obtenerEstadoInterno()
    const { Precio, PrecioContrata } = this.obtenerPrecioEspecialidad(MO.DetalleChile.Especialidad.Codigo, MO.DetalleChile.Actividad.Codigo, MO, estadoInterno)
    const DetalleChile = this.obtenerDetalleChile(MO)
    const newDto: DtoValorizacion = {
      ID_Valorizacion: 0,
      Amap: MO.Amap,
      Cantidad,
      CantidadUO: 0,
      Codigo: MO.Codigo,
      Descripcion: MO.Descripcion,
      Contrata: new DtoContrata(),
      Certificacion: new DtoCertificacion(),
      Produccion: new DtoProduccion(),
      Ultimo_Estado_Interno: estadoInterno,
      Estados: [estadoInterno],
      FechaLiquidacion: new Date(),
      Semana: 0,
      Calle: '',
      Numero: '',
      Anexos: [],
      Fotos: [],
      Moneda: MO.Moneda,
      Simbolo: MO.Simbolo,
      Decimales: MO.Decimales,
      Observaciones: 'Sub Mano Obra',
      Precio,
      Puntos: 0,
      Total: Cantidad * Precio,
      Unidad: MO.Unidad,
      MaterialesUtilizados: mat,
      MaterialesRetirados: [],
      DetalleChile,
      HomePass: data.homePassMO,
      SubManoObra: [],
      PrecioContrata,
      TotalContrata: PrecioContrata * Cantidad
    }
    return newDto
  }

  //#endregion

  //#region SubManoObra Regla Liquidacion Clientes No Clientes (Pero tambien aplica a clientes)

  private subMOClientesYNoClientes(valorizacion: DtoDataAddedValorizaciones) {

    if (!['Residential', 'Business'].includes(this.typeHPList)) return
    if (!codMOPublico.includes(valorizacion.ManoObra.Codigo.trim())) return

    const codMO = '010206700'
    const MO = this.buscarMO(codMO)
    const newVal = this.generarDtoValorizacionSubMO(MO, valorizacion.materialesInstalado, valorizacion.CantidadPreLiquidado, valorizacion)
    valorizacion.materialesInstalado = []
    return newVal
  }

  //#endregion

  //#region SubManoObra Regla Liquidacion Clientes

  private subMOClientes(valorizacion: DtoDataAddedValorizaciones) {

    if (!['Residential'].includes(this.typeHPList)) return
    if (!codMOClientes_ReglaLiquidacion1.includes(valorizacion.ManoObra.Codigo.trim())) return

    const codMO = '010206700'
    const MO = this.buscarMO(codMO)
    const newVal = this.generarDtoValorizacionSubMO(MO, valorizacion.materialesInstalado.filter(e => ['03DUC-109-214'].includes(e.Codigo)), valorizacion.CantidadPreLiquidado, valorizacion)
    valorizacion.materialesInstalado = valorizacion.materialesInstalado.filter(e => ['03DUC-110-210', '03DUC-110-211'].includes(e.Codigo))
    return newVal
  }

  //#endregion

  private inactivarValorizacionesRechazadasPorMetraje() {
    if (!this.valRechazadaXMetraje) return
    const estadoInterno = this.obtenerEstadoInterno('INACTIVO')
    this.trabajo.Ultima_PreLiquidacion.Valorizacion.forEach(element => {
      if (
        element.ID_Valorizacion === this.valRechazadaXMetraje ||
        element.Semana === this.valRechazadaXMetraje
      ) {
        estadoInterno.Observacion = `VALORIZACION RECHAZADA POR METRAJE`
        element.Ultimo_Estado_Interno = estadoInterno
        element.Estados.push(estadoInterno)
      }
    })
  }

}