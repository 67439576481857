import { DtoResponseEstadoInterno } from '../../../../../app/Domain/DtoResponseEstadoInterno'
import { EntityEstadoInterno } from '../../../../shared/Domain/Entity/EntityEstadoInterno'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { RepositoryMain } from '../Domain/RepositoryMain'
import { DtoTrabajosDE } from '../../Trabajos/Domain/DtoTrabajosDE'
import { DtoRequestUpdateTrabajo } from '../../Preliquidar/Domain/DtoRequestUpdateTrabajo'
import { DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { DtoValorizacion } from '../../../Trabajos/Asignacion/Domain/DtoValorizacion'
import { DtoFilesDoc } from '../../../Trabajos/Trabajos/Domain/DtoFilesDoc'

export class UseCaseUpdateAttachedValuations {
  private repository: RepositoryMain
  private trabajo: DtoTrabajosDE
  private user: EntityDataUsuario
  private userGestor: EntityDataUsuario
  private dataEstadoInterno: DtoResponseEstadoInterno[]
  private uploadFiles: File[]

  constructor(_repository: RepositoryMain, gestor: EntityDataUsuario) {
    this.repository = _repository
    this.trabajo = {} as DtoTrabajosDE
    this.user = {} as EntityDataUsuario
    this.dataEstadoInterno = []
    this.uploadFiles = []
    this.userGestor = gestor
  }

  async exec(
    user: EntityDataUsuario,
    trabajo: DtoTrabajosDE,
    dataEstadoInterno: DtoResponseEstadoInterno[],
    nodos: DtoNodoDoc[],
    updatedValorizacion: DtoValorizacion
  ) {
    this.trabajo = trabajo
    this.user = user
    this.dataEstadoInterno = dataEstadoInterno
    await this._exec(nodos, updatedValorizacion)
  }

  private async _exec(nodos: DtoNodoDoc[], updatedValorizacion: DtoValorizacion) {

    // Obtener los anexos de manera recursiva
    const newAnexos = this.getAnexosHPList(nodos, updatedValorizacion)

    //Actualizar estado valorizacion
    const estado = this.obtenerEstadoInterno()
    this.trabajo.Ultima_PreLiquidacion.Valorizacion.forEach(e => {
      if (e.ID_Valorizacion === updatedValorizacion.ID_Valorizacion) {
        e.Anexos = [...e.Anexos, ...newAnexos]
        e.Ultimo_Estado_Interno = estado
        e.Estados.push(estado)
      }
    })

    const params: DtoRequestUpdateTrabajo = {
      Header: {
        Coleccion: {
          Codigo: this.user.Delegacion.Codigo,
          Nombre: this.user.Pais.Nombre
        }
      },
      Body: this.trabajo
    }
    // console.log(newValorizaciones); return []
    await this.repository.updateTrabajos(params, this.user)
    await this.repository.uploadFiles(this.uploadFiles)
  }

  private getAnexosHPList(nodos: DtoNodoDoc[], updatedValorizacion: DtoValorizacion): DtoFilesDoc[] {
    const anexos: DtoFilesDoc[] = []
    nodos.forEach(nodo => {
      if (nodo.Children.length) {
        this.getAnexosHPList(nodo.Children, updatedValorizacion)
      } else {
        nodo.Files.forEach(file => {
          const ruta: string = `OBRA_${this.trabajo.ColeccionObras[0].ID_incidencia}_${this.trabajo.ID_GOM}_${this.trabajo.ID_Trabajo}_${updatedValorizacion.ID_Valorizacion}_Fotos_Anexos_`
          if (file.File && !file.Ruta.includes(ruta)) {
            file.Ruta = ruta
            file.Filename = `${ruta}${file.File.name}`
            const newFile = new File([file.File], `${this.trabajo.Pais.Nombre}_${this.trabajo.Delegacion.Codigo}_${ruta}-${file.Filename}`, {
              type: file.File.type,
              lastModified: file.File.lastModified
            })
            this.uploadFiles.push(newFile)
            delete file.File
            file.Ultimo_Flujo.Usuario.Identificacion = this.userGestor.Identificacion
            file.Ultimo_Flujo.Usuario.Apellidos = `${this.userGestor.ApellidoPaterno} ${this.userGestor.ApellidoMaterno}`
            file.Ultimo_Flujo.Usuario.Nombres = this.userGestor.Nombres
            anexos.push(file)
          }
        })
      }
    })
    return anexos
  }

  private obtenerEstadoInterno() {
    const estado = this.dataEstadoInterno.filter(e => e.Descripcion.trim().toUpperCase() === 'PRELIQUIDADA')
    if (estado.length !== 1) throw Error(`¡No se pudo obtener Estado Interno! Comúnicate con Soporte`)
    const newEstadoInterno = new EntityEstadoInterno()
    newEstadoInterno.ID_EstadoInterno = estado[0].ID_EstadoInterno
    newEstadoInterno.Descripcion = estado[0].Descripcion
    newEstadoInterno.Icono = estado[0].Icono
    newEstadoInterno.Color = estado[0].Color
    newEstadoInterno.Orden = estado[0].Orden
    newEstadoInterno.ColorFondo = estado[0].ColorFondo
    newEstadoInterno.Tipo = estado[0].Tipo
    newEstadoInterno.Observacion = estado[0].Observacion
    newEstadoInterno.Sistema = estado[0].Sistema
    newEstadoInterno.Anexos = estado[0].Anexos
    newEstadoInterno.Pais = estado[0].Pais
    newEstadoInterno.UsuarioEdicion.Identificacion = this.userGestor.Identificacion
    newEstadoInterno.UsuarioEdicion.User = this.userGestor.User
    newEstadoInterno.UsuarioEdicion.Cuadrilla = 'GESTOR'
    return newEstadoInterno
  }

}