import { Link } from "react-router-dom";
import "./ElementItemCardGenerico.scss";
import { typeColorsBootstrap } from '../../Domain/typeColorsBootstrap';

interface PropsView {
    text1: string | JSX.Element;
    text2?: string | JSX.Element;
    text3?: string | JSX.Element;
    text4?: string | JSX.Element;
    text5?: string | JSX.Element;
    text6?: string | JSX.Element;
    text7?: string | JSX.Element;
    text8?: string | JSX.Element;
    data1: string | JSX.Element;
    data2?: string | JSX.Element;
    data3?: string | JSX.Element;
    data4?: string | JSX.Element;
    data5?: string | JSX.Element;
    data6?: string | JSX.Element;
    data7?: string | JSX.Element;
    data8?: string | JSX.Element;
    icono?: boolean;
    icono1?: string;
    icono2?: string;
    icono3?: string;
    icono4?: string;
    linkIcono1?: string;
    linkIcono2?: string;
    linkIcono3?: string;
    linkIcono4?: string;
    colorIcono1?: typeColorsBootstrap
    buttons?: React.ReactNode[];
    buttonsBottom?: React.ReactNode[];
    indice?: number;
    onClick?: () => void
    backgroundColor?: string
}

export const ElementItemCardGenerico = (props: PropsView) => {
    const {
        icono,
        icono1,
        icono2,
        icono3,
        icono4,
        linkIcono1,
        linkIcono2,
        linkIcono3,
        linkIcono4,
        colorIcono1,
        buttons,
        indice,
        onClick,
        backgroundColor = '#2C3E50',
        buttonsBottom
    } = props;

    return (
        <div className='shadow-lg ElementItemCard' style={{ backgroundColor }}>
            <div className="d-flex flex-row align-items-center">
                <div className={icono ? 'col-11' : 'col-12'}>
                    <div className="row pb-1" style={{ margin: '1px' }}>
                        <div className="col-3 ps-0">
                            <span className="text-white" style={{ fontSize: '1rem' }}>{props.text1}</span>
                        </div>
                        <div className="col-9">
                            <span className='rosa-neon' style={{ fontSize: '1rem', textAlign: 'justify', paddingLeft: '0.4rem' }}>{props.data1}</span>
                        </div>
                    </div>
                    {
                        (props.data2 || props.text2) && (
                            <div className="row pb-1" style={{ margin: '1px' }}>
                                <div className="col-3 ps-0">
                                    <span className="text-white" style={{ fontSize: '1rem' }}>{props.text2}</span>
                                </div>
                                <div className="col-9">
                                    <span className='rosa-neon' style={{ fontSize: '1rem', textAlign: 'justify', paddingLeft: '0.4rem' }}>{props.data2}</span>
                                </div>
                            </div>)
                    }
                    {
                        (props.data3 || props.text3) && (
                            <div className="row pb-1" style={{ margin: '1px' }}>
                                <div className="col-3 ps-0">
                                    <span className="text-white" style={{ fontSize: '16px' }}>{props.text3}</span>
                                </div>
                                <div className="col-9">
                                    <span className='verde-neon' style={{ fontSize: '13px', textAlign: 'justify', paddingLeft: '0.4rem' }}>{props.data3}</span>
                                </div>
                            </div>
                        )
                    }
                    {
                        (props.data4 || props.data4) && (
                            <div className="row pb-1" style={{ margin: '1px' }}>
                                <div className="col-3 ps-0">
                                    <span className="text-black" style={{ fontSize: '16px' }}>{props.text4}</span>
                                </div>
                                <div className="col-9">
                                    <span className='verde-neon' style={{ fontSize: '13px', textAlign: 'justify', paddingLeft: '0.4rem' }}>{props.data4}</span>
                                </div>
                            </div>
                        )
                    }
                    {
                        (props.data5 || props.text5) && (
                            <div className="row pb-1" style={{ margin: '1px' }}>
                                <div className="col-3 ps-0">
                                    <span className="text-black" style={{ fontSize: '16px' }}>{props.text5}</span>
                                </div>
                                <div className="col-9">
                                    <span className="text-danger" style={{ fontSize: '13px', textAlign: 'justify', paddingLeft: '0.4rem' }}>{props.data5}</span>
                                </div>
                            </div>)
                    }
                    {
                        (props.data6 || props.text6) && (
                            <div className="row pb-1" style={{ margin: '1px' }}>
                                <div className="col-3 ps-0">
                                    <span className="text-black" style={{ fontSize: '16px', fontWeight: 'bold' }}>{props.text6}</span>
                                </div>
                                <div className="col-9">
                                    <span className='verde-neon' style={{ fontSize: '13px', textAlign: 'justify', paddingLeft: '0.4rem' }}>{props.data6}</span>
                                </div>
                            </div>
                        )
                    }
                    {
                        (props.data7 || props.text7) && (
                            <div className="row pb-1" style={{ margin: '1px' }}>
                                <div className="col-3 ps-0">
                                    <span className="text-black" style={{ fontSize: '16px', fontWeight: 'bold' }}>{props.text7}</span>
                                </div>
                                <div className="col-9">
                                    <span className='verde-neon' style={{ fontSize: '13px', textAlign: 'justify', paddingLeft: '0.4rem' }}>{props.data7}</span>
                                </div>
                            </div>
                        )
                    }
                    {
                        (props.data8 || props.text8) && (
                            <div className="row pb-1" style={{ margin: '1px' }}>
                                <div className="col-3 ps-0">
                                    <span className="text-black" style={{ fontSize: '16px', fontWeight: 'bold' }}>{props.text8}</span>
                                </div>
                                <div className="col-9">
                                    <span className='verde-neon' style={{ fontSize: '13px', textAlign: 'justify', paddingLeft: '0.4rem' }}>{props.data8}</span>
                                </div>
                            </div>
                        )
                    }
                </div>
                {
                    icono && (
                        <div className='col-1'>
                            <Link to={linkIcono1 ? linkIcono1 : ''}>
                                <div className={`pt-2 pb-2 text-center w-100 ${colorIcono1 ? `text-${colorIcono1}` : 'amarillo-neon'}`} onClick={onClick}>
                                    <i className={icono1 ? icono1 : 'fa-solid fa-eye'} style={{ fontSize: '20px' }} />
                                </div>
                            </Link>
                            {
                                icono2 && (
                                    <Link to={linkIcono2 ? linkIcono2 : ''}>
                                        <div className='pt-2 pb-2 text-center w-100 rosa-neon'>
                                            <i className={icono2 ? icono2 : 'fa-solid fa-eye'} style={{ fontSize: '20px' }} />
                                        </div>
                                    </Link>
                                )
                            }
                            {
                                icono3 && (
                                    <Link to={linkIcono3 ? linkIcono3 : ''}>
                                        <div className='pt-2 pb-2 text-center w-100 cian-neon'>
                                            <i className={icono3 ? icono3 : 'fa-solid fa-eye'} style={{ fontSize: '20px' }} />
                                        </div>
                                    </Link>
                                )
                            }
                            {
                                icono4 && (
                                    <Link to={linkIcono4 ? linkIcono4 : ''}>
                                        <div className='pt-2 pb-2 text-center w-100 verde-neon'>
                                            <i className={icono4 ? icono4 : 'fa-solid fa-eye'} style={{ fontSize: '20px' }} />
                                        </div>
                                    </Link>
                                )
                            }
                        </div>
                    )
                }
            </div>
            {buttons && (
                <div className='d-flex flex-row justify-content-between align-items-center naranja-neon'>
                    {buttons}
                </div>
            )}
            {
                indice && <div className='spanIndice'><span>{indice}</span></div>
            }
            {
                buttonsBottom ?
                    <div className='d-flex gap-1'>
                        {buttonsBottom}
                    </div>
                    : null
            }
        </div>
    );
};
