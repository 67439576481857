const SCHEMA: string = process.env.REACT_APP_MAESTRO_SEDE_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MAESTRO_SEDE_ENTITY || '';

const SELECT_PAIS: string = '/SigoPwa/Configuracion/MantenimientosGlobales/Pais/Select';
const SELECT_PERSONAL: string = '/SigoPwa/Configuracion/MantenimientosGlobales/Personal/Select';
const SELECT_ESTADOINTERNO: string = '/SigoPwa/Programados/Mantenimientos/EstadoInterno/Select';
const SELECT_CUADRILLAS: string = '/SigoPwa/Configuracion/MantenimientosGlobales/Cuadrillas/Select';
const SELECT_PRECIO_ESPECIALIDAD: string = '/SigoConfiguracion/MatenimientosGlobales/PrecioEspecialidad/Select';
const SELECT_MANO_OBRA_GLOBAL: string = '/SigoConfiguracion/MatenimientosGlobales/ManoObraGlobal/Select';
const SELECT_ITEMS: string = '/SigoPwa/Configuracion/MantenimientosGlobales/Items/Select';
const SELECT_DOCUMENTACIONBAREMOPEX: string = '/SigoProgramados/Mantenimentos/DocumentacionBaremoPEX/Select'
const SELECT_DOCUMENTACIONPEX: string = '/SigoProgramados/Mantenimentos/DocumentacionPEX/Select'
const SELECT_PRECIO_MATERIAL: string = '/SigoConfiguracion/MatenimientosGlobales/PrecioMaterial/Select';
const SELECT_CONTRATO_OT: string = '/SigoConfiguracion/MatenimientosGlobales/ContratoOT/Select'

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    SELECT_CUADRILLAS,
    SELECT_PAIS,
    SELECT_PERSONAL,
    SELECT_ESTADOINTERNO,
    SELECT_PRECIO_ESPECIALIDAD,
    SELECT_MANO_OBRA_GLOBAL,
    SELECT_ITEMS,
    SELECT_DOCUMENTACIONBAREMOPEX,
    SELECT_DOCUMENTACIONPEX,
    SELECT_PRECIO_MATERIAL,
    SELECT_CONTRATO_OT,
};