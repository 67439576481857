import { Filtros } from "../../Domain/EntityParams";

export const FiltroCuadrillas: Filtros = {
    Filtros: [
        {
            $match: {
                'Estado.ID_Estado': 1
            }
        },
        {
            $project: {
                ID_Cuadrilla: 1,
                Pais: 1,
                Empresa: 1,
                Delegacion: 1,
                Area: 1,
                ZonaTrabajo: 1,
                TipoMovil: 1,
                Encargado: 1,
                Codigo: 1,
                Nombre: 1,
                Descripcion: 1,
                Personal: 1
            }
        }]
}