import { RepositoryMain } from "../Domain/RepositoryMain";
import { DtoRequestSelectSalidaAlmacen } from "../Domain/DtoRequestSelectSalidaAlmacen";
import { EntityDataUsuario } from "../../../shared/Domain/EntityDataUsuario";
import { EntitySalidaAlmacen } from "../../FirmaAlbaran/Domain/EntitySalidaAlmacen";

export class UseCaseSelectSalidaAlmacen {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(user: EntityDataUsuario, idDespachoAlmacen: number): Promise<Array<EntitySalidaAlmacen>> {
        return await this._exec(user, idDespachoAlmacen);
    }

    private async _exec(user: EntityDataUsuario, idDespachoAlmacen: number): Promise<Array<EntitySalidaAlmacen>> {
        let params: DtoRequestSelectSalidaAlmacen = {
            datos: {
                Codigo: user.Delegacion.Codigo,
                Nombre: user.Pais.Nombre
            },
            Filtros: [
                {
                    $match: { ID_DespachoAlmacen: idDespachoAlmacen }
                }
            ],
        };
        const SalidaAlmacen = await this.repository.selectSalidaAlmacen(params);
        for (const salAlmacen of SalidaAlmacen) {
            salAlmacen.Fechas.Aprobacion = salAlmacen.Fechas.Aprobacion.split('T').shift() || '';   
        }
        return SalidaAlmacen
    }
}