import { ElementBreadCrumb } from '../../../../shared/Components/ElementBreadCrumb';
import { InputTextSearch } from '../../../../shared/Components/ElementInputSearch';
import { InputText } from '../../../../shared/Components/ElementInputsCostume';
import ElementModalCostume from '../../../../shared/Components/ElementModalCostume';
import { ElementItemCardGenerico } from '../../../../shared/Components/Programados/ElementItemCardGenerico';
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate';
import { AdapterConfigure } from '../Infraestructure/AdapterConfigure';
import './Style.scss';
import { DtoTrabajos } from '../../../../Master/Home/Domain/DtoTrabajos';
import { DtoParteDiario } from '../Domain/DtoParteDiario';
import { GetCodigoFechaActual } from 'sigo-package';

interface PropsViewMain {
  onChange: (name: string, value: any) => void;
  formSearch: {
    values: any;
    touched: any;
    errors: any;
    handleBlur: Function;
  };
  trabajos: DtoTrabajos[];
  showModal: boolean;
  onSearchSubmit: Function;
  formSearchDB: {
    values: any;
    touched: any;
    errors: any;
    handleBlur: Function;
  };
  setModal: (value: React.SetStateAction<boolean>) => void;
};

export const ViewMain = (props: PropsViewMain) => {
  const { onChange, formSearch, trabajos, formSearchDB } = props;
  const languageTranslate = LanguageTranslate();

  return (
    <>
      <ElementModalCostume
        show={props.showModal}
        closeModal={() => props.setModal(!props.showModal)}
        title={languageTranslate.general.BuscarTrabajo}
        height='250px'
      >
        <div className='row pb-3 ViewAutochequeoList'>
          <div className='col-12'>
            <div className='row'>
              <div className='col-12 topInput'>
                <InputText
                  label={languageTranslate.general.OrdenTrabajo}
                  name='OrdenTrabajo'
                  values={[formSearchDB.values['OrdenTrabajo']]}
                  isRequired
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-between mt-1 mb-1'>
          <button onClick={(evt) => {
            props.onSearchSubmit(evt);
          }} className='btn btn-primary w-100' style={{ minWidth: 120 }} > {languageTranslate.general.Buscar} </button>
        </div>
      </ElementModalCostume>
      <div className='ViewProfile'>
        <div className='container-fluid'>
          {/** BLOQUE DE BREADCRUMB */}
          <ElementBreadCrumb list={[
            { text: languageTranslate.moduloMain.textoProgramados, navigate: true, path: `${AdapterConfigure.ROUTE_PROGRAMADOS}` },
            { text: languageTranslate.menu['002'].titulo, navigate: false, path: '' }]}
          />
          <div className='d-flex flex-row w-100'>
            <div className='col-11'>
              <InputTextSearch
                name='textSearch'
                onChange={onChange}
                values={formSearch.values}
              />
            </div>
            <div className='col-1'>
              <span className='d-inline-block text-white rounded-circle text-center align-middle ms-1' style={{ backgroundColor: '#FF2079', width: '2rem', height: '2rem', lineHeight: '2rem' }}>
                {trabajos.length}
              </span>
            </div>
          </div>
          {
            trabajos.map((row, index) => {
              const esHoy = CompararFechas(row.PartesDiarios);
              return (
                <ElementItemCardGenerico
                  key={index}
                  icono
                  icono3={'fa-solid fa-user-check'}
                  icono2={esHoy ? 'fa-solid fa-gear' : ''}
                  linkIcono1={`${AdapterConfigure.ROUTE_TRABAJOS}/${row.ID_Trabajo}`}
                  linkIcono2={`${AdapterConfigure.ROUTE_ASIGNACION_TRABAJO}/${row.ID_Trabajo}`}
                  linkIcono3={`${AdapterConfigure.ROUTE_PARTEDIARIO_TRABAJO}/${row.ID_Trabajo}`}
                  data1={row.ColeccionObras[0].OrdenTrabajo}
                  data2={row.ZonaAsignada}
                  data3={`${row.Ultima_asignacion.Fecha.toString().split('T')[0]} ${row.Ultima_asignacion.Fecha.toString().split('T')[1].split('.')[0]}`}
                  data4={row.ColeccionObras[0].Proyecto}

                  text1={
                    <span style={{ fontSize: '15px', paddingLeft: '0px', color: 'white', fontWeight: 'bold' }} >
                      {languageTranslate.moduloProgramados.textObra}
                    </span>
                  }
                  text2={
                    <span style={{ fontSize: '15px', paddingLeft: '0px', color: 'white', fontWeight: 'bold' }} >
                      {languageTranslate.moduloProgramados.textZona}
                    </span>
                  }
                  text3={
                    <span style={{ fontSize: '15px', paddingLeft: '0px', color: 'white', fontWeight: 'bold' }} >
                      {languageTranslate.moduloProgramados.textFechaAsignacion}
                    </span>
                  }
                  text4={
                    <span style={{ fontSize: '15px', paddingLeft: '0px', color: 'white', fontWeight: 'bold' }} >
                      {languageTranslate.moduloProgramados.Descripcion}
                    </span>
                  }
                />
              )
            })
          }
          <div className='position-fixed search gris-oscuro' style={{ backgroundColor: '#FFA500' }}>
            <i className='fa-solid fa-magnifying-glass' style={{ fontSize: 20 }} onClick={() => {
              onChange('OrdenTrabajo', '');
              props.setModal(true)
            }}></i>
          </div>
          <div className='position-fixed refresh gris-oscuro' style={{ backgroundColor: '#FFA500' }}>
            <i className='fa-solid fa-arrows-rotate' style={{ fontSize: 20 }} onClick={() => { onChange('RefrescarTrabajos', '') }}></i>
          </div>
          {/* <ScrollToTopButton containerRef={containerRef} /> */}
        </div>
      </div >
    </>
  )
};

const CompararFechas = (PartesDiarios: DtoParteDiario[]): boolean => {
  const esHoy = PartesDiarios.some(element => {
    return element.Codigo === GetCodigoFechaActual()
  });
  return esHoy;
}
