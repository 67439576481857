import React from 'react'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import ElementStepperContent from '../../../../shared/Components/ElementStepperContent'
import { Adjuntos } from '../../../../shared/Components/Adjuntos/Adjuntos'
import { DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../shared/Infraestructure/AdapterStore'

interface PropsReplanteo {
    keyTab: number
    setKeyTab: React.Dispatch<React.SetStateAction<number>>
    documentacionReplanteo: DtoNodoDoc[]
    setDocumentacionReplanteo: React.Dispatch<React.SetStateAction<DtoNodoDoc[]>>
    setNewFilesUpload: React.Dispatch<React.SetStateAction<File[]>>
    newFilesUpload: File[]
    onSave: () => void
}

export const Step3 = ({ keyTab, setKeyTab, documentacionReplanteo, setDocumentacionReplanteo, newFilesUpload, setNewFilesUpload, onSave }: PropsReplanteo): React.ReactElement => {

    const languageTranslate = LanguageTranslate()
    const { obra } = useSelector((state: RootState) => state.replanteo)

    return (
        <ElementStepperContent
            step={3}
            currentStep={keyTab}
            title={languageTranslate.general.Archivos}
            onSubmit={() => onSave()}
            isLastStep
        >
            <Adjuntos
                Nodos={documentacionReplanteo}
                setNodos={setDocumentacionReplanteo}
                newFiles={newFilesUpload}
                setNewFiles={setNewFilesUpload}
                ruta={`OBRA_${obra ? obra.ID_incidencia : ''}_${obra ? obra.AreaGOM[0].GOMS[0].ID_GOM : ''}_PlanoAuxiliarFinal_`}
            />
        </ElementStepperContent>
    )
}
