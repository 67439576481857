import { useDispatch } from 'react-redux'
import { hideIconMenu } from '../../../../shared/Infraestructure/SliceGenerico'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../shared/Infraestructure/AdapterStore'
import { setUpdateAutoInventario } from '../../shared/infrastructure/slice'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { AdapterConfigure } from '../../shared/infrastructure/AdapterConfigure'
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom'

export const Controller = () => {

  const dispatch = useDispatch()
  const navigate: NavigateFunction = useNavigate()
  const { codigo } = useParams<{ codigo: string }>()
  const { dataAutoInventario } = useSelector((state: RootState) => state.autoInventario)
  const { ROUTE_AUTO_INVENTARIO } = AdapterConfigure

  const init = async () => {
    try {
      dispatch(hideIconMenu())
      const updateAutoInventario = dataAutoInventario.find(e => e.Codigo === codigo)
      if (!updateAutoInventario) {
        navigate(ROUTE_AUTO_INVENTARIO, { replace: true })
      }
      dispatch(setUpdateAutoInventario(updateAutoInventario))
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error').then(() => navigate(ROUTE_AUTO_INVENTARIO))
    }
  }

  return {
    init
  }
}