import { ElementBreadCrumb } from '../../../shared/Components/ElementBreadCrumb';
import { InputSelect} from '../../../shared/Components/ElementInputsCostume';
import { ElementItemCardGenerico } from '../../../shared/Components/Programados/ElementItemCardGenerico';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import { DtoResponseSelectSalidaAlmacen } from '../Domain/DtoResponseSelectSalidaAlmacen';
import './Style.scss';

interface PropsViewMain {
    salidaAlmacenAux: DtoResponseSelectSalidaAlmacen[];
    onChange: (name: string, value: any) => void;
    formSearchAlmacen: {
        values: any;
        touched: any;
        errors: any;
        handleBlur: Function;
      };
      selectAlmacenUsuario:any[];
};

// const animatedComponents = makeAnimated();
export const ViewMain = (props: PropsViewMain) => {
    const {
        onChange,
        formSearchAlmacen,
        selectAlmacenUsuario,
        salidaAlmacenAux,
    } = props;

    const languageTranslate = LanguageTranslate();

    return (
        
        <div className='ViewProfile'>
            <div className="container-fluid">
                {/** BLOQUE DE BREADCRUMB */}
                <ElementBreadCrumb list={[{ text: languageTranslate.moduloMain.textoLogistica, navigate: true, path: '/main/logistica' }, { text: languageTranslate.menu['052'].titulo, navigate: false, path: '' }]} />

                <InputSelect
                        label={languageTranslate.moduloLogistica.firmaAlbaran.textSelectAlmacen}
                        name='selectSearch'
                        onChange={onChange}
                        values={formSearchAlmacen.values}
                        isRequired
                        options={selectAlmacenUsuario}
                        loading={false}
                        disabled={false}
                        disabledVirtualized
                    />

                {salidaAlmacenAux.length ? salidaAlmacenAux.map((row, index) => (
                        <ElementItemCardGenerico
                            key={index}
                            icono
                            linkIcono1={`/main/logistica/firmaAlbaran/${row.ID_DespachoAlmacen}`}
                            data1={row.Almacen.Codigo}
                            data2={row.Almacen.Nombre}
                            data3={row.OrdenTrabajo}
                            data4={row.Estado.Nombre}

                            text1={
                                <span style={{ fontSize: '15px', paddingLeft: '0px', color: 'white', fontWeight: 'bold' }} >
                                    {languageTranslate.moduloLogistica.firmaAlbaran.textCodigoAlmacen}
                                </span>
                            }
                            text2={
                                <span style={{ fontSize: '15px', paddingLeft: '0px', color: 'white', fontWeight: 'bold' }} >
                                    {languageTranslate.moduloLogistica.firmaAlbaran.textNombreAlmacen}
                                </span>
                            }
                            text3={
                                <span style={{ fontSize: '15px', paddingLeft: '0px', color: 'white', fontWeight: 'bold' }} >
                                    {languageTranslate.moduloLogistica.firmaAlbaran.textOrdenTrabajo}
                                </span>
                            }
                            text4={
                                <span style={{ fontSize: '15px', paddingLeft: '0px', color: 'white', fontWeight: 'bold' }} >
                                    {languageTranslate.moduloLogistica.firmaAlbaran.textEstadoNombre}
                                </span>
                            }
                        />
                )) : <div className='d-flex justify-content-center mt-5 text-muted'><label>{languageTranslate.moduloLogistica.firmaAlbaran.textSinRegistro}</label></div>
                }
            </div>
        </div >
    )
};