import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { DtoValorizacion } from '../../../Programados/Trabajos/Asignacion/Domain/DtoValorizacion';
import { EntityMenu } from '../../../shared/Domain/EntityMenu';
import { EntityNotificacionTerminos } from '../../../shared/Domain/EntityNotificacionTerminos';
import { ErrorCostume } from '../../../shared/Domain/ErrorCostume';
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import { changeLoadData } from '../../../shared/Infraestructure/SliceAuthentication';
import { addLoading, changeAutoInventario, changeSaludo, removeLoading, saveDataStockPersonalTotal } from '../../../shared/Infraestructure/SliceGenerico';
import { UseCaseSelectStockPersonal } from '../Application/UseCaseSelectStockPersonal';
import { UseCaseSelectTrabajosLocal } from '../Application/UseCaseSelectTrabajosLocal';
import { DtoResponseSelectStockPersonal } from '../Domain/DtoResponseSelectStockPersonal';
import { DtoTrabajos } from '../../Home/Domain/DtoTrabajos';
import { AdapterConfigure } from './AdapterConfigure';
import { RepositoryImplMain } from './RepositoryImpl';
import { AdapterStorage } from '../../../shared/Infraestructure/AdapterStorage';
import { UseCaseSelectTrabajos } from '../Application/UseCaseSelectTrabajos';
import { UseCaseSelectStockPersonalLocal } from '../Application/UseCaseSelectStockPersonalLocal';
import { UseCaseVerifyToken } from '../Application/UseCaseVerifyToken';
import { UseCaseSelectTrabajosDE } from '../Application/UseCaseSelectTrabajosDE';
import { UseCaseVerifyAutoInventario } from '../Application/UseCaseVerifyAutoInventario';
import { UseCaseSelectStockAlmacen } from '../Application/UseCaseSelectStockAlmacen';
import { UseCaseSelectConsumoMaterial } from '../Application/UseCaseSelectConsumoMaterial';

type ChartData = { name: string; amount: number };
export class ValorizacionDashboard {
    constructor(private valorizaciones: DtoValorizacion[]) { }
    static LABELS = ["Preliquidada", "Liquidada", "Certificada", "Producción"];

    private static groupByValorization = (
        payload: DtoValorizacion[]
    ): ChartData[] => {
        return ValorizacionDashboard.LABELS.reduce((prev: ChartData[], next) => {
            const label = AdapterGenerico.replaceAccents(next).toUpperCase();
            const amount = payload.reduce((p: number, n) => {
                if (n.Ultimo_Estado_Interno.Descripcion === label) return p + 1;
                return p;
            }, 0);
            return [...prev, { name: next, amount }];
        }, []);
    };

    getAmounts(): number[] {
        const records = ValorizacionDashboard.groupByValorization(
            this.valorizaciones
        );
        return Object.values(records).map((d) => d.amount);
    }
    getLabels(): string[] {
        const records = ValorizacionDashboard.groupByValorization(
            this.valorizaciones
        );
        return Object.values(records).map((d) => d.name);
    }
}


export const Controller = () => {
    let { menu, user } = useSelector((state: RootState) => state.auth)
    const { websocket, dbLocal } = useSelector((state: RootState) => state.generico)
    const [Notifications, setNotificacions] = useState<EntityNotificacionTerminos[]>([]);
    const [showModal, setShowModal] = useState(false);
    const languageTranslate = LanguageTranslate();
    const dispatch: Dispatch = useDispatch();
    const navigate = useNavigate();
    const [trabajos, setTrabajos] = useState<DtoTrabajos[]>([]);
    const [stockPersonal, setStockPersonal] = useState<DtoResponseSelectStockPersonal[]>([]);

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    menu = menu.filter(row => !row.esOpcion && false)

    const init = async () => {
        dispatch(changeSaludo(true));
        try {
            // terms();
            await verifyToken()
            await verifyAutoInventario()
            await loadTrabajos();
        } catch (error: any) {
            console.error(error);
            let err: ErrorCostume = new ErrorCostume((error as Error).message);
            AdapterGenerico.createMessage("Alerta", err.message, "warning", false);
        } finally {
            dispatch(removeLoading());
        }
    };

    const verifyToken = async () => {
        if (navigator.onLine)
            await new UseCaseVerifyToken(repository).exec()
    }

    const verifyAutoInventario = async () => {
        if (navigator.onLine) {
            const response = await new UseCaseVerifyAutoInventario(repository).exec(user)
            dispatch(changeAutoInventario(response))
        }
    }

    const onClickAyuda = async () => {
        let confirmacion: boolean = await AdapterGenerico.createMessage(languageTranslate.moduloMain.textoTituloPreguntaAyuda, languageTranslate.moduloMain.textoDescripcionPreguntaAyuda, 'question', true);
        if (!confirmacion) return;
        window.open('https://www.cobraperu.com.pe/helpcenter/sistema-sgso/', '_blank');
    };

    const loadTrabajos = async () => {
        const { loadData } = AdapterStorage.get('loadData');
        if (loadData) {
            dispatch(changeLoadData(false));
            dispatch(addLoading({ textLoading: "Cargando trabajos..." }));

            //#region Trabajos
            const startTime = performance.now();
            const trabajos = ['004'].includes(user.Pais.Codigo)
                ? await new UseCaseSelectTrabajosDE(repository).exec(user)
                : await new UseCaseSelectTrabajos(repository).exec(user)
            const endTime = performance.now();
            console.log(`Tiempo de ejecución: ${((endTime - startTime) / 1000).toFixed(2)} segundos (Trabajos)`);
            //#endregion

            //#region Stock Personal
            const startTime1 = performance.now();
            await new UseCaseSelectStockPersonal(repository).exec(user);
            const endTime1 = performance.now();
            console.log(`Tiempo de ejecución: ${((endTime1 - startTime1) / 1000).toFixed(2)} segundos (StockPersonal)`);
            //#endregion

            //#region Stock Almacen
            if (['004'].includes(user.Pais.Codigo)) {
                const startTime2 = performance.now();
                await new UseCaseSelectStockAlmacen(repository).exec(user)
                const endTime2 = performance.now();
                console.log(`Tiempo de ejecución: ${((endTime2 - startTime2) / 1000).toFixed(2)} segundos (StockAlmacen)`);
            }
            //#endregion

            //#region Consumo Material
            if (['004'].includes(user.Pais.Codigo)) {
                const idsTrabajos = trabajos.map(e => e.ID_Trabajo)
                const startTime2 = performance.now();
                await new UseCaseSelectConsumoMaterial(repository).exec(user, idsTrabajos)
                const endTime2 = performance.now();
                console.log(`Tiempo de ejecución: ${((endTime2 - startTime2) / 1000).toFixed(2)} segundos (ConsumoMaterial)`);
            }
            //#endregion
            dispatch(removeLoading());
        }
        dispatch(addLoading({ textLoading: "Cargando trabajos..." }))

        //#region Trabajos
        const trabajos = await new UseCaseSelectTrabajosLocal(repository).exec(user)
        if (trabajos.length < 1) throw Error(`No tiene trabajos asignados.`)
        setTrabajos(trabajos)
        //#endregion

        //#region Stock Personal
        const stocksPersonal = await new UseCaseSelectStockPersonalLocal(repository).exec(user)
        if (stocksPersonal.length < 1) throw Error(`No tiene stock personal.`)
        loadStockPersonal(stockPersonal)
        setStockPersonal(stocksPersonal)
        //#endregion
    };

    const loadStockPersonal = async (respStockPersonal: DtoResponseSelectStockPersonal[]) => {
        fieldStock(respStockPersonal)
        sumaStockPersonal(respStockPersonal);
    };

    const fieldStock = async (respStockPersonal: DtoResponseSelectStockPersonal[]): Promise<void> => {
        respStockPersonal.forEach((e) => {
            if (e.TipoStock.Nombre === "NUEVO" || e.TipoStock.Nombre === "MAQUINARIA") {
                e.Stock = e.Despacho - e.Devolucion - e.Liquidacion + e.DespachoTR - e.DevolucionTR;
            } else {
                e.Stock = e.Liquidacion - e.Devolucion;
            }
        });
    }

    const sumaStockPersonal = async (respStockPersonal: DtoResponseSelectStockPersonal[]): Promise<void> => {
        const validarStock = (stock: number) => stock > 0 ? stock : 0
        const stockPersonalTotal: DtoResponseSelectStockPersonal[] = respStockPersonal.reduce((acumulador: DtoResponseSelectStockPersonal[], clave) => {
            const indice = acumulador.findIndex((p: DtoResponseSelectStockPersonal) => p.CodigoLlave === clave.CodigoLlave);
            if (indice !== -1) {
                acumulador[indice].TotalStock += clave.Stock;
                acumulador[indice].TotalDespacho += clave.Despacho;
                acumulador[indice].TotalDevolucion += clave.Devolucion;
                acumulador[indice].TotalLiquidacion += clave.Liquidacion;
            } else {
                acumulador.push({
                    ...clave, CodigoLlave: clave.CodigoLlave,
                    TotalStock: clave.Stock,
                    TotalDespacho: clave.Despacho,
                    TotalDevolucion: clave.Devolucion,
                    TotalLiquidacion: clave.Liquidacion
                });
            }
            return acumulador;
        }, []).map((item) => ({ ...item, TotalStock: validarStock(item.TotalStock) }));
        dispatch(saveDataStockPersonalTotal(stockPersonalTotal));
    };

    const onClickReport = async () => {
        let confirmacion: boolean = await AdapterGenerico.createMessage(languageTranslate.moduloMain.textoTituloPreguntaReporte, languageTranslate.moduloMain.textoDescripcionPreguntaReporte, 'question', true);
        if (!confirmacion) return;
        window.open('https://www.cobraperu.com.pe/glpi/', '_blank')
    };

    const onClickMenu = (element: EntityMenu) => {
        navigate(`/${element.ruta}`, { replace: true });
    };

    const groupByDescription = (): ChartData[] => {
        return trabajos.reduce((prev: ChartData[], next) => {
            const description = next.Ultimo_Estado_Interno.Descripcion;
            const idx = prev.findIndex((e) => e.name === description);

            if (idx === -1) return [...prev, { name: description, amount: 1 }];
            else {
                prev[idx].amount = prev[idx].amount + 1;
                return prev;
            }
        }, []);
    };

    const getDashboardAmounts = (): number[] => {
        const records = groupByDescription();
        return Object.values(records).map((d) => d.amount);
    };
    const getDashboardLabels = (): string[] => {
        const records = groupByDescription();
        return Object.values(records).map((d) => d.name);
    };
    return {
        init,
        menu,
        getDashboardAmounts,
        getDashboardLabels,
        onClickAyuda,
        onClickReport,
        onClickMenu,
        trabajos,
        showModal,
        setShowModal,
        Notifications,
        stockPersonal
    };
}