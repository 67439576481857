import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../context/shared/Infraestructure/RepositoryImplGenerico";
import { EntityMain } from "../Domain/EntityMain";
import { AdapterConfigure } from "./AdapterConfigure";
import { EntityParams } from "../../context/shared/Domain/EntityParams";
import { DtoResponsePais } from "../Domain/DtoResponsePais";
import { DtoResponsePersonal } from "../Domain/DtoResponsePersonal";
import { AdapterGenerico } from "../../context/shared/Infraestructure/AdapterGenerico";
import { DtoResponseEstadoInterno } from "../Domain/DtoResponseEstadoInterno";
import { EntityProcessOffline } from "../../context/shared/Domain/EntityProcessOffline";
import { AdapterStorage } from "../../context/shared/Infraestructure/AdapterStorage";
import { EntityInformationDataInitial } from "../../context/shared/Domain/EntityInformationDataInitial";
import { AdapterData } from "../../context/shared/Infraestructure/AdapterData";
import { DtoUnidadObraMaterial } from "../Domain/DtoUnidadObraMaterial";
import { DtoPrecioEspecialidad } from "../Domain/DtoPrecioEspecialidad";
import { FTPService } from "../../context/shared/Infraestructure/AdapterFileSystem";
import { DtoItems } from "../Domain/DtoItems";
import { DtoDocumentacionBaremoPEX } from "../Domain/DtoDocumentacionBaremoPEX";
import { DtoPrecioMaterial } from "../Domain/DtoPrecioMaterial";
import { DtoContratoOT } from "../Domain/DtoContratoOT";
import { DtoDocumentacionPEX } from '../Domain/DtoDocumentacionPEX';

export class RepositoryImplMain extends RepositoryImplGenerico<EntityMain> implements RepositoryMain {

    public async initialService(params: EntityParams): Promise<void> {
        await this._initialService(params);
    }

    private async _initialService(params: EntityParams): Promise<void> {
        let { servicesCalleds }: { servicesCalleds: EntityInformationDataInitial } = AdapterStorage.get('servicesCalleds');

        await Promise.all([
            this.selectPais(params, servicesCalleds),
            this.selectPersonal(params, servicesCalleds),
            this.selectEstadoInterno(params, servicesCalleds),
            this.selectManoObraGlobal(params, servicesCalleds),
            this.selectPrecioMaterial(params, servicesCalleds),
            this.selectCuadrillas(params, servicesCalleds),
            this.selectItems(params, servicesCalleds),
            this.selectDocumentacionBaremoPEX(params, servicesCalleds),
            this.selectPrecioEspecialidad(params, servicesCalleds),
            this.selectContratoOT(params, servicesCalleds),
            this.selectDocumentacionPEX(params, servicesCalleds),
        ]);
        AdapterStorage.set('servicesCalleds', servicesCalleds);
    }

    private async selectPais(params: EntityParams, informationDataInitial: EntityInformationDataInitial): Promise<void> {
        let entity: keyof EntityInformationDataInitial = 'Pais';
        let count = await this.dbLocal.countStore(entity);
        if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
            const url: string = `${this.urlBase}${AdapterConfigure.SELECT_PAIS}`;
            const _params = JSON.stringify({ Filtros: [...params[entity].Filtros,] });
            let data: Array<DtoResponsePais> = await this.service.call<any>('POST', url, _params, 'bearer', 'json', 'json', {});
            Object.assign(informationDataInitial, { [entity]: { called: true, count: data.length, date: new Date() } });
            await this.dbLocal.insertDataStore({ nameStore: entity, data });
        }
    }

    public async selectEstadoInterno(params: EntityParams, informationDataInitial: EntityInformationDataInitial): Promise<void> {
        let entity: keyof EntityInformationDataInitial = 'EstadoInterno';
        let count = await this.dbLocal.countStore(entity);
        if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
            const _params = { Filtros: [...params[entity].Filtros] };
            const url: string = `${this.urlBase}${AdapterConfigure.SELECT_ESTADOINTERNO}`;
            let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(_params), 'bearer', 'json', 'json', {});
            const data: DtoResponseEstadoInterno[] = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response;
            Object.assign(informationDataInitial, { [entity]: { called: true, count: data.length, date: new Date() } });
            await this.dbLocal.insertDataStore({ nameStore: entity, data });
        }
    }

    public async selectItems(params: EntityParams, informationDataInitial: EntityInformationDataInitial): Promise<void> {
        let entity: keyof EntityInformationDataInitial = 'Items';
        let count = await this.dbLocal.countStore(entity);
        if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
            const _params = { Filtros: [...params[entity].Filtros] };
            const url: string = `${this.urlBase}${AdapterConfigure.SELECT_ITEMS}`;
            let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(_params), 'bearer', 'json', 'json', {});
            const data: DtoItems[] = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response;
            Object.assign(informationDataInitial, { [entity]: { called: true, count: data.length, date: new Date() } });
            await this.dbLocal.insertDataStore({ nameStore: entity, data });
        }
    }

    public async selectContratoOT(params: EntityParams, informationDataInitial: EntityInformationDataInitial): Promise<void> {
        let entity: keyof EntityInformationDataInitial = 'ContratoOT';
        let count = await this.dbLocal.countStore(entity);
        if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
            const _params = { Filtros: [...params[entity].Filtros] };
            const url: string = `${this.urlBase}${AdapterConfigure.SELECT_CONTRATO_OT}`;
            let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(_params), 'bearer', 'json', 'json', {});
            const data: DtoContratoOT[] = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response;
            Object.assign(informationDataInitial, { [entity]: { called: true, count: data.length, date: new Date() } });
            await this.dbLocal.insertDataStore({ nameStore: entity, data });
        }
    }

    public async selectDocumentacionBaremoPEX(params: EntityParams, informationDataInitial: EntityInformationDataInitial): Promise<void> {
        let entity: keyof EntityInformationDataInitial = 'DocumentacionBaremoPEX';
        let count = await this.dbLocal.countStore(entity);
        if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
            const _params = { Filtros: [...params[entity].Filtros] };
            const url: string = `${this.urlBase}${AdapterConfigure.SELECT_DOCUMENTACIONBAREMOPEX}`;
            const response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(_params), 'bearer', 'json', 'json', {});
            const data: DtoDocumentacionBaremoPEX[] = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response;
            Object.assign(informationDataInitial, { [entity]: { called: true, count: data.length, date: new Date() } });
            await this.dbLocal.insertDataStore({ nameStore: entity, data });
        }
    }

    public async selectDocumentacionPEX(params: EntityParams, informationDataInitial: EntityInformationDataInitial): Promise<void> {
        let entity: keyof EntityInformationDataInitial = 'DocumentacionPEX';
        let count = await this.dbLocal.countStore(entity);
        if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
            const _params = { Filtros: [...params[entity].Filtros] };
            const url: string = `${this.urlBase}${AdapterConfigure.SELECT_DOCUMENTACIONPEX}`;
            const response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(_params), 'bearer', 'json', 'json', {});
            const data: DtoDocumentacionPEX[] = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response;
            Object.assign(informationDataInitial, { [entity]: { called: true, count: data.length, date: new Date() } });
            await this.dbLocal.insertDataStore({ nameStore: entity, data });
        }
    }

    public async selectManoObraGlobal(params: EntityParams, informationDataInitial: EntityInformationDataInitial): Promise<void> {
        let entity: keyof EntityInformationDataInitial = 'ManoObraGlobal';
        let count = await this.dbLocal.countStore(entity);
        if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
            const _params = { Filtros: [...params[entity].Filtros] };
            const url: string = `${this.urlBase}${AdapterConfigure.SELECT_MANO_OBRA_GLOBAL}`;
            let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(_params), 'bearer', 'json', 'json', {});
            const data: DtoUnidadObraMaterial[] = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response;
            Object.assign(informationDataInitial, { [entity]: { called: true, count: data.length, date: new Date() } });
            await this.dbLocal.insertDataStore({ nameStore: entity, data });
        }
    }
    
    public async selectPrecioEspecialidad(params: EntityParams, informationDataInitial: EntityInformationDataInitial): Promise<void> {
        let entity: keyof EntityInformationDataInitial = 'PrecioEspecialidad';
        let count = await this.dbLocal.countStore(entity);
        if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
            const _params = { Filtros: [...params[entity].Filtros] };
            const url: string = `${this.urlBase}${AdapterConfigure.SELECT_PRECIO_ESPECIALIDAD}`;
            let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(_params), 'bearer', 'json', 'json', {});
            const data: DtoPrecioEspecialidad[] = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response;
            Object.assign(informationDataInitial, { [entity]: { called: true, count: data.length, date: new Date() } });
            await this.dbLocal.insertDataStore({ nameStore: entity, data });
        }
    }

    public async selectPrecioMaterial(params: EntityParams, informationDataInitial: EntityInformationDataInitial): Promise<void> {
        let entity: keyof EntityInformationDataInitial = 'PrecioMaterial';
        let count = await this.dbLocal.countStore(entity);
        if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
            const _params = { Filtros: [...params[entity].Filtros] };
            const url: string = `${this.urlBase}${AdapterConfigure.SELECT_PRECIO_MATERIAL}`;
            let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(_params), 'bearer', 'json', 'json', {});
            const data: DtoPrecioMaterial[] = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response;
            Object.assign(informationDataInitial, { [entity]: { called: true, count: data.length, date: new Date() } });
            await this.dbLocal.insertDataStore({ nameStore: entity, data });
        }
    }

    public async selectCuadrillas(params: EntityParams, informationDataInitial: EntityInformationDataInitial): Promise<void> {
        let entity: keyof EntityInformationDataInitial = 'Cuadrillas';
        let count = await this.dbLocal.countStore(entity);
        if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
            const _params = { Filtros: [...params[entity].Filtros] };
            const url: string = `${this.urlBase}${AdapterConfigure.SELECT_CUADRILLAS}`;
            let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(_params), 'bearer', 'json', 'json', {});
            const data: DtoResponseEstadoInterno[] = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response;
            Object.assign(informationDataInitial, { [entity]: { called: true, count: data.length, date: new Date() } });
            await this.dbLocal.insertDataStore({ nameStore: entity, data });
        }
    }

    private async selectPersonal(params: EntityParams, informationDataInitial: EntityInformationDataInitial): Promise<void> {
        let entity: keyof EntityInformationDataInitial = 'Personal';
        let count = await this.dbLocal.countStore(entity);
        if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
            let url: string = `${this.urlBase}${AdapterConfigure.SELECT_PERSONAL}`;
            const _params = JSON.stringify({ Filtros: params[entity].Filtros });

            let data: DtoResponsePersonal[] = await this.service.call<any>("POST", url, _params, "basic", "json", 'json', {}, 0);
            Object.assign(informationDataInitial, { [entity]: { called: true, count: data.length, date: new Date() } });
            AdapterData.personal = data;

            await this.dbLocal.insertDataStore({ nameStore: entity, data });
        } else {
            let info = await this.dbLocal.selectAllStore(entity);
            AdapterData.personal = info;
        }
    }

    public async executeProcess<T>(params: EntityProcessOffline): Promise<T> {
        let response: T | null = null;
        switch (params.type) {
            case 'api':
                response = await this.service.call<any>(params.method, this.urlBase + params.url, params.body, params.typeAuth, params.typeRequest, params.typeResponse, {}, 3);
                break;
            case 'ws':
                response = await this.websocket.emit(params.url, params.body);
                break;
            default:
                break;
        }
        return response as T;
    }

    public async uploadFiles(files: File[]): Promise<any> {
        const promises: Promise<any>[] = [];
        for (const file of files) {
            promises.push(FTPService.setFileToServer(file.name, file));
        }
        await Promise.all(promises);
    }

}