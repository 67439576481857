import React from "react";
import CardsStyled from "./Cards.module.scss";

type CardsProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export const CardContainer: React.FC<CardsProps> = (payload) => {
  let { className, ...props } = payload;
  className = `${CardsStyled.CardContainer} ${className} mb-4 shadow rounded`
  return (
    <div
      className={className}
      {...props}
    />
  );
};

type CardsHeaderProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;
export const CardHeader: React.FC<CardsHeaderProps> = (payload) => {
  let { className, ...props } = payload;
  className = `${CardsStyled.CardHeader} ${className} d-flex align-items-center justify-content-between rounded-top`
  return (
    <div
      className={className}
      {...props}
    />
  );
};

type CardBodyProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;
export const CardBody: React.FC<CardBodyProps> = (payload) => {
  let { className, ...props } = payload;
  className = `${CardsStyled.CardBody} ${className} rounded-bottom`
  return (
    <div
      className={className}
      {...props}
    />
  );
};
