import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { EntityMain } from "../Domain/EntityMain";
import { EntityDelegacion } from "../../../shared/Domain/EntityDelegacion";
import { EntityOTs } from "../../../shared/Domain/EntityOTs";
import { EntityPais } from "../../../shared/Domain/EntityPais";
import { DtoReqChangePassword } from "../Domain/DtoReqChangePassword";
import { AdapterEncrypt } from "../../../shared/Infraestructure/AdapterEncrypt";
import { EntityPersonal } from "../../../shared/Domain/EntityPersonal";
import { AdapterData } from "../../../shared/Infraestructure/AdapterData";
import { AdapterConfigure } from "./AdapterConfigure";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";

export class RepositoryImplMain extends RepositoryImplGenerico<EntityMain> implements RepositoryMain {
    public async selectPais(): Promise<EntityPais[]> {
        return [];
        // return this.dbLocal.selectAllStore('Pais');
    }

    public async selectDelegaciones(): Promise<EntityDelegacion[]> {
        return [];
        // return this.dbLocal.selectAllStore('Delegacion');
    }

    public async selectOTs(): Promise<EntityOTs[]> {
        return AdapterData.ot;
        // return this.dbLocal.selectAllStore('OT');
    }

    public async selectPersonal(): Promise<EntityPersonal[]> {
        return AdapterData.personal;
        // return this.dbLocal.selectAllStore('Personal');
    }

    public async changePassword(params: DtoReqChangePassword): Promise<any> {

        params.Contrasenia = AdapterEncrypt.encrypt(params.Contrasenia, process.env.REACT_APP_KEY_ENCRYPT_V1 || '', false);
        params.NuevaContrasenia = AdapterEncrypt.encrypt(params.NuevaContrasenia, process.env.REACT_APP_KEY_ENCRYPT_V1 || '', false);

        let url: string = `${this.urlBase}${AdapterConfigure.CHANGE_PASSWORD}`;

        let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'json', {});
        response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response;
        return response;
    }
}