import { RepositoryMain } from '../Domain/RepositoryMain'
import { RepositoryImplGenerico } from '../../../shared/Infraestructure/RepositoryImplGenerico'
import { AdapterConfigure } from './AdapterConfigure'
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico'
import { EntityDataUsuario } from '../../../shared/Domain/EntityDataUsuario'
import { DtoResponseSelectStockPersonal } from '../../../Master/Home/Domain/DtoResponseSelectStockPersonal'
import { DtoRequestSelectStockPersonal } from '../../../Master/Home/Domain/DtoRequestSelectStockPersonal'

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

  public async selectStockPersonalLocal(user: EntityDataUsuario): Promise<DtoResponseSelectStockPersonal[]> {
    const result: DtoResponseSelectStockPersonal[] = await this.dbLocal.selectAllStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockPersonal`)
    return result.filter(e => e.FlagPex === 1)
  }

  public async selectStockPersonal(params: DtoRequestSelectStockPersonal, user: EntityDataUsuario): Promise<DtoResponseSelectStockPersonal[]> {
    let url: string = `${this.urlBase}${AdapterConfigure.SELECT_STOCK_PERSONAL}`
    let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'json', {})
    response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response
    for (const stockPersonal of response) {
      stockPersonal.Personal = { Identificacion: user.Identificacion }
    }
    response = await this._saveLocalStockPersonal(response, user)
    return response
  }

  private async _saveLocalStockPersonal(response: any, user: EntityDataUsuario): Promise<DtoResponseSelectStockPersonal[]> {
    const storeExist = await this.dbLocal.existsStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockPersonal`)
    if (!storeExist) throw Error(`Error al obtener coleccion StockPersonal`)
    await this.dbLocal.clearStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockPersonal`)
    await this.dbLocal.insertDataStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockPersonal`, data: response })
    return response
  }

}