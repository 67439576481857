import { RepositoryMain } from "../Domain/RepositoryMain";
import { EntityDataUsuario } from "../../../shared/Domain/EntityDataUsuario";
import { DtoResponseSelectStockPersonal } from "../../../Master/Home/Domain/DtoResponseSelectStockPersonal";

export class UseCaseSelectStockPersonalLocal {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(user: EntityDataUsuario): Promise<DtoResponseSelectStockPersonal[]> {
        return await this._exec(user);
    }

    private async _exec(user: EntityDataUsuario): Promise<DtoResponseSelectStockPersonal[]> {
        return await this.repository.selectStockPersonalLocal(user);
    }
}
