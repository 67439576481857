import createApiClient from './apiClient';
import { toBase64, getMimeTypeFromFileName } from './utils';
import { FileReady } from '../../Domain/FileReady';
import { AdapterConfigureGenerico } from '../AdapterConfigureGenerico';

function getFileToServer(fileName: string, Exportar: number): Promise<FileReady | string> {
    
    const headers = {
        'Authorization': 'Basic ' + toBase64(`${process.env.REACT_APP_User_Auth_FILE}:${process.env.REACT_APP_Pass_Auth_FILE}`),
        'request-decrypt-response': 'yes',
        'Content-Type': 'application/json',
    };

    const apiClient = createApiClient(AdapterConfigureGenerico.PATH_API_FILE as string, headers);

    const lastIndex = fileName.indexOf('-');
    const directory = fileName.substring(0, lastIndex).split('_').filter((elem) => elem !== '');
    
    const requestBody = JSON.stringify({
        directory,
        fileName,
        user: AdapterConfigureGenerico.user_API_FILE,
    });

    return new Promise(async (resolve, reject) => {
        try {
            const buffer: ArrayBuffer = await apiClient.post('/download', requestBody, { responseType: 'arraybuffer' });

            const array = new Uint8Array(buffer);
            const mimeType = getMimeTypeFromFileName(fileName);
            const blob = new Blob([array], { type: mimeType });
            const downloadUrl = URL.createObjectURL(blob);
            switch (Exportar) {
                case 0:
                    const fileReady: FileReady = { FileName: fileName, Archivo: downloadUrl };
                    resolve(fileReady);
                    break;

                case 1:
                    let a = document.createElement('a');
                    a.className = 'hidden';
                    a.href = downloadUrl;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    resolve('OK');
                    break;

                default:
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = function () {
                        const base64data = reader.result;
                        const fileReady: FileReady = { FileName: fileName, Archivo: base64data };
                        resolve(fileReady);
                    };
                    break;
            }
        } catch (error: any) {
            if (error?.response?.status === 406) {
                resolve('OK')
            }
            reject(error);
        }
    });
}

export { getFileToServer };
