import { Children, Dispatch, SetStateAction, useState } from 'react'
import { OffCanvasBottom } from '../../../../shared/Components/ReactBootstrap/OffCanvasBottom/OffCanvasBottom'
import { IDetectedBarcode, Scanner } from '@yudiel/react-qr-scanner'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'

interface iProps {
    showModal: boolean
    setShowModal: Dispatch<SetStateAction<boolean>>
}

export const Series = (props: iProps) => {

    const { setShowModal, showModal } = props

    return (
        <OffCanvasBottom
            height={90}
            show={showModal}
            setShow={setShowModal}
            children={Body(props)}
        />
    )
}

const Body = (props: iProps) => {

    const [data, setData] = useState<string[]>([])

    const onScan = (detectedCodes: IDetectedBarcode[]) => {
        try {
            const codes = detectedCodes.map(e => e.rawValue)
        } catch (error) {
            console.error(error)
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error')
        }
    }

    return (
        <div>
            <Scanner
                formats={['code_128']}
                onScan={onScan}
                scanDelay={1000}
            />
            {
                Children.toArray(
                    data.map(e => <p>{e}</p>)
                )
            }
        </div>
    )
}
