//Dependency External
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useSelector } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';

//Adapters
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { addLoading, removeLoading } from '../../../shared/Infraestructure/SliceGenerico';
import { AdapterConfigure } from './AdapterConfigure';
import { AdapterValidator } from '../../../shared/Infraestructure/AdapterValidator';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';

//Repository
import { RepositoryImplMain } from './RepositoryImplMain';
import { UseCaseRecuperarPassword } from '../Application/UseCaseRecuperarPassword';

//UseCase

//Entities


export const Controller = () => {
    const { websocket, dbLocal } = useSelector((state: RootState) => state.generico);
    const dispatch: Dispatch = useDispatch();
    const navigate: NavigateFunction = useNavigate();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    const form = useFormik({
        initialValues: { username: '', identificacion: '', correoElectronico: '', },
        validationSchema: Yup.object({
            username: Yup.string().required('Ingresar Usuario').min(3, 'Mímimo 3 caracteres'),
            identificacion: Yup.string().required('Ingresar Número Doc. Identidad').matches(/^[a-zA-Z0-9]{0,12}$/i, 'El Número Doc. Identidad es incorrecto'),
            correoElectronico: Yup.string().required('Ingresar Correo Electrónico').matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'El Correo Electrónico es incorrecto'),
        }),

        onSubmit: (values, formikHelpers) => { },
    });

    const onChange = (name: string, value: any) => {
        if (value === null) { return; }
        form.setFieldValue(name, value);
    };

    const onSubmit = async (e: Event) => {
        try {
            e.preventDefault();
            e.stopPropagation();

            try { await form.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await form.validateForm()); } catch (error) { AdapterGenerico.createMessage('Incompleto', (error as Error).message, 'warning', false); return null; }

            dispatch(addLoading({ textLoading: 'Cargando...' }));

            await (new UseCaseRecuperarPassword(repository)).exec({ IdUsuario: 0, Usuario: form.values.username.trim(), Identificacion: form.values.identificacion.trim(), Email: form.values.correoElectronico.trim() });

            dispatch(removeLoading());
            form.resetForm();
            AdapterGenerico.createMessage('Exitoso', 'Se realizó la recuperación de contraseña correctamente, recibirá un mensaje al correo electrónico registrado', 'success');
            onClickLogin();
        } catch (error) {
            console.error(error);
            dispatch(removeLoading());
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false);
        };
    };

    const onClickLogin = () => {
        navigate(`/${process.env.REACT_APP_ROUTE_PATH_LOGIN}`, { replace: true });
    };


    return {
        form,
        onChange,
        onSubmit,
        onClickLogin,
    };
}