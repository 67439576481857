import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseEnviarFirma {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: object): Promise<any> {
        return await this._exec(params);
    }

    private async _exec(params: object): Promise<any> {
        return await this.repository.enviarFirma(params);
    }
}