import { DtoValueOnSelect } from '../../../Programados/TrabajosDE/Preliquidar/Domain/DtoValueOnSelect'
import { InputSelect } from '../ElementInputsCostume'
import { LanguageTranslate } from '../../Infraestructure/LanguageTranslate'
import { DtoHPList } from '../../../Programados/TrabajosDE/Trabajos/Domain/DtoTrabajosDE'
import { DtoHPListType } from '../../Domain/Dto/DtoHPListType'
import { DtoNodoDoc } from '../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { useEffect, useState } from 'react'

interface iInitialValues {
  address: DtoValueOnSelect<DtoHPList>
}

interface iProps {
  dataAddress: DtoHPListType[]
  onChange: (name: string, value: any) => void
  nodo: DtoNodoDoc
  save: boolean
}

//#region Solo para Alemania (DE) Caso especial
export const AddressDE = ({ dataAddress, onChange, nodo, save }: iProps) => {

  const langTranslate = LanguageTranslate()

  const [initialValues, setInitialValues] = useState<iInitialValues>({ address: new DtoValueOnSelect() })
  const [disabled, setDisabled] = useState<boolean>(false)

  useEffect(() => {
    const File = nodo.Files.find(e => e.Estado.ID_Estado === 8888)
    if (File) {
      const addresss = dataAddress.find(e => `${e.ID_AddressList}@*@${e.tipo}@*@${e.Home_ID}` === File.NameOriginal)
      if (addresss) {
        const initialValues: iInitialValues = { address: new DtoValueOnSelect(), }
        initialValues.address.value = File.NameOriginal
        initialValues.address.label = `${addresss.Street} ${addresss.HouseNumber} ${addresss.HouseNumberAffix}`
        initialValues.address.dataComplete = addresss
        setInitialValues(initialValues)
        // if (verifyNumFiles(nodo) > 0) setDisabled(true)
      }
    }
  }, [dataAddress])

  useEffect(() => {
    const numFiles = verifyNumFiles(nodo)
    setDisabled(numFiles > 0 ? true : false)
  }, [save])

  return (
    <div className='w-100'>
      <InputSelect
        label={langTranslate.general.Direccion}
        name='address'
        onChange={(name, value) => { onChange(name, value) }}
        values={initialValues}
        isRequired
        options={dataAddress.map(e => ({
          label: `${e.Street} ${e.HouseNumber} ${e.HouseNumberAffix}`,
          value: `${e.ID_AddressList}@*@${e.tipo}@*@${e.Home_ID}`,
          dataComplete: e
        }))}
        disabled={disabled}
      />
    </div>
  )
}
//#endregion Solo para Alemania (DE) Caso especial

const verifyNumFiles = (nodo: DtoNodoDoc): number => {
  return nodo.Children.reduce((acc, ele) => {
    return acc + ele.Files.filter(e => e.Estado.ID_Estado === 1).length + verifyNumFiles(ele)
  }, 0)
}