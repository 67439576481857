import { AdapterGenerico } from '../Infraestructure/AdapterGenerico'
import './ElementInputFileImage.scss'

interface PropsView {
    onChange: (fileList: LoadImage[]) => any
    icon: string
    disabled?: boolean
    size?: number
    capture?: boolean
    multiple?: boolean
}

// export interface DetailUploadImage {
//     dataDB: DtoFotos[],
//     dataSubir: ArchivosGrabar[],
//     objectReplace?: any,
//     propiedad?: any,
// }
export class ArchivosGrabar {
    File: File = new File([''], '')
    Ruta: string = ''
}

export interface LoadImage {
    NombreOriginal: string
    File: File
}

export const ElementInputFileImage = (props: PropsView) => {

    const isImage = (fileList: FileList | null): any => {
        try {
            if (!fileList) return
            const fotos: LoadImage[] = []
            let error = { isError: false, fileName: '' }
            Array.from(fileList).forEach((file, i) => {
                if (!file.type.match('image/*')) error = { isError: true, fileName: file.name }
                const nameTemp = file.name
                const [extAux, ...fileNameTemp] = nameTemp.split('.').reverse()
                const nameAux = fileNameTemp.reverse().join('.')
                const img = `${i}${Math.floor(Date.now() / 1000)}III${nameAux.replaceAll('-', '').replaceAll(' ', '').replaceAll('_', '')}.${extAux.toUpperCase()}`
                const photo = new File([file], img, {
                    type: file.type,
                    lastModified: file.lastModified
                })
                fotos.push({ NombreOriginal: nameTemp, File: photo })
            })

            if (error.isError) throw new Error(`${error.fileName} no es una imagen`)

            props.onChange(fotos)
        } catch (error) {
            console.error(error)
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false)
        }
    }

    return (
        <label className="ElementInputFileImage">
            <input type="file" accept="image/*" multiple={props.multiple} capture={props.capture} onChange={(evt) => isImage(evt.target.files)} disabled={props.disabled} value="" />
            <i className={props.icon} style={{ fontSize: props.size || 30 }} />
        </label>
    )
}
