const SCHEMA: string = process.env.REACT_APP_MAESTRO_SEDE_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MAESTRO_SEDE_ENTITY || '';
const SELECT_TRABAJOS: string = '/SigoPwa/Programados/Gestion/Trabajos/Select';
const ROUTE_PROGRAMADOS: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS}`;
const ROUTE_TRABAJOS: string = `${ROUTE_PROGRAMADOS}/${process.env.REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_TRABAJOS}`;
const SELECT_OBRA: string = '/SigoPwa/Programados/Gestion/Obras/Select';
const UPDATE_TRABAJOS: string = '/SigoPwa/Programados/Gestion/Trabajos/Update';

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    SELECT_TRABAJOS,
    ROUTE_PROGRAMADOS,
    ROUTE_TRABAJOS,
    SELECT_OBRA,
    UPDATE_TRABAJOS
};