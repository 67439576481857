import { Filtros } from "../../Domain/EntityParams";

export const FiltroItems: Filtros = {
    Filtros: [
        {
            $match: {
                'Estado.ID_Estado': 1
            }
        },
        {
            $project: {
                ID_Item: 1,
                Pais: 1,
                Delegacion: 1,
                Propio: 1,
                Codigo: 1,
                Nombre: 1,
                Descripcion: 1,
                UnidadMedida: 1,
                Ind_SB: 1,
                Merma: 1,
                Decimales: 1,
                Tipo: 1,
                Valor: 1,
                Prioridad: 1,
                Relacion: 1,
                CodigoSAP: 1,
                Empresa: 1,
            }
        }]
}