import { DtoGeneralCodigoNombre } from "./DtoGeneralCodigoNombre";
import { DtoGeneralCodigoNombreUnidad } from "./DtoGeneralCodigoNombreUnidad";

export class DtoDetalleChileReservaMO {
    Codigo: string = '';
    Linea: string = '';
    DirDesde: string = '';
    AltDesde: string = '';
    DirHasta: string = '';
    AltHasta: string = '';
    Plano: string = '';
    Especialidad: DtoGeneralCodigoNombre = new DtoGeneralCodigoNombre();
    Actividad: DtoGeneralCodigoNombre = new DtoGeneralCodigoNombre();
    Clave: DtoGeneralCodigoNombre = new DtoGeneralCodigoNombre();
    Tarea: string = '';
    ManoObra: DtoGeneralCodigoNombreUnidad = new DtoGeneralCodigoNombreUnidad();
    UnidadObra: DtoGeneralCodigoNombreUnidad = new DtoGeneralCodigoNombreUnidad();
    HomePass: DtoGeneralCodigoNombre = new DtoGeneralCodigoNombre();
    LineaCodigoCub: string = '';
    CodigoCub: string = '';
    CanMOCub: number = 0;
    CanMOInf: number = 0;
    CanMOApr: number = 0;
    CanUOCub: number = 0;
    CanUOInf: number = 0;
    TotalUO: number = 0;
    CanUOApr: number = 0;
    Origen: string = '';
    CodigoBarra: string = '';
    PuntoBaremo: number = 0;
}