import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { DtoResponseSelectSalidaAlmacen } from "../Domain/DtoResponseSelectSalidaAlmacen";
import { DtoRequestSelectSalidaAlmacen } from "../Domain/DtoRequestSelectSalidaAlmacen";
import { AdapterConfigure } from "./AdapterConfigure";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

    public async selectSalidaAlmacen(params: DtoRequestSelectSalidaAlmacen): Promise<DtoResponseSelectSalidaAlmacen[]> {
        let url: string = `${this.urlBase}${AdapterConfigure.SELECT_SALIDA_ALMACEN}`;
        let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'json', {});
        response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response;
        return response;
    }
    
}