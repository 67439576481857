import { AdapterConfigure } from './AdapterConfigure';
import { RepositoryImplMain } from './RepositoryImplMain';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { addLoading, hideIconMenu, removeLoading } from '../../../shared/Infraestructure/SliceGenerico';
import { useState } from 'react';
import { DtoResponseSelectSalidaAlmacen } from '../Domain/DtoResponseSelectSalidaAlmacen';
import { UseCaseSelectSalidaAlmacen } from '../Application/UseCaseSelectSalidaAlmacen';
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ErrorCostume } from '../../../shared/Domain/ErrorCostume';
import { NavigateFunction, useNavigate } from "react-router-dom";

export const Controller = () => {
  const dispatch: Dispatch = useDispatch();
  const [salidaAlmacen, setSalidaAlmacen] = useState<DtoResponseSelectSalidaAlmacen[]>([]);
  const [salidaAlmacenAux, setSalidaAlmacenAux] = useState<DtoResponseSelectSalidaAlmacen[]>([]);
  const [selectAlmacenUsuario, setSelectAlmacenUsuario] = useState<any[]>([]);
  const navigate: NavigateFunction = useNavigate();
  const { websocket, dbLocal } = useSelector((state: RootState) => state.generico);
  const { user } = useSelector((state: RootState) => state.auth);
  const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

  const formSearchAlmacen = useFormik({
    initialValues: {
    } as any,
    validationSchema: Yup.object({
    }),
    onSubmit: (values, formikHelpers) => { },
  });

  const init = async () => {
    try {
      dispatch(hideIconMenu());
      await loadData();
    } catch (error) {
      console.error(error);
      let err: ErrorCostume = new ErrorCostume((error as Error).message);
      AdapterGenerico.createMessage('Alerta', err.message, 'warning', false);
      navigate(AdapterConfigure.REDIRECT_LOGISTICA, { replace: true });
    } finally {
      dispatch(removeLoading());
    }
  };

  const loadData = async () => {
    dispatch(addLoading({ textLoading: 'Cargando salidas de almacen...' }));
    let respSalidaAlmacen = await new UseCaseSelectSalidaAlmacen(repository).exec(user);
    dispatch(removeLoading());
    if (respSalidaAlmacen.length < 1) throw Error(`No hay registros para mostrar.`);
    let dataTemp: DtoResponseSelectSalidaAlmacen[] = []
    for (const element of respSalidaAlmacen) {
      let salidaAlmacenAux: DtoResponseSelectSalidaAlmacen = {
        Almacen: { Codigo: element.Almacen.Codigo, Nombre: element.Almacen.Nombre },
        Estado: { Nombre: element.Estado.Nombre },
        ID_DespachoAlmacen: element.ID_DespachoAlmacen,
        OrdenTrabajo: element.OrdenTrabajo
      }
      dataTemp.push(salidaAlmacenAux)
    }
    setSalidaAlmacen(dataTemp);
    setSalidaAlmacenAux(dataTemp);
    setSelectAlmacenUsuario(user.Almacen.map(al => ({ label: `${al.Nombre} - ${al.Codigo}`, value: al.ID_Almacen, dataComplete: al })));
  };

  const onChange = (name: string, value: any) => {
    try {
      formSearchAlmacen.setFieldValue(name, value);
      if (["selectSearch"].includes(name)) {
        setSalidaAlmacenAux(salidaAlmacen.filter(el => {
          if (el.Almacen.Codigo.includes(value.dataComplete.Codigo)) return el;
        }));
      }
    } catch (error) {
      console.error(error);
      let err: ErrorCostume = new ErrorCostume((error as Error).message);
      AdapterGenerico.createMessage('Alerta', err.message, 'warning', false);
    }
  }

  return {
    init,
    user,
    salidaAlmacenAux,
    formSearchAlmacen,
    selectAlmacenUsuario,
    onChange,
  };
}