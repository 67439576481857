import { RepositoryMain } from '../Domain/RepositoryMain'
import { DtoReqSelectObras } from '../Domain/DtoReqSelectObras'
import { DtoObras } from '../Domain/DtoObras'

export class UseCaseSelectObras {
    private repository: RepositoryMain

    constructor(_repository: RepositoryMain) {
        this.repository = _repository
    }

    public async exec(CodigoDelegacion: string, NombrePais: string, OrdenTrabajo: string): Promise<DtoObras[]> {
        return await this._exec(CodigoDelegacion, NombrePais, OrdenTrabajo)
    }

    private async _exec(CodigoDelegacion: string, NombrePais: string, OrdenTrabajo: string): Promise<DtoObras[]> {

        const params: DtoReqSelectObras = {
            Header: {
                Coleccion: {
                    Codigo: CodigoDelegacion,
                    Nombre: NombrePais,
                },
                Filtros: [
                    {
                        $match: {
                            OrdenTrabajo: {
                                $regex: OrdenTrabajo,
                                $options: 'i'
                            },
                        },
                    },
                    {
                        $project: {
                            ID_incidencia: 1,
                            OrdenTrabajo: 1,
                            // Proyecto: 1,
                            Contrato: 1,
                            'AreaGOM.GOMS.ID_GOM': 1,
                            // ZonaAsignada: 1,
                            'Ultimo_Estado_Interno.Descripcion': 1,
                        },
                    },
                ],
            },
        }
        return await this.repository.selectObras(params)
    }
}
