import './Style.scss';

import logo from '../../../../assets/img/logo.png';
import logoqa from '../../../../assets/img/logoqa.png';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import { useState } from 'react';

//Imagenes

interface PropsViewMain {
    formLogin: { values: any; touched: any; errors: any; handleBlur: Function; };
    onChange: Function;
    onSubmit: Function;
    showPassword: boolean;
    setShowPassword: Function;
    onClickRecuperarPassword: Function;
    onClickRegisterRDI: Function;
};

export const ViewMain = (props: PropsViewMain) => {
    const languageTranslate = LanguageTranslate();

    const [mode, setMode] = useState(process.env.REACT_APP_MODE);

    return (
        <div className="ViewMasterLogin">
            <div className="fondo"></div>
            <div className="login-block">
                {/* <div className="container"> */}
                <div className="row text-center">
                    <div className="col-12">
                        <img src={mode === 'dev' ? logoqa : logo} alt="logo.png" width={270} />
                    </div>
                </div>
                <form noValidate onSubmit={(evt) => { props.onSubmit(evt); }}>
                    <div className="row pt-5">
                        <div className="col-12">
                            <div className="input-group input-group-lg">
                                <span className={`input-group-text${mode === 'dev' ? ' bg-secondary' : ''}`}>
                                    <i className="fa-solid fa-user" />
                                </span>
                                <input
                                    type="text"
                                    name="user"
                                    className="form-control"
                                    required
                                    placeholder={languageTranslate.moduloLogin.txtUsuario}
                                    value={props.formLogin.values['username']}
                                    onChange={(evt) => { props.onChange('username', evt.target.value) }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-12">
                            <div className="input-group">
                                <span className={`input-group-text${mode === 'dev' ? ' bg-secondary' : ''}`}>
                                    <i className="fa-solid fa-lock" />
                                </span>
                                <input
                                    type={props.showPassword ? 'text' : 'password'}
                                    name="password"
                                    className="form-control"
                                    required
                                    placeholder={languageTranslate.moduloLogin.txtPassword}
                                    value={props.formLogin.values['password']}
                                    onChange={(evt) => { props.onChange('password', evt.target.value) }}
                                />
                                <button
                                    className={`btn${mode === 'dev' ? ' btn-secondary' : ' btn-primary'} ${mode === 'dev' ? 'addon-button-secondary' : 'addon-button-primary'}`}
                                    type="button"
                                    onClick={() => props.setShowPassword(!props.showPassword)}
                                >
                                    <i className={`fa-solid fa-eye${props.showPassword ? '-slash' : ''} ${mode === 'dev' ? 'text-light' : ''}`} />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-12">
                            <div className="form-check form-switch">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked={props.formLogin.values['recordar']}
                                    onChange={(evt) => { props.onChange('recordar', evt.target.checked) }}
                                    id="flexSwitchCheckDefault"
                                />
                                <label className="form-check-label text-primary" htmlFor="flexSwitchCheckDefault">{languageTranslate.moduloLogin.chkRecordarUsuario}</label>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-2">
                        <div className="col-12">
                            <button
                                type="submit"
                                className={`btn ${mode === 'dev' ? 'btn-secondary text-light' : 'btn-primary'}`}
                                style={{ width: '100%', backgroundColor: mode === 'dev' ? '' : '#01397D' }}
                            >
                                {languageTranslate.moduloLogin.btnLogin}
                            </button>
                        </div>
                    </div>
                </form>
                <div className="row pt-3">
                    <div className="col-12 text-center">
                        <div className="forgot-phone">
                            {
                                // eslint-disable-next-line
                                <a href="#" className="text-primary" onClick={() => props.onClickRecuperarPassword()} style={{ fontWeight: 'bold' }}>{languageTranslate.moduloLogin.textoOlvidoContrasenia}</a>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
};