import {
  codClientes as codClientesAux,
  codNoCliente as codNoClienteAux,
  codSopladoRD as codSopladoRDAux,
  codMOPublico as codMOPublicoAux,
} from 'sigo-package'
export const codClientes = codClientesAux

export const codNoCliente = codNoClienteAux
export const codSopladoRD = codSopladoRDAux
export const codBaliza = ['073006000']

export const codMOPublico = codMOPublicoAux
// export const codMOExcluidos = ['T21', 'T22', 'T23', 'T24', 'T25', 'T26']
export const codMOExcluidos = ['T21', 'T22', 'T23', 'T24', 'T25']
