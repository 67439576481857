import * as React from 'react'
import { Controller } from "./Infraestructure/Controller"
import { ViewMain } from "./Components/View"

const ManagerEntity = () => {
  const { init, handleSearch, handleChange, handleRefresh, trabajos, showOffCanvas, setShowOffCanvas } = Controller()

  React.useEffect(() => {
    init()
    return () => { }
    // eslint-disable-next-line
  }, [])

  return (
    <ViewMain
      handleSeach={handleSearch}
      handleChange={handleChange}
      trabajos={trabajos}
      showOffCanvas={showOffCanvas}
      setShowOffCanvas={setShowOffCanvas}
      handleRefresh={handleRefresh}
    />
  )
}

export default ManagerEntity