import { DtoDocumentacionPEX } from '../../../../app/Domain/DtoDocumentacionPEX'
import { EntityParams } from '../Domain/EntityParams'
import { RepositoryMain } from '../Domain/RepositoryMain'

export class UseCaseSelectDocumentacionPEX {
    private repository: RepositoryMain

    constructor(_repository: RepositoryMain) {
        this.repository = _repository
    }

    public async exec(params: EntityParams): Promise<DtoDocumentacionPEX[]> {
        return await this._exec(params)
    }

    private async _exec(params: EntityParams): Promise<DtoDocumentacionPEX[]> {
        return await this.repository.selectDocumentacionPEX(params)
    }
}