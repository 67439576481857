import "./ElementStockGenerico.scss";
import { Link } from "react-router-dom";

interface Props {
    text?: string | JSX.Element;
    text1?: string | JSX.Element;
    text2?: string | JSX.Element;
    text3?: string | JSX.Element;
    data: string | JSX.Element;
    data1?: string | JSX.Element;
    data2?: string | JSX.Element;
    data3?: string | JSX.Element;
    data4?: string | JSX.Element;
    icono?: boolean;
    icono1?: string;
    linkIcono1?: string;
    linkIcono2?: string;
}

export const ElementStockGenerico = (props: Props) => {


    return (
        <div className='mb-2 d-flex flex-row align-items-center rounded-pill' style={{ backgroundColor: '#2C3E50', fontSize: '0.9rem' }}>
            <div className='col-2'>
                <div style={{ backgroundColor: '#FFFF00', fontSize: '1rem', width: 50, height: 50 }} className='m-1 d-flex justify-content-center align-items-center rounded-circle'>
                    <span className='fw-bold gris-oscuro'>{Number(props.data4).toFixed(1)}</span>
                </div>
            </div>
            <div className='col-8 pt-1 pb-1'>
                <label style={{ color: '#fff' }} className=''>{props.data}</label>
                <div className='d-flex flex-row'>
                    <div className='col-6'>
                        <label className='m-0 me-2 rosa-neon'>{props.text1}</label>
                        <label className='m-0 verde-neon'>{props.data1}</label>
                    </div>
                    <div className='col-6'>
                        <label className='m-0 me-2 rosa-neon'>{props.text3}</label>
                        <label  className='m-0 verde-neon'>{props.data3}</label>
                    </div>
                </div>
                <div className='d-flex flex-row'>
                    <label className='m-0 me-2 rosa-neon'>{props.text2}</label>
                    <label className='m-0 verde-neon'>{props.data2}</label>
                </div>
            </div>
            <div className='col-2 d-flex justify-content-center align-items-center'>
                <Link to={props.linkIcono1 ? props.linkIcono1 : ''}>
                    <i className='fs-1 fa-solid fa-eye cian-neon'></i>
                </Link>
            </div>
        </div>
    );
};
