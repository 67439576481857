import { useEffect } from 'react'
import { View } from './components/view'
import { Controller } from './infraestructure/controller'

export const ManagerAutoInventarios = () => {

  const { init, handleSave } = Controller()

  useEffect(() => { init() }, [])

  return (
    <View
      handleSave={handleSave}
    />
  )
}
