const {
    REACT_APP_ROUTE_PATH_MAIN,
    REACT_APP_ROUTE_PATH_MAIN_LOGISTICA,
    REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_TRABAJOS,
    REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_STOCKPERSONAL,
} = process.env;
const SCHEMA: string = process.env.REACT_APP_MAESTRO_SEDE_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MAESTRO_SEDE_ENTITY || '';
const ROUTE_LOGISTICA = `${REACT_APP_ROUTE_PATH_MAIN}/${REACT_APP_ROUTE_PATH_MAIN_LOGISTICA}/`;
const ROUTE_TRABAJOS: string = `${ROUTE_LOGISTICA}${REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_TRABAJOS}/`;
const ROUTE_STOCK: string = `${ROUTE_TRABAJOS}${REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_STOCKPERSONAL}/`;
const SELECT_STOCK_PERSONAL: string = '/SigoPwa/Logistica/Reportes/StockPersonal/Select';

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    ROUTE_STOCK,
    SELECT_STOCK_PERSONAL,
    ROUTE_TRABAJOS,
};