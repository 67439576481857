const SCHEMA: string = process.env.REACT_APP_MASTER_MAIN_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MASTER_MAIN_ENTITY || '';
const PAIS: string = '/search/Pais';
const PROYECTO: string = '/search/Proyectos';
const EMPRESA: string = '/search/Empresas';
const DELEGACION: string = '/search/Delegaciones';
const PERSONAL: string = '/search/PersonalPWA';
const TIPOINCIDENTE: string = '/TipoIncidente/1';
const FACTORRIESGO: string = '/FactorRiesgo';
const OT: string = '/search/OTs';
const REDIRECT_FIRMAR = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGISTICA}/${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_FIRMA_ALBARAN}/`;


export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    PROYECTO,
    EMPRESA,
    PERSONAL,
    DELEGACION,
    PAIS,
    TIPOINCIDENTE,
    FACTORRIESGO,
    OT,
    REDIRECT_FIRMAR,
};