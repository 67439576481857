import { DtoResponseSelectSalidaAlmacen } from "../Domain/DtoResponseSelectSalidaAlmacen";
import { RepositoryMain } from "../Domain/RepositoryMain";
import { DtoRequestSelectSalidaAlmacen } from "../Domain/DtoRequestSelectSalidaAlmacen";
import { EntityDataUsuario } from "../../../shared/Domain/EntityDataUsuario";

export class UseCaseSelectSalidaAlmacen {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(user: EntityDataUsuario): Promise<Array<DtoResponseSelectSalidaAlmacen>> {
        return await this._exec(user);
    }

    private async _exec(user: EntityDataUsuario): Promise<Array<DtoResponseSelectSalidaAlmacen>> {
            let params: DtoRequestSelectSalidaAlmacen = {
                datos: {
                    Codigo: user.Delegacion.Codigo,
                    Nombre: user.Pais.Nombre,
                },
                Filtros: [
                    {
                        $match: {
                            FlagPex: 1,
                            "Personal.Identificacion": user.Identificacion,
                            "Estado.ID_Estado": 3
                        },
                    },
                ],
            };
        return await this.repository.selectSalidaAlmacen(params);
    }
}