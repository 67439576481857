import React, { FC, useEffect, useState } from 'react'
import { OffCanvasBottom } from '../../../../shared/Components/ReactBootstrap/OffCanvasBottom/OffCanvasBottom'
import { Adjuntos } from '../../../../shared/Components/Adjuntos/Adjuntos'
import { DtoDocumentacionBaremoPEX, DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { DtoValorizacion } from '../../../Trabajos/Asignacion/Domain/DtoValorizacion'
import { paisMap } from 'sigo-package'
import { DtoTrabajosDE } from '../../Trabajos/Domain/DtoTrabajosDE'

interface iProps {
  setShowOffCanvas: React.Dispatch<React.SetStateAction<boolean>>
  showOffCanvas: boolean
  valorizacion: DtoValorizacion
  dataDocumentacionBaremoPEX: DtoDocumentacionBaremoPEX[]
  trabajo: DtoTrabajosDE | null
  saveFiles: (nodos: DtoNodoDoc[]) => Promise<void>
}

export const AdjuntosValorizaciones: FC<iProps> = ({
  setShowOffCanvas,
  showOffCanvas,
  valorizacion,
  dataDocumentacionBaremoPEX,
  trabajo,
  saveFiles
}) => {

  const [nodos, setNodos] = useState<DtoNodoDoc[]>([])
  const [newFiles, setNewFiles] = useState<File[]>([])

  const restoreFiles = (documentacion: DtoNodoDoc[], valorizacion: DtoValorizacion) => {
    for (const nodo of documentacion) {
      const files = valorizacion.Anexos.filter(e => e.CodigoNodo === nodo.Codigo)
      nodo.Files = files
      restoreFiles(nodo.Children, valorizacion)
    }
  }

  useEffect(() => {
    if (showOffCanvas) {
      const documentacionBaremo = dataDocumentacionBaremoPEX.find(e => e.CodigoMO === valorizacion.DetalleChile.ManoObra.Codigo.trim())
      if (!!!documentacionBaremo) console.warn(`No existe DocumentacionBaremoPEX para el codigo ManoObra ${valorizacion.DetalleChile.ManoObra.Codigo.trim()}`)
      const defaultBaremo = new DtoDocumentacionBaremoPEX()
      const newNodo = new DtoNodoDoc()
      newNodo.id = 999999
      newNodo.label = 'OTROS ANEXOS'
      newNodo.Titulo = 'OTROS ANEXOS'
      newNodo.Codigo = 'Cod999999'
      newNodo.Descripcion = 'OTROS ANEXOS'
      newNodo.Extension = ['ALL']
      newNodo.Size.Size = 50
      newNodo.Size.UM = 'MB'
      newNodo.CantidadMin = trabajo && paisMap[trabajo.Pais.Codigo] ? paisMap[trabajo.Pais.Codigo].CantidadMin : 1
      defaultBaremo.Nodos = [newNodo]
      const documentacion = documentacionBaremo ? documentacionBaremo.Nodos : defaultBaremo.Nodos
      restoreFiles(documentacion, valorizacion)
      setNodos(documentacion)
      setNewFiles([])
    }
  }, [showOffCanvas])

  return (
    <OffCanvasBottom
      height={82}
      show={showOffCanvas}
      setShow={setShowOffCanvas}
      children={
        <Adjuntos
          Nodos={nodos}
          newFiles={newFiles}
          setNewFiles={setNewFiles}
          setNodos={setNodos}
          ruta='Fotos_Anexos'
          onSave={async () => {
            await saveFiles(nodos)
            setShowOffCanvas(false)
          }}
        />
      }
    />
  )
}
