import { FormikHelpers } from 'formik'
import { DtoInitialValuesAddMO } from './Interfaces/DtoInitialValuesAddMO'
import { PreLiquidationProcess } from '../../Preliquidar/Application/UseCasePreLiquidationProcesses/UseCasePreLiquidationProcesses'
import { DtoTrabajosDE } from '../../Trabajos/Domain/DtoTrabajosDE'
import { Dispatch, SetStateAction } from 'react'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { DtoPrecioEspecialidad } from '../../../../../app/Domain/DtoPrecioEspecialidad'
import { DtoAmap } from '../../../Home/Domain/DtoAmap'
import { ObtenerZonaAsignada, BuscarZonaContratista, ObtenerPrecioContratistaVigente, CodsMapeoMOMaterial } from 'sigo-package'
import { DtoPrecioMaterial } from '../../../../../app/Domain/DtoPrecioMaterial'
import { DtoItems } from '../../../../../app/Domain/DtoItems'
import { DtoAsignacionValorizacion } from '../../../Trabajos/Asignacion/Domain/DtoAsignacionValorizacion'

export const onSubmitFormAddMOShared = (
  values: DtoInitialValuesAddMO,
  formikHelpers: FormikHelpers<DtoInitialValuesAddMO>,
  trabajo: DtoTrabajosDE | null,
  setTrabajo: Dispatch<SetStateAction<DtoTrabajosDE | null>>,
  PrecioEspecialidad: DtoPrecioEspecialidad[],
  dataPrecioMaterial: DtoPrecioMaterial[],
  dataItems: DtoItems[],
): void | Promise<any> => {
  if (typeof values.manoObra !== 'string' && trabajo) {
    if (!trabajo.Ultima_asignacion.Empresa.Propia) {
      const { contratista, zona } = getContratista(PrecioEspecialidad, values.manoObra.dataComplete, trabajo)
      const contratistaAux = values.manoObra.dataComplete.DatosCatalogoMO.DatosPrecioEspecialidad.Contratistas.find(e => e.Empresa.Codigo === trabajo?.Ultima_asignacion.Empresa.Codigo)
      if (!contratistaAux && zona) {
        contratista[0].Zonas = [zona]
        values.manoObra.dataComplete.DatosCatalogoMO.DatosPrecioEspecialidad.Contratistas = contratista
      } else if (contratistaAux) {
        const ZonaAsignada = ObtenerZonaAsignada(trabajo.ColeccionObras[0])
        const SearchZona = BuscarZonaContratista(contratistaAux, trabajo.ColeccionObras[0], ZonaAsignada)
        if (!SearchZona && zona) contratistaAux?.Zonas.push(zona)
      }
    }
    const newDtoAsignacionValorizacion = PreLiquidationProcess.generarDtoAsignacionValorizacion(values.manoObra.dataComplete, values.cantidad)
    const newItem = addItemReservaMaterial(trabajo, newDtoAsignacionValorizacion, dataPrecioMaterial, dataItems, values.cantidad)
    const trabajoUpdate = structuredClone(trabajo)
    trabajoUpdate?.Ultima_asignacion.Valorizaciones.push(newDtoAsignacionValorizacion)
    values.manoObra.dataComplete.Cantidad = values.cantidad
    trabajoUpdate?.ColeccionManoObra[0].Valorizacion.push(values.manoObra.dataComplete)
    if (newItem) trabajoUpdate?.ColeccionReserva[0].Item.push(newItem)
    setTrabajo(trabajoUpdate)
    formikHelpers.resetForm()
    AdapterGenerico.createMessage('¡Agregado', 'Se agregó asignación', 'success')
  }
}

const addItemReservaMaterial = (
  trabajo: DtoTrabajosDE | null,
  newAsignacion: DtoAsignacionValorizacion,
  dataPrecioMaterial: DtoPrecioMaterial[],
  dataItems: DtoItems[],
  cantidad: number
) => {
  const codMO = CodsMapeoMOMaterial.find(e => e.CodigoMO === newAsignacion.ManoObra.Codigo)
  if (!codMO) return
  const precioMaterial = dataPrecioMaterial.filter(e =>
    e.Item.Codigo === codMO.CodigoMaterial &&
    e.Contrato.Codigo === trabajo?.ColeccionObras[0].ContratoOT.Codigo
  )
  if (precioMaterial.length !== 1) throw Error('¡No se pudo obtener Precio Material! Intente refrescar PrecioMaterial')
  const item = dataItems.filter(e => e.Codigo === codMO.CodigoMaterial)
  if (item.length !== 1) throw Error('¡No se pudo obtener Item! Intente refrescar Items')
  return PreLiquidationProcess.generarDtoItem(item[0], precioMaterial[0], cantidad)
}

const getContratista = (PrecioEspecialidad: DtoPrecioEspecialidad[], dataComplete: DtoAmap, trabajo: DtoTrabajosDE | null) => {
  if (!trabajo) throw Error(`¡No hay trabajo!`)
  const precios = PrecioEspecialidad.filter(e => e.Especialidad.Codigo === dataComplete.DetalleChile.Especialidad.Codigo)
  const msgErrEspecialidad = `Especialidad con Código: "${dataComplete.DetalleChile.Especialidad.Codigo}"`
  if (!precios.length) throw Error(`¡${msgErrEspecialidad} no existe en Catalogo Precio Especialidad!`)

  const precio = precios.filter(e => e.ContratoOT.Codigo === trabajo.ColeccionObras[0].ContratoOT.Codigo)
  const msgErrContrato = `${msgErrEspecialidad}, con código de contrato "${trabajo.ColeccionObras[0].ContratoOT.Codigo}"`
  if (!precio.length) throw Error(`¡${msgErrContrato} no existe en Catalogo Precio Especialidad!`)
  if (precio.length > 1) throw Error(`¡${msgErrContrato} existe ${precio.length} registros en Catalogo Precio Especialidad!`)

  const contratista = precio[0].Contratistas.filter(e => e.Empresa.Codigo === trabajo.Ultima_asignacion.Empresa.Codigo && e.Estado.ID_Estado === 1)
  const msgErrEmpresa = `${msgErrContrato}, con código de Empresa: "${trabajo.Ultima_asignacion.Empresa.Codigo}"`
  if (!contratista.length) throw Error(`¡${msgErrEmpresa} no existe en Catalogo Precio Especialidad!`)
  if (contratista.length > 1) throw Error(`¡${msgErrEmpresa} existe ${contratista.length} registros en Catalogo Precio Especialidad!`)

  const ZonaAsignada = ObtenerZonaAsignada(trabajo.ColeccionObras[0])
  const SearchZona = BuscarZonaContratista(contratista[0], trabajo.ColeccionObras[0], ZonaAsignada)

  const msgErrZona = `${msgErrEmpresa}, con zona: "${ZonaAsignada}"`
  if (!SearchZona) throw Error(`${msgErrZona} no existe en Catalogo Precio Especialidad`)

  const zona = contratista[0].Zonas.find(e => e.Codigo === SearchZona.Codigo)
  const actividad = zona?.Actividades.filter(e => e.Codigo === dataComplete.DetalleChile.Actividad.Codigo && e.Estado.ID_Estado === 1)
  const msgErrActividad = `${msgErrZona}, con código de actividad: "${dataComplete.DetalleChile.Actividad.Codigo}"`
  if (!actividad?.length) throw Error(`¡${msgErrActividad} no existe en Catalogo Precio Especialidad`)
  if (actividad?.length > 1) throw Error(`¡${msgErrActividad}  existe ${actividad.length} registros en Catalogo Precio Especialidad`)

  const Precio = ObtenerPrecioContratistaVigente({ actividad: actividad[0], FechaVerificar: new Date() })

  if (!Precio) throw Error(`¡No se encontró Precio Vigente en la Línea: <span style="color: red">${dataComplete.DetalleChile.Linea}</span>, para ${msgErrActividad}!`)
  return { contratista, zona }

}