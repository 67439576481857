import { createSlice } from '@reduxjs/toolkit';

interface SliceStockPersonalState {
	textSeachLogTrabajos: string;
	textSeachLogStockPersonal: string;
};

const initialState: SliceStockPersonalState = {
	textSeachLogTrabajos: '',
	textSeachLogStockPersonal: '',
};

const stockPersonalSlice = createSlice({
	name: 'textSearchSlice',
	initialState,
	reducers: {
		changeTextSearchLogTrabajos: (state, action) => {
			return {
				...state,
				textSeachLogTrabajos: action.payload,
			};
		},
		changeTextSearchLogStockPersonal: (state, action) => {
			return {
				...state,
				textSeachLogStockPersonal: action.payload,
			};
		},
	},
});


export const {
	changeTextSearchLogTrabajos,
	changeTextSearchLogStockPersonal,
} = stockPersonalSlice.actions;

export default stockPersonalSlice.reducer;