import { EntityLoadData } from "../Domain/EntityLoadData";
import { RepositoryMain } from "../Domain/RepositoryMain";
import { EntityDataUsuario } from "../../../../shared/Domain/EntityDataUsuario";
import { DtoTrabajos } from "../../../../Master/Home/Domain/DtoTrabajos";
import { DtoDocumentacionBaremoPEX } from "../../../../../app/Domain/DtoDocumentacionBaremoPEX";

export class UseCaseLoadData {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(trabajo: DtoTrabajos, user: EntityDataUsuario): Promise<EntityLoadData> {
        const [DocumentacionBaremoPEX] = await Promise.all([
            this.getDocumentacionBaremoPEX(user, trabajo),
        ]);
        return ({
            DocumentacionBaremoPEX,
        })
    }

    private async getDocumentacionBaremoPEX(user: EntityDataUsuario, trabajo: DtoTrabajos): Promise<DtoDocumentacionBaremoPEX[]> {
        if (trabajo.ColeccionObras.length !== 1) throw new Error(`¡Error al obtener Obra! Comunícate con Soporte`)
        const items = await this.repository.selectDocumentacionBaremoPEX()
        return items.filter(e => e.Pais.Codigo === user.Pais.Codigo && e.Delegacion.Codigo === user.Delegacion.Codigo && e.ContratoCod === trabajo.ColeccionObras[0].ContratoOT.Codigo)
    }

}