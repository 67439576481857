import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { EntityMain } from "../Domain/EntityMain";
import { AdapterStorage } from "../../../shared/Infraestructure/AdapterStorage";
import { EntityInformationDataInitial } from "../../../shared/Domain/EntityInformationDataInitial";

export class RepositoryImplMain extends RepositoryImplGenerico<EntityMain> implements RepositoryMain {
    public async logout(accessToken: string): Promise<void> {
        await this._logout(accessToken);
    }

    private async _logout(accessToken: string): Promise<void> {
        // let url: string = `${this.urlBase}/${AdapterConfigure.SCHEMA}/${AdapterConfigure.ENTITY}/logout`;
        // await this.service.bgCall<void>("POST", url, JSON.stringify({ accessToken }), "basic", "json", 'json', {}, 0);
        let { servicesCalleds }: { servicesCalleds: EntityInformationDataInitial } = AdapterStorage.get('servicesCalleds');
        // await this.dbLocal.clearStore(['RDI', 'TermsAccept', 'Inspeccion', 'Autochequeo', 'Personal']);
        // servicesCalleds.Personal = { called: false, count: 0, date: null, };
        AdapterStorage.set('servicesCalleds', servicesCalleds);
    }

    public async respuestaConexionFirma(params: boolean): Promise<any> {
        await this.websocket.publish('SALA_LOGISTICA', { Accion: 'PWA_LOGISTICS_CONN_RESP', data: params });
    }
}