import './ElementStepper.scss';

interface IStepper {
    data: string[];
    className?: string;
    ocult?: boolean;
    onChange: (currentStep: number) => any;
    stepPosition: number;
}

const Stepper = (props: IStepper) => {
    if (props.data.length === 0 || props.ocult) return <div></div>;

    return (
        <div className={`ElementStepper d-flex mt-1 ml-2 pt-2 pb-4 rounded border mb-1 ${props.className ? props.className : ''} ${props.data.length <= 4 ? 'justify-content-center' : '' }`}>
            <div className={`d-flex flex-row w-100 ${props.data.length <= 4 ? 'justify-content-center' : '' }`}>
                {
                    props.data.map((name, index) => {
                        const isComplete = (index + 1) <= props.stepPosition;
                        const isCurrentStep = (index + 1) === props.stepPosition;
                        const isLastStep = (index + 1) === props.data.length;
                        return (
                            <div key={index} className={`mb-1 position-relative ${isLastStep ? 'stepper-item-finally' : 'stepper-item'}`} onClick={() => props.onChange(index + 1)}>
                                <div className="d-flex flex-row pr-4 align-items-center">
                                    <div className={`rounded-circle stepCircle text-white mb-1 ${isComplete ? ( isCurrentStep ? 'bg-primary' : 'bg-primary' ) : 'circle-incomplete' }`}>
                                        {
                                            isComplete && !isCurrentStep ?
                                            <i className="fa-solid fa-check" style={{fontSize: 15}}/>
                                            :
                                            <span>{index+1}</span>
                                        }
                                        
                                    </div>
                                    { !isLastStep && <div className={`w-100 ${isComplete ? ( isCurrentStep ? 'line-current' : 'line-active' ) : 'line' }`}></div> }
                                </div>
                                <div className='position-absolute stepperDescription'>
                                    <label className="text-dark text-align-center w-100">{name}</label>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Stepper;