
import { DtoConsumoMaterial } from '../../../Master/Home/Domain/DtoConsumoMaterial'
import { EntityDataUsuario } from '../../../shared/Domain/EntityDataUsuario'
import { EntityLoadData } from '../Domain/EntityLoadData'
import { RepositoryMain } from '../Domain/RepositoryMain'

export class UseCaseLoadData {
  private repository: RepositoryMain

  constructor(_repository: RepositoryMain) {
    this.repository = _repository
  }

  async exec(user: EntityDataUsuario): Promise<EntityLoadData> {
    const [
      dataConsumoMaterial,
    ] = await Promise.all([
      this.getConsumoMaterial(user),
    ])
    return {
      dataConsumoMaterial
    }
  }

  private async getConsumoMaterial(user: EntityDataUsuario,): Promise<DtoConsumoMaterial[]> {
    const data = await this.repository.selectConsumoMaterial(user)
    return data
  }

}