import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { RepositoryImplGenerico } from '../../../../shared/Infraestructure/RepositoryImplGenerico'
import { iAutoInventario } from '../../shared/domain/iAutoInventario'
import { iRepository } from '../domain/iRepositoty'
import { iBodyReqSaveData } from '../domain/iBodyReqSaveData'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { AdapterConfigure } from '../../shared/infrastructure/AdapterConfigure'
import { FTPService } from '../../../../shared/Infraestructure/AdapterFileSystem'
import { changeAutoInventario } from '../../../../shared/Infraestructure/SliceGenerico'
import { DtoResponseSelectStockPersonal } from '../../../../Master/Home/Domain/DtoResponseSelectStockPersonal'

export class Repository extends RepositoryImplGenerico<iAutoInventario> implements iRepository {

  async selectStockPersonalLocal(user: EntityDataUsuario): Promise<DtoResponseSelectStockPersonal[]> {
    return await this.dbLocal.selectAllStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockPersonal`)
  }

  async saveDataAutoInventario(body: iBodyReqSaveData) {

    const url: string = `${this.urlBase}/${AdapterConfigure.SCHEMA}/${AdapterConfigure.ENTITY}/save`

    const response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(body), 'bearer', 'json', 'json', {})
    this.dispatch(changeAutoInventario(true))
    return typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response

  }

  async uploadFiles(files: File[]) {
    console.info(`¡Se subirán ${files.length} archivos!`)
    for await (const file of files) {
      await FTPService.setFileToServer(file.name, file)
    }
  }

}