import { createSlice } from '@reduxjs/toolkit';

interface SliceStockPersonalTotalState {
	textSeach: string;
};

const initialState: SliceStockPersonalTotalState = {
	textSeach: '',
};

const stockPersonalTotalSlice = createSlice({
	name: 'stockPersonalTotalSlice',
	initialState,
	reducers: {
		changeTextSeach: (state, action) => {
			return {
				...state,
				textSeach: action.payload,
			};
		},
	},
});


export const {
	changeTextSeach,
} = stockPersonalTotalSlice.actions;

export default stockPersonalTotalSlice.reducer;