import { Filtros } from "../../Domain/EntityParams";

export const FiltroTrabajos: Filtros = {
    Filtros: [
        {
            $match: {
                'Estado.IdEstado': 1
            }
        },
        {
            
        }
    ]
}