import { ElementBreadCrumb } from '../../../../shared/Components/ElementBreadCrumb';
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate';
import './Style.scss';
import { UploadImg } from './UploadImg';
import { InputText } from '../../../../shared/Components/ElementInputsCostume';
import { EntityLoadData } from '../Domain/EntityLoadData';
import { AddPersonal } from './AddPersonal';
import { useEffect, useState } from 'react';
import { DtoTrabajos } from '../../../../Master/Home/Domain/DtoTrabajos';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AdapterConfigure } from '../Infraestructure/AdapterConfigure';
import PdfGenerator from './PdfGenerator ';
import ButtonFloating from '../../../../shared/Components/ButtonFloating/ButtonFloating';
import { DtoParteDiario } from '../../Trabajos/Domain/DtoParteDiario';
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario';

interface PropsViewMain {
  onChange: (name: string, value: any) => void;
  params: { id?: string, origen?: string };
  formRegistro: {
    values: any;
    touched: any;
    errors: any;
    handleBlur: Function;
    setFieldValue: Function;
  };
  onSave: () => void;
  trabajo: DtoTrabajos;
  selectInformation: EntityLoadData;
  parteDiario: DtoParteDiario;
  user: EntityDataUsuario
};

export const ViewMain = (props: PropsViewMain) => {
  const { onChange, params, formRegistro, onSave, trabajo, selectInformation, parteDiario, user } = props;
  const [disabled, setDisabled] = useState<boolean>(true);
  const navigate: NavigateFunction = useNavigate();
  useEffect(() => {
    setDisabled(DeshabilitarBoton(trabajo, params.origen));
  }, [trabajo])

  const languageTranslate = LanguageTranslate();

  return (
    <div className='container-fluid'>
      <ElementBreadCrumb list={[
        { text: languageTranslate.moduloMain.textoProgramados, navigate: true, path: '/main/programados' },
        { text: languageTranslate.menu['002'].titulo, navigate: true, path: '/main/programados/trabajos' },
        { text: params.id ?? '', navigate: false, path: '' }
      ]} />
      <div className='mb-2 d-flex justify-content-center'>
        <span style={{ color: '#2C3E50' }} className='fw-bold'>Fecha:</span>
        <span style={{ color: '#FF2079' }} className='ps-1'>
          {
            parteDiario.Codigo ? `${parteDiario?.Codigo?.slice(6, 8)}/${parteDiario?.Codigo?.slice(4, 6)}/${parteDiario?.Codigo?.slice(0, 4)}`
              : `${new Date().toLocaleString().split(',')[0]}`
          }
        </span>
      </div>
      <AddPersonal
        formRegistro={formRegistro}
        onChange={onChange}
        selectInformation={selectInformation}
        disabled={disabled}
        user={user}
      />
      <UploadImg
        imagenes={formRegistro.values.imagenes}
        onChange={onChange}
        text={languageTranslate.general.FotoCuadrilla}
        disabled={disabled}
      />
      <InputText
        label={languageTranslate.general.Novedades}
        name='Novedad'
        onChange={onChange}
        values={formRegistro.values}
        isRequired={disabled}
        disabled={!disabled}
      />
      {
        disabled && (
          <button onClick={onSave} className='btn btn-secondary w-100 mb-3'>
            {languageTranslate.btnGuardar}
          </button>
        )
      }
      <ButtonFloating
        onClick={() => {
          onChange('ListaPartesDiarios', '');
          navigate(`${AdapterConfigure.ROUTE_PARTESDIARIOS_TRABAJO}/${params.id}`);
        }}
        icono='fa-solid fa-clipboard-list'
        colorIcono='#2C3E50'
      />
      {
        params.origen &&
        <PdfGenerator
          data={parteDiario}
          right={72}
          formRegistro={formRegistro}
          trabajo={trabajo}
        />
      }
    </div>
  )
};

const DeshabilitarBoton = (trabajo: DtoTrabajos, origen?: string): boolean => {

  let disabled = true;

  let parteDiario = trabajo?.PartesDiarios?.filter((e) => e.Codigo === origen);

  if (parteDiario?.length === 1) {
    const estadoInterno = parteDiario[0].EstadosInternos.find((e) => e.Descripcion === 'APROBADO');
    if (estadoInterno) disabled = false;
  }

  return disabled && origen ? false : true;
}