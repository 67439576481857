import { FormikTouched } from 'formik'
import { ChangeEventHandler, FC } from 'react'
import { Col, Form } from 'react-bootstrap'

interface Props {
  name: string
  label: string
  handleChange: ChangeEventHandler<HTMLInputElement>
  values: { [fieldName: string]: any }
  touched: FormikTouched<{ [key: string]: any }>
  // errors: FormikErrors<{ [key: string]: any }>
  errors: any
  size?: number
  placeholder?: string
  required?: boolean
  disabled?: boolean
  type: 'number' | 'text'
  readOnly?: boolean
}

export const InputNumber: FC<Props> = ({
  name, size = 12, handleChange, touched, values, errors, placeholder = '...',
  label, required = false, disabled = false, type,
  readOnly = false
}: Props) => {
  return (
    <Form.Group className='mb-2' as={Col} md={size} controlId={`validation${name}`}>
      <Form.Label style={{ fontSize: 16 }}>{label}<span className='text-danger'>{required ? ' *' : ''}</span></Form.Label>
      <Form.Control
        type={type}
        name={name}
        value={values[name]}
        onChange={handleChange}
        isValid={touched[name] && !errors[name]}
        placeholder={placeholder}
        required={required}
        isInvalid={!!errors[name]}
        disabled={disabled}
        readOnly={readOnly}
      />
      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
      <Form.Control.Feedback type='invalid'>{errors[name]}</Form.Control.Feedback>
    </Form.Group>
  )
}