import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { EntityDataUsuario } from "../../../shared/Domain/EntityDataUsuario";
import { DtoResponseSelectStockPersonal } from "../../../Master/Home/Domain/DtoResponseSelectStockPersonal";

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

    public async selectStockPersonalLocal(user: EntityDataUsuario, id: number): Promise<DtoResponseSelectStockPersonal> {
        return await this.dbLocal.selectByIndexStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockPersonal`, value: id });
    }

}