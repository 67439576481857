import { RepositoryMain } from '../Domain/RepositoryMain'
import { EntityDataUsuario } from '../../../shared/Domain/EntityDataUsuario'
import { DtoStockAlmacen } from '../../../Master/Home/Domain/DtoStockAlmacen'

export class UseCaseSelectStockAlmacenLocal {
    private repository: RepositoryMain

    constructor(_repository: RepositoryMain) {
        this.repository = _repository
    }

    public async exec(user: EntityDataUsuario): Promise<DtoStockAlmacen[]> {
        return await this._exec(user)
    }

    private async _exec(user: EntityDataUsuario): Promise<DtoStockAlmacen[]> {
        return await this.repository.selectStockAlmacenLocal(user)
    }
}
