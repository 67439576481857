import { FormikErrors, FormikTouched } from 'formik'
import { DtoManoObraGlobal } from '../../../../../app/Domain/DtoManoObraGlobal'
import { InputSelect } from '../../../../shared/Components/ElementInputsCostume'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import { useEffect, useState } from 'react'
import { DtoTrabajosDE } from '../../Trabajos/Domain/DtoTrabajosDE'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { DtoHomePass } from '../../../Trabajos/Asignacion/Domain/DtoHomePassSimple'
import { DtoDataAddedValorizaciones } from '../../Shared/Preliquidar/Interfaces/DtoDataAddedValorizaciones'
import { CalcularResumenHPMOLiquidado } from 'sigo-package'
import { DtoInitialValues, codClientes, codNoCliente, codSopladoRD } from '../../Shared'

interface props {
  dataManoObraGlobal: DtoManoObraGlobal[]
  handleChange: (name: string, value: any) => void
  values: DtoInitialValues
  errors: FormikErrors<{ [key: string]: any }>
  touched: FormikTouched<{ [key: string]: any }>
  trabajo: DtoTrabajosDE | null
  valorizaciones: DtoDataAddedValorizaciones[]
  address?: { ID_AddressList: number }
}

export const AddedHPMO = (props: props) => {

  const langTranslate = LanguageTranslate()
  const [data, setData] = useState<DtoHomePass[]>([])

  const { dataManoObraGlobal, handleChange, values, errors, touched, trabajo, valorizaciones, address } = props

  useEffect(() => {
    try {
      if (!trabajo || address) return
      const dataHpListMO = trabajo.ColeccionHPListMO.filter(e => e.Identification.includes('-'))
      const ResumenHPMOUtilizado = CalcularResumenHPMOLiquidado(trabajo)

      const dataAux = trabajo.ColeccionAddressList.reduce((acc: DtoHomePass[], curr) => {

        let HPListMO = dataHpListMO.filter(e => e.Identification.includes(curr.AddressID))

        if (HPListMO.length !== 1) {
          console.warn(`Se encontró ${HPListMO.length} HPListMO para AddressID: ${curr.AddressID}`)
          var HPListMOAux = ResumenHPMOUtilizado.find(e => e.ID_HPListMO === HPListMO[0].ID_HPListMO)
        }
        if (HPListMO.length === 1) {
          var HPListMOAux = ResumenHPMOUtilizado.find(e => e.ID_HPListMO === HPListMO[0].ID_HPListMO)
        }

        return [
          ...acc,
          ...curr.BusinessHPList.map(e => {
            const newItem: DtoHomePass = {
              ID_HPListMO: HPListMO.length === 1 ? HPListMO[0].ID_HPListMO : 0,
              Type: HPListMO.length === 1 ? HPListMO[0].Type : '',
              Cantidad: (HPListMO.length === 1 ? HPListMO[0].Cantidad : 0) - (HPListMOAux ? HPListMOAux.CantidadUtilizado : 0),
              ID_AddressList: curr.ID_AddressList,
              AddressID: curr.AddressID,
              Home_ID: e.Home_ID,
              ClientType: 'Business',
              StreetName: e.Street,
              HouseNumber: e.HouseNumber.toString(),
              HouseNumbreComplement: e.HouseNumberAffix,
            }
            return newItem
          }),
          ...curr.ResidentialHPList.map(e => {
            const newItem: DtoHomePass = {
              ID_HPListMO: HPListMO.length === 1 ? HPListMO[0].ID_HPListMO : 0,
              Type: HPListMO.length === 1 ? HPListMO[0].Type : '',
              Cantidad: (HPListMO.length === 1 ? HPListMO[0].Cantidad : 0) - (HPListMOAux ? HPListMOAux.CantidadUtilizado : 0),
              ID_AddressList: curr.ID_AddressList,
              AddressID: curr.AddressID,
              Home_ID: e.Home_ID,
              ClientType: 'Residential',
              StreetName: e.Street,
              HouseNumber: e.HouseNumber.toString(),
              HouseNumbreComplement: e.HouseNumberAffix,
            }
            return newItem
          })
        ]
      }, [])
      const ID_HPListMO = new Set(valorizaciones.flatMap(e => e.homePassMO.map(e => e.ID_HPListMO)))
      setData(dataAux.filter(e => !ID_HPListMO.has(e.ID_HPListMO)))
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error')
    }
  }, [values])

  return (
    <>
      <InputSelect
        label={langTranslate.general.Direccion}
        name='homePassMO'
        onChange={handleChange}
        values={values}
        isRequired
        options={data
          .filter(e => {
            const codMO = values.asignacion.dataComplete.ManoObra.Codigo
            if (codClientes.includes(codMO) && codNoCliente.includes(codMO)) return true
            if ([...codSopladoRD, ...codClientes].includes(codMO) && e.ClientType === 'Residential') return true
            if (codNoCliente.includes(codMO) && e.ClientType === 'Business') return true
            return false
          })
          .map(e => ({
            label: `${e.StreetName} ${e.HouseNumber} ${e.HouseNumbreComplement} (${e.ClientType})`,
            value: `${e.ID_AddressList}${e.Home_ID}${e.ClientType}`,
            dataComplete: e
          }))}
        loading={false}
        disabled={!!address}
        disabledVirtualized
        errors={errors}
        touched={touched}
      />
    </>
  )
}
