import { RepositoryMain } from '../Domain/RepositoryMain'

export class UseCaseUploadFiles {
    private repository: RepositoryMain

    constructor(_repository: RepositoryMain) {
        this.repository = _repository
    }

    public async exec(fileNames: File[]): Promise<File[]> {
        return await this._exec(fileNames)
    }

    private async _exec(fileNames: File[]): Promise<File[]> {
        return await this.repository.uploadPhoto(fileNames)
    }
}