import { ElementBreadCrumb } from '../../../shared/Components/ElementBreadCrumb';
import ElementContenedorGenerico from "../../../shared/Components/Contenedor/ElementContenedorGenerico";
import GridDetalleStockPersonal from "../Components/GridDetalleStockPersonal";
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import './Style.scss';
import { DtoResponseSelectStockPersonal } from '../../../Master/Home/Domain/DtoResponseSelectStockPersonal';

interface PropsViewMain {
    detalleStockPersonal: DtoResponseSelectStockPersonal;
    params: { id?: string, trabajo?: string };
};

// const animatedComponents = makeAnimated();
export const ViewMain = (props: PropsViewMain) => {
    const {
        detalleStockPersonal,
        params
    } = props;

    const languageTranslate = LanguageTranslate();

    return (

        <div className='ViewProfile'>
            <div className="container-fluid">
                {/** BLOQUE DE BREADCRUMB */}
                <ElementBreadCrumb list={[
                    { text: languageTranslate.moduloMain.textoLogistica, navigate: true, path: '/main/logistica' },
                    { text: languageTranslate.general.Trabajos, navigate: true, path: `/main/logistica/trabajos` },
                    { text: languageTranslate.general.StockPersonal, navigate: true, path: `/main/logistica/trabajos/stockPersonal/${params.trabajo}` },
                    { text: params.id || '', navigate: false, path: '' }
                ]} />
                <ElementContenedorGenerico
                    title={languageTranslate.moduloLogistica.stockPersonal.detalleStockPersonal.titulo}
                >
                    <GridDetalleStockPersonal
                        stockPersonal={detalleStockPersonal}
                    />
                </ElementContenedorGenerico>
            </div>
        </div >
    )
};