const SCHEMA: string = process.env.REACT_APP_MAESTRO_SEDE_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MAESTRO_SEDE_ENTITY || '';
const REDIRECT_LOGISTICA: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGISTICA}` || '';
const SELECT_SALIDA_ALMACEN: string = '/SigoPwa/Logistica/Procesos/SalidaAlmacen/Select';

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    REDIRECT_LOGISTICA,
    SELECT_SALIDA_ALMACEN, 
};