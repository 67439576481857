import { EntityDataUsuario } from "../../../../shared/Domain/EntityDataUsuario";
import { DtoFormAddValorizacion } from "../Domain/DtoAsignacionValorizacion";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseUploadPhoto {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: File[], user: EntityDataUsuario, idTrabajo: number): Promise<any> {
        await this._exec(params, user, idTrabajo);
    }

    private async _exec(params: File[], user: EntityDataUsuario, idTrabajo: number): Promise<any> {
        await this.repository.uploadPhoto(params, user, idTrabajo);
    }
}