const {
    REACT_APP_ROUTE_PATH_MAIN,
    REACT_APP_ROUTE_PATH_MAIN_LOGISTICA,
} = process.env
const SCHEMA: string = process.env.REACT_APP_MAESTRO_SEDE_SCHEMA || ''
const ENTITY: string = process.env.REACT_APP_MAESTRO_SEDE_ENTITY || ''
const ROUTE_LOGISTICA = `/${REACT_APP_ROUTE_PATH_MAIN}/${REACT_APP_ROUTE_PATH_MAIN_LOGISTICA}`
const SELECT_STOCK_ALMACEN: string = '/SigoPwa/Logistica/Reportes/StockAlmacen/Select'

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    ROUTE_LOGISTICA,
    SELECT_STOCK_ALMACEN,
}