import './Style.scss';

import logo from '../../../../assets/img/logo.png';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';

//Imagenes

interface PropsViewMain {
    form: { values: any; touched: any; errors: any; handleBlur: Function; };
    onChange: Function;
    onSubmit: Function;
    onClickLogin: Function;
};

export const ViewMain = (props: PropsViewMain) => {
    const languageTranslate = LanguageTranslate();

    return (
        <div className="ViewMasterRecuperarPassword">
            <div className="fondo"></div>
            <div className="login-block">
                {/* <div className="container"> */}
                <div className="row text-center">
                    <div className="col-12">
                        <img src={logo} alt="logo.png" width={200} />
                    </div>
                </div>
                <form noValidate onSubmit={(evt) => { props.onSubmit(evt); }}>
                    <div className="row pt-5">
                        <div className="col-12">
                            <div className="input-group input-group-lg">
                                <span className="input-group-text" >
                                    <i className="fa-solid fa-user" />
                                </span>
                                <input
                                    type="text"
                                    name="username"
                                    className="form-control"
                                    required
                                    placeholder={languageTranslate.moduloRecuperarPassword.textoUsuario}
                                    value={props.form.values['username']}
                                    onChange={(evt) => { props.onChange('username', evt.target.value) }}
                                />
                            </div>
                        </div>
                        <div className="col-12 pt-3">
                            <div className="input-group input-group-lg">
                                <span className="input-group-text" >
                                    <i className="fa-solid fa-id-card" />
                                </span>
                                <input
                                    type="text"
                                    name="identificacion"
                                    className="form-control"
                                    required
                                    placeholder={languageTranslate.moduloRecuperarPassword.textoIdentificacion}
                                    value={props.form.values['identificacion']}
                                    onChange={(evt) => { props.onChange('identificacion', evt.target.value) }}
                                />
                            </div>
                        </div>
                        <div className="col-12 pt-3">
                            <div className="input-group input-group-lg">
                                <span className="input-group-text" >
                                    <i className="fa-solid fa-envelope" />
                                </span>
                                <input
                                    type="text"
                                    name="correoElectronico"
                                    className="form-control"
                                    required
                                    placeholder={languageTranslate.moduloRecuperarPassword.textoCorreoElectronico}
                                    value={props.form.values['correoElectronico']}
                                    onChange={(evt) => { props.onChange('correoElectronico', evt.target.value) }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row pt-5">
                        <div className="col-12">
                            <button type="submit" className="btn btn-primary" style={{ width: '100%', backgroundColor: '#01397D' }}>
                                {languageTranslate.moduloRecuperarPassword.textoAccion}
                            </button>
                        </div>
                    </div>
                </form>
                <div className="row pt-3">
                    <div className="col-12 text-center">
                        <div className="forgot-phone">
                            {
                                // eslint-disable-next-line
                                <a href="#" className="text-primary" onClick={() => props.onClickLogin()} style={{ fontWeight: 'bold' }}>{languageTranslate.moduloRecuperarPassword.textoRegresarLogin}</a>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
};