import { createSlice } from '@reduxjs/toolkit';

interface SliceDespachoAlmacenState {
	showBtnFirmar: boolean;
};

const initialState: SliceDespachoAlmacenState = {
	showBtnFirmar: false,
};

const despachoAlmacenSlice = createSlice({
	name: 'despachoAlmacenSlice',
	initialState,
	reducers: {
		changeShowBtnFirmar: (state, action) => {
			return {
				...state,
				showBtnFirmar: action.payload,
			};
		},
	},
});


export const {
	changeShowBtnFirmar,
} = despachoAlmacenSlice.actions;

export default despachoAlmacenSlice.reducer;