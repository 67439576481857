const SCHEMA: string = 'Maestro';
const ENTITY: string = 'Pais';

const TIMEZONE: string = 'America/Lima';
const SELECT_STOCK_PERSONAL: string = '/SigoPwa/Logistica/Reportes/StockPersonal/Select';
const SELECT_STOCK_ALMACEN: string = '/SigoPwa/Logistica/Reportes/StockAlmacen/Select';
const SELECT_CONSUMO_MATERIAL: string = '/SigoPwa/Logistica/Reportes/ConsumoMaterial/Select';
const SELECT_TRABAJOS: string = '/SigoPwa/Programados/Gestion/Trabajos/Select';
const VERIFY_TOKEN: string = '/SigoPwa/auth/verify'
const VERIFY_AUTOINVENTARIO: string = '/sigologistica/reportes/auto-inventario/verify'

export const AdapterConfigure = {
  TIMEZONE,

  SCHEMA,
  ENTITY,
  SELECT_STOCK_PERSONAL,
  SELECT_STOCK_ALMACEN,
  SELECT_TRABAJOS,
  VERIFY_TOKEN,
  VERIFY_AUTOINVENTARIO,
  SELECT_CONSUMO_MATERIAL,
};