import { Filtros } from "../../Domain/EntityParams";

export const FiltroPersonal: Filtros = {
    Filtros: [
        {
            $match: {
                'Estado.IdEstado': 1
            }
        },
        {
            $project: {
                IdPersonal: 1,
                Code: 1,
                Key: 1,
                DatosTrabajo: 1,
                ApellidoPaterno: 1,
                ApellidoMaterno: 1,
                Nombres: 1,
                TipoDocumento: 1,
                Identificacion: 1,
                DatosGenerales: 1,
                DatosArea: 1,
                Cargo: 1,
                Group: 1,
                Empresa: 1,
                Pais: 1,
                Administrativo: 1,
                OTs: 1,
                EmpresaUsuario: 1
            }
        }
    ]
}