import { RepositoryMain } from '../Domain/RepositoryMain'
import { DtoParteDiario } from '../../Trabajos/Domain/DtoParteDiario'
import { LoadImage } from '../../../../shared/Components/ElementInputFileImage'

export class UseCaseDownloadFiles {
    private repository: RepositoryMain

    constructor(_repository: RepositoryMain) {
        this.repository = _repository
    }

    public async exec(parteDiario: DtoParteDiario): Promise<LoadImage[]> {
        return await this._exec(parteDiario)
    }

    private async _exec(parteDiario: DtoParteDiario): Promise<LoadImage[]> {
        return await this.repository.downloadFiles(parteDiario)
    }
}