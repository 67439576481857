import { FC, useEffect, useState } from 'react'
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate'
import { DtoMaterialAlmacen } from '../../../Programados/TrabajosDE/Preliquidar/Domain/DtoMaterialAlmacen'
import { DtoStockAlmacen } from '../../../Master/Home/Domain/DtoStockAlmacen'
import { DtoConsumoMaterial } from '../../../Master/Home/Domain/DtoConsumoMaterial'

interface Props {
  item: DtoStockAlmacen
  dataConsumoMaterial: DtoConsumoMaterial[]
}

export const Card: FC<Props> = ({ item, dataConsumoMaterial }) => {

  // const [stockAlmacen, setStockPersonal] = useState<DtoMaterialAlmacen>()
  const [stock, setStock] = useState<number>(0)
  const langTrans = LanguageTranslate()

  useEffect(() => {
    // setStockPersonal(item)
    const disponible = item.Ingreso - item.Despacho + item.Devolucion
    const despachoTemporal = dataConsumoMaterial.reduce((acc, cm) => {
      // if (cm.Estado.ID_Estado !== 1) return acc
      const exist = cm.CosumoMaterial.filter(cmAux => cmAux.CodigoLlave.trim() === item.CodigoLlave.trim())
      if (exist.length > 1) throw Error(`¡No se pudo calcular disponible!`)
      return acc += exist.length ? exist[0].Cantidad : 0
    }, 0)
    setStock(disponible - despachoTemporal)
  }, [item])

  // if (!stockAlmacen) return <></>

  return (
    // <Link to={`${AdapterConfigure.ROUTE_AUTO_INVENTARIO}/${Codigo}`}>
    <div className='d-flex flex-row card rounded-3 p-3 mb-1' style={{ backgroundColor: '#343e56' }}>
      <div className='col-2 d-flex align-items-center'>
        <div
          className='rounded-circle d-flex align-items-center justify-content-center'
          style={{
            width: 45,
            height: 45,
            backgroundColor: '#049bed'
          }}
        >
          <span className='text-white fw-bold fs-6'>
            {Number(stock.toFixed(2))}
          </span>
        </div>
      </div>
      <div className='col-10'>
        <div className='row'>
          <div className='col-4'>
            <h6 className='text-white'>{langTrans.general.Codigo}</h6>
          </div>
          <div className='col-8'>
            <h6 style={{ color: '#95a0b9' }}>{item.Item.Codigo}</h6>
          </div>
        </div>
        <div className='row'>
          <div className='col-4'>
            <h6 className='text-white'>{langTrans.general.Descripcion}</h6>
          </div>
          <div className='col-8'>
            <h6 style={{ color: '#95a0b9' }}>{item.Item.Descripcion}</h6>
          </div>
        </div>
        <div className='row'>
          <div className='col-4'>
            <h6 className='text-white'>{langTrans.general.Lote}</h6>
          </div>
          <div className='col-8'>
            <h6 style={{ color: '#95a0b9' }}>{item.Lote ? item.Lote : '-'}</h6>
          </div>
        </div>
        <div className='row'>
          <div className='col-4'>
            <h6 className='text-white'>{langTrans.general.Bobina}</h6>
          </div>
          <div className='col-8'>
            <h6 style={{ color: '#95a0b9' }}>{item.Bobina ? item.Bobina : '-'}</h6>
          </div>
        </div>
      </div>
    </div>
    // </Link>
  )
}
