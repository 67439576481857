import { Filtros } from "../../Domain/EntityParams";

export const FiltroManoObraGlobal: Filtros = {
    Filtros: [
        {
            $match: {
                'Estado.ID_Estado': 1
            }
        },
        {
            $project: {
                Bitacora: 0,
                Estado: 0,
                IsDebug: 0,
            }
        }]
}