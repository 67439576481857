import { DtoAmap } from '../../../../../Home/Domain/DtoAmap'
import { DtoAsignacionValorizacion } from '../../../../../Trabajos/Asignacion/Domain/DtoAsignacionValorizacion'

export function GenerarDtoAsignacionValorizacion(amap: DtoAmap, cantidad: number) {
  const newAsignacion: DtoAsignacionValorizacion = {
    Actividad: amap.DetalleChile.Actividad,
    CantidadMO: cantidad,
    CantidadUO: 0,
    Clave: amap.DetalleChile.Clave,
    Codigo: amap.DetalleChile.Codigo,
    CodigoCub: amap.DetalleChile.Codigo,
    Especialidad: amap.DetalleChile.Especialidad,
    Estado: { ID_Estado: 1, Nombre: 'ACTIVO' },
    ID_Amap: amap.ID_Amap,
    ID_AsignacionValorizacion: -100,
    Linea: amap.DetalleChile.Linea,
    LineaCodigoCub: amap.DetalleChile.LineaCodigoCub,
    ManoObra: amap.DetalleChile.ManoObra,
    UnidadObra: amap.DetalleChile.UnidadObra
  }
  return newAsignacion
}