const SCHEMA: string = process.env.REACT_APP_MAESTRO_SEDE_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MAESTRO_SEDE_ENTITY || '';
const REDIRECT_FIRMARALBARAN: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGISTICA}/${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_FIRMA_ALBARAN}` || '';
const SELECT_SALIDA_ALMACEN: string = '/SigoPwa/Logistica/Procesos/SalidaAlmacen/Select';
const UPDATE_SALIDA_ALMACEN: string = '/SigoPwa/Logistica/Procesos/SalidaAlmacen/Update';
const SELECT_TRANSACCION_FOLIO: string = '/SigoPwa/Programados/Mantenimientos/TransaccionFolios/Select';

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    SELECT_SALIDA_ALMACEN,
    UPDATE_SALIDA_ALMACEN,
    REDIRECT_FIRMARALBARAN,
    SELECT_TRANSACCION_FOLIO,
};