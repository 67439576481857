const {
    REACT_APP_ROUTE_PATH_MAIN,
    REACT_APP_ROUTE_PATH_MAIN_LOGISTICA,
    REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_AUTO_INVENTARIO,
  } = process.env

const SCHEMA: string = '';
const ENTITY: string = '';

const TIMEZONE: string = 'America/Lima';
const ROUTE_LOGISTICA = `/${REACT_APP_ROUTE_PATH_MAIN}/${REACT_APP_ROUTE_PATH_MAIN_LOGISTICA}`
const ROUTE_AUTO_INVENTARIO: string = `${ROUTE_LOGISTICA}/${REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_AUTO_INVENTARIO}`

export const AdapterConfigure = {
    TIMEZONE,

    SCHEMA,
    ENTITY,
    ROUTE_AUTO_INVENTARIO,
};