import { EmptyData } from '../../../../shared/Components/ReactBootstrap/EmptyData/EmptyData'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import { DtoDataAddedValorizaciones } from '../../Shared/Preliquidar/Interfaces/DtoDataAddedValorizaciones'

interface props {
  valorizaciones: DtoDataAddedValorizaciones[]
  onRemove: (LineaCodigoCub: string) => void
}

export const AddedValorizaciones = (props: props) => {

  const languageTranslate = LanguageTranslate()

  if (!props.valorizaciones.length) return (<EmptyData />)

  return (
    <>
      {
        props.valorizaciones.map((e, i) => (
          <table className='table table-sm shadow' key={i}>
            <tbody >
              <tr>
                <td className='pt-2 ps-2'>{languageTranslate.general.Codigo}</td>
                <td className='pt-2'>{e.ManoObra.Codigo}</td>
                <td rowSpan={4} className='align-middle p-2' onClick={() => props.onRemove(e.LineaCodigoCub)}>
                  <i className='fa-solid fa-trash text-danger' style={{ fontSize: 15 }} />
                </td>
              </tr>
              <tr>
                <td className='ps-2'>{languageTranslate.general.Descripcion}</td>
                <td>{e.ManoObra.Nombre}</td>
              </tr>
              <tr>
                <td className='ps-2'>{languageTranslate.general.Linea}</td>
                <td>{e.Linea}</td>
              </tr>
              <tr>
                <td className='ps-2 pb-2'>{languageTranslate.general.Cantidad}</td>
                <td className='pb-2'>{e.CantidadPreLiquidado}</td>
              </tr>
            </tbody>
          </table>
        ))
      }
    </>
  )
}