import * as React from 'react'
import { Controller } from './Infraestructure/Controller'
import { ViewMain } from './Components/View'

export const ManagerStockAlmacen = () => {
    const { init, selectStockAlmacenDB, dataConsumoMaterial } = Controller()

    React.useEffect(() => {
        init()
        return () => { }
    }, [])

    return (
        <ViewMain
            selectStockAlmacenDB={selectStockAlmacenDB}
            dataConsumoMaterial={dataConsumoMaterial}
        />
    )
}
