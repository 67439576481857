import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./Style.scss";
import "./Style.scss";
import { DtoResponseSelectStockPersonal } from "../Domain/DtoResponseSelectStockPersonal";
import { ElementHorizontalBarChart } from "../../../shared/Components/ElementHorizontalBarChart/ElementHorizontalBarChart";
import { ElementBreadCrumb } from "../../../shared/Components/ElementBreadCrumb";

type PropsViewProps = {
  detalleStockPersonal: DtoResponseSelectStockPersonal;
  codigoLlave: string;
};
export const ViewMain: React.FC<PropsViewProps> = (props) => {
  const LABELS = ["T. Stock", "T. Liqui.", "T. Devo.", "T. Despa."];
  const { TotalStock, TotalLiquidacion, TotalDevolucion, TotalDespacho } =
    props.detalleStockPersonal;

  return (
    <div className="ViewMasterHome" style={{ margin: "20px" }}>
      <ElementBreadCrumb list={[]} />

      <ElementHorizontalBarChart
        labels={LABELS}
        dataGroups={[
          TotalStock,
          TotalLiquidacion,
          TotalDevolucion,
          TotalDespacho,
        ]}
        task={props.detalleStockPersonal.CodigoLlave}
      />
    </div>
  );
};
