import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import { EntitySalidaAlmacen } from "../../FirmaAlbaran/Domain/EntitySalidaAlmacen";
import './GridDetalleSalidaAlmacen.scss';

interface IGridDetalleSalidaAlmacen {
    item: EntitySalidaAlmacen;
}

const GridDetalleSalidaAlmacen = (props: IGridDetalleSalidaAlmacen) => {
    const {
        item
    } = props;

    const languageTranslate = LanguageTranslate();

    return (
        <div className="GridDetalleSalidaAlmacen mb-3 shadow rounded">
            <table className="table table-sm table-striped rowColor">
                <tbody>
                    <tr>
                        <th scope="row">{languageTranslate.moduloLogistica.firmaAlbaran.detalleDesapachosAlmacen.cabecera.textNombreAlmacen}</th>
                        <td>{item.Almacen?.Nombre}</td>    
                    </tr>
                    <tr>
                        <th scope="row">{languageTranslate.moduloLogistica.firmaAlbaran.detalleDesapachosAlmacen.cabecera.textPersonalApellidoNombre}</th>
                        <td>{item.Personal?.Apellidos + " " + item.Personal?.Nombres}</td>  
                    </tr>
                    <tr>
                        <th scope="row">{languageTranslate.moduloLogistica.firmaAlbaran.detalleDesapachosAlmacen.cabecera.textNroDocumento}</th>    
                        <td>{item.NroDocumento}</td>  
                    </tr>
                    <tr>
                        <th scope="row">{languageTranslate.moduloLogistica.firmaAlbaran.detalleDesapachosAlmacen.cabecera.textNroGuiaRemision}</th>
                        <td>{item.NroGuia}</td> 
                    </tr>
                    <tr>
                        <th scope="row">{languageTranslate.moduloLogistica.firmaAlbaran.detalleDesapachosAlmacen.cabecera.textPaisNombre}</th>    
                        <td>{item.Pais?.Nombre}</td> 
                    </tr>
                    <tr>
                        <th scope="row">{languageTranslate.moduloLogistica.firmaAlbaran.detalleDesapachosAlmacen.cabecera.textDelegacionNombre}</th>
                        <td>{item.Delegacion?.Nombre}</td> 
                    </tr>
                    <tr className="">
                        <th scope="row">{languageTranslate.moduloLogistica.firmaAlbaran.detalleDesapachosAlmacen.cabecera.textEmpresaRazonSocial}</th>    
                        <td>{item.Empresa?.RazonSocial}</td> 
                    </tr>
                    <tr>
                        <th scope="row">{languageTranslate.moduloLogistica.firmaAlbaran.detalleDesapachosAlmacen.cabecera.textFechaAprobacion}</th>
                        <td>{item.Fechas?.Aprobacion}</td> 
                    </tr>
                    <tr>
                        <th scope="row">{languageTranslate.moduloLogistica.firmaAlbaran.detalleDesapachosAlmacen.cabecera.textUsuarioIngresoApellidosNombre}</th>    
                        <td>{`${item.Usuarios?.Ingreso.ApellidoPaterno} ${item.Usuarios?.Ingreso.ApellidoMaterno} ${item.Usuarios?.Ingreso.Nombres}`}</td> 
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default GridDetalleSalidaAlmacen;