import { get } from 'env-var'
interface PropsAdapter {
    online: boolean
    URL_BASE: string
    URL_BASE_AUTH: string
    WS_HOST: string
    WS_PORT: string
    WS_PATH: string
    PATH_API_FILE: string
    user_API_FILE: string
    WS_SECURE: string
    SISTEMAS_VALORIZACION_MANO_OBRA: string[]
    SISTEMAS_VALORIZACION_MATERIALES: string[]
}

const MODE: string = process.env.REACT_APP_MODE || 'dev'

const getEnvVariable = (prodVar: string, devVar: string, localVar: string): string => {
    return MODE === 'prod'
        ? process.env[prodVar] ?? ''
        : MODE === 'dev'
            ? process.env[devVar] ?? ''
            : process.env[localVar] ?? ''
}

const URL_MASTER: string = getEnvVariable('REACT_APP_URL_MASTER', 'REACT_APP_URL_MASTER_DEV', 'REACT_APP_URL_MASTER_LOCAL') || 'http://localhost:8002'
const URL_BASE_AUTH: string = getEnvVariable('REACT_APP_URL_MASTER_AUTH', 'REACT_APP_URL_MASTER_AUTH_DEV', 'REACT_APP_URL_MASTER_AUTH_LOCAL') || 'http://localhost:8002'
// const URL_BASE: string = `${URL_MASTER}${MODE === 'prod' ? '/api/SIGO' : '/api/SIGODev'}`
const URL_BASE: string = `${URL_MASTER}${MODE === 'prod' ? '/api/SIGO' : '/api/SIGO'}`

const WS_HOST: string = getEnvVariable('REACT_APP_WS_HOST', 'REACT_APP_WS_HOST_DEV', 'REACT_APP_WS_HOST_LOCAL')
const WS_PORT: string = getEnvVariable('REACT_APP_WS_PORT', 'REACT_APP_WS_PORT_DEV', 'REACT_APP_WS_PORT_LOCAL')
const WS_PATH: string = getEnvVariable('REACT_APP_WS_PATH', 'REACT_APP_WS_PATH_DEV', 'REACT_APP_WS_PATH_LOCAL')
const WS_SECURE: string = getEnvVariable('REACT_APP_WS_SECURE', 'REACT_APP_WS_SECURE_DEV', 'REACT_APP_WS_SECURE_LOCAL')

const PATH_API_FILE: string = getEnvVariable('REACT_APP_PATH_API_FILE', 'REACT_APP_PATH_API_FILE_DEV', 'REACT_APP_PATH_API_FILE_LOCAL')
const user_API_FILE: string = getEnvVariable('REACT_APP_user_API_FILE', 'REACT_APP_user_API_FILE_DEV', 'REACT_APP_user_API_FILE_LOCAL')

const SISTEMAS_VALORIZACION_MATERIALES: string[] = get('REACT_APP_SISTEMAS_VALORIZACION_MATERIALES').asArray() || []
const SISTEMAS_VALORIZACION_MANO_OBRA: string[] = get('REACT_APP_SISTEMAS_VALORIZACION_MANO_OBRA').asArray() || []

export const AdapterConfigureGenerico: PropsAdapter = {
    online: navigator.onLine,
    URL_BASE,
    URL_BASE_AUTH,
    WS_HOST,
    WS_PORT,
    WS_PATH,
    PATH_API_FILE,
    user_API_FILE,
    WS_SECURE,
    SISTEMAS_VALORIZACION_MANO_OBRA,
    SISTEMAS_VALORIZACION_MATERIALES
}
