import { AdapterConfigure } from "./AdapterConfigure";
import { RepositoryImplMain } from "./RepositoryImplMain";
import { RootState } from "../../../shared/Infraestructure/AdapterStore";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { addLoading, hideIconMenu, removeLoading } from "../../../shared/Infraestructure/SliceGenerico";
import { changeTextSearchLogStockPersonal } from "../../../shared/Infraestructure/SliceTextSearch";
import { useEffect, useState } from "react";
import { DtoResponseSelectStockPersonal } from "../Domain/DtoResponseSelectStockPersonal";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ErrorCostume } from '../../../shared/Domain/ErrorCostume';
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { UseCaseSelectStockPersonalLocal } from "../Application/UseCaseSelectStockPersonalLocal";

export const Controller = () => {
  const [StockPersonal, setStockPersonal] = useState<DtoResponseSelectStockPersonal[]>([]);
  const [StockPersonalAux, setStockPersonalAux] = useState<DtoResponseSelectStockPersonal[]>([]);
  const dispatch: Dispatch = useDispatch();
  const navigate: NavigateFunction = useNavigate();
  const params = useParams<{ trabajo: string }>();
  const { websocket, dbLocal } = useSelector((state: RootState) => state.generico);
  const { user } = useSelector((state: RootState) => state.auth);
  const { rutaAnterior } = useSelector((state: RootState) => state.main);
  const { textSeachLogStockPersonal } = useSelector((state: RootState) => state.textSearch);
  const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);
  useEffect(() => {
    if (rutaAnterior.includes('/main/logistica/trabajos/stockPersonal/')) onChange('textSearch', textSeachLogStockPersonal);
    else onChange('textSearch', '');
  }, [StockPersonal])

  const formSearch = useFormik({
    initialValues: {
      textSearch: '',
    },
    validationSchema: Yup.object({}),
    onSubmit: (values, formikHelpers) => { },
  });

  const init = async () => {
    try {
      dispatch(hideIconMenu());
      await loadData();
    } catch (error) {
      console.error(error);
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false);
      navigate(AdapterConfigure.ROUTE_TRABAJOS, { replace: true });
    } finally {
      dispatch(removeLoading());
    }
  };

  const loadData = async () => {
    dispatch(addLoading({ textLoading: "Cargando Stock Personal..." }));
    let respStockPersonal = await new UseCaseSelectStockPersonalLocal(repository).exec(user, Number(params.trabajo));
    dispatch(removeLoading());
    if (respStockPersonal.length < 1) throw Error(`No hay registros para mostrar.`);
    setStockPersonal(respStockPersonal);
    setStockPersonalAux(respStockPersonal);
    fieldStock(respStockPersonal);
  };

  const fieldStock = async (respStockPersonal: DtoResponseSelectStockPersonal[]): Promise<void> => {
    respStockPersonal.map((e) => {
      if (e.TipoStock.Nombre === "NUEVO" || e.TipoStock.Nombre === "MAQUINARIA") {
        e.Stock = e.Despacho - e.Devolucion - e.Liquidacion + e.DespachoTR - e.DevolucionTR;
        e.Stock = e.Stock <= 0 ? 0 : e.Stock
      } else {
        e.Stock = e.Liquidacion - e.Devolucion;
      }
      e.OrdenTrabajo = e.OrdenTrabajo === '' ? 'Sin Orden Trabajo' : e.OrdenTrabajo;
    });
  }

  const onChange = (name: string, value: any) => {
    try {
      formSearch.setFieldValue(name, value);
      if (["textSearch"].includes(name)) {
        dispatch(changeTextSearchLogStockPersonal(value));
        setStockPersonalAux(StockPersonal.filter((el) => {
          if (el.Item.Codigo.includes(value.toUpperCase()) ||
            el.Item.Codigo.includes(value.toLowerCase()) ||
            el.Item.Nombre.includes(value.toUpperCase()) ||
            el.Item.Nombre.includes(value.toLowerCase())) return el;
        }));
      }
    } catch (error) {
      console.error(error);
      let err: ErrorCostume = new ErrorCostume((error as Error).message);
      AdapterGenerico.createMessage('Alerta', err.message, 'warning', false);
    }
  }

  return {
    init,
    user,
    formSearch,
    StockPersonalAux,
    onChange,
    params,
  };
}
