import { DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { DtoResponseEstadoInterno } from '../../../../../app/Domain/DtoResponseEstadoInterno'
import { DtoBitacora } from '../../../../shared/Domain/Dto/DtoBitacora'
import { EntityEstadoInterno } from '../../../../shared/Domain/Entity/EntityEstadoInterno'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { DtoAmap } from '../../../Home/Domain/DtoAmap'
import { DtoItem } from '../../../Home/Domain/DtoItem'
import { DtoGoms } from '../Domain/DtoObras'
import { DtoRequestUpdateGom } from '../Domain/DtoRequestUpdateGom'
import { FirmaReplanteoDto } from '../Domain/FirmaReplanteoDto'
import { IManoObraEditados, IReservaEditados } from '../Domain/IEditados'
import { RepositoryMain } from '../Domain/RepositoryMain'
import { UltimaFirmaReplanteoDto } from '../Domain/UltimaFirmaReplanteoDto'

export interface IUpdateParams {
  CodigoDelegacion: string
  NombrePais: string
  datosMateriales: DtoItem[]
  reservaEditados: IReservaEditados
  datosManoObra: DtoAmap[]
  manoObraEditados: IManoObraEditados
  documentacionReplanteo: DtoNodoDoc[]
  gom: DtoGoms
  usuario: EntityDataUsuario,
  dataEstadoInterno: DtoResponseEstadoInterno[]
  FirmasRePlanteo: FirmaReplanteoDto[]
}
export class UseCaseUpdateGom {
  private repository: RepositoryMain
  private dataEstadoInterno: DtoResponseEstadoInterno[]

  constructor(_repository: RepositoryMain) {
    this.repository = _repository
    this.dataEstadoInterno = []
  }

  public async exec(params: IUpdateParams): Promise<DtoGoms[]> {
    return await this._exec(params)
  }

  private async _exec(params: IUpdateParams): Promise<DtoGoms[]> {
    const { CodigoDelegacion, NombrePais, gom, documentacionReplanteo, datosMateriales, reservaEditados, datosManoObra, manoObraEditados, usuario, dataEstadoInterno, FirmasRePlanteo } = params
    this.dataEstadoInterno = dataEstadoInterno
    this.updateDatosMateriales(gom, datosMateriales, reservaEditados)
    this.updateDatosManoObra(gom, datosManoObra, manoObraEditados)
    this.updatePlanoAuxiliarFinal(gom, documentacionReplanteo)
    this.updateFirmasRePlanteo(gom, FirmasRePlanteo, usuario)
    this.removeFiles(gom)
    this.updateBitacora(gom, usuario)
    this.updateEstadoInterno(gom, usuario)
    const body: DtoRequestUpdateGom = {
      Header: {
        Coleccion: {
          Codigo: CodigoDelegacion,
          Nombre: NombrePais
        }
      },
      Body: gom
    }
    const rpta = await this.repository.updateGom(body)
    return rpta
  }

  private updateDatosMateriales(gom: DtoGoms, datosMateriales: DtoItem[], reservaEditados: IReservaEditados) {
    for (const material of datosMateriales) {
      const cantidad = parseInt(reservaEditados[material.Codigo])
      if (isNaN(cantidad)) throw Error(`¡Error al obtener cantidad!`)
      material.Cantidad = cantidad
      material.Total = material.Cantidad * material.Valor
    }
    gom.DatosMateriales = datosMateriales
  }

  private updateDatosManoObra(gom: DtoGoms, datosManoObra: DtoAmap[], manoObraEditados: IManoObraEditados) {
    let count = 1
    for (const material of datosManoObra) {
      material.Cantidad = Number(manoObraEditados[material.Codigo])
      material.DetalleChile.CanMOCub = material.Cantidad
      if (material.ID_Amap === 0) {
        const ID_Amap = this.GenerarID_Valorizacion(gom, count)
        material.ID_Amap = ID_Amap
        material.DetalleChile.Linea = String(ID_Amap)
        material.DetalleChile.LineaCodigoCub = `${ID_Amap}${material.DetalleChile.Codigo}`
        count++
      }
      material.Total = material.Cantidad * material.Precio
    }
    gom.DatosManoObra = datosManoObra
  }

  private GenerarID_Valorizacion(gom: DtoGoms, index: number): number {
    return Math.max(...gom.DatosManoObra.map(e => e.ID_Amap), 0) + index
  }

  private updatePlanoAuxiliarFinal(gom: DtoGoms, documentacionReplanteo: DtoNodoDoc[]) {
    gom.PlanoAuxiliarFinal = documentacionReplanteo.map(dr => dr.Files.map(f => f)).flat()
  }

  private updateBitacora(gom: DtoGoms, usuario: EntityDataUsuario) {
    const newBitacora = new DtoBitacora()
    newBitacora.Action = 'Actualizar Replanteo'
    newBitacora.Usuario.Identificacion = usuario.Identificacion
    newBitacora.Usuario.Nombres = usuario.Nombres
    newBitacora.Usuario.Apellidos = `${usuario.ApellidoPaterno} ${usuario.ApellidoMaterno}`
    gom.Bitacora = [...gom.Bitacora, newBitacora]
  }

  private updateEstadoInterno(gom: DtoGoms, usuario: EntityDataUsuario) {
    const ultimoEstado = gom.Ultimo_Estado_Interno.Descripcion.trim().toUpperCase()
    const estadosUpd = ['PENDIENTE', 'ACEPTADA', 'ENVIADO']
    if (estadosUpd.includes(ultimoEstado)) {
      const estadoInterno = this.dataEstadoInterno.filter(e => e.Descripcion === 'ABIERTO')
      if (estadoInterno.length !== 1) throw Error(`¡No se pudo obtener Estado Interno! Refrezca los datos de EstadoInterno o Comunícate con Soporte`)

      const newEstado_Interno = this.generarEntityEstadoInterno(estadoInterno[0], usuario)
      gom.Estados_Internos = [...gom.Estados_Internos, newEstado_Interno]
      gom.Ultimo_Estado_Interno = newEstado_Interno
    }
  }

  private generarEntityEstadoInterno(estadoInterno: DtoResponseEstadoInterno, usuario: EntityDataUsuario) {
    const newEntityEstadoInterno = new EntityEstadoInterno()
    newEntityEstadoInterno.ID_EstadoInterno = estadoInterno.ID_EstadoInterno
    newEntityEstadoInterno.Descripcion = estadoInterno.Descripcion
    newEntityEstadoInterno.Icono = estadoInterno.Icono
    newEntityEstadoInterno.Color = estadoInterno.Color
    newEntityEstadoInterno.Orden = estadoInterno.Orden
    newEntityEstadoInterno.Sistema = estadoInterno.Sistema
    newEntityEstadoInterno.ColorFondo = estadoInterno.ColorFondo
    newEntityEstadoInterno.Tipo = estadoInterno.Tipo
    newEntityEstadoInterno.Observacion = estadoInterno.Observacion
    newEntityEstadoInterno.UsuarioEdicion.Identificacion = usuario.Identificacion
    newEntityEstadoInterno.UsuarioEdicion.User = `${usuario.Nombres} ${usuario.ApellidoPaterno} ${usuario.ApellidoMaterno}`
    newEntityEstadoInterno.Anexos = estadoInterno.Anexos
    newEntityEstadoInterno.Pais = estadoInterno.Pais
    return newEntityEstadoInterno
  }

  private updateFirmasRePlanteo(gom: DtoGoms, FirmasRePlanteo: FirmaReplanteoDto[], usuario: EntityDataUsuario) {

    if (!FirmasRePlanteo.length) return

    const newFirmaReplanteo = new UltimaFirmaReplanteoDto()

    // const firmaLider = FirmasRePlanteo.find(e => e.Cargo === 'Lider')
    // if (!firmaLider) throw Error(`¡No se pudo obtener firma del Lider!`)

    const firmaSupervisor = FirmasRePlanteo.find(e => e.Cargo === 'Supervisor')
    if (!firmaSupervisor) throw Error(`¡No se pudo obtener firma del Supervisor!`)

    const firmaTecnico = FirmasRePlanteo.find(e => e.Cargo === 'Tecnico')
    if (!firmaTecnico) throw Error(`¡No se pudo obtener firma del Tecnico!`)

    if (gom.Ultimo_Estado_Interno.Descripcion.trim().toUpperCase() === 'ENVIADO') {
      newFirmaReplanteo.Lider = new FirmaReplanteoDto()
    }

    newFirmaReplanteo.Supervisor = firmaSupervisor
    newFirmaReplanteo.Tecnico = firmaTecnico
    newFirmaReplanteo.UsuarioEdicion.User = usuario.User
    newFirmaReplanteo.UsuarioEdicion.Identificacion = usuario.Identificacion

    gom.UltimaFirmaReplanteo = newFirmaReplanteo
    gom.FirmasRePlanteo.push(newFirmaReplanteo)
  }

  private removeFiles(gom: DtoGoms) {
    for (const plano of gom.PlanoAuxiliarFinal) {
      delete plano.File
    }
    for (const firma of gom.FirmasRePlanteo) {
      delete firma.Lider.file
      delete firma.Lider.usuarioEnSesion
      delete firma.Supervisor.file
      delete firma.Supervisor.usuarioEnSesion
      delete firma.Tecnico.file
      delete firma.Tecnico.usuarioEnSesion
    }
  }
}