const SCHEMA: string = process.env.REACT_APP_MAESTRO_SEDE_SCHEMA || ''
const ENTITY: string = process.env.REACT_APP_MAESTRO_SEDE_ENTITY || ''
const SELECT_TRABAJOS: string = '/SigoPwa/Programados/Gestion/Trabajos/Select'
const UPDATE_TRABAJOS: string = '/SigoPwa/Programados/Gestion/Trabajos/Update'
const ROUTE_PROGRAMADOS: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS}`
const ROUTE_TRABAJOS: string = `${ROUTE_PROGRAMADOS}/${process.env.REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_TRABAJOS}`
const ROUTE_PARTESDIARIOS_TRABAJO: string = `${ROUTE_TRABAJOS}/${process.env.REACT_APP_ROUTE_PATH_TRABAJOS_PARTESDIARIOS}`
const ROUTE_ASIGNACION_TRABAJO: string = `${ROUTE_TRABAJOS}/${process.env.REACT_APP_ROUTE_PATH_TRABAJOS_ASIGNACION}`

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    SELECT_TRABAJOS,
    ROUTE_PROGRAMADOS,
    ROUTE_TRABAJOS,
    ROUTE_PARTESDIARIOS_TRABAJO,
    UPDATE_TRABAJOS,
    ROUTE_ASIGNACION_TRABAJO
}