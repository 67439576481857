import { Outlet } from 'react-router-dom';

import './Style.scss';
import { ObjectHeader } from '../../../shared/Components/ObjectHeader';
import { EntityDataUsuario } from '../../../shared/Domain/EntityDataUsuario';
import { EntityMenu } from '../../../shared/Domain/EntityMenu';

interface ViewProps {
    auth: boolean;
    user: EntityDataUsuario;
    iconMenu: boolean;
    showSaludo: boolean;
    online: boolean;
    countProcess: number;
    onClickHome: Function;
    onClickProgramados: Function;
    onClickLogistica: Function;
    onClickATC: Function;
    onClickHelp: Function;
    onClickReport: Function;
    onClickProfile: Function;
    onClickVariable: Function;
    onClickLogout: Function;
    onHideIconMenu: Function;
    onShowIconMenu: Function;
    menu: EntityMenu[]
};

export const ViewMain = (props: ViewProps) => {
    return (
        <>
            <ObjectHeader
                auth={props.auth}
                user={props.user}
                iconMenu={props.iconMenu}
                online={props.online}
                countProcess={props.countProcess}
                onHideIconMenu={props.onHideIconMenu}
                onShowIconMenu={props.onShowIconMenu}
                onClickHome={props.onClickHome}
                onClickProgramados={props.onClickProgramados}
                onClickLogistica={props.onClickLogistica}
                onClickATC={props.onClickATC}
                onClickHelp={props.onClickHelp}
                onClickReport={props.onClickReport}
                onClickProfile={props.onClickProfile}
                onClickVariable={props.onClickVariable}
                onClickLogout={props.onClickLogout}
                showSaludo={props.showSaludo}
                menu={props.menu}
            />
            <main className='ViewMasterMain'>
                <Outlet />
            </main>
        </>
    )
};