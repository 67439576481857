import * as React from 'react'
import { Controller } from './Infraestructure/Controller'
import { ViewMain } from './Components/View'

const ManagerEntity = () => {
  const { init, trabajo, data } = Controller()

  React.useEffect(() => {
    init()
    return () => { }
    // eslint-disable-next-line
  }, [])

  return (
    <ViewMain
      trabajo={trabajo}
      data={data}
    />
  )
}

export default ManagerEntity