import { useEffect, useState } from 'react'
import { DtoDocumentacionPEX } from '../../../../../app/Domain/DtoDocumentacionPEX'
import { Adjuntos } from '../../../../shared/Components/Adjuntos/Adjuntos'
import { DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { DtoHPList, DtoTrabajosDE } from '../../Trabajos/Domain/DtoTrabajosDE'
// import { useSelector } from 'react-redux'
// import { RootState } from '../../../../shared/Infraestructure/AdapterStore'


// const quitNodos: Array<string> = ["BALIZA", "DROP", "CABLE"];
interface props {
  dataDocumentacionPEX: DtoDocumentacionPEX | undefined
  onSave: (nodos: DtoNodoDoc[], newFiles: File[]) => Promise<void>
  trabajo: DtoTrabajosDE | null
  dataBusiness: DtoHPList[]
  dataResidential: DtoHPList[]
}

export const TabCalidad = (props: props) => {

  const { dataDocumentacionPEX, onSave, trabajo, dataBusiness, dataResidential } = props

  const [newFiles, setNewFiles] = useState<File[]>([])
  const [nodos, setNodos] = useState<DtoNodoDoc[]>([])
  // const [showAlert, setShowAlert] = useState<boolean>(true)
  // const { user } = useSelector((state: RootState) => state.auth)

  useEffect(() => {
    if (dataDocumentacionPEX && trabajo) {
      restoreFiles(dataDocumentacionPEX.Nodos, trabajo)
      setNodos(dataDocumentacionPEX.Nodos)
    }
  }, [dataDocumentacionPEX])

  const restoreFiles = (documentacion: DtoNodoDoc[], trabajo: DtoTrabajosDE) => {
    for (const nodo of documentacion) {
      const files = trabajo.ColeccionObras[0].Anexos.filter(e => e.CodigoNodo === nodo.Codigo)
      nodo.Files = [...nodo.Files.filter(e => e.Estado.ID_Estado === 2024), ...files]
      restoreFiles(nodo.Children, trabajo)
    }
  }

  return (
    <div>
      {/* <div style={{ display: showAlert ? undefined : 'none' }} className="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>Hola {user.Nombres}!</strong> Debes refrescar la colección DocumentacionPEX para ver algunas fotos de ejemplo cargadas. (Omitir si ya refrezcó luego de leer está alerta)
        <button onClick={() => setShowAlert(false)} type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div> */}
      <Adjuntos
        Nodos={nodos}
        newFiles={newFiles}
        setNewFiles={setNewFiles}
        ruta={`OBRA_${trabajo?.ColeccionObras[0].ID_incidencia}_Anexos_`}
        setNodos={setNodos}
        colorNodos
        onSave={async () => {
          await onSave(nodos, newFiles)
          setNewFiles([])
        }}
        dataAddress={[
          ...dataBusiness.map(el => ({ ...el, tipo: 'Business' })),
          ...dataResidential.map(el => ({ ...el, tipo: 'Residential' })),
        ]}
      />
    </div>
  )
}
