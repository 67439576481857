import { DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { DtoFilesDoc } from '../../../Trabajos/Trabajos/Domain/DtoFilesDoc'
import { DtoTrabajosDE } from '../../Trabajos/Domain/DtoTrabajosDE'
import { DtoReqUpdateObraDE } from '../../../../shared/Domain/Dto/DtoReqUpdateObraDE'
import { RepositoryMain } from '../Domain/RepositoryMain'

export class UseCaseUpdateObra {

  private repository: RepositoryMain
  private user: EntityDataUsuario
  private userGestor: EntityDataUsuario

  constructor(_repository: RepositoryMain, _user: EntityDataUsuario, gestor: EntityDataUsuario) {
    this.repository = _repository
    this.user = _user
    this.userGestor = gestor
  }

  async exec(trabajo: DtoTrabajosDE, nodos: DtoNodoDoc[], newFiles: File[]) {
    return await this._exec(trabajo, nodos, newFiles)
  }

  private async _exec(trabajo: DtoTrabajosDE, nodos: DtoNodoDoc[], newFiles: File[]) {

    const newAnexos = this.getAnexos(nodos)
    this.eliminarFile(newAnexos)

    trabajo.ColeccionObras[0].Anexos = newAnexos

    const { Delegacion: { Codigo }, Pais: { Nombre } } = this.user
    const body: DtoReqUpdateObraDE = {
      Header: { Coleccion: { Codigo, Nombre } },
      Body: trabajo.ColeccionObras[0]
    }

    await this.repository.uploadFiles(newFiles)
    const response = await this.repository.updateObra(body, newFiles, this.user, trabajo)

    const indexToUpdate = trabajo.ColeccionObras.findIndex(al => al.ID_incidencia === response[0].ID_incidencia)
    if (indexToUpdate !== -1) trabajo.ColeccionObras.splice(indexToUpdate, 1, response[0])

    return trabajo

  }

  private getAnexos(nodos: DtoNodoDoc[]): DtoFilesDoc[] {
    return nodos.reduce((acc: DtoFilesDoc[], nodo) => {
      return [...acc, ...nodo.Files.filter(e => e.Estado.ID_Estado === 1), ...this.getAnexos(nodo.Children)]
    }, [])
  }

  private eliminarFile(anexos: DtoFilesDoc[]) {
    for (const anexo of anexos) {
      delete anexo.File
      anexo.Ultimo_Flujo.Usuario.Identificacion = this.userGestor.Identificacion
      anexo.Ultimo_Flujo.Usuario.Apellidos = `${this.userGestor.ApellidoPaterno} ${this.userGestor.ApellidoMaterno}`
      anexo.Ultimo_Flujo.Usuario.Nombres = this.userGestor.Nombres
    }
  }

}