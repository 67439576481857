const SCHEMA: string = process.env.REACT_APP_MAESTRO_SEDE_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MAESTRO_SEDE_ENTITY || '';
const SELECT_TRABAJOS: string = '/SigoPwa/Programados/Gestion/Trabajos/Select';
const ROUTE_PROGRAMADOS: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS}`;
const ROUTE_TRABAJOS: string = `${ROUTE_PROGRAMADOS}/${process.env.REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_TRABAJOS}`;
const ROUTE_ASIGNACION_TRABAJO: string = `${ROUTE_TRABAJOS}/${process.env.REACT_APP_ROUTE_PATH_TRABAJOS_ASIGNACION}`;
const ROUTE_PARTEDIARIO_TRABAJO: string = `${ROUTE_TRABAJOS}/${process.env.REACT_APP_ROUTE_PATH_TRABAJOS_PARTEDIARIO}`;

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    SELECT_TRABAJOS,
    ROUTE_PROGRAMADOS,
    ROUTE_TRABAJOS,
    ROUTE_ASIGNACION_TRABAJO,
    ROUTE_PARTEDIARIO_TRABAJO,
};