import { DtoFilesDoc, DtoSize } from '../../context/Programados/Trabajos/Trabajos/Domain/DtoFilesDoc'
import { DtoDelegacion } from '../../context/shared/Domain/Dto/DtoDelegacion'
import { DtoEstado } from '../../context/shared/Domain/Dto/DtoEstado'
import { DtoPais } from '../../context/shared/Domain/Dto/DtoPais'

export class DtoDocumentacionBaremoPEX {
    ID_DocumentacionBaremoPEX: number = 0
    ContratoCod: string = ''
    CodigoMO: string = ''
    DescripcionMO: string = ''
    Codigo: string = ''
    Nombre: string = ''
    Pais: DtoPais = new DtoPais()
    Delegacion: DtoDelegacion = new DtoDelegacion()
    Nodos: DtoNodoDoc[] = []
}

export class DtoNodoDoc {
    id?: number = 0
    label?: string = ''
    Files: DtoFilesDoc[] = []
    Titulo: string = ''
    Codigo: string = ''
    Descripcion: string = ''
    Extension: string[] = []
    Size: DtoSize = new DtoSize()
    Requerido: boolean = false
    CantidadMin: number = 0
    CantidadMax: number = 0
    Children: DtoNodoDoc[] = []
    Estado: DtoEstado = new DtoEstado()
}