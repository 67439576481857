import { createSlice } from '@reduxjs/toolkit'
import { iInitialState } from '../domain/iInitialState'
import { iAutoInventario } from '../domain/iAutoInventario'

const initialState: iInitialState = {
  dataAutoInventario: [],
  dataStockPersonal: [],
  updateAutoInventario: new iAutoInventario(),
}

const autoInventarioSlice = createSlice({
  name: 'autoInventario',
  initialState,
  reducers: {
    setDataStockPersonal: (state, action) => { state.dataStockPersonal = action.payload },
    setDataAutoInventario: (state, action) => { state.dataAutoInventario = action.payload },
    setUpdateAutoInventario: (state, action) => { state.updateAutoInventario = action.payload },
    updateAutoInventarioItem: (state, { payload }) => {
      const index = state.dataAutoInventario.findIndex(item => item.Codigo === payload.Codigo)
      if (index !== -1) {
        state.dataAutoInventario[index] = payload
      }
    },
  },
  extraReducers: builder => { }
})

export const {
  setDataStockPersonal,
  setDataAutoInventario,
  setUpdateAutoInventario,
  updateAutoInventarioItem,
} = autoInventarioSlice.actions
export const autoInventarioReducer = autoInventarioSlice.reducer