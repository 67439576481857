import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ViewApp } from "./Components/View";
import { isMobile } from 'react-device-detect';
import { ReactGAImplementation } from "react-ga4";

const ManagerApp = () => {

    const { init, end, loadingMaestros, availableUpdate, updateApp } = Controller();

    React.useEffect(() => {

        const mode = process.env.REACT_APP_MODE;
        document.title = mode === 'prod' ? 'SIGO' :
            mode === 'dev' ? 'SIGO - PRUEBA' :
                mode === 'local' ? 'SIGO - LOCAL' : 'SIGO - MODO DESCONOCIDO'

        if (isMobile) init();

        if (mode === 'prod') {
            const reactGA = new ReactGAImplementation();
            reactGA.initialize("G-1RYGYHNSS4"); // Reemplaza con tu ID de seguimiento de Google Analytics
            reactGA.event('page_view'); // Envía un evento de vista de página
            window.addEventListener('contextmenu', event => event.preventDefault())
        }
        // controller.termns()
        return () => {
            if (isMobile) end();
        }
        // eslint-disable-next-line
    }, []);

    return (<ViewApp loadingMaestros={loadingMaestros} availableUpdate={availableUpdate} updateApp={updateApp} />);
};

export default ManagerApp;