import { DtoItems } from "../../../../app/Domain/DtoItems";
import { EntityParams } from "../Domain/EntityParams";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseSelectItems {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: EntityParams): Promise<DtoItems[]> {
        return await this._exec(params);
    }

    private async _exec(params: EntityParams): Promise<DtoItems[]> {
        return await this.repository.selectItems(params);
    }
}