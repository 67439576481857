import { EntityMenu } from '../../../shared/Domain/EntityMenu';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import './Style.scss';

interface PropsView {
    menu: Array<EntityMenu>;
    onClickMenu: Function;
};

export const ViewMain = (props: PropsView) => {
    const languageTranslate = LanguageTranslate();

    return (
        <div className="ViewProgramadosHome">
            <div className="container">
                {
                    props.menu.map(row => (
                        <div className="row pb-3" key={row.id}>
                            <div className="col-12">
                                <div className='shadow-lg' style={{ width: '100%', padding: 5, margin: 0, borderRadius: '10px', cursor: 'pointer' }} onClick={() => props.onClickMenu(row)}>
                                    <div className="row">
                                        <div className="col-2">
                                            <button className='btn btn-secondary btn-icon-first' style={{ marginTop: '5px' }}>
                                                <i className={`text-white ${row.icono}`} />
                                            </button>
                                        </div>
                                        <div className="col-8">
                                            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{languageTranslate.menu[row.codigo as keyof typeof languageTranslate.menu].titulo}</span>
                                            <br />
                                            <span style={{ fontSize: '10px' }}>{languageTranslate.menu[row.codigo as keyof typeof languageTranslate.menu].descripcion}</span>
                                        </div>
                                        <div className="col-2 position-relative">
                                            <button className='btn btn-lg btn-secondary text-white text-center'>
                                                {">"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
};