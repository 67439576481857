import { RepositoryMain } from '../Domain/RepositoryMain'

export class UseCaseVerifyToken {
  private repository: RepositoryMain;

  constructor(_repository: RepositoryMain) {
    this.repository = _repository;
  }

  public async exec(): Promise<any> {
    return await this._exec()
  }

  private async _exec(): Promise<any> {
    await this.repository.verifyToken()
  }
}
