import { Filtros } from "../../Domain/EntityParams";

export const FiltroPrecioMaterial: Filtros = {
    Filtros: [
        {
            $match: {
                'Estado.ID_Estado': 1
            }
        },
        {
            $project: {
                ID_PrecioMaterial: 1,
                'Contrato.Codigo': 1,
                'Contrato.Descripcion': 1,
                Pais: 1,
                Delegacion: 1,
                Codigo: 1,
                Descripcion: 1,
                Precio: 1,
                Moneda: 1,
                Simbolo: 1,
                Decimales: 1,
                Item: 1,
                PreciosVigentes: 1,
                Vigencia: 1,
            }
        }
    ]
}