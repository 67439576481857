import { CSSProperties, ReactNode } from 'react'

interface props {
  children: ReactNode
  className?: string
  style?: CSSProperties
}

export function Container({ children, className, style }: props) {
  return (
    <div style={style ? { ...style } : undefined} className={`container ${className ? className : ''}`.trim()}>
      {children}
    </div>
  )
}