import { DtoResponseCuadrillas } from '../../../../app/Domain/DtoResponseCuadrillas';
import { EntityParams } from '../Domain/EntityParams';
import { RepositoryMain } from '../Domain/RepositoryMain';

export class UseCaseSelectCuadrillas {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: EntityParams): Promise<Array<DtoResponseCuadrillas>> {
        return await this._exec(params);
    }

    private async _exec(params: EntityParams): Promise<Array<DtoResponseCuadrillas>> {
        return await this.repository.selectCuadrillas(params);
    }
}