import { useState } from 'react'
import { Container } from '../../../../shared/Components/Container/Container'
import { ElementBreadCrumb } from '../../../../shared/Components/ElementBreadCrumb'
import { ElementItemCardGenerico } from '../../../../shared/Components/Programados/ElementItemCardGenerico'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import { DtoTrabajosDE } from '../../Trabajos/Domain/DtoTrabajosDE'
import { AdapterConfigure } from '../Infraestructure/AdapterConfigure'
import { AdjuntosValorizaciones } from './AdjuntosValorizaciones'
import { DtoValorizacion } from '../../../Trabajos/Asignacion/Domain/DtoValorizacion'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { DtoDocumentacionBaremoPEX, DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { useParams } from 'react-router-dom'
import { InputSearch } from '../../../../shared/Components/ReactBootstrap/InputSearch/InputSearch'
import { AdjuntosAddress } from './AdjuntosAddress'
import { typeHPList } from '../../DocHP/Domain/typeHPList'

interface props {
  trabajo: DtoTrabajosDE | null
  dataDocumentacionBaremoPEX: DtoDocumentacionBaremoPEX[]
  onSaveAdjuntosAddress: (
    nodos: DtoNodoDoc[],
    typeHPList: typeHPList,
    Home_ID: string,
    ID_AddressList: number,
    newFiles: File[],
    valorizacionUpdate: DtoValorizacion
  ) => Promise<boolean>
  saveFiles: (nodos: DtoNodoDoc[], selectedValorizacion: DtoValorizacion) => Promise<void>
}

export const ViewMain = (props: props) => {

  const { trabajo, dataDocumentacionBaremoPEX, onSaveAdjuntosAddress, saveFiles } = props
  const languageTranslate = LanguageTranslate()
  const [showModal, setShowModal] = useState(false)
  const [showModalAddress, setShowModalAddress] = useState(false)
  const [selectedValorizacion, setSelectedValorizacion] = useState<DtoValorizacion>()
  const { estado } = useParams<{ id: string, estado: string }>()
  const [searchTerm, setSearchTerm] = useState<string>('')

  const handleOnClickBtnDocBaremo = (valorizacion: DtoValorizacion) => {
    try {
      setSelectedValorizacion(valorizacion)
      setShowModal(true)
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('¡Alerta!', (error as Error).message, 'error')
    }
  }
  const handleOnClickBtn = (valorizacion: DtoValorizacion) => {
    try {
      setSelectedValorizacion(valorizacion)
      setShowModalAddress(true)
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('¡Alerta!', (error as Error).message, 'error')
    }
  }

  if (!trabajo) return null

  return (
    <>
      <Container>
        <ElementBreadCrumb
          list={[
            {
              text: languageTranslate.moduloMain.textoProgramados,
              navigate: true,
              path: `${AdapterConfigure.ROUTE_PROGRAMADOS}`,
            },
            {
              text: languageTranslate.menu['004'].titulo,
              navigate: true,
              path: `${AdapterConfigure.ROUTE_TRABAJOS}`,
            },
            {
              text: trabajo?.ColeccionObras[0].OrdenTrabajo || '',
              navigate: false,
              path: '',
            },
          ]}
        />
        <InputSearch handleChange={(text) => setSearchTerm(text)} textLength={1} />
        {
          trabajo?.Ultima_PreLiquidacion.Valorizacion
            .filter(e => estado ? [estado.replace(/-/g, ' ')].includes(e.Ultimo_Estado_Interno.Descripcion) : true)
            .filter(e => `${e.DetalleChile.ManoObra.Codigo} ${e.DetalleChile.ManoObra.Nombre}`.toLowerCase().includes(searchTerm.toLowerCase()))
            .map((e, i) => {

              const color = e.Numero && e.CantidadUO > e.Cantidad ? 'white' : 'white'
              const backgroundColor = e.Numero && e.CantidadUO > e.Cantidad ? 'red' : '#2C3E50'

              return <ElementItemCardGenerico
                key={i}
                text1={<span style={{ color }}>{languageTranslate.general.Estado}</span>}
                data1={<span style={{ color }}>{e.Ultimo_Estado_Interno.Descripcion}</span>}
                text2={<span style={{ color }}>{languageTranslate.general.Fecha}</span>}
                data2={<span style={{ color }}>{new Date(e.Ultimo_Estado_Interno.Fecha).toLocaleString()}</span>}
                text3={<span style={{ color }}>{languageTranslate.general.Cod_MO}</span>}
                data3={<span style={{ color }}>{e.Codigo}</span>}
                text4={<span style={{ color }}>{languageTranslate.general.Desc_MO}</span>}
                data4={<span style={{ color }}>{e.Descripcion}</span>}
                text5={<span style={{ color }}>{languageTranslate.general.Cant}</span>}
                data5={<span style={{ color }}>{e.Cantidad.toFixed(2)}</span>}
                text6={['RECHAZADA POR DOCUMENTACION', 'RECHAZADA POR METRAJE'].includes(e.Ultimo_Estado_Interno.Descripcion) && estado ? <span style={{ color, fontWeight: 'normal' }}>{languageTranslate.general.Observacion}</span> : ''}
                data6={estado ? <span style={{ color: 'yellow', fontWeight: 'bold' }}>{e.Ultimo_Estado_Interno.Observacion}</span> : ''}
                backgroundColor={backgroundColor}
                buttons={['RECHAZADA POR DOCUMENTACION'].includes(e.Ultimo_Estado_Interno.Descripcion) && estado ? [
                  <button
                    key={`${i}ver`}
                    className='btn btn-secondary'
                    style={{ color: 'black' }}
                    onClick={() => handleOnClickBtnDocBaremo(e)}
                  >
                    {`${languageTranslate.general.Doc_Baremo} (${e.Anexos.filter(e => e.Estado.ID_Estado === 1).length})`}
                    <i className='fa-solid fa-eye' style={{ fontSize: 20, marginLeft: 10, color: 'black' }} />
                  </button>,
                  e.HomePass.length && e.HomePass[0].ClientType !== 'Troncal' ?
                    <button
                      key={`${i}verDoc`}
                      className='btn btn-secondary'
                      onClick={() => handleOnClickBtn(e)}
                    >
                      {`${languageTranslate.general.VerArchivos}`}
                      <i className='fa-solid fa-eye' style={{ fontSize: 20, marginLeft: 10 }} />
                    </button>
                    : null
                ] : []}
              />
            })
        }
      </Container>
      {
        selectedValorizacion &&
        <AdjuntosValorizaciones
          dataDocumentacionBaremoPEX={dataDocumentacionBaremoPEX}
          setShowOffCanvas={setShowModal}
          showOffCanvas={showModal}
          trabajo={trabajo}
          valorizacion={selectedValorizacion}
          saveFiles={(nodos) => saveFiles(nodos, selectedValorizacion)}
        />
      }
      {
        (selectedValorizacion &&
          selectedValorizacion.HomePass &&
          (
            selectedValorizacion.HomePass[0].ClientType === 'Business' ||
            selectedValorizacion.HomePass[0].ClientType === 'Residential'
          )
        ) &&
        <AdjuntosAddress
          setShowOffCanvas={setShowModalAddress}
          showOffCanvas={showModalAddress}
          trabajo={trabajo}
          valorizacion={selectedValorizacion}
          onSaveAdjuntosAddress={onSaveAdjuntosAddress}
        />
      }
    </>
  )
}