import "./ElementFirmaDigital.scss";
import React, { useRef, useEffect, useCallback } from 'react';
import SignatureCanvas, { ReactSignatureCanvasProps } from 'react-signature-canvas';

interface Props extends ReactSignatureCanvasProps {
    width: number;
    height: number;
    labelBtnClear: string;
    labelBtnSave: string;
    onSendFirm: (imageData: string | undefined) => void;
    clear?: boolean
    setClear?: React.Dispatch<React.SetStateAction<boolean>>
}

interface SignatureCanvasWithCanvasProp extends SignatureCanvas {
    canvas?: HTMLCanvasElement;
}

const Signature: React.FC<Props> = ({ width, height, labelBtnClear, labelBtnSave, onSendFirm, clear, setClear, ...rest }) => {
    const signatureRef = useRef<SignatureCanvasWithCanvasProp>(null);

    const handleClear = () => {
        signatureRef.current?.clear();
    };

    const handleSave = () => {
        const dataUrl = signatureRef.current?.toDataURL();
        onSendFirm(dataUrl);
    };

    const handleTouchMove = useCallback((event: TouchEvent) => {
        event.preventDefault();
    }, []);

    useEffect(() => {
        const canvas = signatureRef.current?.canvas;
        canvas?.addEventListener('touchmove', handleTouchMove, { passive: true });
        return () => {
            canvas?.removeEventListener('touchmove', handleTouchMove);
        };
    }, [handleTouchMove]);

    useEffect(() => {
        if (clear && setClear) {
            handleClear()
            setClear(false)
        }
    }, [clear])

    return (
        <div className='d-flex flex-column align-items-center'>
            <div style={{ width, height, border: '1px solid black' }}>
                <SignatureCanvas
                    ref={signatureRef}
                    canvasProps={{ width, height }}
                    {...rest}
                />
            </div>
            <div className='d-flex flex-row justify-content-around mt-2 w-100' >
                <button className='btn btn-danger' onClick={handleClear}>{labelBtnClear}</button>
                <button className='btn btn-primary' onClick={handleSave}>{labelBtnSave}</button>
            </div>
        </div>
    );
};

export default Signature;
