import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseUploadFiles{
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: File[]): Promise<void> {
        return await this._exec(params);
    }

    private async _exec(params: File[]): Promise<void> {
        return await this.repository.uploadFiles(params);
    }
}