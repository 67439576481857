import { createSlice } from '@reduxjs/toolkit';

interface SliceDetalleStockPersonalState {
    // fromDetail: boolean;
};

const initialState: SliceDetalleStockPersonalState = {
    // fromDetail: false,
};

const DetalleStockPersonalSlice = createSlice({
    name: 'DetalleStockPersonalSlice',
    initialState,
    reducers: {
        // changeFromDetail: (state, action) => {
        //     return {
        //         ...state,
        //         fromDetail: action.payload,
        //     };
        // },
    },
});


export const {
    // changeFromDetail,
} = DetalleStockPersonalSlice.actions;

export default DetalleStockPersonalSlice.reducer;