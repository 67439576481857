import React, { useState } from 'react'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import Stepper from '../../../../shared/Components/ElementStepper'
import { Step1 } from './Step1'
import { Step2 } from './Step2'
import { Step3 } from './Step3'
import { IManoObraEditados, IReservaEditados } from '../Domain/IEditados'
import { DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { DtoAmap } from '../../../Home/Domain/DtoAmap'
import { DtoItem } from '../../../Home/Domain/DtoItem'
import { DtoItems } from '../../../../../app/Domain/DtoItems'
import { DtoManoObraGlobal } from '../../../../../app/Domain/DtoManoObraGlobal'
import { DtoGeneralCodigoNombre } from '../../../../shared/Domain/Dto/DtoGeneralCodigoNombre'

interface PropsReplanteo {
    reservaEditados: IReservaEditados
    setReservaEditados: React.Dispatch<React.SetStateAction<IReservaEditados>>
    manoObraEditados: IManoObraEditados
    setManoObraEditados: React.Dispatch<React.SetStateAction<IManoObraEditados>>
    documentacionReplanteo: DtoNodoDoc[]
    setDocumentacionReplanteo: React.Dispatch<React.SetStateAction<DtoNodoDoc[]>>
    datosMateriales: DtoItem[]
    datosManoObra: DtoAmap[]
    setNewFilesUpload: React.Dispatch<React.SetStateAction<File[]>>
    newFilesUpload: File[]
    dataItems: DtoItems[]
    onChange: (name: string, value: any) => void
    onSave: () => void
    dataManoObraGlobal: DtoManoObraGlobal[]
    dataEspecialidad: DtoGeneralCodigoNombre[]
    selectManoObraGlobal: DtoManoObraGlobal | null
}

export const Replanteo = (props: PropsReplanteo): React.ReactElement => {

    const { reservaEditados, manoObraEditados, setManoObraEditados, setReservaEditados, documentacionReplanteo, setDocumentacionReplanteo,
        datosManoObra, datosMateriales, newFilesUpload, setNewFilesUpload, dataItems, onChange, onSave, dataManoObraGlobal, dataEspecialidad,
        selectManoObraGlobal } = props

    const languageTranslate = LanguageTranslate()
    const [keyTab, setKeyTab] = useState<number>(1)
    const onChangeContentForTab = async (newKeyTab: number) => {
        try {
            if (newKeyTab === keyTab) return
            if (newKeyTab < keyTab) return setKeyTab(newKeyTab)
            return setKeyTab(newKeyTab)
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <Stepper
                data={[
                    languageTranslate.general.Material,
                    languageTranslate.general.ManoDeObra,
                    languageTranslate.general.Archivos,
                ]}
                onChange={onChangeContentForTab}
                stepPosition={keyTab}
            />
            <Step1
                datosMateriales={datosMateriales}
                keyTab={keyTab}
                setKeyTab={setKeyTab}
                reservaEditados={reservaEditados}
                setReservaEditados={setReservaEditados}
                dataItems={dataItems}
                onChange={onChange}
                onSave={onSave}
            />
            <Step2
                datosManoObra={datosManoObra}
                keyTab={keyTab}
                setKeyTab={setKeyTab}
                manoObraEditados={manoObraEditados}
                setManoObraEditados={setManoObraEditados}
                dataManoObraGlobal={dataManoObraGlobal}
                onChange={onChange}
                dataEspecialidad={dataEspecialidad}
                selectManoObraGlobal={selectManoObraGlobal}
                onSave={onSave}
            />
            <Step3
                keyTab={keyTab}
                setKeyTab={setKeyTab}
                documentacionReplanteo={documentacionReplanteo}
                setDocumentacionReplanteo={setDocumentacionReplanteo}
                newFilesUpload={newFilesUpload}
                setNewFilesUpload={setNewFilesUpload}
                onSave={onSave}
            />
        </>
    )
}
