import { setFileToServer } from './setFileToServer';
import { getFileToServer } from './getFileToServer';
import { FileReady } from '../../Domain/FileReady';

export class FTPService {
    public static async setFileToServer(FileName: string, File: File): Promise<void> {
        return await setFileToServer(FileName, File);
    }

    public static async getFileToServer(fileName: string, Exportar: number): Promise<FileReady | string> {
        return await getFileToServer(fileName, Exportar);
    }
}
