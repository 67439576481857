import { InputSelect } from '../../../../shared/Components/ElementInputsCostume';
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario';
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate';
import { DtoSelectPersonal } from '../Domain/DtoFormParteDiario';
import { EntityLoadData } from '../Domain/EntityLoadData';
import './Style.scss';

interface PropsViewMain {
    onChange: (name: string, value: any) => void;
    formRegistro: {
        values: any;
        touched: any;
        errors: any;
        handleBlur: Function;
        setFieldValue: Function;
    };
    selectInformation: EntityLoadData;
    disabled: boolean;
    user: EntityDataUsuario
};

export const AddPersonal = (props: PropsViewMain) => {
    const { formRegistro, selectInformation, disabled, user } = props;

    const languageTranslate = LanguageTranslate();

    const addPersonal = (name: string, value: any) => {
        if (value === null) { return; }
        const personal = formRegistro.values.Personal.find((e: DtoSelectPersonal) => e.value === value.value);
        if (personal) return;
        formRegistro.setFieldValue(name, [...formRegistro.values.Personal, value]);
    }

    const deletePersonal = (psl: DtoSelectPersonal) => {
        formRegistro.setFieldValue('Personal', formRegistro.values.Personal.filter((e: DtoSelectPersonal) => e.value !== psl.value));
    }

    return (
        <div className='mb-2'>
            {
                disabled &&
                <InputSelect
                    label={languageTranslate.moduloInspeccion.form.stepper.stepper3.content.personal}
                    name='Personal'
                    onChange={addPersonal}
                    values={{ Personal: null }}
                    options={selectInformation.Personal
                        .filter(e => e.Identificacion !== user.Identificacion)
                        .map(e => ({ label: `${e.Identificacion} - ${e.Nombres} ${e.ApellidoPaterno} ${e.ApellidoMaterno}`, value: e.Identificacion, dataComplete: e }))}
                    disabledVirtualized
                />
            }
            {
                formRegistro.values.Personal.length > 0 &&
                <table className="table table-striped mb-5 mt-1">
                    <thead>
                        <tr>
                            <th scope="col">{languageTranslate.moduloInspeccion.form.stepper.stepper3.content.table.id}</th>
                            <th scope="col">{languageTranslate.moduloInspeccion.form.stepper.stepper3.content.table.name}</th>
                            <th scope="col">{languageTranslate.moduloInspeccion.form.stepper.stepper3.content.table.lastName}</th>
                            {
                                disabled &&
                                <th scope="col"></th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            formRegistro.values.Personal.map((psl: DtoSelectPersonal, i: number) =>
                                <tr key={i}>
                                    <th scope="row">{psl.dataComplete.IdPersonal}</th>
                                    <td>{psl.dataComplete.Nombres}</td>
                                    <td>{psl.dataComplete.ApellidoPaterno} {psl.dataComplete.ApellidoMaterno}</td>
                                    {
                                        disabled &&
                                        <td onClick={() => deletePersonal(psl)}>
                                            <i className="fa-solid fa-trash" style={{ fontSize: 15, color: 'red' }} />
                                        </td>
                                    }
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            }
        </div >
    )
};