import { RepositoryMain } from '../Domain/RepositoryMain'
import { RepositoryImplGenerico } from '../../../shared/Infraestructure/RepositoryImplGenerico'
import { EntityMain } from '../Domain/EntityMain'
import { EntityParams } from '../Domain/EntityParams'
import { EntityPais } from '../Domain/EntityPais'
import { EntityPersonal } from '../Domain/EntityPersonal'
import { AdapterConfigure } from './AdapterConfigure'
import { EntityEstadoInterno } from '../Domain/EntityEstadoInterno'
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico'
import { DtoResponseCuadrillas } from '../../../../app/Domain/DtoResponseCuadrillas'
import { DtoItems } from '../../../../app/Domain/DtoItems'
import { DtoContratoOT } from '../../../../app/Domain/DtoContratoOT'
import { DtoDocumentacionBaremoPEX } from '../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { DtoPrecioMaterial } from '../../../../app/Domain/DtoPrecioMaterial'
import { DtoManoObraGlobal } from '../../../../app/Domain/DtoManoObraGlobal'
import { DtoPrecioEspecialidad } from '../../../../app/Domain/DtoPrecioEspecialidad'
import { DtoDocumentacionPEX } from '../../../../app/Domain/DtoDocumentacionPEX'

export class RepositoryImplMain extends RepositoryImplGenerico<EntityMain> implements RepositoryMain {

    public async selectEstadoInterno(params: EntityParams): Promise<EntityEstadoInterno[]> {
        const _params = { Filtros: [...params.EstadoInterno.Filtros] }
        const url: string = `${this.urlBase}${AdapterConfigure.SELECT_ESTADOINTERNO}`
        let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(_params), 'bearer', 'json', 'json', {})
        const result: Array<EntityEstadoInterno> = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response
        return result
    }
    
    public async selectItems(params: EntityParams): Promise<DtoItems[]> {
        const _params = { Filtros: [...params.Items.Filtros] }
        const url: string = `${this.urlBase}${AdapterConfigure.SELECT_ITEMS}`
        let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(_params), 'bearer', 'json', 'json', {})
        const result: Array<DtoItems> = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response
        return result
    }
    
    public async selectContratoOT(params: EntityParams): Promise<DtoContratoOT[]> {
        const _params = { Filtros: [...params.ContratoOT.Filtros] }
        const url: string = `${this.urlBase}${AdapterConfigure.SELECT_CONTRATO_OT}`
        let response: DtoContratoOT[] = await this.service.call<any>('POST', url, JSON.stringify(_params), 'bearer', 'json', 'json', {})
        const result: Array<DtoContratoOT> = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response
        return result
    }
    
    public async selectDocumentacionBaremoPEX(params: EntityParams): Promise<DtoDocumentacionBaremoPEX[]> {
        const _params = { Filtros: [...params.DocumentacionBaremoPEX.Filtros] }
        const url: string = `${this.urlBase}${AdapterConfigure.SELECT_DOCUMENTACIONBAREMOPEX}`
        let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(_params), 'bearer', 'json', 'json', {})
        const result: Array<DtoDocumentacionBaremoPEX> = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response
        return result
    }
    
    public async selectDocumentacionPEX(params: EntityParams): Promise<DtoDocumentacionPEX[]> {
        const _params = { Filtros: [...params.DocumentacionPEX.Filtros] }
        const url: string = `${this.urlBase}${AdapterConfigure.SELECT_DOCUMENTACIONPEX}`
        let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(_params), 'bearer', 'json', 'json', {})
        const result: Array<DtoDocumentacionPEX> = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response
        return result
    }
    
    public async selectManoObraGlobal(params: EntityParams): Promise<DtoManoObraGlobal[]> {
        const _params = { Filtros: [...params.ManoObraGlobal.Filtros] }
        const url: string = `${this.urlBase}${AdapterConfigure.SELECT_MANO_OBRA_GLOBAL}`
        let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(_params), 'bearer', 'json', 'json', {})
        const result: Array<DtoManoObraGlobal> = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response
        return result
    }
    
    public async selectPrecioEspecialidad(params: EntityParams): Promise<DtoPrecioEspecialidad[]> {
        const _params = { Filtros: [...params.PrecioEspecialidad.Filtros] }
        const url: string = `${this.urlBase}${AdapterConfigure.SELECT_PRECIO_ESPECIALIDAD}`
        let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(_params), 'bearer', 'json', 'json', {})
        const result: Array<DtoPrecioEspecialidad> = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response
        return result
    }
    
    public async selectPrecioMaterial(params: EntityParams): Promise<DtoPrecioMaterial[]> {
        const _params = { Filtros: [...params.PrecioMaterial.Filtros] }
        const url: string = `${this.urlBase}${AdapterConfigure.SELECT_PRECIO_MATERIAL}`
        let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(_params), 'bearer', 'json', 'json', {})
        const result: Array<DtoPrecioMaterial> = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response
        return result
    }

    public async selectPais(params: EntityParams): Promise<EntityPais[]> {
        let url: string = `${this.urlBase}${AdapterConfigure.SELECT_PAIS}`
        const _params = { Filtros: [...params.Pais.Filtros,] }
        return await this.service.call<any>('POST', url, JSON.stringify(_params), 'basic', 'json', 'json', {}, 0)
    }

    public async selectPersonal(params: EntityParams): Promise<EntityPersonal[]> {
        let url: string = `${this.urlBase}${AdapterConfigure.SELECT_PERSONAL}`
        const _params = { Filtros: [...params.Personal.Filtros] }
        return await this.service.call<any>('POST', url, JSON.stringify(_params), 'basic', 'json', 'json', {}, 0)
    }
   
    public async selectCuadrillas(params: EntityParams): Promise<DtoResponseCuadrillas[]> {
        let url: string = `${this.urlBase}${AdapterConfigure.SELECT_CUADRILLAS}`
        const _params = { Filtros: [...params.Cuadrillas.Filtros] }
        return await this.service.call<any>('POST', url, JSON.stringify(_params), 'basic', 'json', 'json', {}, 0)
    }

}