import './Style.scss';
import { AppRoutes } from './routes';
import { isMobile } from 'react-device-detect';
import { PageNotAvailable } from '../../context/shared/Components/PageNotAvailable';
import ViewSplash from '../../context/Master/Splash';
import { LanguageTranslate } from '../../context/shared/Infraestructure/LanguageTranslate';
import mobileSoftwareUpdate from '../../assets/icons/mobile-software-update-icon.svg';
import { useEffect, useState } from 'react';
import { AdapterStorage } from '../../context/shared/Infraestructure/AdapterStorage';
import { PageNotTwoTabs } from '../../context/shared/Components/PageNotTwoTabs';

interface PropsView {
  loadingMaestros: boolean;
  availableUpdate: boolean;
  updateApp: Function;
}

export const ViewApp = ({ loadingMaestros, updateApp, availableUpdate }: PropsView) => {

  const languageTranslate = LanguageTranslate()
  const [isMobileAux, setIsMobileAux] = useState<boolean>(true)
  const [isTwoTabs, setIswoTabs] = useState<boolean>(true)

  useEffect(() => {

    const platform = navigator.platform
    const mode = process.env.REACT_APP_MODE

    if (mode === 'prod') {
      if (platform.indexOf('Mac') === 0 ||
        platform.indexOf('Win') === 0) {
        // console.log('%c¡Hola! 😊 Esta página es solo para dispositivos móviles.', 'color: red; font-size: 16px;')
        // setIsMobileAux(false)
      }
    }
  }, [])

  if (!isTwoTabs) return <PageNotTwoTabs />

  return (
    isMobile && isMobileAux
      ? loadingMaestros
        ? <ViewSplash />
        : <>
          {
            availableUpdate
              ? <div className="panel shadow text-center" onClick={() => updateApp()}>
                <img src={mobileSoftwareUpdate} alt="update" width={15} style={{ marginRight: '10px' }} />
                <span>{languageTranslate.textoNuevaVersion}</span>
              </div>
              : <></>
          }
          <AppRoutes />
        </>
      : <PageNotAvailable />
  );
}
