import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import makeAnimated from "react-select/animated";
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { FormikErrors, FormikTouched } from 'formik';

interface InputBase {
    label: string;
    name: string;
    onChange: (name: string, value: any) => void;
    values: any;
    isRequired?: boolean;
    disabled?: boolean;
    className?: string;
    isHorizontal?: boolean;
}

interface IInputSelect extends InputBase {
    options?: any[];
    loading?: boolean;
    isMulti?: boolean;
    disabledVirtualized?: boolean;
    isClearable?: boolean;
    arrFilterOption?: string[];
    isAsync?: boolean;
    delaySearch?: number;
    errors?: FormikErrors<{ [key: string]: any }>
    touched?: FormikTouched<{ [key: string]: any }>
}

interface IInput extends InputBase {
    type?: string;
    uppercase?: boolean;
}

export const InputSelect = (props: IInputSelect) => {
    const { label, name, onChange, options, values, disabled, isRequired, loading, className, disabledVirtualized, isHorizontal, arrFilterOption } = props;
    var delayTimer: NodeJS.Timer;  

    const extraProps = {
        filterOption: (option: FilterOptionOption<any>, inputValue: string) => {
            if (!inputValue) return true;
            return arrFilterOption?.some(row => (option.data.dataComplete[row] as string || '').toLocaleLowerCase().search(inputValue.toLocaleLowerCase()) === 0) as any;
        }
    };

    const extraPropsAsync = {
        loadOptions: (inputValue: string) => {
            return new Promise<any>((resolve) => {
                if (delayTimer) clearTimeout(delayTimer);
                delayTimer = setTimeout(() => {
                    if (!inputValue || !arrFilterOption) {
                        resolve(options);
                        return;
                    }
                    resolve(options?.filter(option => arrFilterOption?.some(row => (option.dataComplete[row] as string || '').toLocaleLowerCase().search(inputValue.toLocaleLowerCase()) === 0) as any))
                }, props.delaySearch || 1000);
            });
        }
    };

    const CostumeSelect = props.isAsync ? AsyncSelect : Select;

    return (
        <div className={`${className || ''} form-row d-flex mb-3`}>
            <div className={`${isHorizontal ? 'd-flex justify-content-between' : 'form-group'} w-100`}>
                <label className="mb-1" style={props.isHorizontal ? { width: '49%' } : {}}> {label} {""} {isRequired && <span className="text-danger">*</span>}</label>
                <CostumeSelect
                    {...(Array.isArray(arrFilterOption) ? props.isAsync ? extraPropsAsync : extraProps : {})}
                    className={`custom-select ${isHorizontal ? 'w-50' : ''}`}
                    components={disabledVirtualized ? animatedComponents : { MenuList: MenuListVirtualized }}
                    onChange={(value) => onChange(name, value)}
                    value={values[name]}
                    isDisabled={disabled}
                    isMulti={props.isMulti}
                    options={options || []}
                    isLoading={loading}
                    defaultOptions
                    isClearable={props.isClearable}
                />
                {
                    (props.errors && props.errors[name]) &&
                    // @ts-expect-error
                    <span style={{ color: '#dc3545', fontSize: 14 }}>{props.errors[name].value}</span>
                }
                {
                    (props.touched && props.touched[name] && props.errors && !props.errors[name]) &&
                    <span style={{ color: '#28a745', fontSize: 14 }}>Looks good!</span>
                }
            </div>
        </div>
    )
}
const animatedComponents = makeAnimated();
const MenuListVirtualized = (props: any) => {
    const rows = Array.isArray(props.children) ? props.children : [];
    const allOptions = props.options;
    const RowRenderer = ({ index, style }: any) => <div className='d-inline-block text-truncate option-virtualized-custom' style={{ ...style, minHeight: 'max-content', minWidth: 'max-content', overFlowY: 'scroll' }} key={allOptions[index].value}> {rows[index]} </div>

    const RowRendererEmpty = ({ index, style }: any) => <div style={style} key={-1}> No Options </div>
    return (
        <div style={{ height: (rows.length || 0) * 35, maxHeight: 300, minHeight: 35 }}>
            <AutoSizer>
                {({ height, width }: any) => (
                    <List
                        className="List"
                        height={height}
                        itemCount={rows.length}
                        itemSize={35}
                        width={width}
                    >
                        {rows.length === 0 ? RowRendererEmpty : RowRenderer}
                    </List>
                )}
            </AutoSizer>
        </div>
    )
}

export const InputText = (props: IInput) => {
    const { label, name, onChange, values, disabled, isRequired, type, uppercase, className, isHorizontal } = props;
    return (
        <div className={`${className || ''} form-row d-flex mb-3`}>
            <div className={`${isHorizontal ? 'd-flex justify-content-between align-items-center' : 'form-group'} w-100`}>
                <label className="mb-1" style={props.isHorizontal ? { width: '49%' } : {}}> {label} {" "} {isRequired && <span className="text-danger">*</span>}</label>
                <input
                    type={type ? type : "text"}
                    className={`form-control ${isHorizontal ? 'w-50' : ''}`}
                    placeholder={label}
                    onChange={(evt) => onChange(name, type === 'number' && evt.target.value !== '' ? Number(evt.target.value) : uppercase ? evt.target.value.toLocaleUpperCase() : evt.target.value)}
                    value={values[name]}
                    disabled={disabled}
                />
            </div>
        </div>
    )
}

export const InputCheck = (props: IInput) => {
    const { label, name, onChange, values, disabled, isRequired, className } = props;
    return (
        <div className={`${className || ''} form-row d-flex mb-3`}>
            <div className="form-group w-100 d-flex">
                <label className="mb-1" style={{ marginRight: 'auto' }}>{label} {" "} {isRequired && <span className="text-danger">*</span>}</label>
                <div className="form-check form-switch">
                    <input
                        checked={values[name]}
                        disabled={disabled}
                        onChange={(evt) => onChange(name, evt.target.checked)}
                        className="form-check-input"
                        type="checkbox"
                    />
                </div>
            </div>
        </div>
    )
}
