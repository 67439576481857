import { DtoDocumentacionBaremoPEX } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { DtoItems } from '../../../../../app/Domain/DtoItems'
import { DtoManoObraGlobal } from '../../../../../app/Domain/DtoManoObraGlobal'
import { DtoPrecioEspecialidad } from '../../../../../app/Domain/DtoPrecioEspecialidad'
import { DtoPrecioMaterial } from '../../../../../app/Domain/DtoPrecioMaterial'
import { DtoResponseEstadoInterno } from '../../../../../app/Domain/DtoResponseEstadoInterno'
import { DtoConsumoMaterial } from '../../../../Master/Home/Domain/DtoConsumoMaterial'
import { DtoResponseSelectStockPersonal } from '../../../../Master/Home/Domain/DtoResponseSelectStockPersonal'
import { DtoStockAlmacen } from '../../../../Master/Home/Domain/DtoStockAlmacen'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { DtoTrabajosDE } from '../../Trabajos/Domain/DtoTrabajosDE'
import { EntityLoadData } from '../Domain/EntityLoadData'
import { RepositoryMain } from '../Domain/RepositoryMain'

export class UseCaseLoadData {
  private repository: RepositoryMain

  constructor(_repository: RepositoryMain) {
    this.repository = _repository
  }

  async exec(trabajo: DtoTrabajosDE, user: EntityDataUsuario): Promise<EntityLoadData> {
    const [
      DocumentacionBaremoPEX,
      EstadosInternos,
      ManoObraGlobal,
      PrecioEspecialidad,
      dataItems,
      dataStockPersonal,
      dataPrecioMaterial,
      dataStockAlmacen,
      dataConsumoMaterial,
    ] = await Promise.all([
      this.getDocumentacionBaremoPEX(user, trabajo),
      this.getEstadoInterno(user),
      this.getManoObraGlobal(user),
      this.getPrecioEspecialidad(user),
      this.getItems(user),
      this.getStockPersonal(user, trabajo),
      this.getPrecioMaterial(user),
      this.getStockAlmacen(user),
      this.getConsumoMaterial(user),
    ])
    return {
      DocumentacionBaremoPEX,
      EstadosInternos,
      ManoObraGlobal,
      PrecioEspecialidad,
      dataItems,
      dataStockPersonal,
      dataPrecioMaterial,
      dataStockAlmacen,
      dataConsumoMaterial
    }
  }

  private async getEstadoInterno(user: EntityDataUsuario): Promise<DtoResponseEstadoInterno[]> {
    const data = await this.repository.selectEstadoInterno();
    return data.filter(e => e.Tipo === 'Amap' && e.Pais.Codigo === user.Pais.Codigo);
  }

  private async getManoObraGlobal(user: EntityDataUsuario): Promise<DtoManoObraGlobal[]> {
    const data = await this.repository.selectManoObraGlobal()
    return data.filter(e => e.Pais.Codigo === user.Pais.Codigo && e.Delegacion.Codigo === user.Delegacion.Codigo)
  }

  private async getPrecioEspecialidad(user: EntityDataUsuario): Promise<DtoPrecioEspecialidad[]> {
    const data = await this.repository.selectPrecioEspecialidad()
    return data.filter(e => e.Pais.Codigo === user.Pais.Codigo && e.Delegacion.Codigo === user.Delegacion.Codigo)
  }

  private async getDocumentacionBaremoPEX(user: EntityDataUsuario, trabajo: DtoTrabajosDE): Promise<DtoDocumentacionBaremoPEX[]> {
    if (trabajo.ColeccionObras.length !== 1) throw new Error(`¡Error al obtener Obra! Comunícate con Soporte`)
    const items = await this.repository.selectDocumentacionBaremoPEX()
    return items.filter(e => e.Pais.Codigo === user.Pais.Codigo && e.Delegacion.Codigo === user.Delegacion.Codigo && e.ContratoCod === trabajo.ColeccionObras[0].ContratoOT.Codigo)
  }

  private async getStockPersonal(user: EntityDataUsuario, trabajo: DtoTrabajosDE): Promise<DtoResponseSelectStockPersonal[]> {
    const stockPersonal = await this.repository.selectStockPersonal(user);
    return stockPersonal.filter(e => e.ID_Trabajo === trabajo.ID_Trabajo);
  }

  private async getConsumoMaterial(user: EntityDataUsuario): Promise<DtoConsumoMaterial[]> {
    const data = await this.repository.selectConsumoMaterial(user);
    return data
  }

  private async getStockAlmacen(user: EntityDataUsuario): Promise<DtoStockAlmacen[]> {
    const stock = await this.repository.selectStockAlmacen(user)
    return stock
  }

  private async getItems(user: EntityDataUsuario): Promise<DtoItems[]> {
    const items = await this.repository.selectItems();
    return items.filter(e => e.Pais.Codigo === user.Pais.Codigo && e.Delegacion.Codigo === user.Delegacion.Codigo);
  }

  private async getPrecioMaterial(user: EntityDataUsuario): Promise<DtoPrecioMaterial[]> {
    const items = await this.repository.selectPrecioMaterial()
    return items.filter(e => e.Pais.Codigo === user.Pais.Codigo && e.Delegacion.Codigo === user.Delegacion.Codigo)
  }

}