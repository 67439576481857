import { MouseEvent } from 'react'
import { AdapterGenerico } from '../../Infraestructure/AdapterGenerico'
import './ElementInputFileAdjunto.scss'

interface PropsView {
  onChange: (fileList: LoadAdjunto[]) => any
  icon: string
  disabled?: boolean
  size?: number
  capture?: boolean
  multiple?: boolean
  allowedFormats: string[]
  paddingLeft?: string
  paddingRight?: string
  colorIcon?: string
  onClick?: (e: MouseEvent<HTMLInputElement>) => void
}

export interface ArchivosGrabar {
  File: File,
  Ruta: string,
}

export interface LoadAdjunto {
  NombreOriginal: string
  File: File
}

export const ElementInputFileAdjunto = (props: PropsView) => {

  const { allowedFormats, paddingLeft, paddingRight, colorIcon, onClick } = props

  const isFormatValid = (fileList: FileList | null): any => {
    try {
      if (!fileList) return
      const adjuntos: LoadAdjunto[] = []
      let error = { isError: false, fileName: '' }
      Array.from(fileList).forEach((file, i) => {
        const ext = file.name.split('.').pop()?.toLowerCase()
        if (!allowedFormats.some(e => e.toUpperCase() === 'ALL')) {
          if (!ext || !allowedFormats.map(e => e.toLocaleLowerCase()).includes(ext)) {
            error = { isError: true, fileName: file.name }
          }
        }
        const nameTemp = file.name
        const [extAux] = nameTemp.split('.').reverse()
        // const nameAux = fileNameTemp.reverse().join('.')
        // const fileName = `${i}${Math.floor(Date.now() / 1000)}III${nameAux.replaceAll('-', '').replaceAll(' ', '').replaceAll('.', '').replaceAll('_', '')}.${extAux.toUpperCase()}`
        const fileName = `${i}III${Math.floor(Date.now() / 1000)}.${extAux.toUpperCase()}`
        const adjunto = new File([file], fileName, {
          type: file.type,
          lastModified: file.lastModified
        })
        adjuntos.push({ NombreOriginal: nameTemp, File: adjunto })
      })

      if (error.isError) if (error.isError) throw new Error(`${error.fileName} no es un formato permitido`)

      props.onChange(adjuntos)
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false)
    }
  }

  return (
    <label className='ElementInputFileAdjunto'>
      <input
        type='file'
        multiple={props.multiple}
        capture={props.capture ? true : undefined}
        accept={props.capture ? 'image/*' : (allowedFormats.some(e => e.toUpperCase() === 'ALL') ? '' : allowedFormats.map(formato => `.${formato}`).join(', '))}
        onChange={(evt) => isFormatValid(evt.target.files)}
        disabled={props.disabled}
        value=''
        onClick={onClick}
      />
      <i
        className={props.icon}
        style={{
          fontSize: props.size || 30,
          paddingLeft: paddingLeft || '16px',
          paddingRight: paddingRight || '16px',
          color: colorIcon || 'inherit'
        }}
      />
    </label>
  )
}