import { legacy_createStore as createStore, combineReducers, applyMiddleware } from '@reduxjs/toolkit';
import { composeWithDevTools } from "redux-devtools-extension"
import { useDispatch } from 'react-redux';

import genericoSlice from './SliceGenerico';
import authenticationSlice from './SliceAuthentication';
import textSearchSlice from './SliceTextSearch';
import stockPersonalSlice from '../../Logistica/StockPersonal/Domain/SliceStockPersonal';
import stockPersonalTotalSlice from '../../Logistica/StockPersonalTotal/Domain/SliceStockPersonalTotal';
import trabajosSlice from '../../Programados/Trabajos/Trabajos/Domain/SliceTrabajos';
import replanteoSlice from '../../Programados/Obras/Replanteo/Domain/SliceReplanteo';
import detalleStockPersonalSlice from '../../Logistica/DetalleStockPersonal/Domain/SliceDetalleStockPersonal';
import despachoAlmacenSlice from '../../Logistica/DespachosAlmacen/Domain/SliceDespachoAlmacen';
import mainSlice from '../../Master/Main/Domain/SliceMain';
import { autoInventarioReducer } from '../../Logistica/autoInventario/shared/infrastructure/slice'
import { stockAlmacenReducer } from '../../Logistica/StockAlmacen/Infraestructure/slice'

const rootReducer = combineReducers({
    generico: genericoSlice,
    auth: authenticationSlice,
    stockPersonal: stockPersonalSlice,
    detalleStockPersonal: detalleStockPersonalSlice,
    main: mainSlice,
    trabajos: trabajosSlice,
    despachoAlmacen: despachoAlmacenSlice,
    stockPersonalTotal: stockPersonalTotalSlice,
    textSearch: textSearchSlice,
    replanteo: replanteoSlice,
    autoInventario: autoInventarioReducer,
    stockAlmacen: stockAlmacenReducer
});

export const AdapterStore = createStore(rootReducer, composeWithDevTools(applyMiddleware()));
export const useAppDispatch = () => useDispatch();
export type RootState = ReturnType<typeof rootReducer>;

