import { DtoFormAddValorizacion } from "../Domain/DtoAsignacionValorizacion";

export class Validator {

}

export function ValidarCantidadMaterial(FormAddValorizacion: DtoFormAddValorizacion) {
    for (const iterator of FormAddValorizacion.Items) {
        if (iterator.dataComplete.Cantidad === 0) throw Error(`Hay cantidades vacias o con valor 0 en lista de Materiales`)
    }
}