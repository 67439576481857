import { createSlice } from '@reduxjs/toolkit';

interface SliceTranajos {
	textSeach: string;
};

const initialState: SliceTranajos = {
	textSeach: '',
};

const trabajosSlice = createSlice({
	name: 'trabajosSlice',
	initialState,
	reducers: {
		changeTextSeach: (state, action) => {
			return {
				...state,
				textSeach: action.payload,
			};
		},
	},
});


export const {
	changeTextSeach,
} = trabajosSlice.actions;

export default trabajosSlice.reducer;