import { RepositoryMain } from "../Domain/RepositoryMain";
import { EntityDataUsuario } from "../../../shared/Domain/EntityDataUsuario";
import { DtoResponseSelectStockPersonal } from "../Domain/DtoResponseSelectStockPersonal";

export class UseCaseSelectStockPersonalLocal {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(user: EntityDataUsuario, idTrabajo: Number): Promise<DtoResponseSelectStockPersonal[]> {
        return await this._exec(user, idTrabajo);
    }

    private async _exec(user: EntityDataUsuario, idTrabajo: Number): Promise<DtoResponseSelectStockPersonal[]> {
        return (await this.repository.selectStockPersonalLocal(user)).filter(e => e.ID_Trabajo === idTrabajo);
    }
}
