import { RepositoryMain } from '../Domain/RepositoryMain'
import { RepositoryImplGenerico } from '../../../../shared/Infraestructure/RepositoryImplGenerico'
import { AdapterConfigure } from './AdapterConfigure'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { DtoTrabajosDE } from '../../Trabajos/Domain/DtoTrabajosDE'
import { DtoReqSelectTrabajoDE } from '../../Trabajos/Domain/DtoReqSelectTrabajoDE'

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

  public async selectTrabajo(params: DtoReqSelectTrabajoDE, user: EntityDataUsuario, idTrabajo: number): Promise<DtoTrabajosDE[]> {

    const url: string = `${this.urlBase}${AdapterConfigure.SELECT_TRABAJOS}`

    let response: Array<any> = navigator.onLine
      ? await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'text', {})
      : this.selectTrabajoLocal(user, idTrabajo)
    response = typeof response === 'string'
      ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response
      : response
    response = Array.isArray(response) ? response : [response]
    return response
  }

  public async selectTrabajoLocal(user: EntityDataUsuario, idTrabajo: number): Promise<DtoTrabajosDE> {
    const trabajo = await this.dbLocal.selectByIndexStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_Trabajos`, value: idTrabajo })
    return trabajo
  }

}