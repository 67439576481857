import { Breadcrumb } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { LanguageTranslate } from '../Infraestructure/LanguageTranslate';

import './ElementBreadCrumb.scss';

interface PropsViewMain {
    list: Array<{ text: string; navigate: boolean; path: string }>;
    children?: JSX.Element;
};

export const ElementBreadCrumb = ({ list, children }: PropsViewMain) => {
    const navigate = useNavigate();
    const languageTranslate = LanguageTranslate();

    return (
        <div className="container">
            <div className="navigator">
                <div className="row">
                    <div className="col-12 ps-0">
                        <Breadcrumb>
                            <Breadcrumb.Item style={{ letterSpacing: 0 }} className='breadcrumb-custome' href="#" onClick={() => navigate(`/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_HOME}`, { replace: true })}>{languageTranslate.textoNavbarInicio}</Breadcrumb.Item>
                            {
                                list.map(row => (
                                    <Breadcrumb.Item style={{ letterSpacing: 0, paddingLeft: 4 }} key={row.text} className='breadcrumb-custome' href="#" onClick={() => row.navigate ? navigate(row.path, { replace: true }) : null} active={!row.navigate}>{row.text}</Breadcrumb.Item>
                                ))
                            }
                        </Breadcrumb>
                    </div>
                    <div className="col-2">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
};
