import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from "chart.js";
import { Bar } from "react-chartjs-2";
//import faker from 'faker';
//import { faker } from '@faker-js/faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
};

type ElementVerticalBarChartProps = {
  labels: string[];
  dataGroups: number[];
  task: string;
};

export const ElementVerticalBarChart: React.FC<
  ElementVerticalBarChartProps
> = ({ labels, dataGroups, task }) => {
  const [data, setData] = useState<ChartData<"bar", number[], string>>({
    labels: [],
    datasets: [],
  });

  const [options, setOptions] = useState<ChartOptions<"bar">>({});

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      labels,
      datasets: [
        {
          data: dataGroups,
          backgroundColor: ["#ff0000", "#00a44b", "#00b0f0", "#ffc000"],
        },
      ],
    }));
  }, [dataGroups, labels, task]);

  useEffect(() => {
    setOptions({
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          color: "#000",
          formatter: (value: number): string | number => {
            return value;
          },
          font: {
            weight: 700,
            size: 15,
          },
        },
        title: {
          text: task,
          position: "bottom",
          display: true,
          font: {
            size: 15,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
    });
  }, [task]);

  return <Bar data={data} options={options} />;
};

// export const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       position: 'top' as const,
//     },
//     title: {
//       display: true,
//       text: 'Chart.js Bar Chart',
//     },
//   },
// };

// // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
// const labels = [''];

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Pre Liquidada',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: '#00b050',
//     },
//     {
//       label: 'Liquidada',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: '#00b0f0',
//     },
//     {
//       label: 'Certificada',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: '#ff0000',
//     },
//     {
//       label: 'Produccion',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: '#ffc000',
//     },
//   ],
// };

// export const  ElementVerticalBarChart = () => {
//   return <Bar options={options} data={data} />;
// }
