import { DtoResponseSelectStockPersonal } from '../../../Master/Home/Domain/DtoResponseSelectStockPersonal';
import { EntityDataUsuario } from '../../../shared/Domain/EntityDataUsuario';
import { RepositoryMain } from '../Domain/RepositoryMain';

export class UseCaseSelectStockPersonalLocal {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(user: EntityDataUsuario, idTrabajo: number): Promise<DtoResponseSelectStockPersonal> {
        return await this._exec(user, idTrabajo);
    }

    private async _exec(user: EntityDataUsuario, idTrabajo: number): Promise<DtoResponseSelectStockPersonal> {
        const stockPersonal = await this.repository.selectStockPersonalLocal(user, idTrabajo);
        
        if (stockPersonal.TipoStock.Nombre === 'NUEVO' || stockPersonal.TipoStock.Nombre === 'MAQUINARIA') {
            stockPersonal.Stock = stockPersonal.Despacho - stockPersonal.Devolucion - stockPersonal.Liquidacion + stockPersonal.DespachoTR - stockPersonal.DevolucionTR;
            stockPersonal.Stock = stockPersonal.Stock <= 0 ? 0 : stockPersonal.Stock
        } else {
            stockPersonal.Stock = stockPersonal.Liquidacion - stockPersonal.Devolucion
        }
        stockPersonal.OrdenTrabajo = stockPersonal.OrdenTrabajo === "" ? 'Sin orden Trabajo' : stockPersonal.OrdenTrabajo;
        return stockPersonal;
    }
}