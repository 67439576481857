import Accordion from 'react-bootstrap/Accordion'
import { DtoFormAddValorizacion } from '../Domain/DtoAsignacionValorizacion'
import { FormikErrors, FormikTouched } from 'formik'
import { LoadImage } from '../../../../shared/Components/ElementInputFileImage'
import { useEffect, useState } from 'react'
import { AdapterGeolocation } from '../../../../shared/Infraestructure/AdapterGeolocation'
import { DtoCoordenadas } from '../../../../shared/Domain/Dto/DtoCoordenadas'
import { ElementInputFileAdjunto } from '../../../../shared/Components/Programados/ElementInputFileAdjunto'
import { Button, Card, ListGroup } from 'react-bootstrap'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { DtoFilesDoc, DtoFlujoDoc } from '../../Trabajos/Domain/DtoFilesDoc'
import { DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { saveAs } from 'file-saver'

interface IPropsCargarAdjuntod {
    formAddValorizacion: {
        values: DtoFormAddValorizacion
        touched: FormikTouched<DtoFormAddValorizacion>
        errors: FormikErrors<DtoFormAddValorizacion>
        handleBlur: (e: React.FocusEvent<any>) => void
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
    }
    setShowImageViewer: React.Dispatch<React.SetStateAction<boolean>>
    setFileImageViewer: React.Dispatch<React.SetStateAction<File | null>>
}

export const CargarAdjuntos = (props: IPropsCargarAdjuntod) => {

    const { formAddValorizacion, setFileImageViewer, setShowImageViewer } = props
    const extensionImagen = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'tiff', 'webp', 'svg', 'raw', 'psd', 'eps', 'ico']

    const [Coordenadas, setCoordenadas] = useState<DtoCoordenadas>({ X: '', Y: '' })

    useEffect(() => {
        const GetLocation = async () => {
            const coodenadas = await AdapterGeolocation.getLocation()
            setCoordenadas({ X: String(coodenadas.longitud), Y: String(coodenadas.latitud) })
        }
        GetLocation()
    }, [])

    const addImagen = (imagenes: LoadImage[], nodo: DtoNodoDoc) => {
        try {
            const newFiles: DtoFilesDoc[] = []

            for (const imagen of imagenes) {
                // Properties
                const newFlujoDoc = new DtoFlujoDoc()
                newFlujoDoc.Action = 'Registrar'
                newFlujoDoc.Description = 'Preliquidado'
                newFlujoDoc.Posicion = '1'
                newFlujoDoc.Icono = 'fa fa-registered'
                newFlujoDoc.ColorIcono = '#1d9d74'
                newFlujoDoc.TagPagina = ['tagGestionMacroObrasNuevo']

                const Extension = imagen.File.name.split('.').pop()?.toLowerCase() || imagen.File.type.split('/')[1].trim().toLowerCase()
                if (formAddValorizacion.values.Archivos.filter(ar => ar.CodigoNodo === nodo.Codigo).length >= nodo.CantidadMax && nodo.CantidadMax > 0) return AdapterGenerico.createMessage('', 'No se permiten más documentos', 'warning', false)
                if (!nodo.Extension.map(e => e.trim().toLowerCase()).includes(Extension)) return AdapterGenerico.createMessage('', 'Tipo de Archivo no permitido', 'warning', false);
                const newAdjunto = new DtoFilesDoc()
                newAdjunto.CodigoNodo = nodo.Codigo
                newAdjunto.Coordenadas = Coordenadas
                newAdjunto.Extension = Extension
                newAdjunto.Fecha_Archivo = new Date(imagen.File.lastModified)
                newAdjunto.Filename = imagen.File.name
                newAdjunto.Flujos = [newFlujoDoc]
                newAdjunto.NameOriginal = imagen.NombreOriginal
                newAdjunto.Observacion = 'Cargado desde PWA'
                newAdjunto.Posicion = crypto.randomUUID()
                newAdjunto.Size.Size = parseFloat((imagen.File.size / 1048576).toFixed(2))
                newAdjunto.Size.UM = 'MB'
                newAdjunto.Ultimo_Flujo = newFlujoDoc
                newAdjunto.File = imagen.File

                newFiles.push(newAdjunto)
            }
            formAddValorizacion.setFieldValue('Archivos', [...formAddValorizacion.values.Archivos, ...newFiles])
        } catch (error) {
            console.error(error)
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false)
        }
    }

    const removeImagen = (archivo: DtoFilesDoc, nodo: DtoNodoDoc) => {
        try {
            const updatedArchivos = formAddValorizacion.values.Archivos.filter(
                (arch) => arch.Filename !== archivo.Filename && arch.CodigoNodo === nodo.Codigo
            )
            formAddValorizacion.setFieldValue('Archivos', updatedArchivos);
        } catch (error) {
            console.error(error)
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false)
        }
    }

    return (
        <Accordion className='mb-4' >
            {
                formAddValorizacion.values.DocumentacionBaremo?.Nodos.map((nodo, i) =>
                    <Accordion.Item key={i} eventKey={String(i)}>
                        <Accordion.Header>
                            <div className='d-flex w-100 justify-content-between'>
                                <div>
                                    <span>{nodo.Titulo}</span><span style={{ fontSize: '0.7rem', marginLeft: '0.2rem', color: 'red' }}>{`Min ${nodo.CantidadMin}${nodo.CantidadMax !== 0 ? ` - Max ${nodo.CantidadMax}` : ``}`}</span>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <span className='adjunto'>
                                        <ElementInputFileAdjunto
                                            icon={'fa-solid fa-paperclip'}
                                            multiple
                                            disabled={false}
                                            onChange={(documentos) => addImagen(documentos, nodo)}
                                            size={17}
                                            allowedFormats={nodo.Extension}
                                        />
                                    </span>
                                </div>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className='d-flex justify-content-around flex-wrap' style={{ padding: 4 }}>
                            {
                                formAddValorizacion.values.Archivos.map((archivo, i) =>
                                    <Card key={i} className='mb-1 d-flex' style={{ width: '9rem' }}>
                                        <Card.Body style={{ padding: 6, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                            <div className='w-100 d-flex justify-content-center'>
                                                <i className={`${extensionImagen.includes(archivo.Extension) ? 'fa-solid fa-image' : 'fa-solid fa-file'}`} style={{ fontSize: 40 }}></i>
                                            </div>
                                            <Card.Title style={{ fontSize: 12 }}>{archivo.NameOriginal}</Card.Title>
                                            <div>
                                                <ListGroup variant='flush'>
                                                    <ListGroup.Item style={{ padding: 4 }} action variant='info'>
                                                        {`${typeof archivo.Size.Size === 'number' ? archivo.Size.Size.toFixed(2) : archivo.Size.Size} ${archivo.Size.UM}`}
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </div>
                                            <div className='d-flex w-100 justify-content-between'>
                                                <Button variant='primary' onClick={extensionImagen.includes(archivo.Extension) ? () => {
                                                    archivo.File && setFileImageViewer(archivo.File)
                                                    setShowImageViewer(true)
                                                } : () => {
                                                    if (archivo.File) {
                                                        saveAs(archivo.File, archivo.File.name)
                                                        if ('Notification' in window && Notification.permission === 'granted') {
                                                            new Notification('Descarga completada', {
                                                                body: `El archivo ${archivo.File.name} se ha descargado correctamente.`,
                                                            });
                                                        }
                                                    }
                                                }}>
                                                    {
                                                        extensionImagen.includes(archivo.Extension) ?
                                                            <i className='fa-solid fa-eye' style={{ fontSize: 16 }} /> :
                                                            <i className='fa-solid fa-download' style={{ fontSize: 16 }} />
                                                    }
                                                </Button>
                                                <Button variant='danger' onClick={() => removeImagen(archivo, nodo)}>
                                                    <i className='fa-solid fa-trash' style={{ fontSize: 16 }} />
                                                </Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                )
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                )
            }
        </Accordion>
    )
}