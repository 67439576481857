import { RepositoryMain } from '../Domain/RepositoryMain'
import { DtoReqSelectTrabajo } from '../../../Home/Domain/DtoReqSelectTrabajo'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { DtoTrabajos } from '../../../../Master/Home/Domain/DtoTrabajos'
import { ActualizarDataTrabajos, ActualizarDataManoObra, ActualizarDataReserva } from 'sigo-package'
import { AdapterConfigureGenerico as envSIGO } from '../../../../shared/Infraestructure/AdapterConfigureGenerico'
import { DtoObras } from '../../../../shared/Domain/Dto/DtoObras'

export class UseCaseSelectTrabajo {
  private repository: RepositoryMain

  constructor(_repository: RepositoryMain) {
    this.repository = _repository
  }

  public async exec(user: EntityDataUsuario, idTrabajo: number): Promise<DtoTrabajos> {
    return await this._exec(user, idTrabajo)
  }

  private async _exec(user: EntityDataUsuario, idTrabajo: number): Promise<DtoTrabajos> {
    let params: DtoReqSelectTrabajo = {
      Header: {
        Coleccion: {
          Codigo: user.Delegacion.Codigo,
          Nombre: user.Pais.Nombre
        },
        Filtros: [
          {
            $match: { ID_Trabajo: idTrabajo }
          },
          {
            $lookup: {
              from: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_Obras`,
              localField: 'ID_GOM',
              foreignField: 'AreaGOM.GOMS.ID_GOM',
              as: 'ColeccionObras'
            }
          },
          {
            $lookup: {
              from: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_ManoObra`,
              localField: 'ID_GOM',
              foreignField: 'ID_GOM',
              as: 'ColeccionManoObra'
            }
          },
          {
            $lookup: {
              from: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_Reserva`,
              localField: 'ID_GOM',
              foreignField: 'ID_GOM',
              as: 'ColeccionReserva'
            }
          },
          {
            $project: {
              ID_Trabajo: 1,
              ID_GOM: 1,
              ZonaAsignada: 1,
              'Ultima_asignacion.Fecha': 1,
              'Ultima_asignacion.Personal.Identificacion': 1,
              'Ultima_asignacion.Empresa.Codigo': 1,
              'Ultima_asignacion.Empresa.Propia': 1,
              'Ultima_asignacion.Valorizaciones': 1,
              'Ultima_asignacion.Cuadrilla.Codigo': 1,
              'Ultimo_Estado_Interno.Descripcion': 1,
              'Ultima_PreLiquidacion.Valorizacion': 1,
              'Pais.Nombre': 1,
              'Pais.Codigo': 1,
              'Delegacion.Codigo': 1,
              'PartesDiarios': 1,
              'ColeccionObras.ID_incidencia': 1,
              'ColeccionObras.Proyecto': 1,
              'ColeccionObras.OrdenTrabajo': 1,
              'ColeccionObras.ContratoOT.Codigo': 1,
              'ColeccionObras.ContratoOT.Sistema': 1,
              'ColeccionObras.ZonaAsignada': 1,
              'ColeccionObras.Pais.Codigo': 1,
              'ColeccionObras.Delegacion.Codigo': 1,
              'ColeccionObras.DetalleChile.agencia': 1,
              'ColeccionObras.DetalleChile.numoe': 1,
              'ColeccionObras.DetalleChile.codigo': 1,
              'ColeccionManoObra.ID_ManoObra': 1,
              'ColeccionManoObra.Valorizacion': 1,
              'ColeccionReserva.Item': 1,
              'ColeccionObras.Ubicacion.Municipio': 1
            },
          },
        ]
      }
    }
    const trabajos = await this.repository.selectTrabajos(params, user, idTrabajo)

    if (!trabajos.length) throw Error(`No se encontró Trabajo con ID_Trabajo: ${idTrabajo}`)
    if (trabajos.length > 1) throw Error(`Se encontró más de un(a) Trabajo con ID_Trabajo: ${idTrabajo}`)
    const { ID_GOM, ColeccionManoObra, ColeccionReserva, ColeccionObras } = trabajos[0]
    for (let { name, data } of [
      { name: 'Mano de obra', data: ColeccionManoObra },
      { name: 'Reserva', data: ColeccionReserva },
      { name: 'Obra', data: ColeccionObras }
    ]) {
      if (data && !data.length) throw Error(`No se encontró ${name} con ID_GOM: ${ID_GOM} para el trabajo seleccionado`)
      if (data && data.length > 1) throw Error(`Se encontró más de un(a) ${name} con ID_GOM: ${ID_GOM} para el trabajo seleccionado`)
    }
    const ContratoOT = await this.consultarContratoOT(trabajos[0].ColeccionObras[0])
    // @ts-expect-error
    ActualizarDataTrabajos({ manoObra: trabajos[0].ColeccionManoObra[0], obra: trabajos[0].ColeccionObras[0], trabajos: trabajos, envSIGO, ContratoOT })
    ActualizarDataManoObra({ manoObra: trabajos[0].ColeccionManoObra[0], envSIGO, obra: trabajos[0].ColeccionObras[0], ContratoOT })
    ActualizarDataReserva(trabajos[0].ColeccionReserva[0])
    return trabajos[0]
  }

  private async consultarContratoOT(obra: DtoObras) {
    const ContratosOT = await this.repository.selectContratoOT()
    const ContratoOT = ContratosOT.filter(e => e.Codigo === obra.ContratoOT.Codigo)
    if (ContratoOT.length !== 1) throw Error(`¡No se pudo obtener Contrato OT! Refresca ContratoOT o Comunícate con Soporte`)
    return ContratoOT[0]
  }

}