import { EntityInformationDataInitial } from "../../../shared/Domain/EntityInformationDataInitial";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseCargaMaestro {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: { pais: Array<number>, grupo: Array<number>, delegacion: Array<number>, ot: Array<number> }, information: EntityInformationDataInitial): Promise<void> {
        return await this._exec(params, information);
    }

    private async _exec(params: { pais: Array<number>, grupo: Array<number>, delegacion: Array<number>, ot: Array<number> }, information: EntityInformationDataInitial): Promise<void> {
        await this.repository.selectPersonal(params.pais, information);
    }
}