const SCHEMA: string = process.env.REACT_APP_MAESTRO_SEDE_SCHEMA || 'Trabajo';
const ENTITY: string = process.env.REACT_APP_MAESTRO_SEDE_ENTITY || 'form';
const SELECT_TRABAJOS: string = '/SigoPwa/Programados/Gestion/Trabajos/Select';
const SELECT_OBRA: string = '/SigoPwa/Programados/Gestion/Obras/Select';
const SELECT_MANOOBRA: string = '/SigoPwa/Programados/Gestion/ManoObra/Select';
const SELECT_RESERVA: string = '/SigoPwa/Programados/Gestion/Reserva/Select';
const UPDATE_TRABAJOS: string = '/SigoPwa/Programados/Gestion/Trabajos/Update';
const SELECT_UNIDADOBRAMATERIAL: string = '/SigoPwa/Configuracion/MantenimientosGlobales/UnidadObraMaterial/Select';
const SELECT_PRECIOESPECIALIDAD: string = '/SigoPwa/Configuracion/MantenimientosGlobales/PrecioEspecialidad/Select';
const SELECT_ESTADOINTERNO: string = '/SigoPwa/Programados/Mantenimientos/EstadoInterno/Select';
const ROUTE_PROGRAMADOS = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS}`;
const ROUTE_TRABAJOS: string = `${ROUTE_PROGRAMADOS}/${process.env.REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_TRABAJOS}`;
const ROUTE_PARTEDIARIO_TRABAJO: string = `${ROUTE_TRABAJOS}/${process.env.REACT_APP_ROUTE_PATH_TRABAJOS_PARTEDIARIO}`

export const AdapterConfigure = {
  SCHEMA,
  ENTITY,
  SELECT_TRABAJOS,
  SELECT_MANOOBRA,
  UPDATE_TRABAJOS,
  SELECT_OBRA,
  SELECT_UNIDADOBRAMATERIAL,
  SELECT_RESERVA,
  SELECT_PRECIOESPECIALIDAD,
  SELECT_ESTADOINTERNO,
  ROUTE_PROGRAMADOS,
  ROUTE_TRABAJOS,
  ROUTE_PARTEDIARIO_TRABAJO,
};