import { RepositoryMain } from '../Domain/RepositoryMain'
import { RepositoryImplGenerico } from '../../../../shared/Infraestructure/RepositoryImplGenerico'
import { AdapterConfigure } from './AdapterConfigure'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { DtoRequestUpdateTrabajo } from '../Domain/DtoRequestUpdateTrabajo'
import { DtoFormParteDiario } from '../Domain/DtoFormParteDiario'
import { FTPService } from '../../../../shared/Infraestructure/AdapterFileSystem'
import { DtoParteDiario } from '../../Trabajos/Domain/DtoParteDiario'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { DtoTrabajos } from '../../../../Master/Home/Domain/DtoTrabajos'
import { DtoReqSelectTrabajo } from '../../../Home/Domain/DtoReqSelectTrabajo'
import { LoadImage } from '../../../../shared/Components/ElementInputFileImage'

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

  public async selectTrabajo(params: DtoReqSelectTrabajo): Promise<DtoTrabajos[]> {

    // params.Contrasenia = AdapterEncrypt.encrypt(params.Contrasenia, this.websocket.key, false)

    let url: string = `${this.urlBase}${AdapterConfigure.SELECT_TRABAJOS}`

    let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'text', {})
    response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response
    return response
  }

  public async selectTrabajoLocal(user: EntityDataUsuario, idTrabajo: number): Promise<DtoTrabajos> {
    return await this.dbLocal.selectByIndexStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_Trabajos`, value: idTrabajo })
  }

  public async updateTrabajos(params: DtoRequestUpdateTrabajo, user: EntityDataUsuario): Promise<DtoTrabajos[]> {
    // params.Contrasenia = AdapterEncrypt.encrypt(params.Contrasenia, this.websocket.key, false)

    let url: string = `${this.urlBase}${AdapterConfigure.UPDATE_TRABAJOS}`

    let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'json', {})
    response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response
    const trabajoIndexedLocal = await this.selectTrabajoLocal(user, response[0].ID_Trabajo)
    if (trabajoIndexedLocal) {
      const trabajoUpdated: DtoTrabajos = {
        ColeccionObras: trabajoIndexedLocal.ColeccionObras,
        ColeccionReserva: trabajoIndexedLocal.ColeccionReserva,
        ColeccionManoObra: trabajoIndexedLocal.ColeccionManoObra,
        ...response[0]
      }
      await this.dbLocal.updateByIndexStore({ nameStore: `${params.Body.Pais.Nombre}_${params.Body.Delegacion.Codigo}_Trabajos`, value: trabajoUpdated })
    }
    return response
  }

  public async getPersonal(): Promise<[] | any[]> {
    return await this.dbLocal.selectAllStore('Personal')
  }

  public async getEstadoInterno(): Promise<[] | any[]> {
    return await this.dbLocal.selectAllStore('EstadoInterno')
  }

  public async getCuadrillas(): Promise<[] | any[]> {
    return await this.dbLocal.selectAllStore('Cuadrillas')
  }

  public async uploadPhoto(params: DtoFormParteDiario): Promise<any> {
    const uploadPromises: Promise<any>[] = []
    for (const imagen of params.imagenes) {
      if (imagen.NombreOriginal === '') continue
      const definedFile = imagen.File as File
      uploadPromises.push(FTPService.setFileToServer(definedFile.name, definedFile))
    }
    await Promise.all(uploadPromises)
  }

  public async downloadFiles(parteDiario: DtoParteDiario): Promise<LoadImage[]> {

    const fotosPromises = parteDiario.Fotos_SST
      .filter(foto => foto.Foto.Estado.ID_Estado === 1)
      .map(foto => FTPService.getFileToServer(foto.Foto.img, 2))

    const responses: any = await Promise.all(fotosPromises)

    return responses.filter((e: any) => e !== 'OK').map((e: any) => { return { Nombreoriginal: '', File: AdapterGenerico.base64ToFile(e.Archivo, e.FileName) } })
  }

}