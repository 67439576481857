import { RepositoryMain } from '../Domain/RepositoryMain'
import { RepositoryImplGenerico } from '../../../shared/Infraestructure/RepositoryImplGenerico'
import { AdapterConfigure } from './AdapterConfigure'
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico'
import { EntityDataUsuario } from '../../../shared/Domain/EntityDataUsuario'
import { DtoStockAlmacen } from '../../../Master/Home/Domain/DtoStockAlmacen'
import { DtoRequestSelectStockAlmacen } from '../../../Master/Home/Domain/DtoRequestSelectStockAlmacen'
import { DtoConsumoMaterial } from '../../../Master/Home/Domain/DtoConsumoMaterial'

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

    async selectStockAlmacen(params: DtoRequestSelectStockAlmacen, user: EntityDataUsuario): Promise<DtoStockAlmacen[]> {
        const url: string = `${this.urlBase}${AdapterConfigure.SELECT_STOCK_ALMACEN}`
        let response = await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'json', {})
        response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response
        response = await this._saveLocalStockAlmacen(response, user)
        return response
    }

    private async _saveLocalStockAlmacen(response: DtoStockAlmacen[], user: EntityDataUsuario): Promise<DtoStockAlmacen[]> {
        const storeExist = await this.dbLocal.existsStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockAlmacen`)
        if (!storeExist) throw Error(`Error al obtener coleccion StockAlmacen`)
        await this.dbLocal.clearStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockAlmacen`)
        await this.dbLocal.insertDataStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockAlmacen`, data: response })
        return response
    }

    async selectStockAlmacenLocal(user: EntityDataUsuario): Promise<DtoStockAlmacen[]> {
        return await this.dbLocal.selectAllStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockAlmacen`)
    }

    public async selectConsumoMaterial(user: EntityDataUsuario): Promise<DtoConsumoMaterial[]> {
        return await this.dbLocal.selectAllStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_ConsumoMaterial`)
    }

}