import { DtoEstado } from "../../../../shared/Domain/Dto/DtoEstado";
import { DtoUnidadMedida } from "../../../../shared/Domain/Dto/DtoUnidadMedida";
import { DtoAccion } from "./DtoAccion";
import { DtoMotivo } from "./DtoMotivo";

export class DtoMaterialesUtiRet {
    Codigo: string = '';
    CodigoLlave: string = '';
    Descripcion: string = '';
    Estado: DtoEstado = new DtoEstado();
    UnidadMedida: DtoUnidadMedida = new DtoUnidadMedida();
    Cantidad: number = 0;
    CantidadStock: number = 0;
    CantidadSinStock: number = 0;
    CantidadReutilizada: number = 0;
    CantidadContratista: number = 0;
    CantidadInformada: number = 0;
    Accion: DtoAccion = new DtoAccion();
    Motivo: DtoMotivo = new DtoMotivo();
}