import { RepositoryMain } from '../Domain/RepositoryMain'
import { DtoGoms, DtoObras } from '../Domain/DtoObras'
import { DtoReqSelectObra } from '../Domain/DtoReqSelectObra'

export class UseCaseSelectObra {
  private repository: RepositoryMain

  constructor(_repository: RepositoryMain) {
    this.repository = _repository
  }

  public async exec(CodigoDelegacion: string, NombrePais: string, Obra: DtoObras): Promise<DtoObras[]> {
    return await this._exec(CodigoDelegacion, NombrePais, Obra)
  }

  private async _exec(CodigoDelegacion: string, NombrePais: string, Obra: DtoObras): Promise<DtoObras[]> {

    const params: DtoReqSelectObra = {
      Header: {
        Coleccion: {
          Codigo: CodigoDelegacion,
          Nombre: NombrePais,
        },
        Filtros: [
          {
            $match: {
              ID_incidencia: Obra.ID_incidencia,
              'AreaGOM.GOMS.ID_GOM': Obra.AreaGOM[0].GOMS[0].ID_GOM,
            },
          },
          {
            $lookup: {
              from: `${NombrePais}_${CodigoDelegacion}_ManoObra`,
              localField: 'AreaGOM.GOMS.ID_GOM',
              foreignField: 'ID_GOM',
              as: 'ColeccionManoObra'
            }
          },
          {
            $lookup: {
              from: `${NombrePais}_${CodigoDelegacion}_Reserva`,
              localField: 'AreaGOM.GOMS.ID_GOM',
              foreignField: 'ID_GOM',
              as: 'ColeccionReserva'
            }
          },
          {
            $lookup: {
              from: `${NombrePais}_${CodigoDelegacion}_Goms`,
              localField: 'AreaGOM.GOMS.ID_GOM',
              foreignField: 'ID_GOM',
              as: 'ColeccionGoms'
            }
          },
          {
            $project: {
              ID_incidencia: 1,
              OrdenTrabajo: 1,
              Contrato: 1,
              'AreaGOM.GOMS.ID_GOM': 1,
              'Ultimo_Estado_Interno.Descripcion': 1,
              'DetalleChile.agencia': 1,
              ColeccionManoObra: 1,
              ColeccionReserva: 1,
              ColeccionGoms: 1,
            },
          },
        ],
      },
    }
    const respuesta = await this.repository.selectObra(params)
    this.validarRespuesta(respuesta, Obra)
    // this.verificarEstado(respuesta[0].ColeccionGoms[0])
    return respuesta
  }

  private validarRespuesta = (respuesta: DtoObras[], Obra: DtoObras) => {
    if (respuesta.length !== 1) throw Error(`¡Error al consultar Obra ${Obra.OrdenTrabajo}! Comunícate con Soporte`)
    if (respuesta[0].ColeccionReserva.length !== 1) throw Error(`¡Error al consultar Reserva de la Obra ${Obra.OrdenTrabajo}! Comunícate con Soporte`)
    if (respuesta[0].ColeccionManoObra.length !== 1) throw Error(`¡Error al consultar Mano Obra de la Obra ${Obra.OrdenTrabajo}! Comunícate con Soporte`)
    if (respuesta[0].ColeccionGoms.length !== 1) throw Error(`¡Error al consultar Goms de la Obra ${Obra.OrdenTrabajo}! Comunícate con Soporte`)
  }

  private verificarEstado = (gom: DtoGoms) => {
    if (!['ABIERTO', 'PENDIENTE', 'ACEPTADA'].includes(gom.Ultimo_Estado_Interno.Descripcion))
      throw Error(`¡El estado del replanteo debe ser PENDIENTE, ABIERTO o ACEPTADA!`)
  }
}
