import { RepositoryMain } from '../Domain/RepositoryMain';
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario';
import { DtoTrabajos } from '../../../../Master/Home/Domain/DtoTrabajos';

export class UseCaseSelectTrabajosLocal {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(user: EntityDataUsuario): Promise<Array<DtoTrabajos>> {
        return await this._exec(user);
    }

    private async _exec(user: EntityDataUsuario): Promise<Array<DtoTrabajos>> {
        return await this.repository.selectTrabajosLocal(user);
    }
}
