import { AdapterConfigure } from './AdapterConfigure'
import { RepositoryImplMain } from './RepositoryImplMain'
import { RootState } from '../../../shared/Infraestructure/AdapterStore'
import { useSelector, useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { addLoading, hideIconMenu, removeLoading } from '../../../shared/Infraestructure/SliceGenerico'
import { changeTextSearchLogTrabajos } from '../../../shared/Infraestructure/SliceTextSearch'
import { useEffect, useState } from 'react'
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ErrorCostume } from '../../../shared/Domain/ErrorCostume'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { UseCaseSelectStockPersonalLocal } from '../Application/UseCaseSelectStockPersonalLocal'
import { DtoTrabajosStock } from '../Domain/DtoTrabajosStock'
import { DtoResponseSelectStockPersonal } from '../../../Master/Home/Domain/DtoResponseSelectStockPersonal'
import { UseCaseSelectStockPersonal } from '../Application/UseCaseSelectStockPersonal'

export const Controller = () => {
  const [StockPersonal, setStockPersonal] = useState<DtoResponseSelectStockPersonal[]>([])
  const [Trabajos, setTrabajos] = useState<DtoTrabajosStock[]>([])
  const [TrabajosAux, setTrabajosAux] = useState<DtoTrabajosStock[]>([])

  useEffect(() => {
    const map: { [key: string]: boolean } = {}
    const stockReducer = StockPersonal.reduce<DtoTrabajosStock[]>((acc, el) => {
      if (!map[el.ID_Trabajo]) {
        map[el.ID_Trabajo] = true
        acc.push({
          OrdenTrabajo: el.OrdenTrabajo ? el.OrdenTrabajo : `Sin OE (ID_Trabajo:${el.ID_Trabajo.toString()})`,
          Estado: { ID_Estado: 2, Nombre: 'VALIDADO' },
          ID_Trabajo: el.ID_Trabajo,
        })
      }
      return acc
    }, [])
    setTrabajos(stockReducer)
    setTrabajosAux(stockReducer)
  }, [StockPersonal])

  const dispatch: Dispatch = useDispatch()
  const navigate: NavigateFunction = useNavigate()
  const { websocket, dbLocal } = useSelector((state: RootState) => state.generico)
  const { user } = useSelector((state: RootState) => state.auth)
  const { rutaAnterior } = useSelector((state: RootState) => state.main)
  const { textSeachLogTrabajos } = useSelector((state: RootState) => state.textSearch)
  const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY)

  useEffect(() => {
    if (rutaAnterior.includes('/main/logistica/trabajos/stockPersonal/')) onChange('textSearch', textSeachLogTrabajos)
  }, [Trabajos])

  const formSearch = useFormik({
    initialValues: {
      textSearch: '',
    },
    validationSchema: Yup.object({}),
    onSubmit: (values, formikHelpers) => { },
  })

  const init = async () => {
    try {
      dispatch(hideIconMenu())
      await loadData()
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false)
    } finally {
      dispatch(removeLoading())
    }
  }

  const loadData = async () => {
    dispatch(addLoading({ textLoading: 'Cargando Stock Personal Local...' }))
    const respStockPersonal = await new UseCaseSelectStockPersonalLocal(repository).exec(user)
    dispatch(removeLoading())
    if (respStockPersonal.length < 1) return AdapterGenerico.createMessage('Información!', `No hay registros para mostrar.`, 'info', false)
    setStockPersonal(respStockPersonal)
  }

  const onChange = (name: string, value: any) => {
    try {
      formSearch.setFieldValue(name, value)
      if (['textSearch'].includes(name)) {
        dispatch(changeTextSearchLogTrabajos(value))
        setTrabajosAux(Trabajos.filter((el) => {
          if (el.OrdenTrabajo.includes(value.toUpperCase()) ||
            el.OrdenTrabajo.includes(value.toLowerCase())
          ) return el
        }))
      }
    } catch (error) {
      console.error(error)
      let err: ErrorCostume = new ErrorCostume((error as Error).message)
      AdapterGenerico.createMessage('Alerta', err.message, 'warning', false)
    }
  }

  const selectTrabajosDB: () => void = async () => {
    try {
      dispatch(addLoading({ textLoading: 'Cargando Stock Personal...' }))
      await new UseCaseSelectStockPersonal(repository).exec(user)
      dispatch(removeLoading())
      loadData()
    } catch (error) {
      dispatch(removeLoading())
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false)
    }
  }

  return {
    init,
    user,
    formSearch,
    StockPersonal,
    onChange,
    TrabajosAux,
    selectTrabajosDB
  }
}
