import { createSlice } from '@reduxjs/toolkit'
import { iInitialState } from '../Domain/iInitialState'

const initialState: iInitialState = {
  data: []
}

const slice = createSlice({
  name: 'stockAlmacen',
  initialState,
  reducers: {
    setData: (state, action) => { state.data = action.payload },
  }
})

export const { setData } = slice.actions
export const stockAlmacenReducer = slice.reducer