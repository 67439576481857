import { Dispatch } from 'redux'
import { useSelector, useDispatch } from 'react-redux'
import { addLoading, hideIconMenu, removeLoading } from '../../../../shared/Infraestructure/SliceGenerico'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { useEffect, useState } from 'react'
import { RootState } from '../../../../shared/Infraestructure/AdapterStore'
import { AdapterConfigure } from './AdapterConfigure'
import { RepositoryImplMain } from './RepositoryImplMain'
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom'
import { UseCaseSelectTrabajo } from '../Application/UseCaseSelectTrabajo'
import { PreLiquidationProcess } from '../Application/UseCasePreLiquidationProcesses/UseCasePreLiquidationProcesses'
import { DtoDataSelectAsignaciones } from '../Domain/DtoDataSelectAsignaciones'
import { DtoInitialValues } from '../../Shared/Preliquidar/Interfaces/DtoInitialValues'
import { FormikHelpers } from 'formik'
import { DtoDataAddedValorizaciones } from '../../Shared/Preliquidar/Interfaces/DtoDataAddedValorizaciones'
import { DtoDocumentacionBaremoPEX, DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { UseCaseLoadData } from '../Application/UseCaseLoadData'
import { UseCasePreliquidar } from '../Application/UseCasePreliquidar'
import { DtoResponseEstadoInterno } from '../../../../../app/Domain/DtoResponseEstadoInterno'
import { DtoManoObraGlobal } from '../../../../../app/Domain/DtoManoObraGlobal'
import { DtoPrecioEspecialidad } from '../../../../../app/Domain/DtoPrecioEspecialidad'
import { DtoTrabajosDE } from '../../Trabajos/Domain/DtoTrabajosDE'
import { DtoMaterialesUtiRet } from '../../../Trabajos/Asignacion/Domain/DtoMaterialesUtiRet'
import { DtoItems } from '../../../../../app/Domain/DtoItems'
import { DtoResponseSelectStockPersonal } from '../../../../Master/Home/Domain/DtoResponseSelectStockPersonal'
import { DtoDataMateriales } from '../Domain/DtoDataMateriales'
import { onChangeShared, onSubmitFormAddMOShared, onSubmitShared } from '../../Shared'
import { DtoInitialValuesAddMO } from '../../Shared/Preliquidar/Interfaces/DtoInitialValuesAddMO'
import { DtoPrecioMaterial } from '../../../../../app/Domain/DtoPrecioMaterial'
import { DtoStockAlmacen } from '../../../../Master/Home/Domain/DtoStockAlmacen'
import { DtoValorizacion } from '../../../Trabajos/Asignacion/Domain/DtoValorizacion'
import { DtoConsumoMaterial } from '../../../../Master/Home/Domain/DtoConsumoMaterial'
import { typeEstadosAddressID } from '../../Shared/Domain'
import { UseCaseChangeEstadoHPList } from '../Application/UseCaseChangeEstadoHPList'

export const Controller = () => {

  const dispatch: Dispatch = useDispatch()
  const { id } = useParams<{ id: string }>()
  const navigate: NavigateFunction = useNavigate()
  const currentPath = useLocation().pathname
  const lastPath = currentPath.replace(/\/preliquidar\/\d+$/, '')
  const { websocket, dbLocal } = useSelector((state: RootState) => state.generico)
  const { user, userGestor } = useSelector((state: RootState) => state.auth)
  const repository = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY)
  const [trabajo, setTrabajo] = useState<DtoTrabajosDE | null>(null)
  const [showOffCanvas, setShowOffCanvas] = useState<boolean>(false)
  const [asignaciones, setAsignaciones] = useState<DtoDataSelectAsignaciones[]>([])
  const [valorizaciones, setValorizaciones] = useState<DtoDataAddedValorizaciones[]>([])
  const [documentacionValorizacion, setDocumentacionValorizacion] = useState<DtoNodoDoc[]>([])
  const [newFilesUpload, setNewFilesUpload] = useState<File[]>([])
  const [dataDocumentacionBaremoPEX, setDocumentacionBaremoPEX] = useState<DtoDocumentacionBaremoPEX[]>([])
  const [dataEstadoInterno, setDataEstadoInterno] = useState<DtoResponseEstadoInterno[]>([])
  const [dataManoObraGlobal, setDataManoObraGlobal] = useState<DtoManoObraGlobal[]>([])
  const [dataPrecioEspecialidad, setDataPrecioEspecialidad] = useState<DtoPrecioEspecialidad[]>([])
  const [dataMateriales, setDataMateriales] = useState<DtoDataMateriales[]>([])
  const [dataMaterialesAlmacen, setDataMaterialesAlmacen] = useState<DtoDataMateriales[]>([])
  const [dataItems, setDataItems] = useState<DtoItems[]>([])
  const [dataStockPersonal, setDataStockPersonal] = useState<DtoResponseSelectStockPersonal[]>([])
  const [dataStockAlmacen, setDataStockAlmacen] = useState<DtoStockAlmacen[]>([])
  const [dataPrecioMaterial, setDataPrecioMaterial] = useState<DtoPrecioMaterial[]>([])
  const [dataConsumoMaterial, setDataConsumoMaterial] = useState<DtoConsumoMaterial[]>([])

  const init = async () => {
    try {
      dispatch(hideIconMenu())
      dispatch(addLoading({ textLoading: 'Buscando Trabajo ...' }))
      const trabajo = await new UseCaseSelectTrabajo(repository).exec(user, Number(id))
      const {
        DocumentacionBaremoPEX,
        EstadosInternos,
        ManoObraGlobal,
        PrecioEspecialidad,
        dataItems,
        dataStockPersonal,
        dataPrecioMaterial,
        dataStockAlmacen,
        dataConsumoMaterial
      } = await new UseCaseLoadData(repository).exec(trabajo, user)
      setTrabajo(trabajo)
      setDocumentacionBaremoPEX(DocumentacionBaremoPEX)
      setDataEstadoInterno(EstadosInternos)
      setDataManoObraGlobal(ManoObraGlobal)
      setDataPrecioEspecialidad(PrecioEspecialidad)
      setDataItems(dataItems)
      setDataStockPersonal(dataStockPersonal)
      setDataStockAlmacen(dataStockAlmacen)
      setDataPrecioMaterial(dataPrecioMaterial)
      setDataConsumoMaterial(dataConsumoMaterial)
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error').then(() => navigate(lastPath))
    } finally {
      dispatch(removeLoading())
    }
  }

  useEffect(() => {
    if (trabajo) {
      setAsignaciones(PreLiquidationProcess.getDataAsignaciones(trabajo))
      setDataManoObraGlobal((prev) => prev.filter(e => e.ContratoOT.Codigo === trabajo.ColeccionObras[0].ContratoOT.Codigo))
    }
  }, [trabajo])

  const onSubmit = (
    values: DtoInitialValues,
    formikHelpers: FormikHelpers<DtoInitialValues> | null,
    materialesInstalado: DtoMaterialesUtiRet[],
    materialesRetirado: DtoMaterialesUtiRet[]
  ): void | Promise<any> => {
    try {
      onSubmitShared(
        values,
        formikHelpers,
        materialesInstalado,
        materialesRetirado,
        documentacionValorizacion,
        setValorizaciones,
        setDocumentacionValorizacion,
        dataManoObraGlobal,
        dataStockPersonal,
        dataItems,
        valorizaciones
      )
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error')
    }
  }

  const onSubmitFormAddMO = (values: DtoInitialValuesAddMO, formikHelpers: FormikHelpers<DtoInitialValuesAddMO>): void | Promise<any> => {
    try {
      onSubmitFormAddMOShared(
        values,
        formikHelpers,
        trabajo,
        setTrabajo,
        dataPrecioEspecialidad,
        dataPrecioMaterial,
        dataItems
      )
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error')
    }
  }

  const onRemove = (LineaCodigoCub: string) => {
    try {
      setValorizaciones(valorizaciones.filter(e => e.LineaCodigoCub !== LineaCodigoCub))
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error')
    }
  }

  const onSave = async (valRechazadaXMetraje: DtoValorizacion | null) => {
    try {
      if (!valorizaciones.length) throw Error(`¡No se agregó asignaciones!`)
      if (trabajo) {
        dispatch(addLoading({ textLoading: '⚙ Pre Liquidando ...' }))
        await new UseCasePreliquidar(repository)
          .exec(
            user,
            userGestor,
            trabajo,
            valorizaciones,
            dataEstadoInterno,
            dataManoObraGlobal,
            'Troncal',
            valRechazadaXMetraje ? valRechazadaXMetraje.ID_Valorizacion : null
          )
        AdapterGenerico.createMessage('¡Éxito!', 'Pre Liquidado correctamente', 'success').then(() => navigate(lastPath))
      }
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error').then(() => navigate(lastPath))
    } finally {
      dispatch(removeLoading())
    }
  }

  const onChange = (name: string, value: any, materialesInstalado: DtoMaterialesUtiRet[]) => {
    try {
      return onChangeShared(
        name,
        value,
        setDataMateriales,
        setDataMaterialesAlmacen,
        dataItems,
        dataStockPersonal,
        dataStockAlmacen,
        valorizaciones,
        materialesInstalado,
        dataConsumoMaterial
      )
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error')
    }
  }

  const cambiarEstadoHPList = async (campo: typeEstadosAddressID) => {
    try {
      if (!trabajo) throw Error(`No se pudo obtener trabajo`)
      const mapa: { [key in typeEstadosAddressID]: string } = {
        ASPH: 'Asfaltado',
        OC: 'Obra Civil',
        SOP: 'Soplado'
      }
      const confirmacion = await AdapterGenerico.createMessage('Confirmar', `¿Estás seguro de cerrar ${mapa[campo]}?`, 'question', true)
      if (!confirmacion) return
      dispatch(addLoading({ textLoading: 'Actualizando ...' }))
      const trabajoAux = await new UseCaseChangeEstadoHPList(repository, trabajo, user).exec(campo)
      setTrabajo(trabajoAux)
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error')
    } finally {
      dispatch(removeLoading())
    }
  }

  return {
    init,
    trabajo,
    showOffCanvas,
    setShowOffCanvas,
    asignaciones,
    valorizaciones,
    onSubmit,
    onRemove,
    documentacionValorizacion,
    setDocumentacionValorizacion,
    newFilesUpload,
    setNewFilesUpload,
    dataDocumentacionBaremoPEX,
    onSave,
    dataManoObraGlobal,
    dataPrecioEspecialidad,
    onSubmitFormAddMO,
    onChange,
    dataMateriales,
    dataMaterialesAlmacen,
    cambiarEstadoHPList
  }
}
