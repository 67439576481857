import { DtoEstado } from "../../../shared/Domain/Dto/DtoEstado";
import { DtoDetalleChileReservaMO } from "../../../shared/Domain/Dto/DtoDetalleChileReservaMO";
import { DtoWBE } from "./DtoWBE";
import { DtoGeneralCodigoNombre } from "../../../shared/Domain/Dto/DtoGeneralCodigoNombre";
import { DtoUnidadMedida } from "../../../shared/Domain/Dto/DtoUnidadMedida";

export class DtoAmap {
    ID_Amap: number = 0;
    Amap: number = 0;
    Cantidad: number = 0;
    CantidadUsado: number = 0;
    CantidadTransito: number = 0;
    CantidadAprobada: number = 0;
    Codigo: string = '';
    Descripcion: string = '';
    Estado: DtoEstado = new DtoEstado();
    Precio: number = 0;
    Total: number = 0;
    Unidad: string = '';
    Moneda: string = '';
    Simbolo: string = '';
    Decimales: number = 0;
    WBE: DtoWBE = new DtoWBE();
    DetalleChile: DtoDetalleChileReservaMO = new DtoDetalleChileReservaMO();
    CantidadUO: number = 0;
    CantidadUOUsado: number = 0;
    CantidadUOTransito: number = 0;
    CantidadUOAprobada: number = 0;
    Cantidades: DtoAmapCantidades[] = [];
    DatosCatalogoMO: DtoDatosCatalogoMO = new DtoDatosCatalogoMO();
}

export class DtoAmapCantidades {
    ID_AmapCantidades: number = 0;
    CanMOCub: number = 0;
    CanUOCub: number = 0;
    Fecha: Date = new Date();
}

export class DtoDatosCatalogoMO {
    DatosManoObraGlobal: DtoDatosManoObraGlobal = new DtoDatosManoObraGlobal();
    DatosPrecioEspecialidad: DtoDatosPrecioEspecialidad = new DtoDatosPrecioEspecialidad();
    DatosUnidadObraMaterial: DtoDatosUnidadObraMaterial = new DtoDatosUnidadObraMaterial();
}
export class DtoDatosUnidadObraMaterial {
    Codigo: string = '';
    Descripcion: string = '';
    Materiales: DtoMaterialAmap[] = [];
}

export class DtoDatosPrecioEspecialidad {
    Moneda: string = '';
    Simbolo: string = '';
    Decimales: number = 0;
    Agencias: DtoPreciosAgencia[] = [];
    Contratistas: DtoPreciosContrata[] = [];
}

export class DtoPreciosContrata {
    ID_PrecioContrata: number = 0;
    Zonas: DtoPreciosZonas[] = [];
    Empresa: DtoEmpresaUsuario = new DtoEmpresaUsuario();
    Estado: DtoEstado = new DtoEstado();
}

export class DtoEmpresaUsuario {
    ID_Empresa: number = 0;
    Codigo: string = '';
    NombreComercial: string = '';
    RazonSocial: string = '';
    NumeroDocumentoIdentidad: string = '';
}

export class DtoPreciosZonas {
    ID_PreciosZonas: number = 0;
    Codigo: string = '';
    Descripcion: string = '';
    Actividades: DtoPrecioActividad[] = [];
    Estado: DtoEstado = new DtoEstado();
}

export class DtoPrecioActividad {
    ID_PrecioActividad: number = 0;
    Codigo: string = '';
    Descripcion: string = '';
    Ultimo_Precio: DtoPreciosVigentes = new DtoPreciosVigentes();
    Precios: DtoPreciosVigentes[] = [];
    FechaRegistro: Date = new Date();
    Estado: DtoEstado = new DtoEstado();
}

export class DtoPreciosAgencia {
    ID_PreciosAgencia: number = 0;
    Codigo: string = '';
    Nombre: string = '';
    Ultimo_Precio: DtoPreciosVigentes = new DtoPreciosVigentes();
    Precios: DtoPreciosVigentes[] = [];
    Estado: DtoEstado = new DtoEstado();
}

export class DtoPreciosVigentes {
    ID_PreciosVigentes: number = 0;
    Precio: number = 0;
    Vigencia: DtoVigencia = new DtoVigencia();
    Estado: DtoEstado = new DtoEstado();
}

export class DtoVigencia {
    FechaInicio: Date = new Date();
    FechaFin: Date = new Date();
}

export class DtoDatosManoObraGlobal {
    Actividad: string = '';
    Alcance: string = '';
    Material: DtoMaterialAmap[] = [];
    DetalleChile: DtoDetalleManoObraChile = new DtoDetalleManoObraChile();
}

export class DtoMaterialAmap {
    ID_MaterialAmap: number = 0;
    ID_Item: number = 0;
    Transaccion: string = '';
    Requerido: number = 0;
    Codigo: string = '';
    Descripcion: string = '';
    Unidad: string = '';
    CantidadMax: number = 0;
    Grupo: string = '';
    MaterialLogistica: DtoMaterialLogistica[] = [];
    Estado: DtoEstado = new DtoEstado();
}

export class DtoMaterialLogistica {
    ID_Material: number = 0;
    ID_Item: number = 0;
    Codigo: string = '';
    Descripcion: string = '';
    Unidad: DtoUnidadMedida = new DtoUnidadMedida();
    CantidadMax: number = 0;
}

export class DtoDetalleManoObraChile {
    ManoObraBaremo: DtoManoObraBaremo[] = [];
}

export class DtoManoObraBaremo {
    ID_ManoObraBaremo: number = 0;
    Especialidad: DtoGeneralCodigoNombre = new DtoGeneralCodigoNombre();
    PuntoBaremo: number = 0;
    PuntoBaremoContratista: number = 0;
}