//Dependency External
import { LanguageTranslate } from '..//Infraestructure/LanguageTranslate';
import { Navbar, Offcanvas } from 'react-bootstrap';

// Imagenes
import imgLogo from '../../../assets/img/logoIcono.png';
import imgUser from '../../../assets/img/user.png';
import imgNoSignal from '../../../assets/img/noSignal.png';

//Style
import './ObjectHeader.scss';
import { EntityDataUsuario } from '../Domain/EntityDataUsuario';
import { EntityMenu } from '../Domain/EntityMenu';

interface ViewProps {
  auth: boolean;
  user: EntityDataUsuario;

  iconMenu: boolean;
  onHideIconMenu: Function;
  onShowIconMenu: Function;

  online: boolean;
  countProcess: number;

  onClickHome?: Function;
  onClickProgramados?: Function;
  onClickLogistica?: Function;
  onClickATC?: Function;
  onClickHelp?: Function;
  onClickReport?: Function;
  onClickProfile?: Function;
  onClickVariable?: Function;
  onClickLogout?: Function;
  showSaludo: boolean;
  menu: EntityMenu[]
};

export const ObjectHeader = (props: ViewProps) => {

  const { menu } = props

  const mode = process.env.REACT_APP_MODE

  const languageTranslate = LanguageTranslate();

  return (
    <div className='ObjectHeader'>
      <Navbar bg={`${mode === 'dev' ? 'secondary' : 'primary'}`}>
        <div className="container-fluid">
          <div className="navbar-title text-center">
            <img className='img-thumbnail' style={{ borderRadius: '50%' }} src={imgLogo} alt="Logo" width={props.showSaludo ? 70 : 70} />
            <h6 className="mb-0 fw-bold text-white text-truncate pt-2" style={{ fontSize: '20px' }}>
              {languageTranslate.nombreProyecto} {mode === 'dev' ? ' PRUEBA' : ''}
            </h6>
            {
              props.showSaludo
                ? <>
                  <h3 className='text-white' style={{ paddingTop: '10px' }}>{languageTranslate.moduloMain.textoBienvenida}</h3>
                  <h6 className='text-white'>{`${props.user.Nombres} ${props.user.ApellidoPaterno} ${props.user.ApellidoMaterno}`.trim()}</h6>
                </>
                : <div className='pb-2'></div>
            }
          </div>
        </div>

        <div className="navbar-offline" hidden={props.online}>
          <div>
            <img className='imgNoSignal' src={imgNoSignal} alt="noSignal" width={20} />
            <label className='count-offline'>{props.countProcess}</label>
          </div>
        </div>

        <div className='navbar-profile' onClick={() => props.onShowIconMenu()}>
          <div className='profile d-flex align-items-center' style={{ cursor: 'pointer' }} >
            <i className="fa-solid fa-bars text-white userIcon" />
          </div>
        </div>

        <Offcanvas show={props.iconMenu} onHide={props.onHideIconMenu}>
          <Offcanvas.Header className={mode === 'dev' ? 'bg-dark' : ''}>
            <div className='header-container-sidebar'>
              <img className='imgLogoSidebar shadow' src={imgLogo} alt="logo" width={30} />
              <span className='spanNombreSistema'>SIGO {mode === 'dev' ? ' PRUEBA' : ''}</span>
            </div>

            <div className='header-container-sidebar' onClick={() => !!props.onClickProfile ? props.onClickProfile() : undefined}>
              <img title={props.user?.User} src={imgUser} alt="userIcon" width="30" className="imgLogoSidebar shadow" />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className='spanNombreUsuario'>{`${props.user.Nombres} ${props.user.ApellidoPaterno} ${props.user.ApellidoMaterno}`.trim()}</span>
                <span className='spanDetalleUsuario'>{`${props.user.Perfil.Perfil ? props.user.Perfil.Perfil : languageTranslate.general.SinPerfil}`}</span>
              </div>
            </div>

          </Offcanvas.Header>
          <Offcanvas.Body className={mode === 'dev' ? 'bg-dark' : ''}>
            <div className='body-container-sidebar' onClick={() => !!props.onClickHome ? props.onClickHome() : undefined}>
              <i className='fa-solid fa-home iconOpcion' />
              <span className='spanTextoOpcion'>{languageTranslate.moduloMain.textoInicio}</span>
            </div>
            {
              menu.find(e => e.codigo === '001') &&
              <div className='body-container-sidebar' onClick={() => !!props.onClickProgramados ? props.onClickProgramados() : undefined}>
                <i className='fa-solid fa-clock iconOpcion' />
                <span className='spanTextoOpcion'>{languageTranslate.moduloMain.textoProgramados}</span>
              </div>
            }
            {
              menu.find(e => e.codigo === '050') &&
              <div className='body-container-sidebar' onClick={() => !!props.onClickLogistica ? props.onClickLogistica() : undefined}>
                <i className='fa-solid fa-box iconOpcion' />
                <span className='spanTextoOpcion'>{languageTranslate.moduloMain.textoLogistica}</span>
              </div>
            }
            {/* <div className='body-container-sidebar' onClick={() => !!props.onClickATC ? props.onClickATC() : undefined}>
                            <i className='fa-brands fa-intercom iconOpcion' />
                            <span className='spanTextoOpcion'>{languageTranslate.moduloMain.textoATC}</span>
                        </div> */}
            {/* <div className='body-container-sidebar' onClick={() => !!props.onClickHelp ? props.onClickHelp() : undefined}>
                            <i className='fa-regular fa-circle-question iconOpcion' />
                            <span className='spanTextoOpcion'>{languageTranslate.moduloMain.textoAyuda}</span>
                        </div>
                        <div className='body-container-sidebar' onClick={() => !!props.onClickReport ? props.onClickReport() : undefined}>
                            <i className='fa-solid fa-bug iconOpcion' />
                            <span className='spanTextoOpcion'>{languageTranslate.moduloMain.textoReporte}</span>
                        </div>
                        */}
            <div className='body-container-sidebar' onClick={() => !!props.onClickVariable ? props.onClickVariable() : undefined}>
              <i className='fa-solid fa-table iconOpcion' />
              <span className='spanTextoOpcion'>{languageTranslate.textoCatalogos}</span>
            </div>
            <div className='body-container-sidebar' onClick={() => !!props.onClickLogout ? props.onClickLogout() : undefined}>
              <i className='fa fa-power-off iconOpcion' />
              <span className='spanTextoOpcion'>{languageTranslate.textoCerrarSesion}</span>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

      </Navbar>
    </div>
  )
}