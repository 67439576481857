import { createSlice } from '@reduxjs/toolkit';

interface SliceStockPersonalState {
	textSeach: string;
};

const initialState: SliceStockPersonalState = {
	textSeach: '',
};

const stockPersonalSlice = createSlice({
	name: 'stockPersonalSlice',
	initialState,
	reducers: {
		changeTextSeach: (state, action) => {
			return {
				...state,
				textSeach: action.payload,
			};
		},
	},
});


export const {
	changeTextSeach,
} = stockPersonalSlice.actions;

export default stockPersonalSlice.reducer;