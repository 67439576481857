import { RepositoryMain } from '../Domain/RepositoryMain'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { DtoTrabajosDE } from '../../Trabajos/Domain/DtoTrabajosDE'

export class UseCaseSelectTrabajoLocal {
  private repository: RepositoryMain

  constructor(_repository: RepositoryMain) {
    this.repository = _repository
  }

  public async exec(user: EntityDataUsuario, ID_Trabajo: number): Promise<DtoTrabajosDE> {
    return await this._exec(user, ID_Trabajo)
  }

  private async _exec(user: EntityDataUsuario, ID_Trabajo: number): Promise<DtoTrabajosDE> {
    const result = await this.repository.selectTrabajoLocal(user, ID_Trabajo)
    if (!result) throw Error(`¡No se pudo obtener Trabajo!`)
    return result
  }
}
