import { Filtros } from "../../Domain/EntityParams";

export const FiltroEstadoInterno: Filtros = {
    Filtros: [
        {
            $match: {
                'Estado.ID_Estado': 1
            }
        },
        {
            $project: {
                ID_EstadoInterno: 1,
                Descripcion: 1,
                Icono: 1,
                Color: 1,
                Orden: 1,
                ColorFondo: 1,
                Tipo: 1,
                Observacion: 1,
                UsuarioEdicion: 1,
                Anexos: 1,
                Sistema: 1,
                Pais: 1,
            }
        }]
}