import { typeHPList } from '../../../TrabajosDE/DocHP/Domain/typeHPList'

export class DtoHomePass {
    ID_HPListMO: number = 0
    Type: string = ''
    Cantidad: number = 0
    ID_AddressList: number = 0
    AddressID: string = ''
    Home_ID: string = ''
    ClientType: typeHPList = 'Business'
    StreetName: string = ''
    HouseNumber: string = ''
    HouseNumbreComplement: string = ''
}
interface DtoHomePassSimpleOld {
    ID_HomePassSimple: number
    AddressID: string
    StreetName: string
    HouseNumber: string
    HouseNumbreComplement: string
    Latitude: number
    Longitude: number
}