import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ViewMain } from "./Components/View";
import { useParams } from 'react-router-dom';

const ManagerEntity = () => {
    const params = useParams();
    const controller = Controller();

    React.useEffect(() => {
        controller.init();
        return () => {
        }
        // eslint-disable-next-line
    }, []);

    return (<ViewMain
        detalleStockPersonal={controller.detalleStockPersonal}
        codigoLlave={params.codigoLlave || ''}
    />);
};

export default ManagerEntity;