import { EntityLoadData } from "../Domain/EntityLoadData";
import { RepositoryMain } from "../Domain/RepositoryMain";
import { DtoResponsePersonal } from "../../../../../app/Domain/DtoResponsePersonal";
import { EntityDataUsuario } from "../../../../shared/Domain/EntityDataUsuario";
import { DtoResponseEstadoInterno } from "../../../../../app/Domain/DtoResponseEstadoInterno";
import { DtoResponseCuadrillas } from "../../../../../app/Domain/DtoResponseCuadrillas";

export class UseCaseLoadData {
    private repository: RepositoryMain;
    private user: EntityDataUsuario;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
        this.user = {} as EntityDataUsuario;
    }

    public async exec(user: EntityDataUsuario): Promise<EntityLoadData> {
        this.user = user;
        const [Personal, EstadoInterno, Cuadrillas] = await Promise.all([
            this.getPersonal(),
            this.getEstadoInterno(),
            this.getCuadrillas(),
        ]);
        return (
            {
                Personal,
                EstadoInterno,
                Cuadrillas,
            }
        )
    }

    private async getPersonal(): Promise<DtoResponsePersonal[]> {
        const Personal: DtoResponsePersonal[] = await this.repository.getPersonal();
        return Personal.filter((personal) =>
            personal.Pais.Key === this.user.Pais.Codigo &&
            personal.DatosTrabajo.Delegacion.Key === this.user.Delegacion.Codigo
        )
    }

    private async getEstadoInterno(): Promise<DtoResponseEstadoInterno[]> {
        const EstadoInterno: DtoResponseEstadoInterno[] = await this.repository.getEstadoInterno();
        return EstadoInterno.filter((elemento) => this.user.Pais.Codigo === elemento.Pais.Codigo && elemento.Tipo.trim().toLocaleUpperCase() === 'Parte Diario'.trim().toLocaleUpperCase());
    }

    private async getCuadrillas(): Promise<DtoResponseCuadrillas[]> {
        const Cuadrillas: DtoResponseCuadrillas[] = await this.repository.getCuadrillas();
        return Cuadrillas.filter((elemento) => elemento);
    }
}