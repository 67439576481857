import { DtoItems } from '../../../../../../../app/Domain/DtoItems'
import { DtoPrecioMaterial } from '../../../../../../../app/Domain/DtoPrecioMaterial'
import { DtoItem } from '../../../../../Home/Domain/DtoItem'

export function GenerarDtoItem(item: DtoItems, precioMaterial: DtoPrecioMaterial, cantidad: number) {
  const newAsignacion: DtoItem = {
    ID_Item: item.ID_Item,
    Empresa: { Codigo: item.Empresa.CodigoGlobal, RazonSocial: item.Empresa.RazonSocial },
    Codigo: item.Codigo,
    Nombre: item.Nombre,
    Descripcion: item.Descripcion,
    Variante: { Descripcion: '', ID_Variante: 0 },
    UnidadMedida: item.UnidadMedida.length
      ? item.UnidadMedida[0]
      : { ID_UnidadMedida: 0, Nombre: '' },
    Ind_SB: item.Ind_SB,
    Lote: '',
    Cantidad: cantidad,
    CantidadDespacho: 0,
    CantidadUsados: 0,
    CantidadDevolucion: 0,
    CantidadAprobada: 0,
    POS: 0,
    Valor: item.Valor,
    WBE: { CodigoWBE: '', DescripcionWBE: 'PWA', ID_WBE: 0 },
    Total: cantidad * item.Valor,
    Moneda: precioMaterial.Moneda,
    Simbolo: precioMaterial.Simbolo,
    Decimales: precioMaterial.Decimales,
    Cantidades: [],
    DatosCatalogoItem: {
      DatosItem: {
        Ind_SB: item.Ind_SB,
        Propio: item.Propio,
        Tipo: item.Tipo,
        Valor: item.Valor
      },
      DatosPrecioMaterial: {
        Decimales: precioMaterial.Decimales,
        Moneda: precioMaterial.Moneda,
        Precio: precioMaterial.Precio,
        PreciosVigentes: precioMaterial.PreciosVigentes,
        Simbolo: precioMaterial.Simbolo,
        Vigencia: precioMaterial.Vigencia
      }
    }
  }
  return newAsignacion
}