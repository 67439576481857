import { DtoTrabajos } from '../../../../Master/Home/Domain/DtoTrabajos';
import { ElementBreadCrumb } from '../../../../shared/Components/ElementBreadCrumb';
import { ElementItemCardGenerico } from '../../../../shared/Components/Programados/ElementItemCardGenerico';
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate';
import { AdapterConfigure } from '../Infraestructure/AdapterConfigure';
import './Style.scss';

interface PropsViewMain {
  trabajo: DtoTrabajos;
};

export const ViewMain = (props: PropsViewMain) => {
  const { trabajo } = props;
  const languageTranslate = LanguageTranslate();

  return (
    <>
      <div className='ViewProfile'>
        <div className='container-fluid'>
          {/** BLOQUE DE BREADCRUMB */}
          <ElementBreadCrumb
            list={[
              {
                text: languageTranslate.moduloMain.textoProgramados,
                navigate: true,
                path: `${AdapterConfigure.ROUTE_PROGRAMADOS}`
              },
              {
                text: languageTranslate.menu['002'].titulo,
                navigate: true,
                path: `${AdapterConfigure.ROUTE_TRABAJOS}`,
              },
              {
                text: trabajo?.ID_Trabajo?.toString() || "",
                navigate: false,
                path: "",
              },
            ]}
          />
          <div className='row mb-2'>
            <div className='d-flex justify-content-between align-items-center col-12'>
              <h6 style={{ fontWeight: 'bold' }}>{languageTranslate.general.PartesDiarios.toLocaleUpperCase()}:</h6>
              <h6>{Object.keys(trabajo).length && trabajo.ColeccionObras[0].OrdenTrabajo}</h6>
            </div>
          </div>
          {
            trabajo?.PartesDiarios?.map((row, index) => (
              <ElementItemCardGenerico
                key={index}
                icono
                linkIcono1={`${AdapterConfigure.ROUTE_PARTEDIARIO_TRABAJO}/${trabajo.ID_Trabajo}/${row.Codigo}`}
                data1={new Date(row.Fecha).toLocaleString()}
                data2={
                  <span style={{ color: row.UltimoEstadoInterno.Color, fontWeight: 'bold' }}>
                    {row.UltimoEstadoInterno.Descripcion}
                  </span>
                }
                text1={
                  <span style={{ fontSize: '15px', paddingLeft: '0px', color: 'white', fontWeight: 'bold' }} >
                    {languageTranslate.general.Fecha}
                  </span>
                }
                text2={
                  <span style={{ fontSize: '15px', paddingLeft: '0px', color: 'white', fontWeight: 'bold' }} >
                    {languageTranslate.general.Estado}
                  </span>
                }
              />
            ))
          }
        </div>
      </div >
    </>
  )
};
