import { RepositoryMain } from '../Domain/RepositoryMain'
import { EntityDataUsuario } from '../../../shared/Domain/EntityDataUsuario'
import { DtoResponseSelectStockPersonal } from '../../../Master/Home/Domain/DtoResponseSelectStockPersonal'
import { DtoRequestSelectStockPersonal } from '../../../Master/Home/Domain/DtoRequestSelectStockPersonal'

export class UseCaseSelectStockPersonal {
  private repository: RepositoryMain

  constructor(_repository: RepositoryMain) {
    this.repository = _repository
  }

  public async exec(user: EntityDataUsuario): Promise<Array<DtoResponseSelectStockPersonal>> {
    return await this._exec(user)
  }

  private async _exec(user: EntityDataUsuario): Promise<Array<DtoResponseSelectStockPersonal>> {
    let params: DtoRequestSelectStockPersonal = {
      datos: {
        Codigo: user.Delegacion.Codigo,
        Nombre: user.Pais.Nombre,
      },
      Filtros: [
        {
          $match: {
            // FlagPex: 1,
            'Personal.Identificacion': user.Identificacion,
            'Estado.ID_Estado': 2,
            'TipoStock.Nombre': 'NUEVO'
          },
        },
        {
          $project: {
            _id: 0,
            ID_StockPersonal: 1,
            Item: 1,
            Lote: 1,
            Despacho: 1,
            Devolucion: 1,
            Liquidacion: 1,
            DespachoTR: 1,
            DevolucionTR: 1,
            OrdenTrabajo: 1,
            TipoStock: 1,
            CodigoLlave: 1,
            ID_Trabajo: 1,
            FlagPex: 1,
            Empresa: 1,
            Bobina: 1,
            Precio: 1,
            Tipo: 1,
            Estado: 1,
            Reserva: 1,
          },
        }
      ],
    }
    return await this.repository.selectStockPersonal(params, user)
  }
}