import { RepositoryMain } from '../Domain/RepositoryMain'
import { RepositoryImplGenerico } from '../../../../shared/Infraestructure/RepositoryImplGenerico'
import { DtoReqSelectObras } from '../Domain/DtoReqSelectObras';
import { DtoGoms, DtoObras } from '../Domain/DtoObras';
import { AdapterConfigure } from './AdapterConfigure';
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico';
import { DtoReqSelectObra } from '../Domain/DtoReqSelectObra';
import { FTPService } from '../../../../shared/Infraestructure/AdapterFileSystem';
import { DtoRequestUpdateGom } from '../Domain/DtoRequestUpdateGom';
import { FileReady } from '../../../../shared/Domain/FileReady';
import { DtoPrecioEspecialidad } from '../../../../../app/Domain/DtoPrecioEspecialidad';
import { DtoResponseEstadoInterno } from '../../../../../app/Domain/DtoResponseEstadoInterno';

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

    public async selectObras(params: DtoReqSelectObras): Promise<DtoObras[]> {

        // params.Contrasenia = AdapterEncrypt.encrypt(params.Contrasenia, this.websocket.key, false);

        const url: string = `${this.urlBase}${AdapterConfigure.SELECT_OBRAS}`

        let response: Array<DtoObras> = await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'text', {})
        return typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response
    }

    public async selectObra(params: DtoReqSelectObra): Promise<DtoObras[]> {

        // params.Contrasenia = AdapterEncrypt.encrypt(params.Contrasenia, this.websocket.key, false);

        const url: string = `${this.urlBase}${AdapterConfigure.SELECT_OBRAS}`

        let response: Array<DtoObras> = await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'text', {})
        return typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response
    }

    public async updateGom(params: DtoRequestUpdateGom): Promise<DtoGoms[]> {
        // params.Contrasenia = AdapterEncrypt.encrypt(params.Contrasenia, this.websocket.key, false)

        const url: string = `${this.urlBase}${AdapterConfigure.UPDATE_GOMS}`

        const response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'json', {})
        return typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response
    }

    public async uploadFiles(params: File[]): Promise<any> {
        const uploadPromises = params.map(file => FTPService.setFileToServer(file.name, file))
        await Promise.all(uploadPromises)
    }

    public async downloadFiles(fileNames: string[]): Promise<File[]> {

        const downloadPromises: Promise<File | null>[] = fileNames.map(async (fileName) => {
            const response: FileReady | string = await FTPService.getFileToServer(fileName, 2)
            if (typeof response !== 'string' && typeof response.Archivo === 'string') {
                return AdapterGenerico.base64ToFile(response.Archivo, response.FileName)
            }
            return null
        })

        const downloadedFiles: (File | null)[] = await Promise.all(downloadPromises)

        return downloadedFiles.filter((file) => file !== null) as File[]
    }

    public async selectItems(): Promise<any[]> {
        return await this.dbLocal.selectAllStore('Items')
    }

    public async selectPrecioMaterial(): Promise<any[]> {
        return await this.dbLocal.selectAllStore('PrecioMaterial')
    }

    public async selectManoObraGlobal(): Promise<any[]> {
        return await this.dbLocal.selectAllStore('ManoObraGlobal')
    }

    public async selectPrecioEspecialidad(): Promise<DtoPrecioEspecialidad[]> {
        return await this.dbLocal.selectAllStore('PrecioEspecialidad')
    }

    public async selectEstadoInterno(): Promise<DtoResponseEstadoInterno[]> {
        return await this.dbLocal.selectAllStore('EstadoInterno')
    }
}