import * as React from 'react'
import { Controller } from './Infraestructure/Controller'
import { ViewMain } from './Components/View'

const ManagerEntity = () => {
  const { view, formSearch, obras, init, selectObra, setReservaEditados, reservaEditados, manoObraEditados, dataItems,
    setManoObraEditados, documentacionReplanteo, setDocumentacionReplanteo, datosManoObra, datosMateriales, newFilesUpload,
    setNewFilesUpload, onChange, onSave, dataManoObraGlobal, dataEspecialidad, selectManoObraGlobal, setModal, showModal,
    onSendFirm, cargoSelect, firmas, UsuarioEnSesion, setUsuario, usuario, disabledInputCkech } = Controller()

  React.useEffect(() => {
    init()
    return () => { }
    // eslint-disable-next-line
  }, [])

  return (
    <ViewMain
      view={view}
      formSearch={formSearch}
      obras={obras}
      selectObra={selectObra}
      reservaEditados={reservaEditados}
      setReservaEditados={setReservaEditados}
      manoObraEditados={manoObraEditados}
      setManoObraEditados={setManoObraEditados}
      documentacionReplanteo={documentacionReplanteo}
      setDocumentacionReplanteo={setDocumentacionReplanteo}
      datosManoObra={datosManoObra}
      datosMateriales={datosMateriales}
      newFilesUpload={newFilesUpload}
      setNewFilesUpload={setNewFilesUpload}
      dataItems={dataItems}
      onChange={onChange}
      onSave={onSave}
      dataManoObraGlobal={dataManoObraGlobal}
      dataEspecialidad={dataEspecialidad}
      selectManoObraGlobal={selectManoObraGlobal}
      showModal={showModal}
      setModal={setModal}
      onSendFirm={onSendFirm}
      cargoSelect={cargoSelect}
      firmas={firmas}
      UsuarioEnSesion={UsuarioEnSesion}
      setUsuario={setUsuario}
      usuario={usuario}
      disabledInputCkech={disabledInputCkech}
    />
  )
}

export default ManagerEntity