import { EntityOTs } from "../Domain/EntityOTs"
import { EntityPersonal } from "../Domain/EntityPersonal";

interface props {
    ot: Array<EntityOTs>;
    personal: Array<EntityPersonal>;
};

export const AdapterData: props = {
    ot: [],
    personal: []
};