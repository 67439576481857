import { Button } from 'react-bootstrap'
import { DtoNodoDoc } from '../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { AccordionAdjuntos } from './AccordionAdjuntos'
import { LanguageTranslate } from '../../Infraestructure/LanguageTranslate'
import { DtoHPListType } from '../../Domain/Dto/DtoHPListType'
import { useState } from 'react'

interface IPropsAdjuntos {
  Nodos: DtoNodoDoc[]
  setNodos: React.Dispatch<React.SetStateAction<DtoNodoDoc[]>>
  setNewFiles: React.Dispatch<React.SetStateAction<File[]>>
  newFiles: File[]
  ruta: string
  onSave?: () => void | Promise<void>
  colorNodos?: boolean
  btnDelete?: boolean
  btnAdjuntar?: boolean
  //#region Solo para Alemania Caso especial
  dataAddress?: DtoHPListType[]
  //#endregion Solo para Alemania Caso especial
}

export const Adjuntos = (props: IPropsAdjuntos) => {

  const { Nodos, newFiles, setNewFiles, setNodos, ruta, onSave, colorNodos, dataAddress = [], btnDelete, btnAdjuntar } = props
  const langTranslate = LanguageTranslate()
  const [save, SetSave] = useState<boolean>(true)

  return (
    <div className='h-100 d-flex flex-column justify-content-between'>
      <AccordionAdjuntos
        Nodos={Nodos}
        NodoPrincipal={Nodos}
        newFiles={newFiles}
        setNewFiles={setNewFiles}
        ruta={ruta}
        setNodos={setNodos}
        colorNodos={colorNodos}
        dataAddress={dataAddress}
        save={save}
        btnDelete={btnDelete}
        btnAdjuntar={btnAdjuntar}
      />
      {
        onSave &&
        <Button
          onClick={async () => {
            await onSave()
            SetSave(prev => !prev)
          }}
          className='mt-3'
        >
          {langTranslate.btnGuardar}
        </Button>
      }
    </div>
  )
}
