import { EntityEstadoInterno } from "../Domain/EntityEstadoInterno";
import { EntityParams } from "../Domain/EntityParams";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseSelectEstadoInterno {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: EntityParams): Promise<Array<EntityEstadoInterno>> {
        return await this._exec(params);
    }

    private async _exec(params: EntityParams): Promise<Array<EntityEstadoInterno>> {
        return await this.repository.selectEstadoInterno(params);
    }
}