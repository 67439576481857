import { AdapterConfigure } from './AdapterConfigure';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { addLoading, hideIconMenu, removeLoading} from '../../../shared/Infraestructure/SliceGenerico';
import { useState } from 'react';
import { DtoResponseSelectStockPersonal } from '../Domain/DtoResponseSelectStockPersonal';
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { ErrorCostume } from '../../../shared/Domain/ErrorCostume';
import { NavigateFunction, useNavigate , useParams } from "react-router-dom";

export const Controller = () => {
    const [detalleStockPersonal, setDetalleStockPersonal] = useState<DtoResponseSelectStockPersonal>({} as DtoResponseSelectStockPersonal);
    const dispatch: Dispatch = useDispatch();
    const navigate: NavigateFunction = useNavigate();
    const params = useParams();
    const { generico: { dataStockPersonalTotal } } = useSelector((state: RootState) => state);

    const init = () => {
        try {
            dispatch(hideIconMenu());
            loadData();
        } catch (error) {
            console.error(error);
            let err: ErrorCostume = new ErrorCostume((error as Error).message);
            AdapterGenerico.createMessage('Alerta', err.message, 'warning', false);
            navigate(AdapterConfigure.REDIRECT_LOGISTICA, { replace: true });
        } finally {
            dispatch(removeLoading());
        }
    };

    const loadData = () => {
            dispatch(addLoading({ textLoading: 'Cargando Detallle Stock Personal Total...' }));
            const respDetalleStockPersonalTotal: DtoResponseSelectStockPersonal[] = dataStockPersonalTotal.filter((p: DtoResponseSelectStockPersonal) => p.CodigoLlave === params.codigoLlave)
            dispatch(removeLoading());
            if (respDetalleStockPersonalTotal.length !== 1) throw Error(`No se encontró el detalle con codigoLlave: ${params.codigoLlave}`);
            setDetalleStockPersonal(respDetalleStockPersonalTotal[0]);
    };

    return {
        init,
        detalleStockPersonal,
    };
}