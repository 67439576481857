const SCHEMA: string = process.env.REACT_APP_MAESTRO_SEDE_SCHEMA || ''
const ENTITY: string = process.env.REACT_APP_MAESTRO_SEDE_ENTITY || ''
const ROUTE_PROGRAMADOS: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS}`
const ROUTE_OBRAS: string = `${ROUTE_PROGRAMADOS}/${process.env.REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_OBRAS}`;
const ROUTE_REPLANTEO: string = `${ROUTE_OBRAS}/${process.env.REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_REPLANTEO}`;
const SELECT_OBRAS: string = '/SigoPwa/Programados/Gestion/Obras/Select'
const UPDATE_GOMS: string = '/SigoPwa/Programados/Gestion/Goms/UpdateGomsReplanteo'

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    ROUTE_PROGRAMADOS,
    ROUTE_REPLANTEO,
    SELECT_OBRAS,
    UPDATE_GOMS
}