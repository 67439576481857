import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
  Plugin,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

type DoughnutChartProps = {
  labels: string[];
  dataGroups: number[];
};
export const DoughnutChart: React.FC<DoughnutChartProps> = ({
  labels,
  dataGroups,
}) => {
  const data: ChartData<"doughnut", number[], string> = {
    labels,
    datasets: [
      {
        data: dataGroups,
        backgroundColor: ["#ff0000", "#00a44b", "#00b0f0"],
        borderColor: ["#ffffff", "#ffffff", "#ffffff"],
        borderWidth: 3,
      },
    ],
  };

  const options: ChartOptions<"doughnut"> = {
    plugins: {
      datalabels: {
        formatter(value, context): string {
          if (!context.chart.data.labels) return "";
          // return `${context.chart.data.labels[context.dataIndex]}: ${value}%`;
          return value;
        },
        color: "#000",
        font: {
          weight: 700,
          size: 20,
        },
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        position: 'top',
        labels: {
          boxWidth: 13,
          font: {
            size: 20,
          }
          //maxWidth: 50,
        },
      },
    },
  };
  const plugins: Plugin<"doughnut"> = {
    id: "TEXTO",
    afterDatasetsDraw(chart) {
      const chartInstance = chart;
      const ctx = chartInstance.ctx;
      const centerX =
        (chartInstance.chartArea.left + chartInstance.chartArea.right) / 2;
      const centerY =
        (chartInstance.chartArea.top + chartInstance.chartArea.bottom) / 2;
      ctx.save();
      ctx.font = "bold 20px Arial";
      ctx.fillStyle = "#333333";
      ctx.textAlign = "center";
      const total = dataGroups.reduce((p, n) => p + n, 0);
      ctx.fillText(`TOTAL: ${total}`, centerX, centerY);
    },
  };
  return <Doughnut data={data} plugins={[plugins]} options={options} />;
};
