import { AdapterConfigure } from './AdapterConfigure'
import { RepositoryImplMain } from './RepositoryImplMain'
import { RootState } from '../../../shared/Infraestructure/AdapterStore'
import { useSelector, useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { addLoading, hideIconMenu, removeLoading } from '../../../shared/Infraestructure/SliceGenerico'
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { UseCaseSelectStockAlmacenLocal } from '../Application/UseCaseSelectStockAlmacenLocal'
import { setData } from './slice'
import { UseCaseSelectStockAlmacen } from '../Application/UseCaseSelectStockAlmacen'
import { UseCaseLoadData } from '../Application/UseCaseLoadData'
import { useState } from 'react'
import { DtoConsumoMaterial } from '../../../Master/Home/Domain/DtoConsumoMaterial'

export const Controller = () => {

  const dispatch: Dispatch = useDispatch()
  const navigate: NavigateFunction = useNavigate()
  const { websocket, dbLocal } = useSelector((state: RootState) => state.generico)
  const { user } = useSelector((state: RootState) => state.auth)
  const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY)
  const { ROUTE_LOGISTICA } = AdapterConfigure
  const [dataConsumoMaterial, setDataConsumoMaterial] = useState<DtoConsumoMaterial[]>([])

  const init = async () => {
    try {
      dispatch(hideIconMenu())
      await loadData()
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error').then(() => navigate(ROUTE_LOGISTICA, { replace: true }))
    } finally {
      dispatch(removeLoading())
    }
  }

  const loadData = async () => {
    dispatch(addLoading({ textLoading: 'Cargando datos...' }))

    const {
      dataConsumoMaterial
    } = await new UseCaseLoadData(repository).exec(user)
    setDataConsumoMaterial(dataConsumoMaterial)

    const respStockPersonal = await new UseCaseSelectStockAlmacenLocal(repository).exec(user)
    dispatch(setData(respStockPersonal))
  }

  const selectStockAlmacenDB = async () => {
    try {
      dispatch(addLoading({ textLoading: 'Cargando Stock Almacén...' }))
      await new UseCaseSelectStockAlmacen(repository).exec(user)
      await loadData()
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error').then(() => navigate(ROUTE_LOGISTICA))
    } finally {
      dispatch(removeLoading())
    }
  }

  return {
    init,
    selectStockAlmacenDB,
    dataConsumoMaterial
  }
}
