import React from 'react'
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import { DtoParteDiario } from '../../Trabajos/Domain/DtoParteDiario'
import './PdfGenerator.scss'
import { DtoTrabajos } from '../../../../Master/Home/Domain/DtoTrabajos'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import ButtonFloating from '../../../../shared/Components/ButtonFloating/ButtonFloating'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { AdapterConfigure } from '../Infraestructure/AdapterConfigure'

declare module "jspdf" {
  interface jsPDF {
    autoTable: any
  }
}

interface IProps {
  data: DtoParteDiario,
  right?: number,
  formRegistro: {
    values: any
    touched: any
    errors: any
    handleBlur: Function
    setFieldValue: Function
  }
  trabajo: DtoTrabajos
}

const PdfGenerator: React.FC<IProps> = ({ data, right, formRegistro, trabajo }) => {
  const navigate: NavigateFunction = useNavigate()
  const downloadPDF = async () => {
    try {
      if (!Object.keys(data).length) throw Error('Sin datos')
      AdapterGenerico.createToast('Generando documento', 'info')

      const doc = new jsPDF({ format: 'a4' })

      const pageWidth = doc.internal.pageSize.getWidth()

      const size = 3
      const pos = Array.from({ length: Math.round(pageWidth / size) }, (_, index) => (index + 1) * size)

      const text = 'PARTE DIARIO'
      const textWidth = doc.getStringUnitWidth(text) * 12
      doc.setFontSize(12)
      doc.text(text, (pageWidth - textWidth) - (textWidth / 2), pos[8])

      doc.text('Fecha:', pos[4], pos[10])
      doc.text(new Date(data.Fecha).toLocaleString(), pos[15], pos[10])

      doc.text('Estado:', pos[4], pos[12])
      doc.setTextColor(data.UltimoEstadoInterno.Color)
      doc.text(data.UltimoEstadoInterno.Descripcion, pos[15], pos[12])
      doc.setTextColor(0, 0, 0)

      data.Lider.Cargo = 'LIDER'
      const bodyPersonal = [Object.values(data.Lider), ...data.Personal.map((item) => Object.values(item))]

      const headerPersonal = [
        [{ content: 'Personal', colSpan: 4, styles: { halign: 'center', fillColor: [22, 160, 133] } }],
        ['Identificación', 'Apellidos', 'Nombres', 'Cargo']
      ]
      doc.autoTable({
        head: headerPersonal,
        body: bodyPersonal.length ? bodyPersonal : [['Sin Personal']],
        startY: pos[14],
      })

      let currentY = doc.autoTable.previous.finalY + (size * 3)

      for await (const el of formRegistro.values.imagenes) {
        const imageUrl = URL.createObjectURL(el.File)

        const image = new Image()
        image.src = imageUrl

        await new Promise((resolve) => {
          image.onload = () => {
            const imageWidth = 100 // Ancho de la imagen en el PDF
            const imageHeight = imageWidth * (image.height / image.width)

            const remainingHeight = doc.internal.pageSize.getHeight() - currentY
            if (imageHeight > remainingHeight) {
              doc.addPage()
              currentY = pos[8]
            }

            const img = data.Fotos_SST.find(ftsst => ftsst.Foto.img === el.File.name)
            doc.text(img ? `${img.Titulo} (${img.Descripcion})` : 'Sin Titulo', (pageWidth - imageWidth) - (imageWidth / 2), currentY)
            currentY += doc.getFontSize() / 3
            doc.addImage(image, 'JPEG', (pageWidth - imageWidth) - (imageWidth / 2), currentY, imageWidth, imageHeight)

            currentY += imageHeight + 10

            URL.revokeObjectURL(imageUrl)

            resolve('')
          }
        })
      }

      const valorizaciones = trabajo.Ultima_PreLiquidacion.Valorizacion.filter(e => data.Valorizaciones.some(el => el === e.ID_Valorizacion))

      const bodyValorizaciones = [...valorizaciones].map((item) => {
        return [
          item.DetalleChile.ManoObra.Codigo,
          item.DetalleChile.ManoObra.Nombre,
          item.Cantidad,
          item.Ultimo_Estado_Interno.Descripcion,
        ]
      })

      const headerValorizaciones = [
        [{ content: 'Valorizaciones', colSpan: 4, styles: { halign: 'center', fillColor: [22, 160, 133] } }],
        ['Cód. MO', 'Descripción MO', 'Cantidad', 'Estado']
      ]

      let estimatedRowHeight = 13
      let estimatedTableHeight = (bodyValorizaciones.length + 2) * estimatedRowHeight
      let remainingHeight = doc.internal.pageSize.getHeight() - currentY
      if (estimatedTableHeight > remainingHeight) {
        doc.addPage()
        currentY = pos[8]
      }

      doc.autoTable({
        head: headerValorizaciones,
        body: bodyValorizaciones.length ? bodyValorizaciones : [['Sin Valorizaciones']],
        startY: currentY
      })

      currentY = doc.autoTable.previous.finalY

      const bodyMateriales = [...valorizaciones].map((item) => item.MaterialesUtilizados.map(e => ({ Codigo: e.Codigo, Descripcion: e.Descripcion, Cantidad: e.Cantidad })).map(mu => Object.values(mu).flat()).flat()).filter(e => e.length)
      estimatedTableHeight = (bodyValorizaciones.length + 2) * estimatedRowHeight

      remainingHeight = doc.internal.pageSize.getHeight() - currentY

      if (estimatedTableHeight > remainingHeight) {
        doc.addPage()
        currentY = pos[8]
      } else {
        currentY += size * 2
      }
      const headerMateriales = [
        [{ content: 'Materiales', colSpan: 3, styles: { halign: 'center', fillColor: [22, 160, 133] } }],
        ['Código', 'Descripción', 'Cantidad']
      ]
      doc.autoTable({
        head: headerMateriales,
        body: bodyMateriales.length ? bodyMateriales : [['Sin Materiales']],
        startY: currentY
      })

      const fecha = new Date(data.Fecha)
      doc.save(`ParteDiario_${fecha.getDate()}-${fecha.getMonth() + 1}-${fecha.getFullYear()}_${trabajo.ColeccionObras[0].OrdenTrabajo}.pdf`)
    } catch (error) {
      console.error(error)
      AdapterGenerico.createToast((error as Error).message, 'error')
    }
  }

  return (
    <div className='PdfGeneratorPartesDiarios'>
      <ButtonFloating
        onClick={() => navigate(`${AdapterConfigure.ROUTE_ASIGNACION_TRABAJO}/${trabajo.ID_Trabajo}/${data.Codigo}`)}
        icono='fa-solid fa-square-plus'
        right={130}
        backgroundColor='#2C3E50'
        colorIcono='#08F7FE'
      />
      <div className='position-fixed buttonFloating' style={{ right: right ? right : 16 }}>
        <i className={'fa-solid fa-file-pdf'} onClick={downloadPDF}></i>
      </div>
    </div>
  )
}

export default PdfGenerator