import { DtoEstado } from "../Dto/DtoEstado";
import { DtoUsuarioEdicion } from "../Dto/DtoUsuarioEdicion";
import { DtoAnexo } from '../Dto/DtoAnexo';
import { DtoPais } from '../Dto/DtoPais';
import { DtoBitacora } from "../Dto/DtoBitacora";
export class EntityEstadoInterno {
    ID_EstadoInterno: number = 0;
    Estado: DtoEstado = new DtoEstado();
    Fecha: Date = new Date();
    Descripcion: string = '';
    Icono: string = '';
    Color: string = '';
    Orden: number = 0;
    Sistema: string = '';
    ColorFondo: string = '';
    Tipo: string = '';
    Observacion: string = '';
    UsuarioEdicion: DtoUsuarioEdicion = new DtoUsuarioEdicion();
    Anexos: DtoAnexo[] = [];
    Pais: DtoPais = new DtoPais();
    Bitacora: DtoBitacora[] = [];
}