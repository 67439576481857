import { AdapterConfigure } from './AdapterConfigure';
import { RepositoryImplMain } from './RepositoryImplMain';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { changeSaludo, hideIconMenu, removeLoading } from '../../../shared/Infraestructure/SliceGenerico';
import { AdapterStorage } from '../../../shared/Infraestructure/AdapterStorage';
import { useState } from 'react';
import { EntityInformation } from '../Domain/EntityInformation';
import { UseCaseSelectPais } from '../Application/UseCaseSelectPais';
import { UseCaseSelectDelegacion } from '../Application/UseCaseSelectDelegacion';
import { UseCaseSelectOTs } from '../Application/UseCaseSelectOTs';
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { changePreference } from '../../../shared/Infraestructure/SliceAuthentication';
import { AdapterValidator } from '../../../shared/Infraestructure/AdapterValidator';
import { UseCaseChangePassword } from '../Application/UseCaseChangePassword';
import { UseCaseSelectPersonal } from '../Application/UseCaseSelectPersonal';

export const Controller = () => {
    const { auth, user, preferencia, permisoVariables } = useSelector((state: RootState) => state.auth);
    const { websocket, dbLocal } = useSelector((state: RootState) => state.generico);
    const dispatch: Dispatch = useDispatch();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    const [showPreference, setShowPreference] = useState<boolean>(true);
    const [showLanguage, setShowLanguage] = useState<boolean>(true);
    const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
    const [information, setInformation] = useState<EntityInformation>({ pais: [], delegacion: [], ot: [], personal: [] });

    const { language } = AdapterStorage.get('language');

    const formPreferencia = useFormik({
        initialValues: {
            pais: preferencia.pais,
            delegacion: preferencia.delegacion,
            ot: preferencia.ot,
            personal: preferencia.personal,
            buscarCuadrilla: preferencia.buscarCuadrilla
        } as any,
        validationSchema: Yup.object({
            pais: Yup.object().required('Seleccione un País'),
            delegacion: Yup.object({}).required('Seleccione una Delegación'),
            ot: Yup.object().required('Seleccione una OT'),
            personal: Yup.array().required('Seleccione un peersonal de Medios Humanos').nullable()
        }),

        onSubmit: (values, formikHelpers) => { },
    });

    const formChangePassword = useFormik({
        initialValues: {
            password: '',
            newPassword: '',
            reNewPassword: '',
        } as any,
        validationSchema: Yup.object({
            password: Yup.string().required('Ingresar Contraseña'),
            newPassword: Yup.string().required('Ingresar Nueva Contraseña').min(6, 'La contraseña debe tener entre 6 y 16 caracteres')
                .matches(/^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040-\u002e])(?=.*[A-Z])(?=.*[a-z])\S{6,16}$/,
                    'La contraseña debe tener al menos un dígito, al menos una minúscula, al menos una mayúscula y al menos un caracter no alfanumérico.'),
            reNewPassword: Yup.string().required('Ingresar Repetir Nueva Contraseña'),
        }),

        onSubmit: (values, formikHelpers) => { },
    });


    const init = async () => {
        try {
            dispatch(changeSaludo(false));
            dispatch(hideIconMenu());
            // dispatch(addLoading('Cargando datos...'));
            await loadData();
            // dispatch(removeLoading())
        } catch (error) {
            dispatch(removeLoading())
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning');
        }
    };

    const loadData = async () => {
        let [pais, delegacion, ot, personal] = await Promise.all([
            new UseCaseSelectPais(repository).exec(),
            new UseCaseSelectDelegacion(repository).exec(),
            new UseCaseSelectOTs(repository).exec(),
            new UseCaseSelectPersonal(repository).exec()
        ]);

        if (user.IdUsuario !== 0) {
            if (permisoVariables.arrIdPaises.length > 0) {
                pais = pais.filter(row => permisoVariables.arrIdPaises.includes(row.IdPais) ? true : false);
            }

            if (permisoVariables.arrIdDelegaciones.length > 0) {
                delegacion = delegacion.filter(row => permisoVariables.arrIdDelegaciones.includes(row.IdDelegacion) ? true : false);
            }

            if (permisoVariables.arrIdOT.length > 0) {
                ot = ot.filter(row => permisoVariables.arrIdOT.includes(row.IdOT) ? true : false);
            }
        }

        setInformation({
            pais: pais.map(row => ({ label: row.Pais, value: row.IdPais, dataComplete: row })),
            delegacion: delegacion.map(row => ({ label: `${row.Codigo} - ${row.Delegacion}`, value: row.Codigo, dataComplete: row })),
            ot: ot.map(row => ({ label: AdapterGenerico.formattingNameOT(row.Codigo, row.OT), value: row.Codigo, dataComplete: row })),
            personal: (AdapterGenerico.formatoPersonalizadoSelect(personal, 'Identificacion', ['Nombres', 'Apellidos']) as [])
        });
    };

    const onChangeLanguage = async (code: string) => {
        AdapterStorage.set('language', code);
        window.location.reload();
    };

    const onChangePreferencia = async (name: string, value: any) => {
        let preference = { ...formPreferencia.values };
        switch (name) {
            case 'ot':
                let delegacion = information.delegacion.find(row => row.value === value.dataComplete.Delegacion.Codigo);
                let pais = information.pais.find(row => row.value === value.dataComplete.Pais.IdPais);
                let personal = preference.personal;

                let isSamePais = preference.pais?.dataComplete.IdPais === pais?.dataComplete.IdPais;
                if (!isSamePais) {
                    personal = [];
                    Object.assign(preference, { personal: [] });
                }

                formPreferencia.setValues({ ...formPreferencia.values, delegacion: delegacion, pais: pais, personal });
                Object.assign(preference, { delegacion: delegacion, pais: pais })
                break;
            case 'personal':
                formPreferencia.setFieldValue(name, value);
                break;
            default:
                break;
        }
        formPreferencia.setFieldValue(name, value);
        Object.assign(preference, { [name]: value });
        dispatch(changePreference({ ...preference }));
    };

    const onChangePassword = async (name: string, value: any) => {
        formChangePassword.setFieldValue(name, value);
    };

    const onSubmitChangePassword = async (e: Event) => {
        try {
            e.preventDefault();
            e.stopPropagation();

            try { await formChangePassword.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formChangePassword.validateForm()); } catch (error) { AdapterGenerico.createMessage('Incompleto', (error as Error).message, 'warning', false); return null; }

            if (formChangePassword.values.newPassword !== formChangePassword.values.reNewPassword) {
                AdapterGenerico.createMessage('Alerta', 'La nueva contraseña no coincide.', 'warning', false); return null;
            }
            await (new UseCaseChangePassword(repository)).exec({ IdUsuario: user.IdUsuario, Contrasenia: formChangePassword.values.password, NuevaContrasenia: formChangePassword.values.newPassword });
            formChangePassword.resetForm();
            AdapterGenerico.createMessage('Exitoso', 'Se realizó el cambio de contraseña correctamente', 'success');
        } catch (error) {
            console.error(error);

            dispatch(removeLoading());
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false);
        }
    }

    return {
        init,
        information,
        auth,
        user,

        showPreference,
        setShowPreference,
        formPreferencia,
        onChangePreferencia,

        showLanguage,
        setShowLanguage,
        language,
        onChangeLanguage,

        showChangePassword,
        setShowChangePassword,
        formChangePassword,
        onChangePassword,
        onSubmitChangePassword,
    };
}