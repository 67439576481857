import { CSSProperties } from 'react';
import './ElementContenedorGenerico.scss';

interface IElementContenedorGenerico {
    title: string;
    children: any;
    style?: CSSProperties  
}

const ElementContenedorGenerico = (props: IElementContenedorGenerico) => {
    return (
        <div className="ElementContenedorGenerico mb-4 shadow rounded">
            <div className="titleTab d-flex align-items-center justify-content-between rounded-top">
                <label className="d-flex h-auto text-muted" style={{fontSize: '18px'}}>{props.title}</label>
            </div>
            <div className="contentTab rounded-bottom" style={props.style}>
            { props.children }
            </div>
        </div>
    )
}

export default ElementContenedorGenerico;