import { FC, useEffect, useState } from 'react'
import * as yup from 'yup'
import { Container } from '../../../../shared/Components/Container/Container'
import { ElementBreadCrumb } from '../../../../shared/Components/ElementBreadCrumb'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import { AdapterConfigure } from '../../shared/infrastructure/AdapterConfigure'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../shared/Infraestructure/AdapterStore'
import { nodos } from '../infrastructure/AdapterConfigure'
import { Button, Col, Row } from 'react-bootstrap'
import { Form, Formik } from 'formik'
import { InputNumber } from '../../../../shared/Components/ReactBootstrap/InputNumber/InputNumber'
import { Adjuntos } from '../../../../shared/Components/Adjuntos/Adjuntos'
import { DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { iFormValues } from '../domain/iFormValues'
import { useDispatch } from 'react-redux'
import { setUpdateAutoInventario, updateAutoInventarioItem } from '../../shared/infrastructure/slice'
import { iAutoInventario } from '../../shared/domain/iAutoInventario'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { DtoResponseSelectStockPersonal } from '../../../../Master/Home/Domain/DtoResponseSelectStockPersonal'
import { Series } from './series'

export const View: FC = () => {

  const langTrans = LanguageTranslate()
  const dispatch = useDispatch()
  const navigate: NavigateFunction = useNavigate()
  const { updateAutoInventario, dataStockPersonal } = useSelector((state: RootState) => state.autoInventario)
  const { autoInventario } = useSelector((state: RootState) => state.generico)
  const { ROUTE_LOGISTICA, ROUTE_AUTO_INVENTARIO } = AdapterConfigure
  const [newFilesUpload, setNewFilesUpload] = useState<File[]>([])
  const [documentacion, setDocumentacion] = useState<DtoNodoDoc[]>([])
  const [stock, setStock] = useState<number>(0)
  const [stockPersonal, setStockPersonal] = useState<DtoResponseSelectStockPersonal>()
  const [showOffCanvas, setShowOffCanvas] = useState<boolean>(false)

  useEffect(() => {
    return () => {
      dispatch(setUpdateAutoInventario(new iAutoInventario()))
    }
  }, [dispatch])

  useEffect(() => {
    if (updateAutoInventario) {
      for (const nodo of nodos) {
        const exist = updateAutoInventario.Files.filter(e => e.CodigoNodo === nodo.Codigo)
        nodo.Files = exist
      }
      setDocumentacion(nodos)
      const stockPersonal = dataStockPersonal.find(e => e.ID_StockPersonal === updateAutoInventario.ID_StockPersonal)
      if (stockPersonal) {
        setStockPersonal(stockPersonal)
        let stock = stockPersonal.Despacho - stockPersonal.Devolucion - stockPersonal.Liquidacion + stockPersonal.DespachoTR - stockPersonal.DevolucionTR
        const precision = 1e-10
        if (Math.abs(stock) < precision) {
          stock = 0
        }
        setStock(Number(stock.toFixed(2)))
      }
    }
  }, [updateAutoInventario, dataStockPersonal])


  if (!updateAutoInventario || !updateAutoInventario.Codigo || !stockPersonal) return <p>Cargando ...</p>

  const initialValues: iFormValues = {
    cantidad: updateAutoInventario.Cantidad,
    observacion: updateAutoInventario.Observacion
  }

  const schemaForm = yup.object().shape({
    cantidad: yup
      .number()
      .min(0.1, 'Ingrese una cantidad mayor a 0')
      .required('Campo es requerido'),
    observacion: yup
      .string()
      .test({
        test(value, ctx) {
          if (this.parent.cantidad !== stock && value === updateAutoInventario.Observacion) {
            return ctx.createError({ message: '¡Ingrese su Observación!' })
          }
          if (this.parent.cantidad !== stock && !value) {
            return ctx.createError({ message: 'Campo es requerido' })
          }
          return true
        }
      }),
  })

  return (
    <>
      <Container>
        <ElementBreadCrumb
          list={[
            { text: langTrans.moduloMain.textoLogistica, navigate: true, path: ROUTE_LOGISTICA },
            { text: langTrans.menu['054'].titulo, navigate: true, path: ROUTE_AUTO_INVENTARIO },
            { text: updateAutoInventario.Codigo ?? '', navigate: false, path: '' },
          ]}
        />
        <Formik
          validationSchema={schemaForm}
          initialValues={initialValues}
          onSubmit={async (values, formikHelpers) => {
            try {
              if (values.cantidad !== stock && !(newFilesUpload.length || updateAutoInventario.Files.length)) {
                throw Error(`¡Adjunte un archivo!`)
              }
              updateAutoInventario.Cantidad = values.cantidad
              if (values.cantidad !== stock) {
                updateAutoInventario.Observacion = values.observacion
                updateAutoInventario.Files = documentacion.flatMap(e => e.Files)
                dispatch(updateAutoInventarioItem(updateAutoInventario))
              } else {
                updateAutoInventario.Observacion = 'Confirmado'
                updateAutoInventario.Files = []
              }
              navigate(ROUTE_AUTO_INVENTARIO)
            } catch (error) {
              console.error(error)
              await AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error')
            }
          }}
        >
          {
            ({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
              <Form
                className='h-100 d-flex flex-column justify-content-between'
                noValidate
              >
                <div>
                  <Row>
                    <InputNumber
                      type='number'
                      label={langTrans.general.Cantidad}
                      name='cantidad'
                      errors={errors}
                      touched={touched}
                      values={values}
                      handleChange={handleChange}
                      required
                      readOnly={!!autoInventario}
                    />
                  </Row>
                  <Row>
                    <InputNumber
                      type='text'
                      label={langTrans.general.Observacion}
                      name='observacion'
                      errors={errors}
                      touched={touched}
                      values={values}
                      handleChange={handleChange}
                      required
                      readOnly={!!autoInventario}
                    />
                  </Row>
                  {
                    stockPersonal.Item.Ind_SB === 'S'
                      ? <Row className='mb-2'>
                        <Col>
                          <button
                            className='btn btn-warning'
                            onClick={() => setShowOffCanvas(true)}
                            type='button'
                          >
                            <span
                              className='me-1 fw-bold text-danger'
                            >
                              S
                            </span>
                            <i
                              className="fa-solid fa-barcode text-danger"
                            >
                            </i>
                          </button>
                        </Col>
                      </Row>
                      : null
                  }
                  {
                    values.cantidad !== stock &&
                    <Row className='mb-2'>
                      <Adjuntos
                        Nodos={documentacion}
                        newFiles={newFilesUpload}
                        setNewFiles={setNewFilesUpload}
                        setNodos={setDocumentacion}
                        ruta={`AutoInventario_${updateAutoInventario.ID_AutoInventario}_Anexos_`}
                      />
                    </Row>
                  }
                </div>
                {
                  (!autoInventario && values.cantidad !== initialValues.cantidad) &&
                  <Row>
                    <Col>
                      <Button
                        className='w-100'
                        type='submit'
                      >
                        {langTrans.btnGuardar}
                      </Button>
                    </Col>
                  </Row>
                }
              </Form>
            )
          }
        </Formik>
      </Container>
      <Series
        setShowModal={setShowOffCanvas}
        showModal={showOffCanvas}
      />
    </>
  )
}
