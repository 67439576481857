import { Form, InputGroup } from 'react-bootstrap'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'

interface props {
  cantidadDisponible: number
}

export const ShowAvailable = ({ cantidadDisponible }: props) => {

  const langTranslate = LanguageTranslate()

  return (
    <InputGroup className='mb-2'>
      <InputGroup.Text
        id='basic-addon1'
      >
        {langTranslate.general.Disponible}
      </InputGroup.Text>
      <Form.Control
        placeholder='0'
        aria-label='Username'
        aria-describedby='basic-addon1'
        value={cantidadDisponible.toFixed(2)}
        readOnly
        style={{ textAlign: 'center' }}
      />
    </InputGroup>
  )
}
