import { DtoTrabajos } from '../../../../Master/Home/Domain/DtoTrabajos';
import { ElementBreadCrumb } from '../../../../shared/Components/ElementBreadCrumb';
import { ElementItemCardGenerico } from '../../../../shared/Components/Programados/ElementItemCardGenerico';
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate';
import { AdapterConfigure } from '../Infraestructure/AdapterConfigure';
import './Style.scss';
import { EntityModal } from '../Domain/EntityModal';

interface PropsViewMain {
    trabajo: DtoTrabajos;
    setModalData: React.Dispatch<React.SetStateAction<EntityModal>>
}

export const ViewMain = (props: PropsViewMain) => {
    const { trabajo, setModalData } = props
    const languageTranslate = LanguageTranslate()

    const footerData = trabajo?.Ultima_PreLiquidacion?.Valorizacion.reduce(
        (footerData, val) => {
            const TotalPB = val.Cantidad * val.DetalleChile.PuntoBaremo
            const estadosValidos: { [key: string]: boolean } = {
                'PRELIQUIDADA': true,
                'LIQUIDADA': true,
                'CERTIFICADA': true,
                'PRODUCCION': true
            }
            estadosValidos[val.Ultimo_Estado_Interno.Descripcion] && (footerData.totalPB += TotalPB)
            return footerData
        },
        { totalPB: 0 }
    );

    return (
        <div className='ViewProfile'>
            <div className='container-fluid'>
                {/** BLOQUE DE BREADCRUMB */}
                <ElementBreadCrumb
                    list={[
                        {
                            text: languageTranslate.moduloMain.textoProgramados,
                            navigate: true,
                            path: `${AdapterConfigure.ROUTE_PROGRAMADOS}`,
                        },
                        {
                            text: languageTranslate.menu['002'].titulo,
                            navigate: true,
                            path: `${AdapterConfigure.ROUTE_TRABAJOS}`,
                        },
                        {
                            text: trabajo?.ID_Trabajo?.toString() || '',
                            navigate: false,
                            path: '',
                        },
                    ]}
                />
                <div className='row mb-2'>
                    <div className='d-flex justify-content-center align-items-center col-9'>
                        <h6>{Object.keys(trabajo).length && trabajo.ColeccionObras[0].OrdenTrabajo}</h6>
                    </div>
                    <div className='col-3 d-flex align-items-center'>
                        <table className='table table-striped table-bordered mb-0'>
                            <thead>
                                <tr>
                                    <th style={{ fontSize: '1rem' }} className='align-middle text-center p-0' scope='col'>
                                        {languageTranslate.moduloProgramados.trabajos.moduloDetalle.TotalPB}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ fontSize: '1.5rem' }} className='text-center p-0'>
                                        {footerData?.totalPB.toFixed(2)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {trabajo?.Ultima_PreLiquidacion?.Valorizacion.map((row, index) => {
                    let periodo: string = '';
                    switch (row.Ultimo_Estado_Interno.Descripcion) {
                        case 'CERTIFICADA':
                            periodo = row.Certificacion.UltimoPeriodo.Codigo;
                            break;
                        case 'PRODUCCION':
                            periodo = row.Produccion.UltimoPeriodo.Codigo;
                            break;
                        default:
                            break;
                    }
                    return (
                        <ElementItemCardGenerico
                            key={index}
                            indice={index + 1}
                            data1={
                                <span style={{ fontSize: '1.1rem' }} >
                                    {row.Ultimo_Estado_Interno.Descripcion}
                                </span>
                            }
                            data2={
                                <span style={{ fontSize: '1.1rem' }} >
                                    {new Date(row.Ultimo_Estado_Interno.Fecha).toLocaleString()}
                                </span>
                            }
                            data3={periodo}
                            data4={row.DetalleChile.ManoObra.Codigo}
                            data5={row.DetalleChile.ManoObra.Nombre}
                            data6={row.Cantidad.toFixed(2)}
                            data7={
                                <span style={{ fontSize: '1rem' }}>
                                    {(row.DetalleChile.PuntoBaremo * row.Cantidad).toFixed(2).toString()}
                                </span>
                            }
                            text1={
                                <span
                                    style={{
                                        fontSize: '15px',
                                        paddingLeft: '0px',
                                        color: 'white',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {languageTranslate.moduloProgramados.trabajos.moduloDetalle.card.Estado}
                                </span>
                            }
                            text2={
                                <span
                                    style={{
                                        fontSize: '15px',
                                        paddingLeft: '0px',
                                        color: 'white',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {languageTranslate.general.Fecha}
                                </span>
                            }
                            text3={
                                <span
                                    style={{
                                        fontSize: '15px',
                                        paddingLeft: '0px',
                                        color: 'white',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {languageTranslate.moduloProgramados.trabajos.moduloDetalle.card.Periodo}
                                </span>
                            }
                            text4={
                                <span
                                    style={{
                                        fontSize: '15px',
                                        paddingLeft: '0px',
                                        color: 'white',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {languageTranslate.moduloProgramados.trabajos.moduloDetalle.card.CodigoMO}
                                </span>
                            }
                            text5={
                                <span
                                    style={{
                                        fontSize: '15px',
                                        paddingLeft: '0px',
                                        color: 'white',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {languageTranslate.moduloProgramados.trabajos.moduloDetalle.card.ManoObra}
                                </span>
                            }
                            text6={
                                <span
                                    style={{
                                        fontSize: '15px',
                                        paddingLeft: '0px',
                                        color: 'white',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {languageTranslate.general.Cantidad}
                                </span>
                            }
                            text7={
                                <span
                                    style={{
                                        fontSize: '15px',
                                        paddingLeft: '0px',
                                        color: 'white',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {languageTranslate.moduloProgramados.trabajos.moduloDetalle.TotalPB}
                                </span>
                            }
                            buttons={[
                                <div key={index}></div>
                                ,
                                <button key={`${index}ver`} className='btn gris-oscuro' onClick={() => setModalData((prev) => ({ ...prev, show: true, valorizacion: row }))} style={{ backgroundColor: '#FFA500' }}>
                                    {`${languageTranslate.general.VerArchivos} (${row.Anexos.filter(e => e.Estado.ID_Estado === 1).length})`}
                                    <i className='fa-solid fa-eye' style={{ fontSize: 20, marginLeft: 10 }} />
                                </button>
                            ]}
                        />
                    );
                })}
            </div>
        </div>
    );
};
