import { Children, useEffect, useState } from 'react'
import { Container } from '../../../shared/Components/Container/Container'
import { ElementBreadCrumb } from '../../../shared/Components/ElementBreadCrumb'
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate'
import { useSelector } from 'react-redux'
import { RootState } from '../../../shared/Infraestructure/AdapterStore'
import { Card } from './card'
import ButtonFloating from '../../../shared/Components/ButtonFloating/ButtonFloating'
import { InputSearch } from '../../../shared/Components/ReactBootstrap/InputSearch/InputSearch'
import { DtoMaterialAlmacen } from '../../../Programados/TrabajosDE/Preliquidar/Domain/DtoMaterialAlmacen'
import { DtoConsumoMaterial } from '../../../Master/Home/Domain/DtoConsumoMaterial'

interface PropsViewMain {
    selectStockAlmacenDB: () => Promise<void>
    dataConsumoMaterial: DtoConsumoMaterial[]
}

export const ViewMain = (props: PropsViewMain) => {

    const { data } = useSelector((state: RootState) => state.stockAlmacen)
    const { selectStockAlmacenDB, dataConsumoMaterial } = props
    const languageTranslate = LanguageTranslate()
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [dataStockAlmacenResumen, setDataStockAlmacenResumen] = useState<DtoMaterialAlmacen[]>([])

    // useEffect(() => {
    //     //Sacar resumen de dataStockAlmacen
    //     const dataResumen: DtoMaterialAlmacen[] = []
    //     data.forEach(stock => {
    //         const exist = dataResumen.find(e => e.Item.Codigo === stock.Item.Codigo)
    //         if (exist) {
    //             exist.Despacho += stock.Despacho
    //             exist.Devolucion += stock.Devolucion
    //             exist.Ingreso += stock.Ingreso
    //         } else {
    //             dataResumen.push({
    //                 ColeccionConsumoMaterial: stock.ColeccionConsumoMaterial,
    //                 Despacho: stock.Despacho,
    //                 Devolucion: stock.Devolucion,
    //                 Ingreso: stock.Ingreso,
    //                 Item: {
    //                     Codigo: stock.Item.Codigo,
    //                     Descripcion: stock.Item.Descripcion
    //                 }
    //             })
    //         }
    //     })
    //     setDataStockAlmacenResumen(dataResumen)
    // }, [data])

    return (
        <Container>
            <ElementBreadCrumb
                list={
                    [
                        { text: languageTranslate.moduloMain.textoLogistica, navigate: true, path: '/main/logistica' },
                        { text: languageTranslate.menu['055'].titulo, navigate: false, path: `` }
                    ]
                }
            />
            <InputSearch handleChange={(text) => setSearchTerm(text)} textLength={1} />
            {
                Children.toArray(
                    data
                        .filter(e =>
                            `${e.Item.Codigo} ${e.Item.Descripcion}`
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                        )
                        .map(sa => (
                            <Card item={sa} dataConsumoMaterial={dataConsumoMaterial} />
                        ))
                )
            }
            <ButtonFloating
                onClick={selectStockAlmacenDB}
                icono='fa-solid fa-arrows-rotate'
                colorIcono='#2C3E50'
            />
        </Container>
    )
}