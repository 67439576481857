import { Filtros } from "../../Domain/EntityParams";

export const FiltroPais: Filtros = {
    Filtros: [
        {
            $match: {
                'Estado.ID_Estado': 1
            }
        },
        {
            $project: {
                ID_Pais: 1,
                Codigo: 1,
                Nombre: 1
            }
        }
    ]
}