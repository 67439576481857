import * as React from 'react'
import { Controller } from './Infraestructure/Controller'
import { ViewMain } from './Components/View'

const ManagerEntity = () => {
  const {
    init,
    trabajo,
    setShowOffCanvas,
    showOffCanvas,
    asignaciones,
    valorizaciones,
    onSubmit,
    onRemove,
    setDocumentacionValorizacion,
    documentacionValorizacion,
    newFilesUpload,
    setNewFilesUpload,
    dataDocumentacionBaremoPEX,
    onSave,
    dataManoObraGlobal,
    dataPrecioEspecialidad,
    onSubmitFormAddMO,
    onChange,
    dataMateriales,
    dataMaterialesAlmacen,
    cambiarEstadoHPList
  } = Controller()

  React.useEffect(() => {
    init()
    return () => { }
    // eslint-disable-next-line
  }, [])

  return (
    <ViewMain
      trabajo={trabajo}
      setShowOffCanvas={setShowOffCanvas}
      showOffCanvas={showOffCanvas}
      asignaciones={asignaciones}
      onSubmit={onSubmit}
      valorizaciones={valorizaciones}
      onRemove={onRemove}
      documentacionValorizacion={documentacionValorizacion}
      setDocumentacionValorizacion={setDocumentacionValorizacion}
      newFilesUpload={newFilesUpload}
      setNewFilesUpload={setNewFilesUpload}
      dataDocumentacionBaremoPEX={dataDocumentacionBaremoPEX}
      onSave={onSave}
      dataManoObraGlobal={dataManoObraGlobal}
      dataPrecioEspecialidad={dataPrecioEspecialidad}
      onSubmitFormAddMO={onSubmitFormAddMO}
      onChange={onChange}
      dataMateriales={dataMateriales}
      dataMaterialesAlmacen={dataMaterialesAlmacen}
      cambiarEstadoHPList={cambiarEstadoHPList}
    />
  )
}

export default ManagerEntity