import { EntityLoadData } from '../Domain/EntityLoadData'
import { RepositoryMain } from '../Domain/RepositoryMain'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { DtoItems } from '../../../../../app/Domain/DtoItems'
import { DtoPrecioMaterial } from '../../../../../app/Domain/DtoPrecioMaterial'
import { DtoManoObraGlobal } from '../../../../../app/Domain/DtoManoObraGlobal'
import { DtoPrecioEspecialidad } from '../../../../../app/Domain/DtoPrecioEspecialidad'
import { DtoResponseEstadoInterno } from '../../../../../app/Domain/DtoResponseEstadoInterno'

export class UseCaseLoadData {
    private repository: RepositoryMain

    constructor(_repository: RepositoryMain) {
        this.repository = _repository
    }

    public async exec(user: EntityDataUsuario): Promise<EntityLoadData> {
        const [Items, PrecioMaterial, ManoObraGlobal, PrecioEspecialidad, EstadoInterno] = await Promise.all([
            this.getItems(user),
            this.getPrecioMaterial(user),
            this.getManoObraGlobal(user),
            this.getPrecioEspecialidad(user),
            this.getEstadoInterno(user),
        ])
        return ({
            Items,
            PrecioMaterial,
            ManoObraGlobal,
            PrecioEspecialidad,
            EstadoInterno
        })
    }

    private async getItems(user: EntityDataUsuario): Promise<DtoItems[]> {
        const items = await this.repository.selectItems()
        return items.filter(e => e.Pais.Codigo === user.Pais.Codigo && e.Delegacion.Codigo === user.Delegacion.Codigo)
    }

    private async getPrecioMaterial(user: EntityDataUsuario): Promise<DtoPrecioMaterial[]> {
        const items = await this.repository.selectPrecioMaterial()
        return items.filter(e => e.Pais.Codigo === user.Pais.Codigo && e.Delegacion.Codigo === user.Delegacion.Codigo)
    }

    private async getManoObraGlobal(user: EntityDataUsuario): Promise<DtoManoObraGlobal[]> {
        const items = await this.repository.selectManoObraGlobal()
        return items.filter(e => e.Pais.Codigo === user.Pais.Codigo && e.Delegacion.Codigo === user.Delegacion.Codigo)
    }

    private async getPrecioEspecialidad(user: EntityDataUsuario): Promise<DtoPrecioEspecialidad[]> {
        const items = await this.repository.selectPrecioEspecialidad()
        return items.filter(e => e.Pais.Codigo === user.Pais.Codigo && e.Delegacion.Codigo === user.Delegacion.Codigo)
    }

    private async getEstadoInterno(user: EntityDataUsuario): Promise<DtoResponseEstadoInterno[]> {
        const items = await this.repository.selectEstadoInterno()
        return items.filter(e => e.Pais.Codigo === user.Pais.Codigo && e.Tipo.trim().toUpperCase() === 'GOM')
    }

}