import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ViewMain } from "./Components/View";
import { useParams } from 'react-router-dom';
import ImageViewer from '../../../shared/Components/ImageViewer/ImageViewer';
// import ViewModal from '../../../shared/Components/ViewModal/ViewModal';

const ManagerEntity = () => {
    const params = useParams();
    const controller = Controller();

    React.useEffect(() => {
        controller.init();
        return () => {
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <ViewMain
                Asignaciones={controller.Asignaciones}
                formAddValorizacion={controller.formAddValorizacion}
                keyTab={controller.keyTab}
                onChangeContentForTab={controller.onChangeContentForTab}
                onSave={controller.onSave}
                asignacionesTrabajo={controller.asignacionesTrabajo}
                onChange={controller.onChange}
                ID_Trabajo={params.id || ''}
                onAddValorizacion={controller.onAddValorizacion}
                onRemoveValorizacion={controller.onRemoveValorizacion}
                // modalData={controller.modalData}
                // setModalData={controller.setModalData}
                trabajo={controller.trabajo}
                Items={controller.dataItems}
                dataTipoMaterial={controller.dataTipoMaterial}
                dataStockPersonal={controller.dataStockPersonal}
                setShowImageViewer={controller.setShowImageViewer}
                setFileImageViewer={controller.setFileImageViewer}
            />
             {
                controller.fileImageViewer &&
                <ImageViewer
                    show={controller.showImageViewer}
                    file={controller.fileImageViewer}
                    setShow={controller.setShowImageViewer}
                    setFile={controller.setFileImageViewer}
                />
            }
        </>
    );
};

export default ManagerEntity;