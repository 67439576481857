import { DtoUsuarioEdicion } from "../../../../shared/Domain/Dto/DtoUsuarioEdicion"
import { FirmaReplanteoDto } from "./FirmaReplanteoDto"

export class UltimaFirmaReplanteoDto {
    Tecnico: FirmaReplanteoDto = new FirmaReplanteoDto()
    Supervisor: FirmaReplanteoDto = new FirmaReplanteoDto()
    Lider: FirmaReplanteoDto = new FirmaReplanteoDto()
    Fecha: Date = new Date()
    UsuarioEdicion: DtoUsuarioEdicion = new DtoUsuarioEdicion()
    setValores({
        Tecnico,
        Supervisor,
        Lider,
        Fecha,
        UsuarioEdicion
    }: {
        Tecnico: FirmaReplanteoDto,
        Supervisor: FirmaReplanteoDto,
        Lider: FirmaReplanteoDto,
        Fecha: Date,
        UsuarioEdicion: DtoUsuarioEdicion
    }): void {
        if (!(Tecnico instanceof FirmaReplanteoDto))
            throw new Error('El Tecnico debe ser una instancia de FirmaReplanteoDto.')
        if (!(Supervisor instanceof FirmaReplanteoDto))
            throw new Error('El Supervisor debe ser una instancia de FirmaReplanteoDto.')
        if (!(Lider instanceof FirmaReplanteoDto))
            throw new Error('El Lider debe ser una instancia de FirmaReplanteoDto.')
        if (!(Fecha instanceof Date))
            throw new Error('El Fecha debe ser una instancia de Date.')
        if (!(UsuarioEdicion instanceof DtoUsuarioEdicion))
            throw new Error('El UsuarioEdicion debe ser una instancia de DtoUsuarioEdicion.')
        this.Tecnico = Tecnico
        this.Supervisor = Supervisor
        this.Lider = Lider
        this.Fecha = Fecha
        this.UsuarioEdicion = UsuarioEdicion
    }
}