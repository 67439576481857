import { AdapterGenerico } from "./AdapterGenerico";

export class AdapterGeolocation {
  public static async getLocation(): Promise<{ latitud: number, longitud: number }> {

    return new Promise<{ latitud: number, longitud: number }>((resolve, reject) => {
      const options = {
        enableHighAccuracy: true,
        timeout: 15000, //Se Cambió a 15000 para pruebas Alemania, anterior estaba en 5000
        maximumAge: 0
      }

      const onSuccess: PositionCallback = (position: GeolocationPosition) => {
        resolve({ latitud: position.coords.latitude, longitud: position.coords.longitude })
      }

      const onError: PositionErrorCallback = (e: GeolocationPositionError) => {
        // reject(new Error(`Error ${e.code} - ${e.message}`))
        resolve({ latitud: 0, longitud: 0 }) //Se Cambió para Alemania
      }

      if (navigator.onLine) navigator.geolocation.getCurrentPosition(onSuccess, onError, options)
      else resolve({ latitud: 0, longitud: 0 })
    })
  }

  public static async getAddress(params: { latitud: number, longitud: number }): Promise<string> {
    try {
      let response = await fetch(`${process.env.REACT_APP_GEO_URL}${params.longitud},${params.latitud}.json?access_token=${process.env.REACT_APP_GEO_TOKEN}`);
      let result = await response.json();

      let addres: string = '';
      let neighborhood: string = '';
      let postcode: string = '';
      let locality: string = '';
      let place: string = '';
      let district: string = '';
      let region: string = '';
      let country: string = '';

      for (let row of result.features) {
        if (row.id.includes('address')) {
          addres = row.text;
        } else if (row.id.includes('postcode')) {
          postcode = row.text;
        } else if (row.id.includes('neighborhood')) {
          neighborhood = row.text;
        } else if (row.id.includes('locality')) {
          locality = row.text;
        } else if (row.id.includes('place')) {
          place = row.text;
        } else if (row.id.includes('district')) {
          district = row.text;
        } else if (row.id.includes('region')) {
          region = row.text;
        } else if (row.id.includes('country')) {
          country = row.text;
        }
      }

      let data: string = `${addres}`;
      data += `${!neighborhood.length ? '' : `, ${neighborhood}`}`;
      data += `${!postcode.length ? '' : `, ${postcode}`}`;
      data += `${!locality.length ? '' : `, ${locality}`}`;
      data += `${!place.length ? '' : `, ${place}`}`;
      data += `${!district.length ? '' : `, ${district}`}`;
      data += `${!region.length ? '' : `, ${region}`}`;
      data += `${!country.length ? '' : `, ${country}`}`;

      return data;
    } catch (error) {
      return '';
    }
  }

  public static async getAllGeolocation(): Promise<{ lat: number; lng: number; address: string; }> {
    let result = { lat: 0, lng: 0, address: '' };
    if (!navigator.onLine) return result;

    try {
      let resGeolocation = await this.getLocation();
      let resAddress = await this.getAddress(resGeolocation);
      Object.assign(result, { lat: resGeolocation.latitud, lng: resGeolocation.longitud, address: resAddress });
    } catch (error) {
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false);
    }

    return result;
  }
}