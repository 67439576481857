import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { DtoRequestLogin } from "../Domain/DtoRequestLogin";
import { DtoResponseLogin } from "../Domain/DtoResponseLogin";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { removeLoading } from "../../../shared/Infraestructure/SliceGenerico";
import { EntityMenu } from "../../../shared/Domain/EntityMenu";
import { EntityInformationDataInitial } from "../../../shared/Domain/EntityInformationDataInitial";
import { AdapterConfigure } from "./AdapterConfigure";
import { AdapterData } from "../../../shared/Infraestructure/AdapterData";

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {
  public async login(params: DtoRequestLogin): Promise<DtoResponseLogin | null> {
    return this._login(params);
  }

  private async _login(params: DtoRequestLogin): Promise<DtoResponseLogin | null> {
    if (!window.navigator.onLine) {
      this.dispatch(removeLoading());
      AdapterGenerico.createMessage('Sin Conexión', 'No posee conexión a internet', 'warning');
      return null;
    }
    // let response = await this.websocket.emit<DtoResponseLogin>('Login', params);

    const url: string = `${this.urlBase}${AdapterConfigure.LOGIN}`;
    let response: DtoResponseLogin = await this.service.call<any>('POST', url, JSON.stringify(params), 'basic', 'json', 'json', {});
    response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response;

    let menu: Array<EntityMenu> = [
      //#region Programados
      { id: 1, codigo: '001', icono: 'fa-solid fa-person-digging', orden: 1, permiso: true, ruta: 'main/programados', sigla: 'SIGOAPP_PEX', esOpcion: true, permitirSubOpcion: false, estado: true },
      { id: 2, codigo: '002', icono: 'fa-solid fa-person-digging', orden: 2, permiso: true, ruta: 'main/programados/trabajos', sigla: 'SIGOAPP_PEX', esOpcion: false, permitirSubOpcion: true, estado: true },
      { id: 3, codigo: '003', icono: 'fa-solid fa-sliders', orden: 3, permiso: true, ruta: 'main/programados/obras/replanteo', sigla: 'SIGOAPP_PEX', esOpcion: false, permitirSubOpcion: true, estado: true },
      { id: 4, codigo: '004', icono: 'fa-solid fa-person-digging', orden: 4, permiso: true, ruta: 'main/programados/de_trabajos', sigla: 'SIGOAPP_PEX', esOpcion: false, permitirSubOpcion: true, estado: true },
      //#endregion Programados

      //#region Logistica
      { id: 50, codigo: '050', icono: 'fa-sharp fa-solid fa-cubes-stacked', orden: 50, permiso: true, ruta: 'main/logistica', sigla: 'SIGOAPP_LOGISTICA', esOpcion: true, permitirSubOpcion: false, estado: true },
      { id: 51, codigo: '051', icono: 'fa-sharp fa-solid fa-cubes-stacked', orden: 51, permiso: true, ruta: 'main/logistica/trabajos', sigla: 'SIGOAPP_LOGISTICA', esOpcion: false, permitirSubOpcion: true, estado: true },
      { id: 52, codigo: '052', icono: 'fa-sharp fa-solid fa-signature', orden: 52, permiso: true, ruta: 'main/logistica/firmaAlbaran', sigla: 'SIGOAPP_LOGISTICA', esOpcion: false, permitirSubOpcion: true, estado: true },
      { id: 53, codigo: '053', icono: 'fa-sharp fa-solid fa-boxes-stacked', orden: 53, permiso: true, ruta: 'main/logistica/stockPersonalTotal', sigla: 'SIGOAPP_LOGISTICA', esOpcion: false, permitirSubOpcion: true, estado: true },
      { id: 54, codigo: '054', icono: 'fa-solid fa-check-to-slot', orden: 54, permiso: true, ruta: 'main/logistica/auto-inventario', sigla: 'SIGOAPP_LOGISTICA', esOpcion: false, permitirSubOpcion: true, estado: true },
      { id: 55, codigo: '055', icono: 'fa-solid fa-warehouse', orden: 55, permiso: true, ruta: 'main/logistica/stock-almacen', sigla: 'SIGOAPP_LOGISTICA', esOpcion: false, permitirSubOpcion: true, estado: true },
      //#endregion Logistica
    ];

    for (const m of menu) {
      if (!m.permiso) continue
      m.estado = response.usuario.PermisosApp.map(e => e.Codigo).includes(m.codigo) ? true : false
    }

    // switch (response.Perfil.Code) {
    //     case '01':
    //     case '08':
    //     case '09':
    //         break;
    //     case '02':
    //     case '04':
    //     case '05':
    //         menu = menu.filter(row => ![20, 21].includes(row.id));
    //         break;
    //     case '03':
    //     case '06':
    //     case '07':
    //     case '10':
    //         menu = menu.filter(row => ![20, 21, 30, 40, 41, 60, 61].includes(row.id));
    //         break;
    //     default:
    //         break;
    // }

    switch (response.usuario.Pais.Codigo) {
      case '512':
        menu = menu.filter(row => ![3].includes(row.id))
        break
      default:
        break
    }

    menu = menu.filter(e => e.estado)

    response.permisos = { menu };

    return response;
  }

  async v1InfoUser(params: string): Promise<string> {
    const url: string = `${this.urlBaseAuth}${AdapterConfigure.V1_INFO_USER}`
    return await this.service.call<any>('POST', url, params, 'bearer', 'text', 'json', {})
  }

  public async selectPersonal(params: number[], informationDataInitial: EntityInformationDataInitial): Promise<void> {
    // const body = { Query: JSON.stringify({ Filtros: [{ $match: { EmpresaHistorial: { $elemMatch: { "Pais.IdPais": { [!params.length ? '$nin' : '$in']: !params.length ? [0] : params }, "Estado.IdEstado": 1 } } } }] }) };
    // await this.selectEntityREST('Personal', informationDataInitial, body);
  }

  public async getCuadrillas(): Promise<any[]> {
    return await this.dbLocal.selectAllStore('Cuadrillas')
  }

  private async selectEntityREST(entity: keyof EntityInformationDataInitial, informationDataInitial: EntityInformationDataInitial, body: any) {
    let count = await this.dbLocal.countStore(entity);
    if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
      // let url: string = `${this.urlBase}${AdapterConfigure.PERSONAL}`;

      // // let data: Array<any> = await this.service.call<any>("POST", url, JSON.stringify(body), "basic", "json", 'json', {}, 0);
      // Object.assign(informationDataInitial, { [entity]: { called: true, count: data.length, date: new Date() } });
      // AdapterData[entity.toLocaleLowerCase() as keyof typeof AdapterData] = data;
      // await this.dbLocal.insertDataStore({ nameStore: entity, data });
    } else {
      let info = await this.dbLocal.selectAllStore(entity);
      AdapterData[entity.toLocaleLowerCase() as keyof typeof AdapterData] = info;
    }
  }

}