import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { DtoRequestSelectSalidaAlmacen } from "../Domain/DtoRequestSelectSalidaAlmacen";
import { EntitySalidaAlmacen } from "../../FirmaAlbaran/Domain/EntitySalidaAlmacen";
import { AdapterConfigure } from "./AdapterConfigure";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

    public async selectSalidaAlmacen(params: DtoRequestSelectSalidaAlmacen): Promise<EntitySalidaAlmacen[]> {
        let url: string = `${this.urlBase}${AdapterConfigure.SELECT_SALIDA_ALMACEN}`;
        let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'json', {});
        response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response;
        return response;
    }

    public async enviarFirma(params: any): Promise<any> {
        let { imagen, NroDocumento, Identificacion } = params;
        const base64Data = imagen.split(',')[1];
        await this.websocket.publish('SALA_LOGISTICA', {
            Accion: 'DOCUMENTO_FIRMADO_LOGISTICA',
            imagen: base64Data,
            data: {
                NroDocumento,
                Identificacion,
                Personal: {
                    Firma: 'FIRMA PWA'
                }
            }
        });
    }
}