import { DtoDetalleChileReservaMO } from '../../../../shared/Domain/Dto/DtoDetalleChileReservaMO'
import { DtoUsuarioEdicion } from '../../../../shared/Domain/Dto/DtoUsuarioEdicion'
import { EntityEstadoInterno } from '../../../../shared/Domain/Entity/EntityEstadoInterno'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { DtoAsignacionValorizacion, DtoFormAddValorizacion } from '../Domain/DtoAsignacionValorizacion'
import { DtoFotos } from '../../../../shared/Domain/Dto/DtoFotos'
import { DtoMaterialesUtiRet } from '../Domain/DtoMaterialesUtiRet'
import { DtoRequestUpdateTrabajo } from '../Domain/DtoRequestUpdateTrabajo'
import { DtoValorizacion } from '../Domain/DtoValorizacion'
import { RepositoryMain } from '../Domain/RepositoryMain'
import { DtoTrabajos } from '../../../../Master/Home/Domain/DtoTrabajos'
import { DtoCertificacion } from '../Domain/DtoCertificacion'
import { DtoContrata } from '../Domain/DtoContrata'
import { EntityGestionDocumental } from '../../../../shared/Domain/Entity/EntityGestionDocumental'
import { DtoProduccion } from '../Domain/DtoProduccion'
import { DtoAmap } from '../../../Home/Domain/DtoAmap'
import { DtoFilesDoc } from '../../Trabajos/Domain/DtoFilesDoc'
import { GetCodigoFechaActual, ObtenerPrecioContratistaVigente } from 'sigo-package'

export class UseCaseUpdateTrabajo {
  private repository: RepositoryMain
  private user: EntityDataUsuario
  private trabajo: DtoTrabajos
  private Asignaciones: DtoFormAddValorizacion[]
  private dataEstadoInterno: any[]
  private params: Readonly<Partial<{ id: string; codParteDiario?: string | undefined; }>>

  constructor(_repository: RepositoryMain) {
    this.repository = _repository
    this.user = {} as EntityDataUsuario
    this.trabajo = {} as DtoTrabajos
    this.Asignaciones = []
    this.dataEstadoInterno = []
    this.params = {}
  }

  public async exec(data: any): Promise<DtoTrabajos[]> {
    this.user = data.user
    this.trabajo = data.trabajo
    this.Asignaciones = data.Asignaciones
    this.dataEstadoInterno = data.dataEstadoInterno
    this.params = data.params
    return await this._exec()
  }

  private async _exec(): Promise<DtoTrabajos[]> {
    const valorizaciones: DtoValorizacion[] = this.generarDtosValorizaciones()
    this.agregarIDValorizacionAParteDiario(valorizaciones)
    this.trabajo.Ultima_PreLiquidacion.Valorizacion = this.trabajo.Ultima_PreLiquidacion.Valorizacion.concat(valorizaciones)
    let params: DtoRequestUpdateTrabajo = {
      Header: {
        Coleccion: {
          Codigo: this.user.Delegacion.Codigo,
          Nombre: this.user.Pais.Nombre
        }
      },
      Body: this.trabajo
    }
    return await this.repository.updateTrabajos(params, this.user)
  }

  private generarDtosValorizaciones(): DtoValorizacion[] {
    let valorizacionesPreLiquidacion: DtoValorizacion[] = []
    this.Asignaciones.forEach((asignaciones) => {
      let dtoValorizacion = this.generarDtoValorizacion(asignaciones,)
      valorizacionesPreLiquidacion.push(dtoValorizacion)
    })
    return valorizacionesPreLiquidacion
  }

  private generarDtoValorizacion(addValorizacion: DtoFormAddValorizacion): DtoValorizacion {
    let asignacion: DtoAsignacionValorizacion = addValorizacion.Valorizacion.dataComplete
    let val = this.trabajo.ColeccionManoObra[0].Valorizacion.find((va: any) => va.DetalleChile.LineaCodigoCub === asignacion.LineaCodigoCub)
    if (!val) throw Error(`No se encontró Valorización con LineaCodigoCub: ${asignacion.LineaCodigoCub} en Mano Obra con ID_ManoObra: ${this.trabajo.ColeccionManoObra[0].ID_ManoObra}.`)
    let newDetalleChile: DtoDetalleChileReservaMO = {
      Codigo: val.DetalleChile.Codigo,
      Linea: val.DetalleChile.Linea,
      DirDesde: val.DetalleChile.DirDesde,
      AltDesde: val.DetalleChile.AltDesde,
      DirHasta: val.DetalleChile.DirHasta,
      AltHasta: val.DetalleChile.AltHasta,
      Plano: val.DetalleChile.Plano,
      Especialidad: asignacion.Especialidad,
      Actividad: asignacion.Actividad,
      Clave: val.DetalleChile.Clave,
      Tarea: val.DetalleChile.Tarea,
      ManoObra: val.DetalleChile.ManoObra,
      UnidadObra: val.DetalleChile.UnidadObra,
      HomePass: { Codigo: '', Nombre: '' },
      LineaCodigoCub: val.DetalleChile.LineaCodigoCub,
      CodigoCub: val.DetalleChile.LineaCodigoCub.replace(val.DetalleChile.Linea, ''),
      CanMOCub: val.DetalleChile.CanMOCub,
      CanMOInf: val.DetalleChile.CanMOInf,
      CanMOApr: val.DetalleChile.CanMOApr,
      CanUOCub: val.DetalleChile.CanUOCub,
      CanUOInf: val.DetalleChile.CanUOInf,
      TotalUO: +addValorizacion.CantidadUOPreliquidada * val.Precio,
      CanUOApr: val.DetalleChile.CanUOApr,
      Origen: val.DetalleChile.Origen,
      CodigoBarra: val.DetalleChile.CodigoBarra,
      PuntoBaremo: val.DetalleChile.PuntoBaremo,
    }
    let estadoInterno = this.generarEstadoInterno()
    let { PrecioContrata, Precio } = this.obtenerPrecioEspecialidad(newDetalleChile.Especialidad.Codigo, newDetalleChile.Actividad.Codigo, val, estadoInterno)

    let newValorizacion: DtoValorizacion = {
      ID_Valorizacion: +addValorizacion.ID_Valorizacion,
      Amap: val.Amap,
      Cantidad: +addValorizacion.CantidadMOPreliquidada,
      CantidadUO: +addValorizacion.CantidadUOPreliquidada,
      Codigo: val.Codigo,
      Descripcion: val.Descripcion,
      Contrata: new DtoContrata(),
      Certificacion: new DtoCertificacion(),
      Produccion: new DtoProduccion(),
      Ultimo_Estado_Interno: estadoInterno,
      Estados: [estadoInterno],
      FechaLiquidacion: new Date(),
      Semana: 0,
      Calle: '',
      Numero: '',
      Anexos: this.generarDtoAnexos(addValorizacion),
      Fotos: this.generarDtosFotos(addValorizacion),
      Moneda: val.Moneda,
      Simbolo: val.Simbolo,
      Decimales: val.Decimales,
      Observaciones: 'PWA',
      Precio: Precio,
      Puntos: 0,
      Total: +addValorizacion.CantidadMOPreliquidada * Precio,
      Unidad: val.Unidad,
      MaterialesUtilizados: this.generarMaterialesUtilizados(newDetalleChile.UnidadObra.Codigo, +addValorizacion.CantidadUOPreliquidada, val, addValorizacion),
      MaterialesRetirados: this.generarMaterialesRetirados(addValorizacion),
      DetalleChile: newDetalleChile,
      HomePass: [],
      SubManoObra: [],
      PrecioContrata: PrecioContrata,
      TotalContrata: PrecioContrata * +addValorizacion.CantidadMOPreliquidada,
      // Documentacion: new EntityGestionDocumental(),
    }
    return newValorizacion
  }

  private obtenerPrecioEspecialidad(codigoEspecialidad: string, codigoActividad: string, val: DtoAmap, estadoInterno: EntityEstadoInterno): { Precio: number, PrecioContrata: number } {

    const { DatosCatalogoMO: { DatosPrecioEspecialidad: { Agencias, Contratistas } }, DetalleChile: { Linea } } = val
    const { ColeccionEmpresa } = this.user
    if (!ColeccionEmpresa.length) throw Error(`No se pudo encontrar empresa con Codigo: ${this.user.Empresa.CodEmpresa} de usuario con Identificacion: ${this.user.Identificacion}`)

    if (ColeccionEmpresa[0].Propia) {
      const agencia = Agencias.filter(e => e.Codigo === this.trabajo.ColeccionObras[0].DetalleChile.agencia)
      if (agencia.length !== 1) throw Error(`No se encontró Agencia con Codigo: ${this.trabajo.ColeccionObras[0].DetalleChile.agencia} para obra: ${this.trabajo.ColeccionObras[0].OrdenTrabajo}`)
      return { Precio: Agencias[0].Ultimo_Precio.Precio, PrecioContrata: 0 }
    }

    const contratista = Contratistas.filter((el) => el.Empresa.Codigo === this.trabajo?.Ultima_asignacion.Empresa.Codigo && el.Estado.ID_Estado === 1)
    if (contratista.length !== 1)
      throw Error(`No se pudo encontrar Contratista: ${this.trabajo?.Ultima_asignacion.Empresa.Codigo} en PrecioEspecialidad con Codigo de ContratoOT: ${this.trabajo.ColeccionObras[0].ContratoOT.Codigo} y Codigo Especialidad: ${codigoEspecialidad}`)

    const zonaContratista = contratista[0].Zonas.filter((el) => el.Descripcion === this.trabajo.ColeccionObras[0].ZonaAsignada && el.Estado.ID_Estado === 1)
    if (zonaContratista.length !== 1)
      throw Error(`No se pudo encontrar Zona: ${this.trabajo.ColeccionObras[0].ZonaAsignada} para Contratista: ${this.trabajo?.Ultima_asignacion.Empresa.Codigo} en PrecioEspecialidad con Codigo de ContratoOT: ${this.trabajo.ColeccionObras[0].ContratoOT.Codigo} y Codigo Especialidad: ${codigoEspecialidad}`)

    const actividadZonaContratista = zonaContratista[0].Actividades.filter((el) => el.Codigo === codigoActividad && el.Estado.ID_Estado === 1)
    if (actividadZonaContratista.length !== 1)
      throw Error(`No se pudo encontrar Actividad: ${codigoActividad} para la Zona: ${this.trabajo.ColeccionObras[0].ZonaAsignada} para Contratista: 
            ${this.trabajo.Ultima_asignacion.Empresa.Codigo} en PrecioEspecialidad con Codigo de ContratoOT: ${this.trabajo.ColeccionObras[0].ContratoOT.Codigo} y Codigo Especialidad: ${codigoEspecialidad}`)

    const Precio = ObtenerPrecioContratistaVigente({ actividad: actividadZonaContratista[0], FechaVerificar: estadoInterno.Fecha })
    if (!Precio) throw Error(`¡No se encontró Precio Vigente en la Línea: <span style="color: red">${Linea}</span>, Especialidad: <span style="color: red">${val.DetalleChile.Especialidad.Codigo}</span>, para la Contratista: <span style="color: red">${this.trabajo.Ultima_asignacion.Empresa.Codigo}</span>, para la Zona: <span style="color: red">${this.trabajo.ColeccionObras[0].ZonaAsignada}</span>, para la Actividad: <span style="color: red">${actividadZonaContratista[0].Descripcion}</span>!`)

    return { Precio: Agencias[0].Ultimo_Precio.Precio, PrecioContrata: Precio.Precio }
  }

  private generarEstadoInterno(): EntityEstadoInterno {
    let estadoInterno = this.dataEstadoInterno.find(e => e.Descripcion === 'PRELIQUIDADA')
    if (!estadoInterno) throw Error(`No se puso encontrar EstadoInterno con Descripcion: PRELIQUIDADA`)
    let usuarioEdicion: DtoUsuarioEdicion = {
      Identificacion: this.user.Identificacion,
      Cuadrilla: '',
      User: `${this.user.Nombres} ${this.user.ApellidoPaterno} ${this.user.ApellidoMaterno}`
    }
    let newEstadoInterno: EntityEstadoInterno = {
      ID_EstadoInterno: estadoInterno.ID_EstadoInterno,
      Estado: estadoInterno.Estado,//Falta
      Fecha: new Date(),
      Descripcion: estadoInterno.Descripcion,
      Icono: estadoInterno.Icono,
      Color: estadoInterno.Color,
      Orden: estadoInterno.Orden,
      ColorFondo: estadoInterno.ColorFondo,
      Tipo: estadoInterno.Tipo,
      Observacion: estadoInterno.Observacion,
      Sistema: estadoInterno.Sistema,
      UsuarioEdicion: usuarioEdicion,
      Anexos: estadoInterno.Anexos,
      Pais: estadoInterno.Pais,
      Bitacora: estadoInterno.Bitacora,//Falta
    }
    return newEstadoInterno
  }

  private generarMaterialesUtilizados(CodigoUO: string, CantidadUO: number, val: DtoAmap, data: DtoFormAddValorizacion): DtoMaterialesUtiRet[] {
    const { DatosCatalogoMO: { DatosUnidadObraMaterial: { Materiales } } } = val
    let materialesUtilizados: DtoMaterialesUtiRet[] = []
    if (this.user.Pais.Codigo === '480') return data.Items.filter(e => e.tipo === 'Instalado').map(e => e.dataComplete)
    if (CantidadUO === 0) return materialesUtilizados
    if (Materiales.length > 0) {
      for (const kMat of Materiales) {
        let itemFromData = this.trabajo.ColeccionReserva[0].Item.find((item: any) => item.Codigo === kMat.Codigo)
        if (!itemFromData) throw Error(`No existe el Material (Código: ${kMat.Codigo}) de la Unidad Obra Material (Codigo: ${CodigoUO}) en la Reserva y/o Catalogo. Actualiza la obra e intente nuevamente.`)
        let newMatUtil: DtoMaterialesUtiRet = {
          Codigo: kMat.Codigo,
          CodigoLlave: '',
          Descripcion: kMat.Descripcion,
          Estado: { ID_Estado: 1, Nombre: 'ACTIVO' },
          UnidadMedida: { ID_UnidadMedida: itemFromData.UnidadMedida.ID_UnidadMedida, Nombre: itemFromData.UnidadMedida.Nombre },
          Cantidad: kMat.CantidadMax * CantidadUO,
          CantidadStock: 0,
          CantidadSinStock: 0,
          CantidadReutilizada: 0,
          CantidadContratista: 0,
          CantidadInformada: 0,
          Accion: { Codigo: '', Descripcion: '' },
          Motivo: { Codigo: '', Descripcion: '' },
        }
        materialesUtilizados.push(newMatUtil)
      }
    }
    return materialesUtilizados
  }

  private generarMaterialesRetirados(data: DtoFormAddValorizacion): DtoMaterialesUtiRet[] {
    let materialesUtilizados: DtoMaterialesUtiRet[] = []
    if (this.user.Pais.Codigo === '480') return data.Items.filter(e => e.tipo === 'Retirado').map(e => e.dataComplete)
    return materialesUtilizados
  }

  private generarDtosFotos(data: DtoFormAddValorizacion): DtoFotos[] {
    let Fotos: DtoFotos[] = []
    return Fotos
  }

  private generarDtoAnexos(data: DtoFormAddValorizacion): DtoFilesDoc[] {
    for (const archivo of data.Archivos) {
      delete archivo.File
    }
    let newEstructuraAnexos: DtoFilesDoc[] = data.Archivos
    return newEstructuraAnexos
  }

  private agregarIDValorizacionAParteDiario(valorizaciones: DtoValorizacion[]) {
    const codParteDiario = this.params.codParteDiario ? this.params.codParteDiario : GetCodigoFechaActual()
    const parteDiarioAux = this.trabajo.PartesDiarios.filter(e => e.Codigo === codParteDiario)
    if (parteDiarioAux.length !== 1) throw Error(`¡No se pudo obtener parte diario! Comunícate con Soporte`)
    const parteDiario = this.trabajo.PartesDiarios.find(e => e.Codigo === codParteDiario)
    if (!parteDiario) throw Error(`¡No se pudo obtener parte diario! Comunícate con Soporte`)
    parteDiario.Valorizaciones = [...parteDiario.Valorizaciones, ...valorizaciones.map(e => e.ID_Valorizacion)]
  }

}