import { RepositoryMain } from '../Domain/RepositoryMain'
import { RepositoryImplGenerico } from '../../../../shared/Infraestructure/RepositoryImplGenerico'
import { AdapterConfigure } from './AdapterConfigure'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { DtoTrabajosDE } from '../Domain/DtoTrabajosDE'
import { DtoReqSelectTrabajoDE } from '../Domain/DtoReqSelectTrabajoDE'

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

  public async selectTrabajosDE(params: DtoReqSelectTrabajoDE, user: EntityDataUsuario): Promise<DtoTrabajosDE[]> {

    const url: string = `${this.urlBase}${AdapterConfigure.SELECT_TRABAJOS}`

    let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'text', {})
    response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response
    for (const trab of response) {
      trab.Ultima_asignacion.Personal = [{ Identificacion: user.Identificacion }]
    }
    await this._saveLocalTrabajos(response, user)
    return response
  }

  public async selectTrabajosLocal(user: EntityDataUsuario): Promise<DtoTrabajosDE[]> {
    return await this.dbLocal.selectAllStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_Trabajos`)
  }

  private async _saveLocalTrabajos(response: any, user: EntityDataUsuario): Promise<DtoTrabajosDE> {
    const storeExist = await this.dbLocal.existsStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_Trabajos`)
    if (!storeExist) throw Error(`Error al obtener coleccion Trabajos`)
    await this.dbLocal.clearStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_Trabajos`)
    await this.dbLocal.insertDataStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_Trabajos`, data: response })
    return response
  }

}