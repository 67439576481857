import { useFormik } from 'formik'
import { ElementBreadCrumb } from '../../../../shared/Components/ElementBreadCrumb'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import { BuscarObra } from './BuscarObra'
import { Replanteo } from './Replanteo'
import './Style.scss'
import { FormSearchValues } from '../Domain/IForms'
import { DtoObras } from '../Domain/DtoObras'
import { IManoObraEditados, IReservaEditados } from '../Domain/IEditados'
import { DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { DtoItem } from '../../../Home/Domain/DtoItem'
import { DtoAmap } from '../../../Home/Domain/DtoAmap'
import { DtoItems } from '../../../../../app/Domain/DtoItems'
import { DtoManoObraGlobal } from '../../../../../app/Domain/DtoManoObraGlobal'
import { DtoGeneralCodigoNombre } from '../../../../shared/Domain/Dto/DtoGeneralCodigoNombre'
import { ModalFirmar } from './ModalFirmar'
import { DtoUsuario } from '../../../../shared/Domain/Dto/DtoUsuario'
import { FirmaReplanteoDto } from '../Domain/FirmaReplanteoDto'

interface PropsViewMain {
  view: string
  selectObra: (value: number) => void
  formSearch: ReturnType<typeof useFormik<FormSearchValues>>
  obras: DtoObras[]
  reservaEditados: IReservaEditados
  setReservaEditados: React.Dispatch<React.SetStateAction<IReservaEditados>>
  manoObraEditados: IManoObraEditados
  setManoObraEditados: React.Dispatch<React.SetStateAction<IManoObraEditados>>
  documentacionReplanteo: DtoNodoDoc[]
  setDocumentacionReplanteo: React.Dispatch<React.SetStateAction<DtoNodoDoc[]>>
  datosMateriales: DtoItem[]
  datosManoObra: DtoAmap[]
  setNewFilesUpload: React.Dispatch<React.SetStateAction<File[]>>
  newFilesUpload: File[]
  dataItems: DtoItems[]
  onChange: (name: string, value: any) => void
  onSave: (firma?: boolean) => void
  dataManoObraGlobal: DtoManoObraGlobal[]
  dataEspecialidad: DtoGeneralCodigoNombre[]
  selectManoObraGlobal: DtoManoObraGlobal | null
  showModal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSendFirm: (base64String: string | undefined, Usuario: DtoUsuario) => void;
  cargoSelect: string;
  firmas: FirmaReplanteoDto[]
  UsuarioEnSesion: boolean,
  usuario: DtoUsuario
  setUsuario: React.Dispatch<React.SetStateAction<DtoUsuario>>;
  disabledInputCkech: boolean,
}

interface Views {
  [key: string]: { componente: (props: any) => React.ReactElement, props: any }
}

export const ViewMain = (props: PropsViewMain) => {
  const { view, selectObra, formSearch, obras, reservaEditados, setReservaEditados, manoObraEditados, setManoObraEditados,
    documentacionReplanteo, setDocumentacionReplanteo, datosManoObra, datosMateriales, setNewFilesUpload, newFilesUpload,
    dataItems, onChange, onSave, dataManoObraGlobal, dataEspecialidad, selectManoObraGlobal, setModal, showModal,
    onSendFirm, cargoSelect, firmas, UsuarioEnSesion, setUsuario, usuario, disabledInputCkech } = props

  const languageTranslate = LanguageTranslate()
  const VIEWS: Views = {
    BuscarObra: { componente: BuscarObra, props: { formSearch, obras, selectObra } },
    Replanteo: {
      componente: Replanteo,
      props: {
        reservaEditados, setReservaEditados, manoObraEditados, setManoObraEditados, documentacionReplanteo,
        setDocumentacionReplanteo, datosManoObra, datosMateriales, setNewFilesUpload, newFilesUpload, dataItems,
        onChange, onSave, dataManoObraGlobal, dataEspecialidad, selectManoObraGlobal
      }
    },
  }

  return (
    <>
      <ModalFirmar
        onChange={onChange}
        onSendFirm={onSendFirm}
        setModal={setModal}
        showModal={showModal}
        onSave={() => onSave(true)}
        cargoSelect={cargoSelect}
        firmas={firmas}
        UsuarioEnSesion={UsuarioEnSesion}
        setUsuario={setUsuario}
        usuario={usuario}
        disabledInputCkech={disabledInputCkech}
      />
      <div className='ViewReplanteo'>
        <div className='container-fluid'>
          <ElementBreadCrumb list={[
            { text: languageTranslate.moduloMain.textoProgramados, navigate: true, path: '/main/programados' },
            { text: languageTranslate.menu['002'].titulo, navigate: false, path: '' }
          ]} />
          {
            VIEWS[view].componente(VIEWS[view].props)
          }
        </div>
      </div>
    </>
  )
}