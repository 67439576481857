import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { DtoResponseSelectStockPersonal } from "../Domain/DtoResponseSelectStockPersonal";
import { DtoRequestSelectStockPersonal } from "../Domain/DtoRequestSelectStockPersonal";
import { AdapterConfigure } from "./AdapterConfigure";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { EntityDataUsuario } from "../../../shared/Domain/EntityDataUsuario";

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

    public async selectStockPersonalLocal(user: EntityDataUsuario): Promise<DtoResponseSelectStockPersonal[]> {
        const result: DtoResponseSelectStockPersonal[] = await this.dbLocal.selectAllStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockPersonal`);
        return result.filter(e => e.FlagPex === 1)
    }

    public async selectStockPersonal(params: DtoRequestSelectStockPersonal): Promise<DtoResponseSelectStockPersonal[]> {
        let url: string = `${this.urlBase}${AdapterConfigure.SELECT_STOCK_PERSONAL}`;
        let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'json', {});
        response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response;
        return response;
    }

}