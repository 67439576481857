import { ElementBreadCrumb } from '../../../shared/Components/ElementBreadCrumb';
import ElementContenedorGenerico from "../../../shared/Components/Contenedor/ElementContenedorGenerico";
import GridDetalleSalidaAlmacen from "./GridDetalleSalidaAlmacen";
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import { ElementItemCardGenerico } from '../../../shared/Components/Programados/ElementItemCardGenerico';
import { EntitySalidaAlmacen } from "../../FirmaAlbaran/Domain/EntitySalidaAlmacen";
import './Style.scss';
import ElementModalCostume from '../../../shared/Components/ElementModalCostume';
import Signature from '../../../shared/Components/ElementFirmaDigital/ElementFirmaDigital';

interface PropsViewMain {
    salidaAlmacen: EntitySalidaAlmacen;
    ID_DespachoAlmacen: string;
    onSendFirm: (base64String: string | undefined) => void;
    setModal: React.Dispatch<React.SetStateAction<boolean>>;
    openModal: () => void;
    showModal: boolean;
    showBtnFirmar: boolean;
};

export const ViewMain = (props: PropsViewMain) => {
    const {
        salidaAlmacen,
        onSendFirm,
        showModal,
        setModal,
        openModal,
        showBtnFirmar,
    } = props;

    // const Canvas = useRef<any>(null);
    const languageTranslate = LanguageTranslate();

    const aspectRatio = 3 / 2; // Relación de aspecto de 3:2
    const viewportHeight = ((100 * window.innerHeight) / 100)-((14 * window.innerHeight) / 100);
    const viewportWidth = ((100 * window.innerWidth) / 100) - ((14 * window.innerWidth) / 100);

    let height = viewportHeight;
    let width = viewportWidth;

    if (height * aspectRatio < width) {
        // La imagen es más alta que ancha
        width = height * aspectRatio;
    } else {
        // La imagen es más ancha que alta
        height = width / aspectRatio;
    }

    if (viewportHeight / height > viewportWidth / width) {
        // La ventana es más ancha que alta
        height = viewportWidth / aspectRatio;
    } else {
        // La ventana es más alta que ancha
        width = viewportHeight * aspectRatio;
    }

    return (
        <>
            <ElementModalCostume
                show={showModal}
                closeModal={() => setModal(!showModal)}
                title={languageTranslate.btnFirmar}
            >
                <Signature
                    width={width}
                    height={height}
                    onSendFirm={onSendFirm}
                    labelBtnClear={languageTranslate.btnLimpiar}
                    labelBtnSave={languageTranslate.btnFirmar}
                />
            </ElementModalCostume>

            <div className='ViewProfile'>
                <div className="container-fluid">
                    {/** BLOQUE DE BREADCRUMB */}
                        <ElementBreadCrumb list={[
                            { text: languageTranslate.moduloMain.textoLogistica, navigate: true, path: '/main/logistica' },
                            { text: languageTranslate.menu['052'].titulo, navigate: true, path: '/main/logistica/firmaAlbaran' },
                            { text: props.ID_DespachoAlmacen, navigate: false, path: '' }
                        ]} />
                    <ElementContenedorGenerico
                        title={languageTranslate.moduloLogistica.firmaAlbaran.detalleDesapachosAlmacen.titulo}
                    >
                        <GridDetalleSalidaAlmacen
                            item={salidaAlmacen}
                        />
                        {
                            showBtnFirmar && <button onClick={() => openModal()} className='btn btn-primary w-100'>
                                {languageTranslate.btnFirmar}
                            </button>
                        }
                    </ElementContenedorGenerico>
                    {
                        salidaAlmacen.Detalle?.map(item => (
                            <ElementItemCardGenerico
                                key={item.CodigoLlave}
                                data1={item.Item?.Codigo}
                                data2={item.Item?.Descripcion}
                                data3={item.Item?.UnidadMedida.Nombre}
                                data4={item.Item?.Ind_SB}
                                data5={item.Lote}
                                data6={item.Bobina}
                                data7={item.Cantidad.toString()}
                                data8={item.CantidadRecepcion.toString()}
                                text1={
                                    <span style={{ fontSize: '15px', paddingLeft: '0px', color: 'white', fontWeight: 'bold' }} >
                                        {languageTranslate.moduloLogistica.firmaAlbaran.detalleDesapachosAlmacen.cardDetalleItem.textDetalleItemCodigo}
                                    </span>
                                }
                                text2={
                                    <span style={{ fontSize: '15px', paddingLeft: '0px', color: 'white', fontWeight: 'bold' }} >
                                        {languageTranslate.moduloLogistica.firmaAlbaran.detalleDesapachosAlmacen.cardDetalleItem.textDetalleItemDescripcion}
                                    </span>
                                }
                                text3={
                                    <span style={{ fontSize: '15px', paddingLeft: '0px', color: 'white', fontWeight: 'bold' }} >
                                        {languageTranslate.moduloLogistica.firmaAlbaran.detalleDesapachosAlmacen.cardDetalleItem.textDetalleItemUnidadMedidaNombre}
                                    </span>
                                }
                                text4={
                                    <span style={{ fontSize: '15px', paddingLeft: '0px', color: 'white', fontWeight: 'bold' }} >
                                        {languageTranslate.moduloLogistica.firmaAlbaran.detalleDesapachosAlmacen.cardDetalleItem.textDetalleItemInd_SB}
                                    </span>
                                }
                                text5={
                                    <span style={{ fontSize: '15px', paddingLeft: '0px', color: 'white', fontWeight: 'bold' }} >
                                        {languageTranslate.moduloLogistica.firmaAlbaran.detalleDesapachosAlmacen.cardDetalleItem.textDetalleLote}
                                    </span>
                                }
                                text6={
                                    <span style={{ fontSize: '15px', paddingLeft: '0px', color: 'white', fontWeight: 'bold' }} >
                                        {languageTranslate.moduloLogistica.firmaAlbaran.detalleDesapachosAlmacen.cardDetalleItem.textDetalleBobina}
                                    </span>
                                }
                                text7={
                                    <span style={{ fontSize: '15px', paddingLeft: '0px', color: 'white', fontWeight: 'bold' }} >
                                        {languageTranslate.moduloLogistica.firmaAlbaran.detalleDesapachosAlmacen.cardDetalleItem.textDetalleCantidad}
                                    </span>
                                }
                                text8={
                                    <span style={{ fontSize: '15px', paddingLeft: '0px', color: 'white', fontWeight: 'bold' }} >
                                        {languageTranslate.moduloLogistica.firmaAlbaran.detalleDesapachosAlmacen.cardDetalleItem.textDetalleCantidadRecepcion}
                                    </span>
                                }
                            />
                        ))
                    }
                </div>
            </div >
        </>
    )
};