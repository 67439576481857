import { Modal, Button } from 'react-bootstrap'
import { saveAs } from 'file-saver'
import './ImageViewer.scss'
import { DtoFilesDoc } from '../../../Programados/Trabajos/Trabajos/Domain/DtoFilesDoc'

interface IImageViewer {
    show: boolean
    setShow: React.Dispatch<React.SetStateAction<boolean>>
    file: File
    setFile: React.Dispatch<React.SetStateAction<File | null>>
    archivo?: DtoFilesDoc
}

const ImageViewer = ({ show, setShow, file, setFile, archivo }: IImageViewer) => {


    return (
        <Modal className='image-viewer' show={show} onHide={() => {
            setShow(false)
            setFile(null)
        }
        } size='lg' centered>
            <Modal.Header closeButton>
                <Modal.Title className='text-light'>Imágen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img src={URL.createObjectURL(file)} alt='Imagen' className='img-fluid' />
                {
                    archivo &&
                    <p className='pt-4'>{archivo.Observacion}</p>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={() => {
                    saveAs(file, file.name)
                    if ('Notification' in window && Notification.permission === 'granted') {
                        new Notification('Descarga completada', {
                            body: `El archivo ${file.name} se ha descargado correctamente.`,
                        })
                    }
                }}
                >
                    <i className='fa-solid fa-download' style={{ fontSize: 16 }} />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ImageViewer
