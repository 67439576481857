import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseV1InfoUser {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: string): Promise<any> {
        return await this._exec(params);
    }

    private async _exec(params: string): Promise<any> {
        return await this.repository.v1InfoUser(params)
    }
}