import { DtoTrabajos } from '../../../../Master/Home/Domain/DtoTrabajos'
import { DtoObras } from '../../../../shared/Domain/Dto/DtoObras'
import { DtoReqUpdateAddressList } from '../../../../shared/Domain/Dto/DtoReqUpdateAddressList'
import { DtoUsuario } from '../../../../shared/Domain/Dto/DtoUsuario'
import { DtoFilesDoc } from '../../../Trabajos/Trabajos/Domain/DtoFilesDoc'

export interface DtoTrabajosDE extends DtoTrabajos {
  ColeccionAddressList: DtoAddressList[]
  ColeccionObras: DtoObrasDE[]
  ColeccionHPListMO: DtoHPListMO[]
}

export interface DtoHPListMO {
  ID_HPListMO: number
  Identification: string
  Type: string
  Cantidad: number
}

export interface DtoObrasDE extends Omit<DtoObras, 'DetalleChile'> {
  Anexos: DtoFilesDoc[]
  TotalesGeneralObra: DtoTotalesGeneralObra
  DetalleChile: {
    agencia: string;
    numoe: string
    codigo: string,
    central: string
  }
}

interface DtoTotalesGeneralObra {
  PresupuestadoTotalObra: DtoPresupuestadoTotalObra
}

interface DtoPresupuestadoTotalObra {
  TotMetros: number
}

export interface DtoAddressList {
  ID_AddressList: number
  KeyDP: string
  AddressID: string
  StreetName: string
  HouseNumber: string
  HouseNumbreComplement: string
  ResidentialHPList: DtoHPList[]
  BusinessHPList: DtoHPList[]
  dataSend: { params: DtoReqUpdateAddressList, archivos: File[] }
}

export class DtoHPList {
  Home_ID: string = ''
  AddressID?: string // No es parte de DtoHPList
  ID_AddressList?: number // No es parte de DtoHPList
  Gemeinde_Plan: string = ''
  DP_ID: string = ''
  Home_Target_Status: string = ''
  Current_Home_Status: string = ''
  Insert_Date: Date = new Date()
  WorkorderOrigin: string = ''
  HouseNumber: number = 0
  HouseNumberAffix: string = ''
  WorkStatus: string = ''
  SurveyTeam: string = ''
  NCMWDCode: string = ''
  Street: string = ''
  Wave: string = ''
  // Anexos: DtoFilesDoc[]
  // Asignacion: DtoHPListAsignacion
  Asignaciones: DtoHPListAsignacion[] = []
  OC: EstadoHPListDto = new EstadoHPListDto()
  Soplado: EstadoHPListDto = new EstadoHPListDto()
  Asfaltado: EstadoHPListDto = new EstadoHPListDto()
}

export class EstadoHPListDto {
  Estado: boolean = false
  Especialidad:
    '01.2-OBRA CIVIL/DROPS' |
    '02-SOPLADO RD' |
    'ASPHALT/DROPS' |
    string = ''
}

interface DtoHPListAsignacion {
  Cuadrilla: string
  Empresa: string
  Fecha: Date
  Usuario: DtoUsuario
  Anexos: DtoFilesDoc[]
}
