import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ViewMain } from "./Components/View";

const ManagerEntity = () => {
    const {detalleStockPersonal, params, init} = Controller();

    React.useEffect(() => {
        init();
        return () => {
        }
        // eslint-disable-next-line
    }, []);

    return (<ViewMain
        detalleStockPersonal={detalleStockPersonal}
        params={params}
    />);
};

export default ManagerEntity;