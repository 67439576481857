import { EntityPersonal } from "../../../shared/Domain/EntityPersonal";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseSelectPersonal {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(): Promise<Array<EntityPersonal>> {
        return await this._exec();
    }

    private async _exec(): Promise<Array<EntityPersonal>> {
        return await this.repository.selectPersonal();
    }
}