import { DtoFormAddValorizacion } from "../../../Programados/Trabajos/Asignacion/Domain/DtoAsignacionValorizacion";
import { LanguageTranslate } from "../../Infraestructure/LanguageTranslate";
import './CardDetallePreLiquidacion.scss';

interface ICardDetallePreLiquidacion {
    item: DtoFormAddValorizacion;
    onRemove: (value: string) => void;
}

const CardDetallePreLiquidacion = (props: ICardDetallePreLiquidacion) => {

    const languageTranslate = LanguageTranslate();

    return (
        <div className="CardDetallePreLiquidacionContent mb-3 shadow rounded">
            <table className="table table-striped mb-1">
                <thead className="table-bordered">
                    <tr>
                        <th scope="col"></th>
                        <th className='align-middle' scope="col">{languageTranslate.cardDetallePreLiquidacion.Codigo}</th>
                        <th className='align-middle' scope="col">{languageTranslate.cardDetallePreLiquidacion.Descripcion}</th>
                        <th className='align-middle text-center' scope="col">{languageTranslate.cardDetallePreLiquidacion.CantidadPreLiquidar}</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{languageTranslate.cardDetallePreLiquidacion.ManoObra}</td>
                        <td>{props.item.Valorizacion.dataComplete.ManoObra.Codigo}</td>
                        <td>{props.item.Valorizacion.dataComplete.ManoObra.Nombre}</td>
                        <td className='text-center'>{props.item.CantidadMOPreliquidada}</td>
                        <td rowSpan={2} className='align-middle table-danger' onClick={() => props.onRemove(props.item.Valorizacion.value)}>
                            <i className="fa-solid fa-trash" style={{ fontSize: 15 }} />
                        </td>
                    </tr>
                    <tr>
                        <td>{languageTranslate.cardDetallePreLiquidacion.UnidadObra}</td>
                        <td>{props.item.Valorizacion.dataComplete.UnidadObra.Codigo}</td>
                        <td>{props.item.Valorizacion.dataComplete.UnidadObra.Nombre}</td>
                        <td className='text-center'>{props.item.CantidadUOPreliquidada}</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            <table className="table table-striped mb-5">
                <thead>
                    <tr>
                        <th>{languageTranslate.cardDetallePreLiquidacion.Linea}</th>
                        <th>{props.item.Valorizacion.dataComplete.Linea}</th>
                    </tr>
                </thead>
            </table>
        </div>
    )
}

export default CardDetallePreLiquidacion;