import { RepositoryMain } from '../Domain/RepositoryMain'
import { RepositoryImplGenerico } from '../../../../shared/Infraestructure/RepositoryImplGenerico'
import { AdapterConfigure } from './AdapterConfigure'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { DtoRequestUpdateTrabajo } from '../../Asignacion/Domain/DtosRequest/DtoRequestUpdateTrabajo'
import { FTPService } from '../../../../shared/Infraestructure/AdapterFileSystem'
import { FileReady } from '../../../../shared/Domain/FileReady'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { DtoTrabajos } from '../../../../Master/Home/Domain/DtoTrabajos'
import { DtoReqSelectTrabajo } from '../../../Home/Domain/DtoReqSelectTrabajo'
import { DtoDocumentacionBaremoPEX } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

  public async selectTrabajos(params: DtoReqSelectTrabajo): Promise<DtoTrabajos[]> {
    // params.Contrasenia = AdapterEncrypt.encrypt(params.Contrasenia, this.websocket.key, false)

    let url: string = `${this.urlBase}${AdapterConfigure.SELECT_TRABAJOS}`

    let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'json', {})
    response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response
    return response
  }

  public async selectTrabajoLocal(user: EntityDataUsuario, idTrabajo: number): Promise<DtoTrabajos> {
    return await this.dbLocal.selectByIndexStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_Trabajos`, value: idTrabajo })
  }

  public async updateTrabajo(params: DtoRequestUpdateTrabajo, user: EntityDataUsuario): Promise<DtoTrabajos[]> {
    // params.Contrasenia = AdapterEncrypt.encrypt(params.Contrasenia, this.websocket.key, false)

    let url: string = `${this.urlBase}${AdapterConfigure.UPDATE_TRABAJOS}`

    let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'json', {})
    response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response
    const trabajoIndexedLocal = await this.selectTrabajoLocal(user, response[0].ID_Trabajo)
    if (trabajoIndexedLocal) {
      const trabajoUpdated: DtoTrabajos = {
        ColeccionObras: trabajoIndexedLocal.ColeccionObras,
        ColeccionReserva: trabajoIndexedLocal.ColeccionReserva,
        ColeccionManoObra: trabajoIndexedLocal.ColeccionManoObra,
        ...response[0]
      }
      await this.dbLocal.updateByIndexStore({ nameStore: `${params.Body.Pais.Nombre}_${params.Body.Delegacion.Codigo}_Trabajos`, value: trabajoUpdated })
    }

    return response
  }

  public async selectDocumentacionBaremoPEX(): Promise<DtoDocumentacionBaremoPEX[]> {
    return await this.dbLocal.selectAllStore('DocumentacionBaremoPEX')
  }

  public async uploadPhoto(params: File[]): Promise<any> {
    const uploadPromises = params.map(file => FTPService.setFileToServer(file.name, file))
    return await Promise.all(uploadPromises)
  }

  public async downloadFiles(fileNames: string[]): Promise<File[]> {

    const downloadPromises: Promise<File | null>[] = fileNames.map(async (fileName) => {
      const response: FileReady | string = await FTPService.getFileToServer(fileName, 2)
      if (typeof response !== 'string' && typeof response.Archivo === 'string') {
        return AdapterGenerico.base64ToFile(response.Archivo, response.FileName)
      }
      return null
    })

    const downloadedFiles: (File | null)[] = await Promise.all(downloadPromises)

    return downloadedFiles.filter((file) => file !== null) as File[]
  }

}