import { RepositoryMain } from '../Domain/RepositoryMain'

export class UseCaseUploadFiles {
    private repository: RepositoryMain

    constructor(_repository: RepositoryMain) {
        this.repository = _repository
    }

    public async exec(filesUpload: File[]): Promise<void> {
        return await this._exec(filesUpload)
    }

    private async _exec(filesUpload: File[]): Promise<void> {
        await this.repository.uploadFiles(filesUpload)
    }
}