import { RepositoryMain } from '../Domain/RepositoryMain';
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario';
import { DtoTrabajos } from '../../../../Master/Home/Domain/DtoTrabajos';
import { DtoReqSelectTrabajos } from '../../../../Master/Home/Domain/DtoReqSelectTrabajos';

export class UseCaseSelectTrabajos {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(user: EntityDataUsuario, OrdenTrabajo?: string): Promise<DtoTrabajos[]> {
        return await this._exec(user, OrdenTrabajo);
    }

    private async _exec(user: EntityDataUsuario, OrdenTrabajo?: string): Promise<DtoTrabajos[]> {

        const EstadosCliente = ['En Ejecucion', '03 Ejecucion']
        if (user.Pais.Codigo === '480') EstadosCliente.push('Con Orden de Trabajo')

        let params: DtoReqSelectTrabajos = {
            Header: {
                Coleccion: {
                    Codigo: user.Delegacion.Codigo,
                    Nombre: user.Pais.Nombre,
                },
                Filtros: [
                    {
                        $match: {
                            'Ultima_asignacion.Personal.Identificacion': user.Identificacion,
                        },
                    },
                    {
                        $lookup: {
                            from: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_Obras`,
                            localField: 'ID_GOM',
                            foreignField: 'AreaGOM.GOMS.ID_GOM',
                            as: 'ColeccionObras'
                        }
                    },
                    {
                        $match: {
                            $and: [
                                {
                                    'ColeccionObras.DetalleChile.UltimoEstadoCliente.Descripcion': {
                                        $in: EstadosCliente
                                    }
                                },
                                {
                                    'ColeccionObras.Ultimo_Estado_Interno.Descripcion': {
                                        $in: [
                                            'EJECUTANDO',
                                            'PENDIENTE POR GESTION'
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        $lookup: {
                            from: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_ManoObra`,
                            localField: 'ID_GOM',
                            foreignField: 'ID_GOM',
                            as: 'ColeccionManoObra'
                        }
                    },
                    {
                        $lookup: {
                            from: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_Reserva`,
                            localField: 'ID_GOM',
                            foreignField: 'ID_GOM',
                            as: 'ColeccionReserva'
                        }
                    },
                    {
                        $project: {
                            ID_Trabajo: 1,
                            ID_GOM: 1,
                            ZonaAsignada: 1,
                            'Ultima_asignacion.Fecha': 1,
                            'Ultima_asignacion.Personal.Identificacion': 1,
                            'Ultima_asignacion.Empresa.Codigo': 1,
                            'Ultima_asignacion.Empresa.Propia': 1,
                            'Ultima_asignacion.Valorizaciones': 1,
                            'Ultima_asignacion.Cuadrilla.Codigo': 1,
                            'Ultimo_Estado_Interno.Descripcion': 1,
                            'Ultima_PreLiquidacion.Valorizacion': 1,
                            'Pais.Nombre': 1,
                            'Pais.Codigo': 1,
                            'Delegacion.Codigo': 1,
                            'PartesDiarios': 1,
                            'ColeccionObras.ID_incidencia': 1,
                            'ColeccionObras.Proyecto': 1,
                            'ColeccionObras.OrdenTrabajo': 1,
                            'ColeccionObras.ContratoOT.Codigo': 1,
                            'ColeccionObras.ContratoOT.Sistema': 1,
                            'ColeccionObras.ZonaAsignada': 1,
                            'ColeccionObras.Pais.Codigo': 1,
                            'ColeccionObras.Delegacion.Codigo': 1,
                            'ColeccionObras.DetalleChile.agencia': 1,
                            'ColeccionObras.DetalleChile.numoe': 1,
                            'ColeccionObras.DetalleChile.codigo': 1,
                            'ColeccionManoObra.ID_ManoObra': 1,
                            'ColeccionManoObra.Valorizacion': 1,
                            'ColeccionReserva.Item': 1,
                            'ColeccionObras.Ubicacion.Municipio': 1
                        },
                    },
                ],
            },
        };
        if (OrdenTrabajo) {
            params.Header.Filtros[2].$match.$and = [
                {
                    'ColeccionObras.OrdenTrabajo': {
                        $regex: OrdenTrabajo,
                        $options: 'i'
                    }
                },
                {
                    $nor: [
                        {
                            'ColeccionObras.Ultimo_Estado_Interno.Descripcion': {
                                $in: [
                                    'CAMBIO DE CONTRATO'
                                ]
                            }
                        },
                        {
                            'ColeccionObras.DetalleChile.UltimoEstadoCliente.Descripcion': {
                                $in: [
                                    'Anulada',
                                    '08 Orden Cerrada',
                                    '10 Orden Cerrada',
                                    'Orden Cerrada',
                                    '09 Orden de Facturacion',
                                    'Orden de Facturacion',
                                    '07 Orden de Facturacion',
                                ]
                            }
                        }
                    ]
                },
                {
                    'ColeccionObras.Ultimo_Estado_Interno.Descripcion': {
                        $in: [
                            'EJECUTANDO',
                            'PENDIENTE POR GESTION'
                        ]
                    }
                }
            ];
        }
        const Trabajos = await this.repository.selectTrabajos(params, user)
        return Trabajos;
    }
}
