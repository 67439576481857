import { typeBaremoSubcontrata } from '../Domain/types'

const {
  REACT_APP_MAESTRO_SEDE_SCHEMA,
  REACT_APP_MAESTRO_SEDE_ENTITY,
  REACT_APP_ROUTE_PATH_MAIN,
  REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS,
  REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_DE_TRABAJOS,
} = process.env

const SCHEMA: string = REACT_APP_MAESTRO_SEDE_SCHEMA || ''
const ENTITY: string = REACT_APP_MAESTRO_SEDE_ENTITY || ''
const ROUTE_PROGRAMADOS: string = `/${REACT_APP_ROUTE_PATH_MAIN}/${REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS}`
const ROUTE_TRABAJOS: string = `${ROUTE_PROGRAMADOS}/${REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_DE_TRABAJOS}`
const SELECT_TRABAJOS: string = '/SigoPwa/Programados/Gestion/Trabajos/Select'
const UPDATE_TRABAJOS: string = '/SigoPwa/Programados/Gestion/Trabajos/Update'

export const codigosBaremosSubcontrata = [
  '010601000',
  '010600600',
  '010600700',
  '010600500',
  '010601300',
  '010601600',
]

export const TiposBaremoSubcontrata: typeBaremoSubcontrata[] = ['Asphalt', 'Trenching']

export const AdapterConfigure = {
  SCHEMA,
  ENTITY,
  SELECT_TRABAJOS,
  ROUTE_PROGRAMADOS,
  ROUTE_TRABAJOS,
  UPDATE_TRABAJOS,
}