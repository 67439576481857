import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntityDataUsuario } from '../Domain/EntityDataUsuario';
import { EntityDelegacion } from '../Domain/EntityDelegacion';
import { EntityFuncionalidad } from '../Domain/EntityFuncionalidad';
import { EntityMenu } from '../Domain/EntityMenu';
import { EntityOTs } from '../Domain/EntityOTs';
import { EntityPais } from '../Domain/EntityPais';
import { EntityPersonal } from '../Domain/EntityPersonal';
import { EntityRol } from '../Domain/EntityRol';
import { AdapterStorage } from './AdapterStorage';

var { auth, token, tokenRefresh, user, userGestor, menu, rol, funcionalidad, permisoVariables } = AdapterStorage.get(['auth', 'token', 'tokenRefresh', 'user', 'userGestor', 'menu', 'rol', 'funcionalidad', 'permisoVariables']);

if (!!auth) {
    var { [`preference${(user as EntityDataUsuario).IdUsuario}`]: preference } = AdapterStorage.get(`preference${(user as EntityDataUsuario).IdUsuario}`);
}

interface SliceAuthenticationState {
    auth: boolean;
    token: string | null;
    tokenRefresh: string | null;

    user: EntityDataUsuario;
    userGestor: EntityDataUsuario;
    loadData: boolean;

    menu: Array<EntityMenu>;
    rol: EntityRol;
    funcionalidad: EntityFuncionalidad;

    permisoVariables: {
        arrIdPaises: Array<number>;
        arrIdGrupos: Array<number>;
        arrIdDelegaciones: Array<number>;
        arrIdOT: Array<number>;
    };

    preferencia: { pais: EntityPais | null, delegacion: EntityDelegacion | null, ot: EntityOTs | null, personal: EntityPersonal[] | null, buscarCuadrilla: boolean | null };
};

const userDefault: EntityDataUsuario = {
    User: 'SIGO',
    IdUsuario: 0,
    ApellidoPaterno: 'PRUEBAS',
    ApellidoMaterno: 'PRUEBAS',
    Nombres: 'SIGO',
    TipoDocumento: {
        TipoDocumento: '',
    },
    Identificacion: '12345678',
    Codigo: '',
    Empresa: {
        CodEmpresa: '',
        CDEmpresa: '',
        Empresa: '',
        Grupo: '',
    },
    Pais: {
        Codigo: '',
        Nombre: '',
    },
    Delegacion: {
        ID_Delegacion: 0,
        Codigo: '',
        Nombre: '',
        Estado: {
            ID_Estado: 0,
            Nombre: '',
        }
    },
    PaisesGestion: [{
        Codigo: '',
        Nombre: '',
    }],
    Email: '',
    DatosTrabajo: {
        Delegacion: '',
        OT: '',
        Area: '',
        Cargo: '',
    },
    Profile: [{
        "IdPerfil": 0,
        "Perfil": "",
        "Codigo": "",
        "Pais": {
            "Codigo": "",
            "Nombre": ""
        },
        "Delegacion": {
            "Codigo": "",
            "Nombre": ""
        }
    }],
    Perfil: {
        IdPerfil: 0,
        Perfil: '',
        Codigo: '',
    },
    Sistemas: [0],
    ResetPassword: 0,
    ZonaTrabajo: [{
        Codigo: '',
        ID_ZonaTrabajo: 0,
        Nombre: '',
    }],
    Almacen: [{
        ID_Almacen: 0,
        Codigo: '',
        Nombre: '',
    }],
    SuperUsuario: false,
    token: '',
    ColeccionEmpresa: [],
    AlmacenEmpresa: {
        ID_Almacen: '',
        Codigo: '',
        Nombre: '',
    }
};

const menuDefault: Array<EntityMenu> = [
    { id: 50, codigo: '050', icono: 'fa-solid fa-person-falling-burst', orden: 50, permiso: false, ruta: 'main/RDI', sigla: 'SIGOAPP_RDI', esOpcion: true, permitirSubOpcion: false, estado: true },
    { id: 51, codigo: '051', icono: 'fa-solid fa-person-falling-burst', orden: 51, permiso: false, ruta: 'main/RDI/form', sigla: 'SIGOAPP_RDI_FORM', esOpcion: false, permitirSubOpcion: true, estado: true },
];

const initialState: SliceAuthenticationState = {
    auth: !!auth ? true : false,
    token,
    tokenRefresh,
    user: !!user ? user : userDefault,
    userGestor: !!userGestor ? userGestor : userDefault,
    loadData: true,
    menu: !!menu ? menu : menuDefault,
    // menu: !!menu ? menu : [],
    rol: !!rol ? rol : {},
    funcionalidad: !!funcionalidad ? funcionalidad : [],

    permisoVariables: !!permisoVariables ? permisoVariables : {
        arrIdPaises: [],
        arrIdGrupos: [],
        arrIdDelegaciones: [],
        arrIdOT: [],
    },

    preferencia: !!preference ? preference : { pais: null, delegacion: null, ot: null }
};


const authenticationSlice = createSlice({
    name: 'authenticationSlice',
    initialState,
    reducers: {
        signIn: (state, { payload }: PayloadAction<any>) => {
            AdapterStorage.set('auth', true);
            AdapterStorage.set('token', payload.token);
            AdapterStorage.set('user', payload.user);
            AdapterStorage.set('userGestor', payload.userGestor);
            AdapterStorage.set('menu', payload.menu);
            AdapterStorage.set('loadData', true);
            // AdapterStorage.set('language', payload.language);

            return {
                ...state,
                auth: true,
                token: payload.token,
                tokenRefresh: payload.tokenRefresh,
                user: payload.user,
                userGestor: payload.userGestor,
                menu: payload.menu,
            };
        },
        signOut: state => {
            AdapterStorage.remove(['auth', 'token', 'tokenRefresh', 'user', 'userGestor', 'menu']);

            return {
                ...state,
                user: userDefault,
                userGestor: userDefault,
                menu: menuDefault,
                auth: false,
                token: null,
                tokenRefresh: null,
            };
        },
        refreshTokenSisace: (state, { payload }: PayloadAction<any>) => {
            return {
                ...state,
                token: payload.token,
                tokenRefresh: payload.tokenRefresh,
            };
        },

        changePreference: (state, { payload }: PayloadAction<any>) => {
            let preferencia = {
                ...state.preferencia,
                ...payload
            };

            AdapterStorage.set(`preference${state.user.IdUsuario}`, preferencia);

            return { ...state, preferencia };
        },
        changeLoadData: (state, { payload }: PayloadAction<any>) => {
            AdapterStorage.set(`loadData`, payload);
            return { ...state, payload };
        },
        changePermisoVariable: (state, { payload }: PayloadAction<any>) => {
            let permisoVariables = {
                ...state.permisoVariables,
                // arrIdPaises: payload.arrIdPaises,
                // arrIdGrupos: payload.arrIdGrupos,
                // arrIdDelegaciones: payload.arrIdDelegaciones,
                // arrIdOT: payload.arrIdOT,
            };

            AdapterStorage.set('permisoVariables', permisoVariables);

            return { ...state, permisoVariables };
        },

        signInInvite: state => {
            AdapterStorage.set('auth', true);
            return {
                ...state,
                auth: true,
            };
        }
    },
});

export const {
    signIn,
    signOut,
    refreshTokenSisace,
    changePreference,
    changeLoadData,
    signInInvite,
    changePermisoVariable
} = authenticationSlice.actions;

export default authenticationSlice.reducer;