const {
  REACT_APP_ROUTE_PATH_MAIN,
  REACT_APP_ROUTE_PATH_MAIN_LOGISTICA,
  REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_AUTO_INVENTARIO,
} = process.env

const SCHEMA: string = 'sigologistica/reportes'
const ENTITY: string = 'auto-inventario'
const ROUTE_LOGISTICA = `/${REACT_APP_ROUTE_PATH_MAIN}/${REACT_APP_ROUTE_PATH_MAIN_LOGISTICA}`
const ROUTE_AUTO_INVENTARIO: string = `${ROUTE_LOGISTICA}/${REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_AUTO_INVENTARIO}`

export const AdapterConfigure = {
  SCHEMA,
  ENTITY,
  ROUTE_LOGISTICA,
  ROUTE_AUTO_INVENTARIO,
}