import { DtoUsuario } from "../../../../shared/Domain/Dto/DtoUsuario"

export class FirmaReplanteoDto {
  Img: string = ''
  Cargo: string = ''
  Usuario: DtoUsuario = new DtoUsuario()
  file?: File | null
  usuarioEnSesion?: boolean = false

  // setValores({ Img, Cargo, Usuario }: { Img: string, Cargo: string, Usuario: DtoUsuario, file?: File | null }): void {
  //     if (!(Usuario instanceof DtoUsuario))
  //         throw new Error('El Usuario debe ser una instancia de DtoUsuario.')

  //     if (typeof Img !== 'string')
  //         throw new Error('El campo Img debe ser una cadena de texto.')

  //     if (typeof Cargo !== 'string')
  //         throw new Error('El campo Cargo debe ser una cadena de texto.')

  //     this.Img = Img
  //     this.Cargo = Cargo
  //     this.Usuario = Usuario
  //     this.file = file?file:null
  // }
}