import { RepositoryMain } from '../Domain/RepositoryMain'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { DtoTrabajosDE } from '../Domain/DtoTrabajosDE'

export class UseCaseSelectTrabajosLocal {
  private repository: RepositoryMain

  constructor(_repository: RepositoryMain) {
    this.repository = _repository
  }

  public async exec(user: EntityDataUsuario): Promise<DtoTrabajosDE[]> {
    return await this._exec(user)
  }

  private async _exec(user: EntityDataUsuario): Promise<DtoTrabajosDE[]> {
    return await this.repository.selectTrabajosLocal(user)
  }
}
