import React from 'react'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import ElementStepperContent from '../../../../shared/Components/ElementStepperContent'
import { IReservaEditados } from '../Domain/IEditados'
import { DtoItem } from '../../../Home/Domain/DtoItem'
import { InputSelect } from '../../../../shared/Components/ElementInputsCostume'
import { DtoItems } from '../../../../../app/Domain/DtoItems'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'

interface PropsReplanteo {
    keyTab: number
    setKeyTab: React.Dispatch<React.SetStateAction<number>>
    reservaEditados: IReservaEditados
    setReservaEditados: React.Dispatch<React.SetStateAction<IReservaEditados>>
    datosMateriales: DtoItem[]
    dataItems: DtoItems[]
    onChange: (name: string, value: any) => void
    onSave: () => void
}

export const Step1 = ({ keyTab, setKeyTab, setReservaEditados, reservaEditados, datosMateriales, dataItems, onChange, onSave }: PropsReplanteo): React.ReactElement => {

    const languageTranslate = LanguageTranslate()

    const handleEdit = (codigo: string, value: string) => {
        setReservaEditados((prevEditados) => ({
            ...prevEditados,
            [codigo]: value,
        }))
    }

    return (
        <ElementStepperContent
            step={1}
            currentStep={keyTab}
            title={languageTranslate.general.Material}
            onSubmit={() => {
                if (Object.keys(reservaEditados).find((clave) => !reservaEditados[clave].length))
                    return AdapterGenerico.createMessage('Alerta', 'complete todas las cantidades', 'warning', false)
                // setKeyTab(2)
                onSave()
            }}
            isLastStep
        >
            <InputSelect
                label={languageTranslate.general.Material}
                name='Item'
                onChange={onChange}
                values={datosMateriales}
                isRequired
                options={
                    dataItems.map(row => ({ label: `${row.Codigo} - ${row.Nombre}`, value: row.Codigo, dataComplete: row }))
                        .filter(e => !datosMateriales.map(e => e.Codigo).includes(e.value))
                }
                loading={false}
                disabled={false}
                disabledVirtualized
            />
            <table className='table-responsive table-sm'>
                <thead>
                    <tr>
                        <th className='text-center'>OPC</th>
                        <th>{languageTranslate.general.Codigo}</th>
                        <th>{languageTranslate.general.Nombre}</th>
                        <th className='text-center'>{languageTranslate.general.Reserva}</th>
                        <th className='text-center'>{languageTranslate.general.Cantidad}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        datosMateriales.map((row, i) => (
                            <tr key={i}>
                                <td onClick={() => onChange('deleteItem', row.Codigo)} style={{ verticalAlign: 'middle' }}  >
                                    <i className='fa-solid fa-trash' style={{ fontSize: 15, color: 'red' }} />
                                </td>
                                <td>{row.Codigo}</td>
                                <td>{row.Nombre}</td>
                                <td className='text-center align-middle'>{row.Cantidad}</td>
                                <td className='align-middle'>
                                    <input
                                        type='number'
                                        className='form-control'
                                        name={row.Codigo}
                                        value={reservaEditados[row.Codigo] ? reservaEditados[row.Codigo] : ''}
                                        onChange={(e) => handleEdit(row.Codigo, e.target.value)}
                                    />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </ElementStepperContent>
    )
}
