import { Accordion, Button, Card, ListGroup } from 'react-bootstrap'
import { DtoDocumentacionBaremoPEX, DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import { EntityModal } from '../Domain/EntityModal'
import './ViewModal.scss'
import { LoadImage } from '../../../../shared/Components/ElementInputFileImage'
import { ElementInputFileAdjunto } from '../../../../shared/Components/Programados/ElementInputFileAdjunto'
import { DtoFilesDoc, DtoFlujoDoc } from '../../Trabajos/Domain/DtoFilesDoc'
import { useEffect, useState } from 'react'
import { AdapterGeolocation } from '../../../../shared/Infraestructure/AdapterGeolocation'
import { DtoCoordenadas } from '../../../../shared/Domain/Dto/DtoCoordenadas'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { saveAs } from 'file-saver'
import { DtoTrabajos } from '../../../../Master/Home/Domain/DtoTrabajos'

interface IViewModal {
  modalData: EntityModal
  setModalData: React.Dispatch<React.SetStateAction<EntityModal>>
  disabledButton: boolean
  documentacionBaremoPEX: DtoDocumentacionBaremoPEX | null
  setShowImageViewer: React.Dispatch<React.SetStateAction<boolean>>
  setFileImageViewer: React.Dispatch<React.SetStateAction<File | null>>
  setNewFilesUpload: React.Dispatch<React.SetStateAction<File[]>>
  newFilesUpload: File[]
  trabajo: DtoTrabajos
  saveFiles: () => Promise<void>
  uploadFile: (imagenes: LoadImage[], anexo: DtoFilesDoc) => Promise<void>
  showButtonSave: boolean
}

const ViewModal = (props: IViewModal) => {

  const { documentacionBaremoPEX, setModalData, modalData, setShowImageViewer, setFileImageViewer, setNewFilesUpload, newFilesUpload,
    trabajo, saveFiles, showButtonSave, uploadFile } = props
  const extensionImagen = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'tiff', 'webp', 'svg', 'raw', 'psd', 'eps', 'ico']

  const [Coordenadas, setCoordenadas] = useState<DtoCoordenadas>({ X: '', Y: '' })
  const [deleteFile, setDeleteFile] = useState<string[]>([])

  useEffect(() => {
    const GetLocation = async () => {
      const coodenadas = await AdapterGeolocation.getLocation()
      setCoordenadas({ X: String(coodenadas.longitud), Y: String(coodenadas.latitud) })
    }
    GetLocation()
  }, [])

  const languageTranslate = LanguageTranslate()
  const closeModal = () => {
    if (modalData.valorizacion) {
      modalData.valorizacion.Anexos = modalData.valorizacion.Anexos.filter(e => !newFilesUpload.find(nf => nf.name.split('_').pop() === e.File?.name))
      modalData.valorizacion.Anexos = modalData.valorizacion.Anexos.map(e => {
        const deleted = deleteFile.find(el => el === e.Filename)
        if (deleted) {
          e.Estado.ID_Estado = 1
          e.Estado.Nombre = 'ACTIVO'
        }
        return e
      })
    }
    setDeleteFile([])
    setModalData((prev) => ({ ...prev, show: false }))
  }
  const addFile = (imagenes: LoadImage[], nodo: DtoNodoDoc) => {
    try {
      const newFiles: DtoFilesDoc[] = []
      const newFilesUp: File[] = []

      if (!modalData.valorizacion) throw Error(`¡No existe valorizacion! Comunícate con Soporte`)
      for (const imagen of imagenes) {
        // Properties
        const newFlujoDoc = new DtoFlujoDoc()
        newFlujoDoc.Action = 'Registrar'
        newFlujoDoc.Description = 'Preliquidado'
        newFlujoDoc.Posicion = '1'
        newFlujoDoc.Icono = 'fa fa-registered'
        newFlujoDoc.ColorIcono = '#1d9d74'
        newFlujoDoc.TagPagina = ['tagGestionMacroObrasNuevo']

        const Extension = imagen.File.name.split('.').pop()?.toLowerCase() || imagen.File.type.split('/')[1].trim().toLowerCase()

        const Ruta = `OBRA_${trabajo.ColeccionObras[0].ID_incidencia}_${trabajo.ID_GOM}_${trabajo.ID_Trabajo}_${modalData.valorizacion.ID_Valorizacion}_Fotos_Anexos_`
        if (modalData.valorizacion.Anexos.filter(e => e.Estado.ID_Estado === 1 && e.CodigoNodo === nodo.Codigo).length >= nodo.CantidadMax && nodo.CantidadMax > 0) throw Error('No se permiten más documentos')
        if (!nodo.Extension.map(e => e.trim().toLowerCase()).includes(Extension)) throw Error('Tipo de Archivo no permitido')
        const newAdjunto = new DtoFilesDoc()
        newAdjunto.CodigoNodo = nodo.Codigo
        newAdjunto.Coordenadas = Coordenadas
        newAdjunto.Extension = Extension
        newAdjunto.Fecha_Archivo = new Date(imagen.File.lastModified)
        newAdjunto.Filename = `${Ruta}${imagen.File.name}`
        newAdjunto.Flujos = [newFlujoDoc]
        newAdjunto.NameOriginal = imagen.NombreOriginal
        newAdjunto.Observacion = 'Cargado desde PWA'
        newAdjunto.Posicion = crypto.randomUUID()
        newAdjunto.Size.Size = parseFloat((imagen.File.size / 1048576).toFixed(2))
        newAdjunto.Size.UM = 'MB'
        newAdjunto.Ultimo_Flujo = newFlujoDoc
        newAdjunto.File = imagen.File
        newAdjunto.Ruta = Ruta

        newFiles.push(newAdjunto)
        const newFile = new File([imagen.File], `${trabajo.Pais.Nombre}_${trabajo.Delegacion.Codigo}_${Ruta}-${newAdjunto.Filename}`, {
          type: imagen.File.type,
          lastModified: imagen.File.lastModified
        })
        newFilesUp.push(newFile)
      }
      setNewFilesUpload([...newFilesUpload, ...newFilesUp])
      modalData.valorizacion.Anexos = [...modalData.valorizacion.Anexos, ...newFiles]
      setModalData((prev) => ({ ...prev }))
    } catch (error) {
      console.error(error)
      AdapterGenerico.createToast((error as Error).message, 'error')
    }
  }

  const removeFile = (archivo: DtoFilesDoc) => {
    try {
      if (!modalData.valorizacion) throw Error(`¡Error al obtener valorizacion! Comunícate con Soporte`)
      const updateAnexo = modalData.valorizacion.Anexos.find(e => e.Posicion === archivo.Posicion)
      if (!updateAnexo) throw Error(`¡Error al obtener Anexo! Comunícate con Soporte`)
      const isNew = newFilesUpload.find(e => e.name === `${trabajo.Pais.Nombre}_${trabajo.Delegacion.Codigo}_${archivo.Ruta}-${archivo.Filename}`)
      updateAnexo.Estado.ID_Estado = 10
      updateAnexo.Estado.Nombre = 'INACTIVO'
      isNew ? modalData.valorizacion.Anexos = modalData.valorizacion.Anexos.filter(e => e.Filename !== updateAnexo.Filename) : setDeleteFile([...deleteFile, updateAnexo.Filename])
      setModalData((prev) => ({ ...prev }))
      setNewFilesUpload(newFilesUpload.filter(e => e.name !== `${trabajo.Pais.Nombre}_${trabajo.Delegacion.Codigo}_${archivo.Ruta}-${archivo.Filename}`))
    } catch (error) {
      console.error(error)
      AdapterGenerico.createToast((error as Error).message, 'error')
    }
  }

  const showAddAdjunto = ['PRELIQUIDADA'].includes(modalData.valorizacion?.Ultimo_Estado_Interno.Descripcion || '')

  return (
    <>
      {modalData.show ? (
        <div className='ViewRDIModal'>
          <div className='container-modal'>
            <div className='header d-flex'>
              <h6 className='mb-0'>{languageTranslate.moduloRDI.form.modal.textoTitulo}</h6>
              <p className='mb-0' onClick={closeModal}>
                <i className='fa-sharp fa-solid fa-circle-xmark' style={{ fontSize: '20px' }} />
              </p>
            </div>
            <div className='body'>
              <Accordion className='mb-4' >
                {
                  documentacionBaremoPEX?.Nodos.map((nodo, i) =>
                    <Accordion.Item key={i} eventKey={String(i)}>
                      <Accordion.Header>
                        <div className='d-flex w-100 justify-content-between'>
                          <div>
                            <span>{nodo.Titulo}</span>
                            <span style={{ fontSize: '0.7rem', marginLeft: '0.5rem', color: 'red' }}>{`Min ${nodo.CantidadMin}${nodo.CantidadMax !== 0 ? ` - Max ${nodo.CantidadMax}` : ``}`}</span>
                          </div>
                          <div className='d-flex align-items-center'>
                            <span className='adjunto'>
                              {
                                showAddAdjunto &&
                                <ElementInputFileAdjunto
                                  icon={'fa-solid fa-paperclip'}
                                  multiple
                                  disabled={false}
                                  onChange={(documentos) => addFile(documentos, nodo)}
                                  size={17}
                                  allowedFormats={nodo.Extension}
                                />
                              }
                            </span>
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body className='d-flex justify-content-around flex-wrap' style={{ padding: 4 }}>
                        {
                          modalData.valorizacion?.Anexos.filter(e => e.Estado.ID_Estado === 1).map((archivo, i) =>
                            <Card key={i} className='mb-1 d-flex' style={{ width: '9rem' }}>
                              <Card.Body style={{ padding: 6 }}>
                                <div className='w-100 d-flex justify-content-center'>
                                  <i className={`${extensionImagen.includes(archivo.Extension) ? 'fa-solid fa-image' : 'fa-solid fa-file'}`} style={{ fontSize: 40 }}></i>
                                </div>
                                <Card.Title style={{ fontSize: 12 }}>{archivo.NameOriginal}</Card.Title>
                                <Card.Body className='d-flex'>
                                  <ListGroup variant='flush'>
                                    <ListGroup.Item style={{ padding: 4 }} action variant='info'>
                                      {`${typeof archivo.Size.Size === 'number' ? archivo.Size.Size.toFixed(2) : archivo.Size.Size} ${archivo.Size.UM}`}
                                    </ListGroup.Item>
                                  </ListGroup>
                                </Card.Body>
                                <div className='d-flex w-100 justify-content-between align-items-center'>
                                  {
                                    archivo.File
                                      ? <Button variant='primary' onClick={extensionImagen.includes(archivo.Extension) ? () => {
                                        if (archivo.File) {
                                          setFileImageViewer(archivo.File)
                                          setShowImageViewer(true)
                                        } else {
                                          AdapterGenerico.createToast(`¡No se encontró archivo! Comunícate con Soporte`)
                                        }
                                      } : () => {
                                        if (archivo.File) {
                                          saveAs(archivo.File, archivo.File.name)
                                          if ('Notification' in window && Notification.permission === 'granted') {
                                            new Notification('Descarga completada', {
                                              body: `El archivo ${archivo.File.name} se ha descargado correctamente.`,
                                            });
                                          }
                                        } else {
                                          AdapterGenerico.createToast(`¡No se encontró archivo! Comunícate con Soporte`)
                                        }
                                      }}>
                                        {
                                          extensionImagen.includes(archivo.Extension) ?
                                            <i className='fa-solid fa-eye' style={{ fontSize: 16 }} /> :
                                            <i className='fa-solid fa-download' style={{ fontSize: 16 }} />
                                        }
                                      </Button>
                                      : <Button variant='success'>
                                        <ElementInputFileAdjunto
                                          icon='fa-solid fa-upload'
                                          onChange={(documentos) => uploadFile(documentos, archivo)}
                                          size={16}
                                          allowedFormats={nodo.Extension}
                                          paddingLeft='0'
                                          paddingRight='0'
                                          colorIcon='white'
                                        />
                                      </Button>
                                  }
                                  {
                                    showAddAdjunto &&
                                    <Button variant='danger' onClick={() => removeFile(archivo)}>
                                      <i className='fa-solid fa-trash' style={{ fontSize: 16 }} />
                                    </Button>
                                  }
                                </div>
                              </Card.Body>
                            </Card>
                          )
                        }
                      </Accordion.Body>
                    </Accordion.Item>
                  )
                }
              </Accordion>
            </div>
            {
              (showButtonSave || deleteFile.length) &&
              <div className='p-3 d-flex flex-row justify-content-end'>
                <Button onClick={() => {
                  try {
                    const ctd = modalData.valorizacion?.Anexos.filter(e => e.Estado.ID_Estado === 1)
                    if (ctd) {
                      const faltan = documentacionBaremoPEX?.Nodos.some((e) => ctd.filter(ar => ar.CodigoNodo === e.Codigo).length < e.CantidadMin)
                      if (faltan) return AdapterGenerico.createMessage('', 'Faltan agregar archivos', 'warning', false)
                    }
                    setDeleteFile([])
                    saveFiles()
                  } catch (error: any) {
                    AdapterGenerico.createMessage('', error.message, 'error')
                  }
                }}><i className='fa-sharp fa-solid fa-save' style={{ fontSize: '1rem' }} /></Button>
              </div>
            }
          </div>
        </div>
      ) : null}
    </>
  )
}

export default ViewModal
