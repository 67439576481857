import * as React from 'react'
import { Controller } from './Infraestructure/Controller'
import { ViewMain } from './Components/View'


const ManagerEntity = () => {
    const {
        init,
        onChange,
        params,
        formRegistro,
        onSave,
        trabajo,
        selectInformation,
        parteDiario,
        user
    } = Controller()

    React.useEffect(() => {
        init()
        return () => { }
        // eslint-disable-next-line
    }, [])

    return (<ViewMain
        onChange={onChange}
        params={params}
        formRegistro={formRegistro}
        onSave={onSave}
        trabajo={trabajo}
        selectInformation={selectInformation}
        parteDiario={parteDiario}
        user={user}
    />)
}

export default ManagerEntity