import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ViewMain } from "./Components/View";
import { SelectCuadrilla } from './Components/SelectCuadrilla';


const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
        controller.init()
        return () => {
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <ViewMain
                formLogin={controller.formLogin}
                onChange={controller.onChange}
                onSubmit={controller.onSubmit}
                showPassword={controller.showPassword}
                setShowPassword={controller.setShowPassword}
                onClickRecuperarPassword={controller.onClickRecuperarPassword}
                onClickRegisterRDI={controller.onClickRegisterRDI}
            />
            <SelectCuadrilla
                dataCuadrillas={controller.dataCuadrillas}
                show={controller.showDialogo}
                setShow={controller.setShowDialogo}
                onSubmitForm={controller.selectCuadrilla}
            />
        </>
    );
};

export default ManagerEntity;