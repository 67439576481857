import { DtoParteDiario } from '../../../Trabajos/Domain/DtoParteDiario'
import { UseCaseDownloadFiles } from '../../Application/UseCaseDownloadFiles'
import { DtoSelectPersonal } from '../../Domain/DtoFormParteDiario'
import { EntityLoadData } from '../../Domain/EntityLoadData'
import { RepositoryImplMain } from '../RepositoryImplMain'

interface Iparams {
    selectInformation: EntityLoadData
    formRegistro: {
        values: any
        touched: any
        errors: any
        handleBlur: Function
        setFieldValue: Function
    }
    UseCaseDownloadFiles: typeof UseCaseDownloadFiles
    repository: RepositoryImplMain
    parteDiario: DtoParteDiario
}

export const RestablecerParteDiario = async ({ selectInformation, formRegistro, UseCaseDownloadFiles, repository, parteDiario }: Iparams): Promise<void> => {
    let tempPersonal: DtoSelectPersonal[] = []
    formRegistro.setFieldValue('Novedad', parteDiario.Novedad)
    for (const personal of parteDiario.Personal) {
        const personalSearch = selectInformation.Personal.find(e => e.Identificacion === personal.Identificacion)
        if (!personalSearch) throw Error(`Error al restaurar Personal del Parte diario. (Intente actualizar el Catalogo Personal)`)
        tempPersonal = [...tempPersonal, {
            value: personalSearch.Identificacion,
            label: ``,
            dataComplete: personalSearch
        }]
    }
    formRegistro.setFieldValue('Personal', tempPersonal)
    const files = await new UseCaseDownloadFiles(repository).exec(parteDiario)
    formRegistro.setFieldValue('imagenes', files)
}