import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import './Style.scss'
import ElementModalCostume from '../../../../shared/Components/ElementModalCostume'
import Signature from '../../../../shared/Components/ElementFirmaDigital/ElementFirmaDigital'
import { InputCheck, InputSelect, InputText } from '../../../../shared/Components/ElementInputsCostume'
import { useState, useEffect } from 'react'
import { DtoUsuario } from '../../../../shared/Domain/Dto/DtoUsuario'
import { FirmaReplanteoDto } from '../Domain/FirmaReplanteoDto'

interface PropsViewMain {
  showModal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSendFirm: (base64String: string | undefined, Usuario: DtoUsuario) => void;
  onChange: (name: string, value: any) => void;
  onSave: () => void;
  cargoSelect: string;
  firmas: FirmaReplanteoDto[];
  UsuarioEnSesion: boolean;
  usuario: DtoUsuario
  setUsuario: React.Dispatch<React.SetStateAction<DtoUsuario>>;
  disabledInputCkech: boolean;
}

export const ModalFirmar = (props: PropsViewMain) => {
  const { setModal, showModal, onSendFirm, onChange, onSave, cargoSelect, firmas, UsuarioEnSesion, setUsuario, usuario, disabledInputCkech } = props
  const [clear, setClear] = useState<boolean>(false)

  const languageTranslate = LanguageTranslate()

  const aspectRatio = 3 / 2; // Relación de aspecto de 3:2
  const viewportHeight = ((100 * window.innerHeight) / 100) - ((14 * window.innerHeight) / 100);
  const viewportWidth = ((100 * window.innerWidth) / 100) - ((14 * window.innerWidth) / 100);

  let height = viewportHeight;
  let width = viewportWidth;

  if (height * aspectRatio < width) {
    width = height * aspectRatio;
  } else {
    height = width / aspectRatio;
  }

  if (viewportHeight / height > viewportWidth / width) {
    height = viewportWidth / aspectRatio;
  } else {
    width = viewportHeight * aspectRatio;
  }
  const cargos: string[] = ['Tecnico', 'Supervisor']

  return (
    <ElementModalCostume
      show={showModal}
      closeModal={() => setModal(!showModal)}
      title={languageTranslate.btnFirmar}
      btnSave={firmas.length === 2}
      onSave={onSave}
    >
      {
        firmas.length < 2 &&
        <>
          <InputSelect
            label={languageTranslate.general.Cargo}
            name='Cargo'
            onChange={(a, b) => {
              onChange(a, b)
              setClear(true)
              setUsuario(new DtoUsuario())
            }}
            values={{ Cargo: cargos.filter(e => e === cargoSelect).map((row: string) => ({ label: row, value: row, dataComplete: row })) }}
            isRequired
            options={cargos.filter(e => !firmas.map(f => f.Cargo).includes(e)).map((row: string) => ({ label: row, value: row, dataComplete: row }))}
            loading={false}
            disabled={false}
            disabledVirtualized
          />
          {
            !firmas.some(e => e.usuarioEnSesion) &&
            <InputCheck
              label='Usuario en sesión'
              name='UsuarioEnSesion'
              onChange={onChange}
              values={{ UsuarioEnSesion: UsuarioEnSesion }}
              disabled={disabledInputCkech}
            />
          }
        </>
      }
      {
        !UsuarioEnSesion && firmas.length < 2 &&
        <>
          <InputText
            label={languageTranslate.general.Identificacion}
            name='Identificacion'
            onChange={(name: string, value: any): void => {
              setUsuario(prev => ({ ...prev, [name]: value }))
            }}
            values={usuario}
            isRequired
          />
          <InputText
            label={languageTranslate.general.Apellidos}
            name='Apellidos'
            onChange={(name: string, value: any): void => {
              setUsuario(prev => ({ ...prev, [name]: value }))
            }}
            values={usuario}
            isRequired
          />
          <InputText
            label={languageTranslate.general.Nombres}
            name='Nombres'
            onChange={(name: string, value: any): void => {
              setUsuario(prev => ({ ...prev, [name]: value }))
            }}
            values={usuario}
            isRequired
          />
        </>
      }
      {
        firmas.length < 2 ? <Signature
          width={width}
          height={height}
          onSendFirm={(imageData) => {
            onSendFirm(imageData, usuario)
            setClear(true)
          }}
          labelBtnClear={languageTranslate.btnLimpiar}
          labelBtnSave={languageTranslate.btnFirmar}
          clear={clear}
          setClear={setClear}
        /> : <ResumenFirmas firmas={firmas}></ResumenFirmas>
      }

    </ElementModalCostume>
  )
}

const ResumenFirmas = ({ firmas }: { firmas: FirmaReplanteoDto[] }) => {

  const languageTranslate = LanguageTranslate()

  return (
    <div>
      {
        firmas.map((firma, i) => (
          <div key={i} className='mb-3'>
            <span><span className='fw-bold'>{languageTranslate.general.Cargo}:</span> {firma.Cargo}</span>
            <br />
            <span><span className='fw-bold'>{languageTranslate.general.Identificacion}:</span> {firma.Usuario.Identificacion}</span>
            <br />
            <span><span className='fw-bold'>{languageTranslate.general.Apellidos}:</span> {firma.Usuario.Apellidos}</span>
            <br />
            <span><span className='fw-bold'>{languageTranslate.general.Nombre}:</span> {firma.Usuario.Nombres}</span>
            <br />
            {
              firma.file
                ? <img src={URL.createObjectURL(firma.file)} alt='Imagen' className='img-fluid mt-1 mb-1' style={{ border: '1px solid #d1d1d1' }} />
                : <></>
            }
          </div>
        ))
      }
    </div>
  )
}