import { DtoUsuarioEdicion } from '../../../../shared/Domain/Dto/DtoUsuarioEdicion'

export class DtoSubEstadoInterno {
    ID_EstadoInterno: number = 1
    ID_EstadoInternoPadre: number = 1
    Fecha: Date = new Date()
    Descripcion: string = ''
    Icono: string = ''
    Color: string = ''
    Orden: number = 1
    ColorFondo: string = ''
    Tipo: string = ''
    Observacion: string = ''
    UsuarioEdicion: DtoUsuarioEdicion = new DtoUsuarioEdicion()
}