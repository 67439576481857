import * as React from 'react'
import { Controller } from "./Infraestructure/Controller"
import { ViewMain } from "./Components/View"

const ManagerEntity = () => {

  const { init, trabajo, dataDocumentacionBaremoPEX, onSaveAdjuntosAddress ,saveFiles} = Controller()

  React.useEffect(() => {
    init()
    return () => { }
    // eslint-disable-next-line
  }, [])

  return (
    <ViewMain
      trabajo={trabajo}
      dataDocumentacionBaremoPEX={dataDocumentacionBaremoPEX}
      onSaveAdjuntosAddress={onSaveAdjuntosAddress}
      saveFiles={saveFiles}
    />
  )
}

export default ManagerEntity