export class AdapterPermiss {
  public static async permissMicrophone() {
    try {
      if (!navigator.mediaDevices) { return true; }
      await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  public static async permissGeolocation() {
    return new Promise<boolean | null>((resolve, reject) => {
      if (!navigator.geolocation || !navigator.onLine) resolve(true)
      navigator.geolocation.getCurrentPosition((position) => resolve(true), (error) => resolve(false), { enableHighAccuracy: true, maximumAge: 0, timeout: 5000 })
    })
  }

  public static async permissNotification() {
    try {
      if (!("Notification" in window)) { return true; }

      switch (Notification.permission) {
        case 'default':
        case 'denied':
          let permission = await Notification.requestPermission();
          if (permission === "granted") { return true; }
          else { return false; }
        default:
          return true;
      }
    } catch (error) {
      throw error;
    }
  }
}