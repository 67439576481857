function toBase64(str: string): string {
    if (typeof btoa === 'function') {
        return btoa(str);
    } else if (typeof Buffer !== 'undefined') {
        return Buffer.from(str).toString('base64');
    } else {
        throw new Error('No se pudo convertir la cadena a base64');
    }
}

function getMimeTypeFromFileName(fileName: string): string {
    const fileExtension = fileName.split('.').pop()?.toLowerCase();
    switch (fileExtension) {
        case 'jpg':
        case 'jpeg':
            return 'image/jpeg';
        case 'png':
            return 'image/png';
        case 'webp': // Corregido aquí
            return 'image/webp'; // Corregido aquí
        case 'pdf':
            return 'application/pdf';
        // Agrega otros tipos MIME según sea necesario
        default:
            return 'application/octet-stream';
    }
}


export { toBase64, getMimeTypeFromFileName };