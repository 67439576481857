import { FC, useEffect } from 'react'
import { View } from './components/view'
import { Controller } from './infrastructure/controller'

export const ManagerAutoInventario: FC = () => {

  const { init } = Controller()

  useEffect(() => { init() }, [])

  return (
    <View />
  )
}