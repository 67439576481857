import { DtoCoordenadas } from "../../../../shared/Domain/Dto/DtoCoordenadas"
import { DtoEstado } from "../../../../shared/Domain/Dto/DtoEstado"
import { DtoUsuario } from "../../../../shared/Domain/Dto/DtoUsuario"

export class DtoFilesDoc {
    Posicion: string = ''
    Filename: string = ''
    Extension: string = ''
    CodigoNodo: string = ''
    NameOriginal: string = ''
    Size: DtoSize = new DtoSize()
    Ruta: string = ''
    Observacion: string = ''
    Coordenadas: DtoCoordenadas = new DtoCoordenadas()
    Fecha_Archivo: Date = new Date()
    Ultimo_Flujo: DtoFlujoDoc = new DtoFlujoDoc()
    Flujos: DtoFlujoDoc[] = []
    Estado: DtoEstado = new DtoEstado()
    File?: File | null = null
}

export class DtoFlujoDoc {
    Action: string = ''
    Fecha: Date = new Date()
    Usuario: DtoUsuario = new DtoUsuario()
    Description: string = ''
    Posicion: string = ''
    Icono: string = ''
    ColorIcono: string = ''
    TagPagina: string[] = [];
    Visible: boolean = false;
}

export class DtoSize {
    Size: number = 0
    UM: string = ''
}