import { LanguageTranslate } from "../Infraestructure/LanguageTranslate"

interface InputBase {
    name: string
    onChange: (name: string, value: any) => void
    values: any
    disabled?: boolean
}

export const InputTextSearch = (props: InputBase) => {
    const languageTranslate = LanguageTranslate()
    const { name, onChange, values, disabled } = props
    return (
        <div className={`form-row d-flex mb-3`}>
            <div className='input-group input-group-sm'>
                <input
                    type='search'
                    className={`form-control`}
                    placeholder={`${languageTranslate.general.Buscar}...`}
                    onChange={(evt) => onChange(name, evt.target.value.trim())}
                    value={values[name]}
                    disabled={disabled}
                />
                <button className='input-group-text' type='submit' >
                    <i className='fa-solid fa-magnifying-glass' />
                </button>
            </div>
        </div>
    )
}
