import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate';
import './Style.scss';
import { InputSelect } from '../../../../shared/Components/ElementInputsCostume';
import { DtoFormAddValorizacion } from '../Domain/DtoAsignacionValorizacion';
import { DtoItems } from '../../../../../app/Domain/DtoItems';
import { DtoTipoMaterial } from '../Domain/DtoTipoMaterial';
import { DtoResponseSelectStockPersonal } from '../../../../Master/Home/Domain/DtoResponseSelectStockPersonal';
import { FormikErrors, FormikTouched } from 'formik';

interface PropsViewMain {
    Items: DtoItems[];
    onChange: (name: string, value: any) => void;
    formAddValorizacion: {
        values: DtoFormAddValorizacion
        touched: FormikTouched<DtoFormAddValorizacion>
        errors: FormikErrors<DtoFormAddValorizacion>
        handleBlur: (e: React.FocusEvent<any>) => void
    }
    dataTipoMaterial: DtoTipoMaterial[];
    dataStockPersonal: DtoResponseSelectStockPersonal[];
};

export const AgregarMaterial = (props: PropsViewMain) => {
    const {
        Items,
        onChange,
        formAddValorizacion,
        dataTipoMaterial,
        dataStockPersonal,
    } = props;

    const languageTranslate = LanguageTranslate();

    return (
        <>
            <InputSelect
                label={languageTranslate.general.TipoMaterial}
                name='TipoMaterial'
                onChange={onChange}
                values={formAddValorizacion.values}
                isRequired
                options={dataTipoMaterial.map(row => ({ label: row.Descripcion, value: row.Codigo, dataComplete: row }))}
                loading={false}
                disabled={false}
                disabledVirtualized
            />
            {
                formAddValorizacion.values.TipoMaterial?.value === 'Retirado' &&
                <InputSelect
                    label={languageTranslate.general.Material}
                    name='Retirado'
                    onChange={onChange}
                    values={formAddValorizacion.values.Items}
                    isRequired
                    options={
                        Items.map(row => ({ label: `${row.Codigo} - ${row.Nombre}`, value: row.Codigo, dataComplete: row }))
                            .filter(e => !formAddValorizacion.values.Items.map(e => e.value).includes(e.value))
                    }
                    loading={false}
                    disabled={false}
                    disabledVirtualized
                />}
            {
                formAddValorizacion.values.TipoMaterial?.value === 'Instalado' &&
                <InputSelect
                    label={languageTranslate.general.Material}
                    name='Instalado'
                    onChange={onChange}
                    values={formAddValorizacion.values.Items}
                    isRequired
                    options={
                        dataStockPersonal.map(row => ({ label: `${row.Item.Codigo} - ${row.Item.Nombre} ${row.Lote ? ', ' + row.Lote : ''}`, value: row.ID_StockPersonal.toString(), dataComplete: row }))
                            .filter(e => !formAddValorizacion.values.Items.map(e => e.value).includes(e.value))
                    }
                    loading={false}
                    disabled={false}
                    disabledVirtualized
                />
            }
            {
                formAddValorizacion.values.Items.length > 0 &&
                <table className='table table-striped mb-5 mt-1'>
                    <thead>
                        <tr>
                            <th scope='col'>{languageTranslate.general.Codigo}</th>
                            <th scope='col'>{languageTranslate.general.Descripcion}</th>
                            <th scope='col'>{languageTranslate.general.Disp}</th>
                            <th scope='col' style={{ textAlign: 'center' }}>{languageTranslate.general.Cantidad}</th>
                            {
                                <th scope='col'></th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            formAddValorizacion.values.Items.map((psl, i: number) =>
                                <tr key={i}>
                                    <th scope='row'>{psl.dataComplete.Codigo}</th>
                                    <td scope='row'>{psl.dataComplete.Descripcion}</td>
                                    <td scope='row'>{psl.disponible}</td>
                                    <td scope='row' style={{ verticalAlign: 'middle', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <input
                                            type='number'
                                            value={psl.dataComplete.Cantidad === 0 ? '' : psl.dataComplete.Cantidad}
                                            onChange={(event) => onChange('QuantityChange', { Item: psl.value, Cantidad: Number(event.target.value), Disponible: psl.disponible })}
                                            className='form-control'
                                            style={{ width: '3rem' }}
                                        />
                                    </td>
                                    <td scope='row' onClick={() => onChange('deleteItem', psl.value)} style={{ verticalAlign: 'middle' }}  >
                                        <i className='fa-solid fa-trash' style={{ fontSize: 15, color: 'red' }} />
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            }
        </>
    )
};