import { RepositoryMain } from '../Domain/RepositoryMain'
import { RepositoryImplGenerico } from '../../../../shared/Infraestructure/RepositoryImplGenerico'
import { DtoReqSelectTrabajo } from '../../../Home/Domain/DtoReqSelectTrabajo'
import { AdapterConfigure } from './AdapterConfigure'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { DtoRequestUpdateTrabajo } from '../Domain/DtoRequestUpdateTrabajo'
import { FTPService } from '../../../../shared/Infraestructure/AdapterFileSystem'
import { DtoTrabajos } from '../../../../Master/Home/Domain/DtoTrabajos'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { DtoResponseSelectStockPersonal } from '../../../../Master/Home/Domain/DtoResponseSelectStockPersonal'
import { CalcularResumenLiquidaciones, ActualizarDataResumenLiquidaciones } from 'sigo-package'
import { DtoDocumentacionBaremoPEX } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { addCountProcess } from '../../../../shared/Infraestructure/SliceGenerico'
import { DtoContratoOT } from '../../../../../app/Domain/DtoContratoOT'

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

    public async selectTrabajos(params: DtoReqSelectTrabajo, user: EntityDataUsuario, idTrabajo: number): Promise<DtoTrabajos[]> {
        // params.Contrasenia = AdapterEncrypt.encrypt(params.Contrasenia, this.websocket.key, false)

        let url: string = `${this.urlBase}${AdapterConfigure.SELECT_TRABAJOS}`

        let response: Array<any> = navigator.onLine ?
            await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'json', {}) :
            await this.selectTrabajoLocal(user, idTrabajo)
        if (Array.isArray(response)) {
            for (const trab of response) {
                trab.Ultima_asignacion.Personal = [{ Identificacion: user.Identificacion }]
            }
        }
        response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response
        response = Array.isArray(response) ? response : [response]
        return response
    }

    public async selectTrabajoLocal(user: EntityDataUsuario, idTrabajo: number): Promise<DtoTrabajos> {
        const trabajo = await this.dbLocal.selectByIndexStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_Trabajos`, value: idTrabajo })
        return trabajo
    }

    public async updateTrabajos(params: DtoRequestUpdateTrabajo, user: EntityDataUsuario): Promise<DtoTrabajos[]> {
        // params.Contrasenia = AdapterEncrypt.encrypt(params.Contrasenia, this.websocket.key, false)

        let url: string = `${this.urlBase}${AdapterConfigure.UPDATE_TRABAJOS}`

        const trabajoIndexedLocal = await this.selectTrabajoLocal(user, params.Body.ID_Trabajo)

        let response: Array<any> = navigator.onLine ?
            await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'json', {}) :
            await this._saveLocal(params)

        response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response

        if (trabajoIndexedLocal) {
            const trabajoUpdated: DtoTrabajos = {
                ColeccionObras: trabajoIndexedLocal.ColeccionObras,
                ColeccionReserva: trabajoIndexedLocal.ColeccionReserva,
                ColeccionManoObra: trabajoIndexedLocal.ColeccionManoObra,
                ...response.filter(e => e.ID_Trabajo && e.Ultima_PreLiquidacion && e.PartesDiarios)[0]
            }
            await this.dbLocal.updateByIndexStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_Trabajos`, value: trabajoUpdated })
            await this.updateStockPersonal(trabajoUpdated, response, user)
        }

        return response.filter(e => e.ID_Trabajo && e.Ultima_PreLiquidacion && e.PartesDiarios)
    }

    private async _saveLocal(params: DtoRequestUpdateTrabajo): Promise<any> {
        const StatusOffline = { Estado: 'No Enviado' }

        let response = { ...params.Body, StatusOffline: StatusOffline, dataSend: { params, archivos: [] } }
        this.dispatch(addCountProcess())

        return [response]
    }

    private async updateStockPersonal(trabajo: DtoTrabajos, response: any[], user: EntityDataUsuario): Promise<any> {
        const stocks = response.filter(e => e.ID_StockPersonal && e.Reserva)
        for await (const stock of stocks) {
            await this.dbLocal.updateByIndexStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockPersonal`, value: stock })
        }
        if (stocks.length) return
        // Traer todos los trabajos de IndexedDB
        let trabajosIndexedDB: DtoTrabajos[] = await this.dbLocal.selectAllStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_Trabajos`)
        // Filtrar los trabajos con mismo ID_GOM
        trabajosIndexedDB = trabajosIndexedDB.filter(e => e.ID_GOM === trabajo.ID_GOM)
        // Sacar Resumen
        const Resumen = CalcularResumenLiquidaciones(trabajo, trabajosIndexedDB)
        // Actualizar Los Stock segun resumen MatStockPersonal
        let StockPersonalIndexedDB: DtoResponseSelectStockPersonal[] = await this.dbLocal.selectAllStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockPersonal`)
        StockPersonalIndexedDB = StockPersonalIndexedDB.filter(e => e.FlagPex === 1)
        StockPersonalIndexedDB = StockPersonalIndexedDB.filter(e => Resumen.MatStockPersonal.find(el => el.ID_Trabajo === e.ID_Trabajo && el.Codigo === e.Reserva))
        StockPersonalIndexedDB = StockPersonalIndexedDB.map(e => ({ ...e, Personal: { Identificacion: user.Identificacion } }))
        ActualizarDataResumenLiquidaciones(null, null, StockPersonalIndexedDB, Resumen)
        for await (const stockPersonal of StockPersonalIndexedDB) {
            await this.dbLocal.updateByIndexStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockPersonal`, value: stockPersonal })
        }
    }

    public async selectEstadoInterno(): Promise<any[]> {
        return await this.dbLocal.selectAllStore('EstadoInterno')
    }

    public async selectItems(): Promise<any[]> {
        return await this.dbLocal.selectAllStore('Items')
    }

    public async selectDocumentacionBaremoPEX(): Promise<DtoDocumentacionBaremoPEX[]> {
        return await this.dbLocal.selectAllStore('DocumentacionBaremoPEX')
    }

    public async selectStockPersonal(user: EntityDataUsuario): Promise<DtoResponseSelectStockPersonal[]> {
        const result: DtoResponseSelectStockPersonal[] = await this.dbLocal.selectAllStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockPersonal`)
        return result.filter(e => e.FlagPex === 1)
    }

    public async selectContratoOT(): Promise<DtoContratoOT[]> {
        return await this.dbLocal.selectAllStore(`ContratoOT`)
    }

    public async uploadPhoto(parametros: File[], user: EntityDataUsuario, idTrabajo: number): Promise<any> {
        const archivosAux: any[] = []
        const promises: Promise<any>[] = []

        for (const ds of parametros) {
            if (navigator.onLine) {
                promises.push(FTPService.setFileToServer(ds.name, ds))
            } else {
                archivosAux.push({ File: ds })
            }
        }

        await Promise.all(promises)

        if (!navigator.onLine && archivosAux.length) {
            const trabajoIndexedDb = await this.selectTrabajoLocal(user, idTrabajo)
            trabajoIndexedDb.dataSend.archivos = [...trabajoIndexedDb.dataSend.archivos, ...archivosAux]
            await this.dbLocal.updateByIndexStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_Trabajos`, value: trabajoIndexedDb })
        }
    }

}