import { Dispatch, SetStateAction } from 'react'
import { PreLiquidationProcess } from '../../Preliquidar/Application/UseCasePreLiquidationProcesses/UseCasePreLiquidationProcesses'
import { DtoDataMateriales } from '../../Preliquidar/Domain/DtoDataMateriales'
import { DtoItems } from '../../../../../app/Domain/DtoItems'
import { DtoResponseSelectStockPersonal } from '../../../../Master/Home/Domain/DtoResponseSelectStockPersonal'
import { DtoDataAddedValorizaciones } from './Interfaces'
import { DtoStockAlmacen } from '../../../../Master/Home/Domain/DtoStockAlmacen'
import { DtoMaterialesUtiRet } from '../../../Trabajos/Asignacion/Domain/DtoMaterialesUtiRet'
import { DtoConsumoMaterial } from '../../../../Master/Home/Domain/DtoConsumoMaterial'

export const onChangeShared = (
  name: string,
  value: any,
  setDataMateriales: Dispatch<SetStateAction<DtoDataMateriales[]>>,
  setDataMaterialesAlmacen: Dispatch<SetStateAction<DtoDataMateriales[]>>,
  dataItems: DtoItems[],
  dataStockPersonal: DtoResponseSelectStockPersonal[],
  dataStockAlmacen: DtoStockAlmacen[],
  valorizaciones: DtoDataAddedValorizaciones[],
  materialesInstalado: DtoMaterialesUtiRet[],
  dataConsumoMaterial: DtoConsumoMaterial[]
) => {
  if (value === null) return
  switch (name) {
    case 'tipoMaterial':
      const dataMateriales = PreLiquidationProcess.generarDataMaterial(dataItems, dataStockPersonal, value.value, valorizaciones)
      setDataMateriales(dataMateriales)
      const dataAlmacen = PreLiquidationProcess.generarDataMaterialAlmacen(dataStockAlmacen, dataConsumoMaterial, valorizaciones, materialesInstalado)
      setDataMaterialesAlmacen(dataAlmacen)
      return { dataMateriales, dataAlmacen }
    default:
      break
  }
}