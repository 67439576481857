import { DtoFormParteDiario } from "../Domain/DtoFormParteDiario";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseUploadPhoto {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: DtoFormParteDiario): Promise<any> {
        await this._exec(params);
    }

    private async _exec(params: DtoFormParteDiario): Promise<any> {
        await this.repository.uploadPhoto(params);
    }
}