import './FormAdd.scss'
import { Formik, FormikErrors, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { Accordion, Button, Col, Form, Row } from 'react-bootstrap'
import { InputSelect } from '../../../../shared/Components/ElementInputsCostume'
import { InputNumber } from '../../../../shared/Components/ReactBootstrap/InputNumber/InputNumber'
import { DtoDataSelectAsignaciones } from '../Domain/DtoDataSelectAsignaciones'
import { DtoInitialValues } from '../../Shared/Preliquidar/Interfaces/DtoInitialValues'
import { DtoDataAddedValorizaciones } from '../../Shared/Preliquidar/Interfaces/DtoDataAddedValorizaciones'
import { Adjuntos } from '../../../../shared/Components/Adjuntos/Adjuntos'
import { DtoDocumentacionBaremoPEX, DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import {
  CodsMapeoMOMaterialSopladoClientes,
  CodsMapeoMOMaterialSopladoTroncal,
  CodsMapeoMO_Material_Arquertas,
  codMOClientes_ReglaLiquidacion1,
  codMONoClientes_ReglaLiquidacion1,
  codMOTroncal_ReglaLiquidacion1,
  codigosMaterialUGG,
  paisMap
} from 'sigo-package'
import { DtoTrabajosDE } from '../../Trabajos/Domain/DtoTrabajosDE'
import { AddedMaterial } from './AddedMaterial'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import { DtoMaterialesUtiRet } from '../../../Trabajos/Asignacion/Domain/DtoMaterialesUtiRet'
import { DtoValueOnSelect } from '../Domain/DtoValueOnSelect'
import { ShowAvailable } from './ShowAvailable'
import { AddedHPMO } from './AddedHPMO'
import { DtoManoObraGlobal } from '../../../../../app/Domain/DtoManoObraGlobal'
import { addCodigosSoplado } from '../Shared/Utils/AddCodigosSoplado';
import { codBaliza, codMOPublico } from '../../Shared'
import { typeHPList } from '../../DocHP/Domain/typeHPList'
import { DtoHomePass } from '../../../Trabajos/Asignacion/Domain/DtoHomePassSimple'
import { DtoDataMateriales } from '../Domain/DtoDataMateriales'
import { TiposBaremoSubcontrata, codigosBaremosSubcontrata } from '../Infraestructure/AdapterConfigure'
import { typeBaremoSubcontrata } from '../Domain/types'
import { AnyObject } from 'yup/lib/types'
import { Valorizaciones } from './Valorizaciones'
import { DtoValorizacion } from '../../../Trabajos/Asignacion/Domain/DtoValorizacion'

interface props {
  trabajo: DtoTrabajosDE | null
  asignaciones: DtoDataSelectAsignaciones[]
  valorizaciones: DtoDataAddedValorizaciones[]
  onSubmit: (
    values: DtoInitialValues,
    formikHelpers: FormikHelpers<DtoInitialValues> | null,
    materialesInstalado: DtoMaterialesUtiRet[],
    materialesRetirado: DtoMaterialesUtiRet[]
  ) => void | Promise<any>
  setDocumentacionValorizacion: Dispatch<SetStateAction<DtoNodoDoc[]>>
  documentacionValorizacion: DtoNodoDoc[]
  setNewFilesUpload: Dispatch<SetStateAction<File[]>>
  newFilesUpload: File[]
  dataDocumentacionBaremoPEX: DtoDocumentacionBaremoPEX[]
  onClick: () => void
  onChange: (name: string, value: any, materialesInstalado: DtoMaterialesUtiRet[]) => { dataAlmacen: DtoDataMateriales[], dataMateriales: DtoDataMateriales[] } | undefined
  dataMateriales: DtoDataMateriales[]
  dataManoObraGlobal: DtoManoObraGlobal[]
  address?: {
    ID_AddressList: number
    Home_ID: string
    typeHPList: typeHPList
  }
  newFiles?: File[]
  dataMaterialesAlmacen: DtoDataMateriales[]
  setShowOffCanvas?: Dispatch<SetStateAction<boolean>>
  setValRechazadaXMetraje: Dispatch<SetStateAction<DtoValorizacion | null>>
  valRechazadaXMetraje: DtoValorizacion | null
}

export function FormAdd(props: props) {

  const {
    trabajo,
    onClick,
    onChange,
    dataMateriales,
    dataManoObraGlobal,
    address,
    asignaciones,
    dataDocumentacionBaremoPEX,
    documentacionValorizacion,
    newFiles,
    newFilesUpload,
    onSubmit,
    setDocumentacionValorizacion,
    setNewFilesUpload,
    valorizaciones,
    dataMaterialesAlmacen,
    setShowOffCanvas,
    setValRechazadaXMetraje,
    valRechazadaXMetraje
  } = props

  const [materialesInstalado, setMaterialesInstalado] = useState<DtoMaterialesUtiRet[]>([])
  const [materialesRetirado, setMaterialesRetirado] = useState<DtoMaterialesUtiRet[]>([])
  const [existRechazadaPorMetraje, setExistRechazadaPorMetraje] = useState<boolean>(false)
  const [dataMaterialesSoplado, setDataMaterialesSoplado] = useState<DtoDataMateriales[]>([])
  const langTranslate = LanguageTranslate()
  const initialValues: DtoInitialValues = {
    asignacion: new DtoValueOnSelect(),
    tipoBaremoSubcontrata: new DtoValueOnSelect(),
    materialSoplado: new DtoValueOnSelect(),
    homePassMO: new DtoValueOnSelect(),
    cantidad: 0,
    calle: '',
    direcciones: '',
    observacion: '',
  }
  const schema = yup.object().shape({
    asignacion: yup
      .object()
      .shape({ value: yup.string().min(1, 'Seleccione una opción') }),
    tipoBaremoSubcontrata: yup
      .object()
      .shape({
        value: yup.string().test({
          test(value, ctx) {
            if (value === undefined) return true
            if (address) return true
            const CodMO = this.options.context?.asignacion?.dataComplete?.ManoObra?.Codigo.trim()
            if (codigosBaremosSubcontrata.includes(CodMO) && !value)
              return ctx.createError({ message: `Seleccione una opción` })
            return true
          }
        })
      }),
    homePassMO: yup
      .object()
      .shape({
        value: yup.string().test({
          test(value, ctx) {
            if (
              addCodigosSoplado().includes(this.options.context?.asignacion?.dataComplete?.ManoObra?.Codigo.trim()) && address &&
              !value
            ) {
              return ctx.createError({ message: `Seleccione una opción` })
            }
            return true
          }
        })
      }),
    cantidad: yup.number()
      .required('Campo es requerido')
      .test({
        test(value, ctx) {
          if (value === undefined) return true
          const codMO: string = this.options.context?.asignacion?.dataComplete?.ManoObra?.Codigo?.trim()

          //#region 
          if (codMOPublico.includes(codMO) && address) {
            if (address.typeHPList === 'Business' && value < 0.1) //No Clientes
              return ctx.createError({ message: `Ingrese una cantidad mayor a 0` })
            if (address.typeHPList === 'Residential' && value < 0.1) //Clientes
              return ctx.createError({ message: `Ingrese una cantidad mayor a 0` })
            if (this.options.context?.asignacion.value)
              return handleChangeCantidad(this.options.context?.asignacion, value, ctx)
          }
          if (codMOClientes_ReglaLiquidacion1.includes(codMO) && address) {
            if (address.typeHPList === 'Residential' && value < 0.1)
              return ctx.createError({ message: `Ingrese una cantidad mayor a 0` })
            return handleChangeCantidad1(value, ctx)
          }
          if (CodsMapeoMOMaterialSopladoClientes.some(e => e.CodigoMO === codMO) && address && address.typeHPList === 'Residential') {
            if (value < 0.1)
              return ctx.createError({ message: `Ingrese una cantidad mayor a 0` })
            return handleChangeCantidad2(value, ctx, codMO)
          }
          if (
            CodsMapeoMOMaterialSopladoTroncal
              .filter(e => !e.ConsumoManual)
              .some(e => e.CodigoMO === codMO)
          ) {
            if (value < 0.1)
              return ctx.createError({ message: `Ingrese una cantidad mayor a 0` })
            return handleChangeCantidad3(value, ctx, codMO)
          }
          if (
            CodsMapeoMOMaterialSopladoTroncal
              .filter(e => e.ConsumoManual)
              .some(e => e.CodigoMO === codMO)
          ) {
            if (value < 0.1)
              return ctx.createError({ message: `Ingrese una cantidad mayor a 0` })
            return handleChangeCantidad5(value, ctx, codMO)
          }
          if (CodsMapeoMO_Material_Arquertas.some(e => e.CodigoMO === codMO)) {
            if (value < 0.1)
              return ctx.createError({ message: `Ingrese una cantidad mayor a 0` })
            return handleChangeCantidad4(value, ctx, codMO)
          }
          //#endregion

          const especialidad = this.options.context?.asignacion?.dataComplete?.Especialidad?.Codigo?.trim()
          if (!['073006000'].includes(codMO) && ['01.1-OBRA CIVIL'].includes(especialidad) && address && address.typeHPList === 'Business' && value < 2) {
            return ctx.createError({ message: `Ingrese una cantidad no menor a 2` })
          }
          if (value < 0.1) {
            return ctx.createError({ message: `Ingrese una cantidad mayor a 0` })
          }
          return true
        }
      }),
    calle: yup
      .string()
      .test({
        test(value, ctx) {
          if (!address && !value) {
            return ctx.createError({ message: 'Campo es requerido' })
          }
          return true
        }
      }),
    direcciones: yup
      .string()
      .test({
        test(value, ctx) {
          if (!address && !value) {
            return ctx.createError({ message: 'Campo es requerido' })
          }
          return true
        }
      }),
    materialSoplado: yup
      .object()
      .shape({
        value: yup.string().test({
          test(value, ctx) {
            try {
              if (value === undefined) return true
              if (address) return true
              const CodMO = this.options.context?.asignacion?.dataComplete?.ManoObra?.Codigo.trim()
              if (CodsMapeoMOMaterialSopladoTroncal.some(e => e.ConsumoManual && e.CodigoMO === CodMO)) {
                if (!value) throw Error(`Seleccione una opción`)
                selectMaterialSoplado(this.options.context?.cantidad, ctx, value)
              }
              return true
            } catch (error) {
              return ctx.createError({ message: (error as Error).message })
            }
          }
        })
      }),
  })

  useEffect(() => {
    if (!trabajo) return
    setExistRechazadaPorMetraje(
      trabajo.Ultima_PreLiquidacion.Valorizacion
        .some(e => ['RECHAZADA POR METRAJE']
          .includes(e.Ultimo_Estado_Interno.Descripcion) &&
          e.HomePass.length && e.HomePass[0].ClientType === 'Troncal'
        )
      && !address
    )
  }, [trabajo])

  const selectAddress = (codigoMO: string, ID_AddressList: number, typeHPList: typeHPList, Home_ID: string) => {
    if (trabajo) {

      // Buscar Address List
      const addressList = trabajo.ColeccionAddressList.filter(e => e.ID_AddressList === ID_AddressList)
      if (addressList.length !== 1) throw Error(`¡No se pudo Obtener AddressList! Comunícate con Soporte`)

      // Buscar Address
      const direccion = (
        typeHPList === 'Business'
          ? addressList[0].BusinessHPList
          : addressList[0].ResidentialHPList
      ).filter(e => e.Home_ID === Home_ID)
      if (direccion.length !== 1) throw Error(`¡No se pudo obtener Address! Comunícate con Soporte`)

      // BuscarHPListMO
      let hpListMO: any = []
      // let hpListMO = trabajo.ColeccionHPListMO.filter(e => e.Identification.includes(addressList[0].AddressID))
      // if (hpListMO.length > 1) {
      //   const MOG = dataManoObraGlobal.filter(e => e.Codigo === codigoMO)
      //   if (MOG.length !== 1) throw Error(`¡No se pudo obtener Mano de obra Global (Código): ${codigoMO}!`)
      //   hpListMO = hpListMO.filter(e => e.Type.toLocaleUpperCase().trim() === MOG[0].Alcance.toUpperCase().trim())
      //   if (hpListMO.length > 1) throw Error(`¡No se pudo obtener HpListMO!, Comunícate con Soporte`)
      // }
      // Calcular Utilizado
      // if (hpListMO.length) {
      //   const ResumenHPMOUtilizado = CalcularResumenHPMOLiquidado(trabajo)
      //   var HPMOAux = ResumenHPMOUtilizado.find(e => e.ID_HPListMO === hpListMO[0].ID_HPListMO)
      // } else {
      //   AdapterGenerico.createToast('¡Sin registro en coleción HPListMO!', 'warning')
      // }
      const HPMOAux: any = ''

      return { HPMOAux, hpListMO, addressList, direccion: direccion[0] }
    }
  }

  const selectAsignacion = async (
    setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<DtoInitialValues>>,
    value: DtoValueOnSelect<DtoDataSelectAsignaciones>
  ) => {
    if (!address) return //Si es troncal hago un return
    const codMO = value.dataComplete.ManoObra.Codigo.trim()

    //#region Reglas preliquidacion Clientes (Residential) y No clientes (Business) con mos relacionados a agregar mat automaticamente con cantidad 1
    // const materialesCodigos = (address.typeHPList === 'Business' && codMONoClientes_ReglaLiquidacion1.includes(codMO))
    //   ? ['03DUC-110-210', '03DUC-109-214', '03DUC-110-211']
    //   : (address.typeHPList === 'Residential' && codMOClientes_ReglaLiquidacion1.includes(codMO))
    //     ? ['03DUC-110-210']
    //     : []
    const materialesCodigos = (address.typeHPList === 'Business' && codMONoClientes_ReglaLiquidacion1.includes(codMO))
      ? ['03DUC-118-204']
      : (address.typeHPList === 'Residential' && codMOClientes_ReglaLiquidacion1.includes(codMO))
        ? ['03DUC-110-210']
        : []

    if (materialesCodigos.length) {

      const response = onChange('tipoMaterial', { value: 'Instalado' }, materialesInstalado)
      ////
      if (!response) throw Error(`No se pudo seleccionar item`)

      const materialesInstalados = materialesCodigos.map(codigo => {

        const isMatUGG = codigosMaterialUGG.some(e => e === codigo)

        const dataMaterial = isMatUGG ? response.dataAlmacen : response.dataMateriales
        const material = dataMaterial.find(e => e.Codigo.trim() === codigo)

        if (!material) throw Error(`¡No tiene en stock ${isMatUGG ? 'alamcén' : 'personal'} material ${codigo} o refresque stock!`)
        if (material.CantidadDisponible < 1) throw Error(`¡No tiene stock ${isMatUGG ? 'almacén' : 'personal'} disponible material ${codigo} o refresque stock!`)

        if (isMatUGG) { material.Motivo.Descripcion = 'CONSUMO_MATERIAL_AUTOMATICO_DUCTOS' }
        material.Cantidad = 1
        material.CantidadInformada = 1
        delete (material as any).CantidadDisponible

        return material
      })

      setMaterialesInstalado(materialesInstalados)

    }

    //#endregion

    //#region 
    const result = selectAddress(codMO, address.ID_AddressList, address.typeHPList, address.Home_ID)
    if (result) {
      const { HPMOAux, addressList, hpListMO, direccion } = result
      const homePassMO: DtoValueOnSelect<DtoHomePass> = {
        label: `${direccion.Street} ${direccion.HouseNumber} ${direccion.HouseNumberAffix} (${address.typeHPList})`,
        value: `${addressList[0].ID_AddressList}${direccion.Home_ID}${address.typeHPList}`,
        dataComplete: {
          ID_HPListMO: hpListMO.length ? hpListMO[0].ID_HPListMO : 0,
          Type: hpListMO.length ? hpListMO[0].Type : '',
          Cantidad: 0,
          ID_AddressList: addressList[0].ID_AddressList,
          AddressID: addressList[0].AddressID,
          Home_ID: direccion.Home_ID,
          ClientType: address.typeHPList,
          StreetName: direccion.Street,
          HouseNumber: direccion.HouseNumber.toString(),
          HouseNumbreComplement: direccion.HouseNumberAffix,
        }
      }
      await setFieldValue('homePassMO', homePassMO)
      return {
        Cantidad: hpListMO.length ? hpListMO[0].Cantidad : 0,
        Utilizado: HPMOAux ? HPMOAux.CantidadUtilizado : 0
      }
    }
    //#endregion

  }

  const onSelectAsignacion = (a: DtoDataSelectAsignaciones) => {
    try {
      const documentacionBaremo = dataDocumentacionBaremoPEX.find(e => e.CodigoMO === a.ManoObra.Codigo)
      if (!!!documentacionBaremo) console.warn(`No existe DocumentacionBaremoPEX para el codigo ManoObra ${a.ManoObra.Codigo}`)
      const defaultBaremo = new DtoDocumentacionBaremoPEX()
      const newNodo = new DtoNodoDoc()
      newNodo.id = 999999
      newNodo.label = 'OTROS ANEXOS'
      newNodo.Titulo = 'OTROS ANEXOS'
      newNodo.Codigo = 'Cod999999'
      newNodo.Descripcion = 'OTROS ANEXOS'
      newNodo.Extension = ['ALL']
      newNodo.Size.Size = 50
      newNodo.Size.UM = 'MB'
      newNodo.CantidadMin = trabajo && paisMap[trabajo.Pais.Codigo] ? paisMap[trabajo.Pais.Codigo].CantidadMin : 1
      defaultBaremo.Nodos = [newNodo]
      setDocumentacionValorizacion(documentacionBaremo ? documentacionBaremo.Nodos : defaultBaremo.Nodos)
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error')
    }
  }

  const selectMaterialSoplado = (cantidad: number, ctx: yup.TestContext<AnyObject>, CodigoLlave: string) => {
    try {
      const material = dataMaterialesSoplado.find(e => e.CodigoLlave.trim() === CodigoLlave)
      if (!material) throw Error(`¡No tiene stock almacén material ${CodigoLlave}!`)
      if (material.CantidadDisponible < cantidad) {
        const msgError = `¡No tiene stock almacén disponible material ${CodigoLlave}!`
        console.error(msgError)
        throw Error(msgError)
      }
      material.Motivo.Descripcion = 'CONSUMO_MATERIAL_AUTOMATICO_DUCTOS'
      material.Cantidad = cantidad
      material.CantidadInformada = cantidad
      delete (material as any).CantidadDisponible
      setMaterialesInstalado(prev => [...prev.filter(e => CodigoLlave !== e.CodigoLlave), material])
    } catch (error) {
      return ctx.createError({ message: (error as Error).message })
    }
  }

  const handleChangeCantidad = (baremo: DtoValueOnSelect<DtoDataSelectAsignaciones>, cantidad: number, ctx: yup.TestContext<AnyObject>) => {
    // Regla de preliquidacion para Clientes y No clientes con mos relacionados a agregar mat automaticamente con cantidad en funcion a la cantidad mo informada
    const codMO = baremo.dataComplete.ManoObra.Codigo.trim()
    if (!address) return true
    try {
      if (address.typeHPList === 'Business' || address.typeHPList === 'Residential') {

        if (!codMOPublico.includes(codMO)) return true

        const response = onChange('tipoMaterial', { value: 'Instalado' }, materialesInstalado)
        if (!response) throw Error(`No se pudo seleccionar item`)

        const materialesCodigos = ['03DUC-109-214']
        const materialesInstalados = materialesCodigos.map(codigo => {

          const isMatUGG = codigosMaterialUGG.some(e => e === codigo)
          const dataMaterial = isMatUGG ? response.dataAlmacen : response.dataMateriales
          const material = dataMaterial.find(e => e.Codigo.trim() === codigo)

          if (!material) throw Error(`¡No tiene en stock ${isMatUGG ? 'almacén' : 'personal'} ${codigo}!`)
          if (material.CantidadDisponible < cantidad) {
            const msgError = `¡No tiene stock ${isMatUGG ? 'almacén' : 'personal'} disponible material ${codigo}!`
            console.error(msgError)
            throw Error(msgError)
          }

          if (isMatUGG) { material.Motivo.Descripcion = 'CONSUMO_MATERIAL_AUTOMATICO_DUCTOS' }
          material.Cantidad = cantidad
          material.CantidadInformada = cantidad
          delete (material as any).CantidadDisponible

          return material
        })
        setMaterialesInstalado(materialesInstalados)

      }
      return true
    } catch (error) {
      return ctx.createError({ message: (error as Error).message })
    }
  }
  const handleChangeCantidad1 = (cantidad: number, ctx: yup.TestContext<AnyObject>) => {
    // Regla de preliquidacion para Clientes con mos relacionados a agregar mat automaticamente (codMOClientes_ReglaLiquidacion1) con cantidad en funcion a la cantidad mo informada
    if (!address) return true
    try {
      if (address.typeHPList !== 'Residential') return true

      const materialesCodigos = ['03DUC-109-214', '03DUC-110-211']
      const response = onChange('tipoMaterial', { value: 'Instalado' }, materialesInstalado.filter(e => !materialesCodigos.includes(e.Codigo)))
      if (!response) throw Error(`No se pudo seleccionar item`)

      const materialesInstalados = materialesCodigos.map(codigo => {

        const isMatUGG = codigosMaterialUGG.some(e => e === codigo)
        const dataMaterial = isMatUGG ? response.dataAlmacen : response.dataMateriales
        const material = dataMaterial.find(e => e.Codigo.trim() === codigo)

        if (!material) throw Error(`¡No tiene en stock ${isMatUGG ? 'almacén' : 'personal'} material ${codigo}!`)
        if (material.CantidadDisponible < cantidad) {
          const msgError = `¡No tiene stock ${isMatUGG ? 'almacén' : 'personal'} disponible material ${codigo}!`
          console.error(msgError)
          throw Error(msgError)
        }

        if (isMatUGG) { material.Motivo.Descripcion = 'CONSUMO_MATERIAL_AUTOMATICO_DUCTOS' }
        material.Cantidad = cantidad
        material.CantidadInformada = cantidad
        delete (material as any).CantidadDisponible

        return material
      })
      setMaterialesInstalado(prev => [...prev.filter(e => !materialesCodigos.includes(e.Codigo)), ...materialesInstalados])

      return true
    } catch (error) {
      return ctx.createError({ message: (error as Error).message })
    }
  }
  const handleChangeCantidad2 = (cantidad: number, ctx: yup.TestContext<AnyObject>, codMO: string) => {
    // Regla de preliquidacion para Clientes con mos relacionados a agregar mat automaticamente (CodsMapeoMOMaterialSopladoClientes) con cantidad en funcion a la cantidad mo informada
    if (!address) return true
    try {
      if (address.typeHPList !== 'Residential') return true // Si es Clientes

      const mo = CodsMapeoMOMaterialSopladoClientes.find(e => e.CodigoMO === codMO)
      if (!mo) throw Error(`No se puede obtener material relacionado`)

      const materialesCodigos = mo.CodigoMaterial

      const response = onChange('tipoMaterial', { value: 'Instalado' }, materialesInstalado.filter(e => !materialesCodigos.includes(e.Codigo)))
      if (!response) throw Error(`No se pudo seleccionar item`)
      const materialesInstalados = materialesCodigos.map(codigo => {

        const isMatUGG = codigosMaterialUGG.some(e => e === codigo)
        const dataMaterial = isMatUGG ? response.dataAlmacen : response.dataMateriales
        const material = dataMaterial.find(e => e.Codigo.trim() === codigo)

        if (!material) throw Error(`¡No tiene en stock ${isMatUGG ? 'almacén' : 'personal'} material ${codigo}!`)
        if (material.CantidadDisponible < cantidad) {
          const msgError = `¡No tiene stock ${isMatUGG ? 'almacén' : 'personal'} disponible material ${codigo}!`
          console.error(msgError)
          throw Error(msgError)
        }

        if (isMatUGG) { material.Motivo.Descripcion = 'CONSUMO_MATERIAL_AUTOMATICO_DUCTOS' }
        material.Cantidad = cantidad
        material.CantidadInformada = cantidad
        delete (material as any).CantidadDisponible

        return material
      })
      setMaterialesInstalado(prev => [...prev.filter(e => !materialesCodigos.includes(e.Codigo)), ...materialesInstalados])

      return true
    } catch (error) {
      return ctx.createError({ message: (error as Error).message })
    }
  }
  const handleChangeCantidad3 = (cantidad: number, ctx: yup.TestContext<AnyObject>, codMO: string) => {
    // Regla de preliquidacion para Troncal con mos relacionados a agregar mat automaticamente (CodsMapeoMOMaterialSopladoTroncal) con cantidad en funcion a la cantidad mo informada
    if (address) return true // Solo para troncal
    try {
      const mo = CodsMapeoMOMaterialSopladoTroncal.find(e => e.CodigoMO === codMO)
      if (!mo) throw Error(`No se puede obtener material relacionado`)

      const materialesCodigos = mo.CodigoMaterial

      const response = onChange('tipoMaterial', { value: 'Instalado' }, materialesInstalado.filter(e => !materialesCodigos.includes(e.Codigo)))
      if (!response) throw Error(`No se pudo seleccionar item`)

      const materialesInstalados = materialesCodigos.map(codigo => {

        const isMatUGG = codigosMaterialUGG.some(e => e === codigo)
        const dataMaterial = isMatUGG ? response.dataAlmacen : response.dataMateriales
        const material = dataMaterial.find(e => e.Codigo.trim() === codigo)

        if (!material) throw Error(`¡No tiene en stock ${isMatUGG ? 'almacén' : 'personal'} material ${codigo}!`)
        if (material.CantidadDisponible < cantidad) {
          const msgError = `¡No tiene stock ${isMatUGG ? 'almacén' : 'personal'} disponible material ${codigo}!`
          console.error(msgError)
          throw Error(msgError)
        }

        if (isMatUGG) { material.Motivo.Descripcion = 'CONSUMO_MATERIAL_AUTOMATICO_DUCTOS' }
        material.Cantidad = cantidad
        material.CantidadInformada = cantidad
        delete (material as any).CantidadDisponible

        return material
      })
      setMaterialesInstalado(prev => [...prev.filter(e => !materialesCodigos.includes(e.Codigo)), ...materialesInstalados])

      return true
    } catch (error) {
      return ctx.createError({ message: (error as Error).message })
    }
  }
  const handleChangeCantidad4 = (cantidad: number, ctx: yup.TestContext<AnyObject>, codMO: string) => {
    if (address) return true // Solo para troncal
    try {
      const mo = CodsMapeoMO_Material_Arquertas.find(e => e.CodigoMO === codMO)
      if (!mo) throw Error(`No se puede obtener material relacionado`)

      const materialesCodigos = [mo.CodigoMaterial]

      const response = onChange('tipoMaterial', { value: 'Instalado' }, materialesInstalado.filter(e => !materialesCodigos.includes(e.Codigo)))
      if (!response) throw Error(`No se pudo seleccionar item`)

      const materialesInstalados = materialesCodigos.map(codigo => {

        const isMatUGG = codigosMaterialUGG.some(e => e === codigo)
        const dataMaterial = isMatUGG ? response.dataAlmacen : response.dataMateriales
        const material = dataMaterial.find(e => e.Codigo.trim() === codigo)

        if (!material) throw Error(`¡No tiene en stock ${isMatUGG ? 'almacén' : 'personal'} material ${codigo}!`)
        if (material.CantidadDisponible < cantidad) {
          const msgError = `¡No tiene stock ${isMatUGG ? 'almacén' : 'personal'} disponible material ${codigo}!`
          console.error(msgError)
          throw Error(msgError)
        }

        if (isMatUGG) { material.Motivo.Descripcion = 'CONSUMO_MATERIAL_AUTOMATICO_DUCTOS' }
        material.Cantidad = cantidad
        material.CantidadInformada = cantidad
        delete (material as any).CantidadDisponible

        return material
      })
      setMaterialesInstalado(prev => [...prev.filter(e => !materialesCodigos.includes(e.Codigo)), ...materialesInstalados])

      return true
    } catch (error) {
      return ctx.createError({ message: (error as Error).message })
    }
  }
  const handleChangeCantidad5 = (cantidad: number, ctx: yup.TestContext<AnyObject>, codMO: string) => {
    // Regla de preliquidacion para Troncal con mos relacionados a agregar mat manualmente (CodsMapeoMOMaterialSopladoTroncal) con cantidad en funcion a la cantidad mo informada
    if (address) return true // Solo para troncal
    try {
      const mo = CodsMapeoMOMaterialSopladoTroncal.find(e => e.CodigoMO === codMO)
      if (!mo) throw Error(`No se puede obtener material relacionado`)

      const materialesCodigos = mo.CodigoMaterial

      const response = onChange('tipoMaterial', { value: 'Instalado' }, materialesInstalado.filter(e => !materialesCodigos.includes(e.Codigo)))
      if (!response) throw Error(`No se pudo seleccionar item`)
      const responseAux = response.dataAlmacen.filter(e => materialesCodigos.includes(e.Codigo))
      if (!responseAux.length) throw Error(`No tiene stock Almacén para: ${materialesCodigos.join(' ').trim()}`)
      setDataMaterialesSoplado(responseAux)
      return true
    } catch (error) {
      return ctx.createError({ message: (error as Error).message })
    }
  }

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={async (values, formikHelpers) => {
          if (!address) {
            const newHP = new DtoHomePass()
            newHP.ClientType = 'Troncal'
            newHP.StreetName = values.calle
            newHP.HouseNumber = values.direcciones
            newHP.HouseNumbreComplement = values.observacion
            values.homePassMO.dataComplete = newHP
          }
          await onSubmit(values, formikHelpers, materialesInstalado, materialesRetirado)
          setMaterialesInstalado([])
          setMaterialesRetirado([])
          if (valRechazadaXMetraje && !address && setShowOffCanvas) {
            setShowOffCanvas(false)
          }
        }}
      >
        {
          ({ handleSubmit, handleChange, values, touched, errors, setFieldValue, resetForm }) => (
            <Form
              className='h-100 d-flex flex-column justify-content-between'
              noValidate
              onSubmit={async (e) => {
                e.preventDefault()
                if (newFiles && newFiles.length && !values.asignacion.value && address) {
                  await onSubmit(values, null, materialesInstalado, materialesRetirado)
                }
                handleSubmit()
              }}
            >
              <div>
                <Row className='mb-3'>
                  <Col>
                    <button onClick={onClick} type='button' className='btn btn-warning'><i className='fa-regular fa-square-plus'></i></button>
                  </Col>
                </Row>
                {
                  (existRechazadaPorMetraje && !valorizaciones.length) &&
                  <Row>
                    <Col>
                      <Accordion className='mb-2' defaultActiveKey='0'>
                        <Accordion.Item eventKey='0'>
                          <Accordion.Header>{langTranslate.general.Rechazadas_por_metraje}</Accordion.Header>
                          <Accordion.Body>
                            <Valorizaciones
                              trabajo={trabajo}
                              valRechazadaXMetraje={valRechazadaXMetraje}
                              setValRechazadaXMetraje={setValRechazadaXMetraje}
                              resetForm={resetForm}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Col>
                  </Row>
                }
                <Row>
                  <InputSelect
                    label={langTranslate.general.Asignacion}
                    name='asignacion'
                    onChange={async (name, value: DtoValueOnSelect<DtoDataSelectAsignaciones>) => {
                      try {
                        if (codBaliza.includes(value.dataComplete.ManoObra.Codigo)) {
                          value.dataComplete.CantidadMODisponible = 1
                          await setFieldValue('cantidad', 1)
                        } else {
                          await setFieldValue('cantidad', 0)
                        }
                        setMaterialesRetirado([])
                        setMaterialesInstalado([])
                        const result = await selectAsignacion(setFieldValue, value)
                        if (addCodigosSoplado().includes(value.dataComplete.ManoObra.Codigo.trim()) && address && result) {
                          const { Cantidad, Utilizado } = result
                          value.dataComplete.CantidadMO = Cantidad
                          if (codBaliza.includes(value.dataComplete.ManoObra.Codigo))
                            value.dataComplete.CantidadMODisponible = 1
                          else
                            value.dataComplete.CantidadMODisponible = Cantidad - Utilizado
                        }

                        onSelectAsignacion(value.dataComplete)
                        handleChange({ target: { value, name } })
                      } catch (error) {
                        console.error(error)
                        AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error')
                      }
                    }}
                    values={values}
                    isRequired
                    options={asignaciones
                      .filter(e => {
                        if (valRechazadaXMetraje) {
                          return e.ManoObra.Codigo.trim() === valRechazadaXMetraje.DetalleChile.ManoObra.Codigo.trim()
                        }
                        return !new Set(valorizaciones.map(e => e.LineaCodigoCub)).has(e.LineaCodigoCub) ||
                          (addCodigosSoplado().includes(e.ManoObra.Codigo.trim()) && address)
                      }
                      ).map(val => ({
                        label: `${val.ManoObra.Codigo} - ${val.ManoObra.Nombre} => (${val.Linea})`,
                        value: val.LineaCodigoCub,
                        dataComplete: val
                      }))
                    }
                    loading={false}
                    disabled={false}
                    disabledVirtualized
                    errors={newFiles && newFiles.length && address ? undefined : errors}
                    touched={touched}
                  />
                </Row>
                {
                  codigosBaremosSubcontrata.includes(values.asignacion?.dataComplete?.ManoObra?.Codigo.trim()) &&
                  <Row>
                    <InputSelect
                      label={langTranslate.general.Tipo}
                      name='tipoBaremoSubcontrata'
                      onChange={async (name, value: DtoValueOnSelect<typeBaremoSubcontrata>) => {
                        try {
                          if (value.dataComplete === 'Asphalt') {
                            setMaterialesInstalado([])
                            setMaterialesRetirado([])
                          }
                          handleChange({ target: { value, name } })
                        } catch (error) {
                          console.error(error)
                          AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error')
                        }
                      }}
                      values={values}
                      isRequired
                      options={TiposBaremoSubcontrata.map(e => ({
                        label: e,
                        value: e,
                        dataComplete: e
                      }))}
                      loading={false}
                      disabled={false}
                      disabledVirtualized
                      errors={errors}
                      touched={touched}
                    />
                  </Row>
                }
                {
                  values.asignacion.value
                  && <>
                    <Row>
                      <Col>
                        {
                          (addCodigosSoplado().includes(values.asignacion.dataComplete.ManoObra.Codigo.trim()) && address) ?
                            <AddedHPMO
                              dataManoObraGlobal={dataManoObraGlobal}
                              handleChange={(name, value: DtoValueOnSelect<DtoHomePass>) => {
                                const result = selectAddress(
                                  values.asignacion.dataComplete.ManoObra.Codigo,
                                  value.dataComplete.ID_AddressList,
                                  value.dataComplete.ClientType,
                                  value.dataComplete.Home_ID)
                                if (result) {
                                  const { HPMOAux, addressList, hpListMO, direccion } = result
                                  const Cantidad = hpListMO.length ? hpListMO[0].Cantidad : 0
                                  const Utilizado = HPMOAux ? HPMOAux.CantidadUtilizado : 0
                                  values.asignacion.dataComplete.CantidadMO = Cantidad
                                  values.asignacion.dataComplete.CantidadMODisponible = Cantidad - Utilizado
                                }
                                handleChange({ target: { name, value } })
                              }}
                              values={values}
                              errors={errors}
                              touched={touched}
                              trabajo={trabajo}
                              valorizaciones={valorizaciones}
                              address={address}
                            /> :
                            <>
                              <InputNumber
                                label={langTranslate.general.Calle}
                                name='calle'
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                                required
                                type='text'
                              />
                              <InputNumber
                                label={langTranslate.general.Direcciones}
                                name='direcciones'
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                                required
                                type='text'
                              />
                              <InputNumber
                                label={langTranslate.general.Observacion}
                                name='observacion'
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                                type='text'
                              />
                            </>
                        }
                        <ShowAvailable cantidadDisponible={values.asignacion.dataComplete.CantidadMODisponible} />
                      </Col>
                    </Row>
                    <Row>
                      <InputNumber
                        label={langTranslate.general.Cantidad}
                        name='cantidad'
                        errors={errors}
                        touched={touched}
                        values={values}
                        handleChange={async (e) => {
                          await handleChange(e)
                          if (
                            CodsMapeoMOMaterialSopladoTroncal
                              .some(e => e.ConsumoManual && e.CodigoMO === values.asignacion.dataComplete.ManoObra.Codigo)
                          ) {
                            await setFieldValue('materialSoplado', new DtoValueOnSelect())
                          }
                        }}
                        required
                        disabled={
                          codBaliza.includes(values.asignacion.dataComplete.ManoObra.Codigo) ||
                          (
                            !!materialesInstalado.length &&
                            !address &&
                            codMOTroncal_ReglaLiquidacion1
                              .filter(e => !CodsMapeoMOMaterialSopladoTroncal.map(el => el.CodigoMO).includes(e))
                              .includes(values.asignacion.dataComplete.ManoObra.Codigo.trim())
                          )
                        }
                        type='number'
                      />
                    </Row>
                    <Row className='mb-2'>
                      <Adjuntos
                        Nodos={documentacionValorizacion}
                        newFiles={newFilesUpload}
                        setNewFiles={setNewFilesUpload}
                        setNodos={setDocumentacionValorizacion}
                        ruta='Fotos_Anexos'
                      />
                    </Row>
                    {
                      (
                        values.tipoBaremoSubcontrata.value === 'Asphalt' ||
                        ['ASPHALT', 'ASPHALT/DROPS'].includes(values.asignacion.dataComplete.Especialidad.Codigo) ||
                        CodsMapeoMOMaterialSopladoTroncal
                          .some(e => e.ConsumoManual && e.CodigoMO === values.asignacion.dataComplete.ManoObra.Codigo)
                      ) ? null :
                        <Accordion className='mb-2'>
                          <Accordion.Item eventKey='0'>
                            <Accordion.Header>{langTranslate.general.Material}</Accordion.Header>
                            <Accordion.Body>
                              <AddedMaterial
                                dataMateriales={dataMateriales}
                                onChange={onChange}
                                materialesInstalado={materialesInstalado}
                                materialesRetirado={materialesRetirado}
                                setMaterialesInstalado={setMaterialesInstalado}
                                setMaterialesRetirado={setMaterialesRetirado}
                                valuesAux={values}
                                address={address}
                                dataMaterialesAlmacen={dataMaterialesAlmacen}
                              />
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                    }
                    {
                      CodsMapeoMOMaterialSopladoTroncal
                        .some(e => e.ConsumoManual && e.CodigoMO === values.asignacion.dataComplete.ManoObra.Codigo)
                        ? <Row>
                          <Col>
                            <InputSelect
                              label={langTranslate.general.Material}
                              name='materialSoplado'
                              onChange={async (name, value: DtoValueOnSelect<DtoDataMateriales>) => {
                                try {
                                  handleChange({ target: { value, name } })
                                } catch (error) {
                                  console.error(error)
                                  AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error')
                                }
                              }}
                              values={values}
                              isRequired
                              options={dataMaterialesSoplado.map(e => ({
                                label: `${e.Codigo}${e.Lote ? `, Lt: ${e.Lote}` : ''} - ${e.Descripcion}`,
                                value: e.CodigoLlave,
                                dataComplete: e
                              }))}
                              loading={false}
                              disabled={false}
                              disabledVirtualized
                              errors={errors}
                              touched={touched}
                            />
                          </Col>
                        </Row>
                        : null
                    }
                  </>
                }
              </div>
              <Row>
                <Col>
                  <Button className='w-100' type='submit'>{langTranslate.btnGuardar}</Button>
                </Col>
              </Row>
            </Form>
          )
        }
      </Formik>
    </>
  )
}