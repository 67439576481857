import { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { AdapterConfigure } from './AdapterConfigure';
import { RepositoryImplMain } from './RepositoryImplMain';
import { RootState } from '../../../../shared/Infraestructure/AdapterStore';
import {
    addLoading,
    hideIconMenu,
    removeLoading,
} from '../../../../shared/Infraestructure/SliceGenerico';
import { changeTextSeach } from '../Domain/SliceTrabajos';
import { UseCaseSelectTrabajos } from '../Application/UseCaseSelectTrabajos';
import { UseCaseSelectTrabajosLocal } from '../Application/UseCaseSelectTrabajosLocal';
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ErrorCostume } from '../../../../shared/Domain/ErrorCostume';
import { AdapterValidator } from '../../../../shared/Infraestructure/AdapterValidator';
import { DtoTrabajos } from '../../../../Master/Home/Domain/DtoTrabajos';

export const Controller = () => {
    const { websocket, dbLocal } = useSelector((state: RootState) => state.generico)
    const { user } = useSelector((state: RootState) => state.auth)
    const { textSeach } = useSelector((state: RootState) => state.trabajos)
    const { rutaAnterior } = useSelector((state: RootState) => state.main)

    const dispatch: Dispatch = useDispatch();
    const navigate: NavigateFunction = useNavigate();

    const repository: RepositoryImplMain = new RepositoryImplMain(
        websocket,
        dbLocal,
        dispatch,
        AdapterConfigure.SCHEMA,
        AdapterConfigure.ENTITY,
    );
    const [showModal, setModal] = useState(false);
    const [trabajos, setTrabajos] = useState<DtoTrabajos[]>([]);
    const [trabajosAux, setTrabajosAux] = useState<DtoTrabajos[]>([]);
    useEffect(() => {
        if (rutaAnterior.includes('/main/programados/trabajos/')) onChange('textSearch', textSeach);
    }, [trabajos])
    
    const formSearch = useFormik({
        initialValues: {
            textSearch: '',
        },
        validationSchema: Yup.object({}),
        onSubmit: (values, formikHelpers) => { },
    });

    type FieldActionsType = Record<string, (value: any) => void>;
    const fieldActions: FieldActionsType = {
        'textSearch': (value: any) => {
            formSearch.setFieldValue('textSearch', value);
            dispatch(changeTextSeach(value));
            setTrabajosAux(
                trabajos.filter((el) => {
                    if (
                        el.ColeccionObras[0].OrdenTrabajo.includes(value.toUpperCase()) ||
                        el.ColeccionObras[0].OrdenTrabajo.includes(value.toLowerCase())
                    )
                        return el;
                })
            );
        },
        'OrdenTrabajo': (value: any) => {
            formSearchDB.setFieldValue('OrdenTrabajo', value);
        },
        'RefrescarTrabajos': () => onRefrescarTrabajos(),
    };

    const init = async () => {
        try {
            dispatch(hideIconMenu());
            await loadData();
        } catch (error) {
            console.error(error);
            let err: ErrorCostume = new ErrorCostume((error as Error).message);
            AdapterGenerico.createMessage('Alerta', err.message, 'warning', false);
            navigate(AdapterConfigure.ROUTE_PROGRAMADOS, { replace: true });
        } finally {
            dispatch(removeLoading());
        }
    };

    const loadData = async () => {
        dispatch(addLoading({ textLoading: 'Cargando trabajos...' }));
        let trabajos = await new UseCaseSelectTrabajosLocal(repository).exec(user);
        // if (trabajos.length < 1) throw Error(`No tiene trabajos asignados.`);
        setTrabajos(trabajos);
        setTrabajosAux(trabajos);
    };

    const onChange = (name: string, value: any) => {
        try {
            const action = fieldActions[name];
            if (action) {
                action(value);
            }
        } catch (error) {
            console.error(error);
            let err: ErrorCostume = new ErrorCostume((error as Error).message);
            AdapterGenerico.createMessage('Alerta', err.message, 'warning', false);
        }
    };

    const onRefrescarTrabajos = async () => {
        try {
            dispatch(addLoading({ textLoading: 'Cargando trabajos...' }));
            const startTime = performance.now();
            let trabajos = await new UseCaseSelectTrabajos(repository).exec(user);
            const endTime = performance.now();
            console.log(`Tiempo de ejecución: ${((endTime - startTime) / 1000).toFixed(2)} segundos`);
            if (trabajos.length < 1) throw Error(`No tiene trabajos asignados.`);
            setTrabajos(trabajos);
            setTrabajosAux(trabajos);
        } catch (error) {
            console.error(error);
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    const formSearchDB = useFormik({
        initialValues: {
        } as any,
        validationSchema: Yup.object({
            OrdenTrabajo: Yup.string()
                .required('Ingrese una Orden de Trabajo')
                .min(5, 'Orden de Trabajo debe tener al menos 5 caracteres')
                .matches(/^[a-zA-Z0-9\s-]+$/, 'Orden de Trabajo solo puede contener letras, números, guiones y espacios')
                .nullable(),
        }),

        onSubmit: (values, formikHelpers) => { },
    });

    const onSearchSubmit = async (e: Event) => {
        try {

            e.preventDefault();
            e.stopPropagation();

            try { await formSearchDB.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formSearchDB.validateForm()); } catch (error) { AdapterGenerico.createMessage('Incompleto', (error as Error).message, 'warning', false); return null; }
            setModal(false);

            try {
                dispatch(addLoading({ textLoading: `Buscando ${formSearchDB.values['OrdenTrabajo']}...` }));
                const response = await new UseCaseSelectTrabajos(repository).exec(user, formSearchDB.values['OrdenTrabajo']);
                if (response === null) return;
                if (response.length < 1) throw Error(`No se encontró resultados para la busqueda.`);
                setTrabajos(response);
                setTrabajosAux(response);
            } catch (err) {
                let error: ErrorCostume = new ErrorCostume((err as Error).message);
                AdapterGenerico.createMessage('Alerta', error.message, 'warning', false);
            } finally {
                dispatch(removeLoading());
            }

        } catch (error) {
            dispatch(removeLoading());
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false);
        };
    };

    return {
        init,
        trabajosAux,
        onChange,
        formSearch,
        loadData,
        showModal,
        onSearchSubmit,
        formSearchDB,
        setModal,
    };
};
