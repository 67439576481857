import './ButtonFloating.scss';

interface IProps {
  onClick: () => void;
  icono?: string;
  colorIcono?: string;
  right?: number;
  backgroundColor?: string;
}

const ButtonFloating: React.FC<IProps> = ({ onClick, icono, right, colorIcono, backgroundColor }) => {
  return (
    <div className='ButtonFloatingContainer'>
      <div className={'position-fixed buttonFloating'} style={{ right: right ? right : 16, backgroundColor: backgroundColor ? backgroundColor : '#FFA500', width: '2.5rem', height: '2.5rem' }}>
        <i className={icono ? icono : 'fa-solid fa-clipboard-list'} style={{ fontSize: '1.5rem', color: colorIcono ? colorIcono : 'white' }} onClick={onClick}></i>
      </div>
    </div>
  );
}

export default ButtonFloating;