import { DtoContratoOT } from "../../../../app/Domain/DtoContratoOT";
import { EntityParams } from "../Domain/EntityParams";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseSelectContratoOT {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: EntityParams): Promise<DtoContratoOT[]> {
        return await this._exec(params);
    }

    private async _exec(params: EntityParams): Promise<DtoContratoOT[]> {
        return await this.repository.selectContratoOT(params);
    }
}