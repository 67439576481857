import { AdapterConfigure } from "./AdapterConfigure";
import { RepositoryImplMain } from "./RepositoryImplMain";
import { RootState } from "../../../shared/Infraestructure/AdapterStore";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { addLoading, hideIconMenu, removeLoading, saveDataStockPersonalTotal } from "../../../shared/Infraestructure/SliceGenerico";
import { useEffect, useState } from "react";
import { DtoResponseSelectStockPersonal } from "../Domain/DtoResponseSelectStockPersonal";
import { UseCaseSelectStockPersonal } from "../Application/UseCaseSelectStockPersonal";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ErrorCostume } from '../../../shared/Domain/ErrorCostume';
import { NavigateFunction, useNavigate } from "react-router-dom";
import { changeTextSeach } from "../Domain/SliceStockPersonalTotal";

export const Controller = () => {
  const { websocket, dbLocal } = useSelector((state: RootState) => state.generico);
  const { user } = useSelector((state: RootState) => state.auth);
  const { rutaAnterior } = useSelector((state: RootState) => state.main);
  const { textSeach } = useSelector((state: RootState) => state.stockPersonalTotal);
  const [stockPersonal, setStockPersonal] = useState<DtoResponseSelectStockPersonal[]>([]);
  useEffect(() => {
    if (rutaAnterior.includes('/main/logistica/stockPersonalTotal/')) onChange('textSearch', textSeach);
  }, [stockPersonal])
  const [stockPersonalAux, setStockPersonalAux] = useState<DtoResponseSelectStockPersonal[]>([]);
  const dispatch: Dispatch = useDispatch();
  const navigate: NavigateFunction = useNavigate();
  const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

  const formSearch = useFormik({
    initialValues: {
      textSearch: '',
    },
    validationSchema: Yup.object({}),
    onSubmit: (values, formikHelpers) => { },
  });

  const init = async () => {
    try {
      dispatch(hideIconMenu());
      await loadData();
    } catch (error) {
      dispatch(removeLoading());
      console.error(error);
      let err: ErrorCostume = new ErrorCostume((error as Error).message);
      await AdapterGenerico.createMessage('Alerta', err.message, 'warning', false);
      navigate(AdapterConfigure.REDIRECT_STOCK_PERSONAL, { replace: true });
    } finally {
      dispatch(removeLoading());
    }
  };

  const loadData = async () => {
    dispatch(addLoading({ textLoading: "Cargando Stock Personal Total..." }));
    let respStockPersonal = await new UseCaseSelectStockPersonal(repository).exec(user);
    dispatch(removeLoading());
    if (respStockPersonal.length < 1) throw Error(`No hay registros para mostrar.`);
    fieldStock(respStockPersonal)
    sumaStockPersonal(respStockPersonal);
  };

  const fieldStock = async (respStockPersonal: DtoResponseSelectStockPersonal[]): Promise<void> => {
    respStockPersonal.map((e) => {
      if (e.TipoStock.Nombre === "NUEVO" || e.TipoStock.Nombre === "MAQUINARIA") {
        e.Stock = e.Despacho - e.Devolucion - e.Liquidacion + e.DespachoTR - e.DevolucionTR;
        //if(e.Stock <= 0) { e.Stock = 0 }
        //e.Stock = e.Stock <= 0 ? 0 : e.Stock
      } else {
        e.Stock = e.Liquidacion - e.Devolucion;
      }
    });
  }

  const sumaStockPersonal = async (respStockPersonal: DtoResponseSelectStockPersonal[]): Promise<void> => {
    const validarStock = (stock: number) => stock > 0 ? stock : 0
    const stockPersonalTotal: DtoResponseSelectStockPersonal[] = respStockPersonal.reduce((acumulador: DtoResponseSelectStockPersonal[], clave) => {
      const indice = acumulador.findIndex((p: DtoResponseSelectStockPersonal) => p.CodigoLlave === clave.CodigoLlave);
      if (indice !== -1) {
        acumulador[indice].TotalStock += clave.Stock;
        acumulador[indice].TotalDespacho += clave.Despacho;
        acumulador[indice].TotalDevolucion += clave.Devolucion;
        acumulador[indice].TotalLiquidacion += clave.Liquidacion;
      } else {
        acumulador.push({
          ...clave, CodigoLlave: clave.CodigoLlave,
          TotalStock: clave.Stock,
          TotalDespacho: clave.Despacho,
          TotalDevolucion: clave.Devolucion,
          TotalLiquidacion: clave.Liquidacion
        });
      }
      return acumulador;
    }, []).map((item) => ({ ...item, TotalStock: validarStock(item.TotalStock) }));
    setStockPersonalAux(stockPersonalTotal);
    setStockPersonal(stockPersonalTotal);
    dispatch(saveDataStockPersonalTotal(stockPersonalTotal));
  };

  const onChange = (name: string, value: any) => {
    try {
      formSearch.setFieldValue(name, value);
      if (["textSearch"].includes(name)) {
        dispatch(changeTextSeach(value));
        setStockPersonalAux(stockPersonal.filter((el) => {
          if (el.Item.Codigo.includes(value.toUpperCase()) ||
            el.Item.Codigo.includes(value.toLowerCase()) ||
            el.Item.Nombre.includes(value.toUpperCase()) ||
            el.Item.Nombre.includes(value.toLowerCase())) return el;
        }));
      }
    } catch (error) {
      console.error(error);
      let err: ErrorCostume = new ErrorCostume((error as Error).message);
      AdapterGenerico.createMessage('Alerta', err.message, 'warning', false);
    }
  }
  return {
    init,
    user,
    formSearch,
    stockPersonalAux,
    onChange,
    loadData
  };
}
