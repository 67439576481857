import { NavigateFunction, useNavigate } from 'react-router-dom'
import { Dispatch } from 'redux'
import { useDispatch } from 'react-redux'
import { AdapterConfigure } from './AdapterConfigure'
import {
  addLoading,
  hideIconMenu,
  removeLoading,
} from '../../../../shared/Infraestructure/SliceGenerico'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { ErrorCostume } from '../../../../shared/Domain/ErrorCostume'
import { RootState } from '../../../../shared/Infraestructure/AdapterStore'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { FormSearchValues } from '../Domain/IForms'
import { UseCaseSelectObras } from '../Application/UseCaseSelectObras'
import { useSelector } from 'react-redux'
import { RepositoryImplMain } from './RepositoryImplMain'
import { changeObra, changeObras } from '../Domain/SliceReplanteo'
import { UseCaseSelectObra } from '../Application/UseCaseSelectObra'
import { IManoObraEditados, IReservaEditados } from '../Domain/IEditados'
import { DocumentacionReplanteo } from 'sigo-package'
import { DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { DtoDataCatalogoItem, DtoItem } from '../../../Home/Domain/DtoItem'
import { DtoAmap, DtoManoObraBaremo } from '../../../Home/Domain/DtoAmap'
import { UseCaseDownloadFiles } from '../Application/UseCaseDownloadFiles'
import { DtoGoms, DtoObras } from '../Domain/DtoObras'
import { DtoItems } from '../../../../../app/Domain/DtoItems'
import { UseCaseLoadData } from '../Application/useCaseLoadData'
import { DtoPrecioMaterial } from '../../../../../app/Domain/DtoPrecioMaterial'
import { IUpdateParams, UseCaseUpdateGom } from '../Application/UseCaseUpdateGom'
import { UseCaseUploadFiles } from '../Application/UseCaseUploadFiles'
import { DtoManoObraGlobal } from '../../../../../app/Domain/DtoManoObraGlobal'
import { DtoGeneralCodigoNombre } from '../../../../shared/Domain/Dto/DtoGeneralCodigoNombre'
import { DtoAgencias, DtoPrecioEspecialidad } from '../../../../../app/Domain/DtoPrecioEspecialidad'
import { DtoResponseEstadoInterno } from '../../../../../app/Domain/DtoResponseEstadoInterno'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import { FirmaReplanteoDto } from '../Domain/FirmaReplanteoDto'
import { DtoUsuario } from '../../../../shared/Domain/Dto/DtoUsuario'

export const Controller = () => {

  const dispatch: Dispatch = useDispatch()
  const navigate: NavigateFunction = useNavigate()
  const languageTranslate = LanguageTranslate()
  const { user } = useSelector((state: RootState) => state.auth)
  const { obras, obra } = useSelector((state: RootState) => state.replanteo)
  const { websocket, dbLocal } = useSelector((state: RootState) => state.generico)
  const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY)

  const [view, setView] = useState<string>('BuscarObra')
  const [reservaEditados, setReservaEditados] = useState<IReservaEditados>({})
  const [manoObraEditados, setManoObraEditados] = useState<IManoObraEditados>({})
  const [datosMateriales, setDatosMateriales] = useState<DtoItem[]>([])
  const [datosManoObra, setDatosManoObra] = useState<DtoAmap[]>([])
  const [documentacionReplanteo, setDocumentacionReplanteo] = useState<DtoNodoDoc[]>(DocumentacionReplanteo)
  const [newFilesUpload, setNewFilesUpload] = useState<File[]>([])
  const [dataItems, setDataItems] = useState<DtoItems[]>([])
  const [dataPrecioMaterial, setDataPrecioMaterial] = useState<DtoPrecioMaterial[]>([])
  const [dataPrecioEspecialidad, setDataPrecioEspecialidad] = useState<DtoPrecioEspecialidad[]>([])
  const [dataManoObraGlobal, setDataManoObraGlobal] = useState<DtoManoObraGlobal[]>([])
  const [selectManoObraGlobal, setSelectManoObraGlobal] = useState<DtoManoObraGlobal | null>(null)
  const [dataEspecialidad, setDataEspecialidad] = useState<DtoGeneralCodigoNombre[]>([])
  const [dataEstadoInterno, setDataEstadoInterno] = useState<DtoResponseEstadoInterno[]>([])
  const [showModal, setModal] = useState<boolean>(false)
  const [UsuarioEnSesion, setUsuarioEnSesion] = useState<boolean>(false)
  const [firmas, setFirmas] = useState<FirmaReplanteoDto[]>([])
  const [cargoSelect, setCargoSelect] = useState<string>('')
  const [usuario, setUsuario] = useState<DtoUsuario>(new DtoUsuario())
  const [disabledInputCkech, setDisabledInputCkech] = useState<boolean>(false)

  useEffect(() => {
    if (firmas.length === 1 && !firmas.some(e => e.usuarioEnSesion)) {
      setUsuarioEnSesion(true)
      setDisabledInputCkech(true)
    }
  }, [firmas])

  const formSearch = useFormik({
    initialValues: {
      textSearch: '',
    } as FormSearchValues,
    validationSchema: Yup.object({
      textSearch: Yup.string()
        .required('Ingrese una Orden de Trabajo')
        .min(5, 'Orden de Trabajo debe tener al menos 5 caracteres')
        .matches(/^[a-zA-Z0-9\s-]+$/, 'Orden de Trabajo solo puede contener letras, números, guiones y espacios')
        .nullable(),
    }),
    onSubmit: async (values, formikHelpers) => {
      try {
        dispatch(addLoading({ textLoading: `Buscando resultados para ${formSearch.values.textSearch}...` }))
        const response = await new UseCaseSelectObras(repository).exec(user.Delegacion.Codigo, user.Pais.Nombre, values.textSearch)
        if (response.length < 1) throw Error(`No se encontró resultados para la busqueda.`)
        dispatch(changeObras(response))
      } catch (err) {
        mostrarMsgError(err)
      } finally {
        dispatch(removeLoading())
      }
    },
  })

  const init = async () => {
    try {
      dispatch(hideIconMenu())
      await loadData()
    } catch (err) {
      mostrarMsgError(err)
      navigate(AdapterConfigure.ROUTE_PROGRAMADOS, { replace: true })
    } finally {
      dispatch(removeLoading())
    }
  }

  const loadData = async () => {
    dispatch(addLoading({ textLoading: `Cargando datos...` }))
    const { Items, PrecioMaterial, ManoObraGlobal, PrecioEspecialidad, EstadoInterno } = await new UseCaseLoadData(repository).exec(user)
    setDataItems(Items)
    setDataPrecioMaterial(PrecioMaterial)
    setDataManoObraGlobal(ManoObraGlobal)
    setDataPrecioEspecialidad(PrecioEspecialidad)
    setDataEstadoInterno(EstadoInterno)
  }

  const selectObra = async (ID_Incidencia: number) => {
    try {
      await init()
      const obra = obras.find(e => e.ID_incidencia === ID_Incidencia)
      if (!obra) throw new Error(`¡Error al buscar Obra! Comunícate con Soporte`)

      dispatch(addLoading({ textLoading: `Buscando resultados para ${formSearch.values.textSearch}...` }))
      const response = await new UseCaseSelectObra(repository).exec(user.Delegacion.Codigo, user.Pais.Nombre, obra)
      dispatch(removeLoading())

      dispatch(changeObra(response[0]))
      setDataManoObraGlobal([...dataManoObraGlobal.filter(e => e.ContratoOT.Codigo === response[0].Contrato)])

      setView('Replanteo')
      setDatosMateriales(getDatosMateriales(response))
      setDatosManoObra(getDatosManoObra(response))

      const fileNames = prepareFileNames(response)
      dispatch(addLoading({ textLoading: `📂 Descargando Archivos...` }))
      const downloadedFiles = await new UseCaseDownloadFiles(repository).exec(fileNames)

      updateDocumentacionReplanteo(downloadedFiles)

    } catch (err) {
      mostrarMsgError(err)
      setView('BuscarObra')
    } finally {
      dispatch(removeLoading())
    }
  }

  const getDatosMateriales = (response: DtoObras[]) => {
    return response[0].ColeccionGoms[0].DatosMateriales.length
      ? response[0].ColeccionGoms[0].DatosMateriales.map(dm => {
        setReservaEditados((prevEditados) => ({ ...prevEditados, [dm.Codigo]: String(dm.Cantidad) }))
        const item = response[0].ColeccionReserva[0].Item.find(item => dm.Codigo === item.Codigo)
        return { ...dm, Cantidad: item ? item.Cantidad : 0 }
      })
      : response[0].ColeccionReserva[0].Item.map(dm => {
        setReservaEditados((prevEditados) => ({ ...prevEditados, [dm.Codigo]: String(dm.Cantidad) }))
        return dm
      })
  }

  const getDatosManoObra = (response: DtoObras[]) => {
    return response[0].ColeccionGoms[0].DatosManoObra.length
      ? response[0].ColeccionGoms[0].DatosManoObra.map(dmo => {
        const valorizacion = response[0].ColeccionManoObra[0].Valorizacion.find(val => dmo.Codigo === val.Codigo)
        setManoObraEditados((prevEditados) => ({ ...prevEditados, [dmo.Codigo]: String(dmo.Cantidad) }))
        return { ...dmo, Cantidad: valorizacion ? valorizacion.Cantidad : 0 }
      })
      : response[0].ColeccionManoObra[0].Valorizacion.map(dmo => {
        setManoObraEditados((prevEditados) => ({ ...prevEditados, [dmo.Codigo]: String(dmo.Cantidad) }))
        return dmo
      })
  }

  const prepareFileNames = (response: DtoObras[]) => {
    const fileNames: string[] = []
    documentacionReplanteo.forEach(e => {
      const files = response[0].ColeccionGoms[0].PlanoAuxiliarFinal.filter(pf => pf.CodigoNodo === e.Codigo && pf.Estado.ID_Estado === 1)
      files.forEach(f => fileNames.push(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_${f.Ruta}-${f.Filename}`))
      e.Files = files
    })
    return fileNames
  }

  const updateDocumentacionReplanteo = (downloadedFiles: File[]) => {
    const newDocumentacionReplanteoFiles = documentacionReplanteo.map(e => ({
      ...e,
      Files: e.Files.map(file => {
        const fileSearch = downloadedFiles.find(fd => fd.name === `${user.Pais.Nombre}_${user.Delegacion.Codigo}_${file.Ruta}-${file.Filename}`)
        return fileSearch ? { ...file, File: fileSearch } : file
      })
    }))
    setDocumentacionReplanteo(newDocumentacionReplanteoFiles)
  }

  const onSave = async (firma?: boolean) => {
    try {
      if (!validarCambiosMateriales() && !validarCambiosManoObra() && !validarAdjuntos()) {
        AdapterGenerico.createMessage('¡Información!', `No hay Cambios que guardar`, 'info', false)
        return
      }

      if (!firma) {
        const confirmacion = obra?.ColeccionGoms[0].Ultimo_Estado_Interno.Descripcion.trim() === 'ENVIADO'
          ? true
          : await AdapterGenerico.createMessage('¿Firmar?', '¿Desea firmar replanteo?', 'question', true, 'body', () => { }, languageTranslate.btnGuardar, '#28a745')
        if (confirmacion) {
          setModal(true)
          setFirmas([])
          setCargoSelect('')
          setUsuarioEnSesion(false)
          setUsuario(new DtoUsuario())
          setDisabledInputCkech(false)
          return
        }
      }

      // const firmaLider = firmas.find(e => e.Cargo === 'Lider')
      // if (!firmaLider && firma) {
      //   await AdapterGenerico.createMessage('', `¡No se pudo obtener firma del Lider!`, 'warning')
      //   return
      // }

      const firmaSupervisor = firmas.find(e => e.Cargo === 'Supervisor')
      if (!firmaSupervisor && firma) {
        await AdapterGenerico.createMessage('', `¡No se pudo obtener firma del Supervisor!`, 'warning')
        return
      }

      const firmaTecnico = firmas.find(e => e.Cargo === 'Tecnico')
      if (!firmaTecnico && firma) {
        await AdapterGenerico.createMessage('', `¡No se pudo obtener firma del Tecnico!`, 'warning')
        return
      }

      await updateGom()
    } catch (err) {
      mostrarMsgError(err)
      setView('BuscarObra')
    } finally {
      dispatch(removeLoading())
    }
  }

  const onSendFirm = async (base64String: string | undefined, Usuario: DtoUsuario) => {
    try {
      if (base64String === undefined) return await AdapterGenerico.createMessage('', `No se ha firmado el albarán.`, 'warning')
      if (!cargoSelect) return await AdapterGenerico.createMessage('', `Seleccione un Cargo`, 'warning')

      const extension = AdapterGenerico.obtenerExtensionDesdeBase64(base64String)
      if (!extension) return await AdapterGenerico.createMessage('', `¡No se pudo obtener extensión de la imagen! Comunícate con Soporte`, 'warning')

      if (UsuarioEnSesion) {
        Usuario.Identificacion = user.Identificacion
        Usuario.Apellidos = `${user.ApellidoPaterno} ${user.ApellidoMaterno}`
        Usuario.Nombres = user.Nombres
      }

      if (Usuario.Identificacion.length < 4) return await AdapterGenerico.createMessage('', `¡Ingrese una Identificación válida!`, 'warning')
      if (Usuario.Apellidos.length < 2) return await AdapterGenerico.createMessage('', `¡Ingrese Apellidos!`, 'warning')
      if (Usuario.Nombres.length < 2) return await AdapterGenerico.createMessage('', `¡Ingrese Nombres!`, 'warning')

      const ruta: string = `${obra?.ColeccionGoms[0].Pais.Nombre}_${obra?.ColeccionGoms[0].Delegacion.Codigo}_GOM_${obra?.ColeccionGoms[0].ID_GOM}_Replanteo_Firmas-${AdapterGenerico.generarStringUnico()}.${extension}`
      const fileFirma = AdapterGenerico.base64ToFile(base64String, ruta)

      const firmaExistente = firmas.find(firma => firma.Cargo === cargoSelect)

      firmaExistente
        ? setFirmas(prevFirmas => prevFirmas.map(firma => firma.Cargo === cargoSelect ? { ...firma, Img: ruta, file: fileFirma, Usuario } : firma))
        : setFirmas(prevFirmas => [...prevFirmas, { Cargo: cargoSelect, file: fileFirma, Img: ruta, Usuario, usuarioEnSesion: UsuarioEnSesion }])

      setCargoSelect('')
      setUsuarioEnSesion(false)
      setUsuario(new DtoUsuario())
      await AdapterGenerico.createMessage('¡Éxito!', 'Se firmó correctamente.', 'success', false)
    } catch (error) {
      mostrarMsgError(error)
      setModal(false)
    }
  }

  const updateGom = async () => {
    const newFilesUploadFirmas: File[] = firmas.filter(e => e.file !== null && e.file !== undefined).map(e => e.file) as File[]
    const paramsAux: IUpdateParams = {
      CodigoDelegacion: user.Delegacion.Codigo,
      NombrePais: user.Pais.Nombre,
      datosManoObra: structuredClone(datosManoObra),
      datosMateriales: structuredClone(datosMateriales),
      documentacionReplanteo,
      manoObraEditados,
      reservaEditados,
      gom: structuredClone(obra?.ColeccionGoms[0]) || {} as DtoGoms,
      usuario: user,
      dataEstadoInterno,
      FirmasRePlanteo: firmas
    }
    dispatch(addLoading({ textLoading: `Actualizando GOM...` }))
    const response = await new UseCaseUpdateGom(repository).exec(paramsAux)
    dispatch(removeLoading())
    if (response.length !== 1) throw Error(`¡Error al actualizar GOM! Comunícate con Soporte`)
    dispatch(addLoading({ textLoading: `Subiendo Archivos...` }))
    await new UseCaseUploadFiles(repository).exec([...newFilesUpload, ...newFilesUploadFirmas])
    dispatch(removeLoading())
    await AdapterGenerico.createMessage('¡Éxito!', `Se Guardó correctamente`, 'success', false)
    setView('BuscarObra')
    setNewFilesUpload([])
    setDocumentacionReplanteo(DocumentacionReplanteo)
    setReservaEditados({})
    setManoObraEditados({})
    setModal(false)
    setFirmas([])
    setCargoSelect('')
    setUsuarioEnSesion(false)
    setUsuario(new DtoUsuario())
    setDisabledInputCkech(false)
  }

  const validarCambiosMateriales = () => {
    if (!obra) return false


    const materiales = obra.ColeccionGoms[0].DatosMateriales.length
      ? obra.ColeccionGoms[0].DatosMateriales
      : obra.ColeccionReserva[0].Item;

    if (datosMateriales.length !== materiales.length) return true

    return materiales.some(mat => String(mat.Cantidad) !== reservaEditados[mat.Codigo])
  }

  const validarCambiosManoObra = () => {
    if (!obra) return false

    const valorizaciones = obra.ColeccionGoms[0].DatosManoObra.length
      ? obra.ColeccionGoms[0].DatosManoObra
      : obra.ColeccionManoObra[0].Valorizacion;

    if (datosManoObra.length !== valorizaciones.length) return true

    return valorizaciones.some(mat => String(mat.Cantidad) !== manoObraEditados[mat.Codigo])
  }

  const validarAdjuntos = () => {
    if (!obra) return false
    return JSON.stringify(obra.ColeccionGoms[0].PlanoAuxiliarFinal) !== JSON.stringify(documentacionReplanteo.map(dr => dr.Files.map(f => f)).flat().map(e => { delete e.File; return e }))
  }

  const onChange = async (name: string, value: any) => {
    try {
      if (value === null) return
      switch (name) {
        case 'Item':
          const PrecioMaterial = dataPrecioMaterial.find(e => e.Item.Codigo === value.dataComplete.Codigo && e.Contrato.Codigo === obra?.Contrato)
          if (!PrecioMaterial) throw Error(`¡No se encontró Precio Material! Refresca la coleccion PrecioMaterial o Comunícate con Soporte`)
          const newItem = GenerarDtoItem(value.dataComplete, PrecioMaterial)
          setDatosMateriales([...datosMateriales, newItem])
          setReservaEditados((prevEditados) => ({ ...prevEditados, [newItem.Codigo]: String(newItem.Cantidad) }))
          AdapterGenerico.createMessage('Éxito', 'Se agregó Material', 'info', false)
          break
        case 'ManoObraGlobal':
          setSelectManoObraGlobal(value.dataComplete)
          setDataEspecialidad(value.dataComplete.DetalleChile.ManoObraBaremo.map((mo: DtoManoObraBaremo) => ({
            value: mo.Especialidad.Codigo, label: `${mo.Especialidad.Codigo} - ${mo.Especialidad.Nombre}`, dataComplete: mo.Especialidad
          })))
          break
        case 'Especialidad':
          if (!selectManoObraGlobal) throw Error(`¡Error al agregar Mano Obra! Comunícate con Soporte`)

          const PrecioEspecialidad = dataPrecioEspecialidad.filter(e => e.ContratoOT.Codigo === obra?.Contrato && e.Especialidad.Codigo === value.value)
          if (PrecioEspecialidad.length !== 1) throw Error(`¡No se pudo obtener Precio Especialidad! Refresque Precio Especialidad o Comunícate con soporte`)

          const Agencia = PrecioEspecialidad[0].Agencias.filter(e => e.Codigo === obra?.DetalleChile.agencia)
          if (Agencia.length !== 1) throw Error(`¡No se pudo obtener Agencia! Refresque Precio Especialidad o Comunícate con soporte`)

          const CodigoValorizacion = datosManoObra.length ? datosManoObra[0].DetalleChile.Codigo : '00000'

          const newManoObraGlobal = GenerarDtoAmap(selectManoObraGlobal, value.dataComplete, PrecioEspecialidad[0], Agencia[0], CodigoValorizacion)
          setDatosManoObra([...datosManoObra, newManoObraGlobal])
          setManoObraEditados((prevEditados) => ({ ...prevEditados, [newManoObraGlobal.Codigo]: String(newManoObraGlobal.Cantidad) }))
          AdapterGenerico.createMessage('Éxito', 'Se agregó Mano Obra', 'info', false)
          setSelectManoObraGlobal(null)
          setDataEspecialidad([])
          break
        case 'deleteItem':
          setDatosMateriales([...datosMateriales.filter(e => e.Codigo !== value)])
          setReservaEditados(({ [value]: _, ...rest }) => rest);
          break
        case 'deleteManoObra':
          setDatosManoObra([...datosManoObra.filter(e => e.DetalleChile.ManoObra.Codigo !== value)])
          setManoObraEditados(({ [value]: _, ...rest }) => rest);
          break
        case 'Cargo':
          setCargoSelect(value.value)
          break
        case 'UsuarioEnSesion':
          setUsuarioEnSesion(value)
          break
        default:
          break
      }
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false)
    }
  }

  const mostrarMsgError = (err: any) => {
    console.error(err)
    const error: ErrorCostume = new ErrorCostume((err as Error).message)
    AdapterGenerico.createMessage('Error', error.message, 'error', false)
  }

  return {
    init,
    view,
    formSearch,
    obras,
    selectObra,
    setReservaEditados,
    reservaEditados,
    setManoObraEditados,
    manoObraEditados,
    documentacionReplanteo,
    setDocumentacionReplanteo,
    datosMateriales,
    datosManoObra,
    newFilesUpload,
    setNewFilesUpload,
    dataItems,
    onChange,
    onSave,
    dataManoObraGlobal,
    dataEspecialidad,
    selectManoObraGlobal,
    setModal,
    showModal,
    onSendFirm,
    cargoSelect,
    firmas,
    UsuarioEnSesion,
    usuario,
    setUsuario,
    disabledInputCkech
  }
}

function GenerarDtoAmap(ManoObra: DtoManoObraGlobal, Especialidad: DtoGeneralCodigoNombre, PrecioEspecialidad: DtoPrecioEspecialidad, Agencia: DtoAgencias, CodigoValorizacion: string): DtoAmap {
  const ManoObraBaremo = ManoObra.DetalleChile.ManoObraBaremo.filter(e => e.Especialidad.Codigo === Especialidad.Codigo)
  if (ManoObraBaremo.length !== 1) throw Error(`¡Error al Obtener Mano Obra Baremo! Comunícate con Soporte`)

  const newAmap = new DtoAmap()
  newAmap.Amap = ManoObra.Amap
  newAmap.Codigo = ManoObra.Codigo
  newAmap.Descripcion = ManoObra.Actividad
  newAmap.Precio = ManoObraBaremo[0].PuntoBaremo * Agencia.Ultimo_Precio.Precio
  newAmap.Unidad = ManoObra.Unidad.Nombre
  newAmap.Moneda = PrecioEspecialidad.Moneda
  newAmap.Simbolo = PrecioEspecialidad.Simbolo
  newAmap.Decimales = PrecioEspecialidad.Decimales
  newAmap.WBE.ID_WBE = 2
  newAmap.WBE.CodigoWBE = 'CREATEBYREPLANTEO'
  newAmap.WBE.DescripcionWBE = 'CREADO DESDE REPLANTEO PWA'
  newAmap.DetalleChile.Codigo = CodigoValorizacion
  newAmap.DetalleChile.Especialidad.Codigo = Especialidad.Codigo
  newAmap.DetalleChile.Especialidad.Nombre = Especialidad.Nombre
  newAmap.DetalleChile.Actividad.Codigo = 'A'
  newAmap.DetalleChile.Actividad.Nombre = 'ACTIVIDAD GENERAL'
  newAmap.DetalleChile.Clave.Codigo = 'C001'
  newAmap.DetalleChile.Clave.Nombre = 'CLAVE GENERAL'
  newAmap.DetalleChile.Tarea = '0000'
  newAmap.DetalleChile.ManoObra.Codigo = ManoObra.Codigo
  newAmap.DetalleChile.ManoObra.Nombre = ManoObra.Actividad
  newAmap.DetalleChile.ManoObra.UnidadMedida = ManoObra.Unidad.Nombre
  newAmap.DetalleChile.UnidadObra.Codigo = '0'
  newAmap.DetalleChile.UnidadObra.Nombre = 'SIN UO'
  newAmap.DetalleChile.UnidadObra.UnidadMedida = 'UN'
  newAmap.DetalleChile.PuntoBaremo = ManoObraBaremo[0].PuntoBaremo

  newAmap.DatosCatalogoMO.DatosManoObraGlobal.Actividad = ManoObra.Actividad
  newAmap.DatosCatalogoMO.DatosManoObraGlobal.Alcance = ManoObra.Alcance
  newAmap.DatosCatalogoMO.DatosManoObraGlobal.DetalleChile.ManoObraBaremo = ManoObra.DetalleChile.ManoObraBaremo
  newAmap.DatosCatalogoMO.DatosManoObraGlobal.Material = ManoObra.Material

  newAmap.DatosCatalogoMO.DatosPrecioEspecialidad.Agencias = PrecioEspecialidad.Agencias
  newAmap.DatosCatalogoMO.DatosPrecioEspecialidad.Contratistas = PrecioEspecialidad.Contratistas
  newAmap.DatosCatalogoMO.DatosPrecioEspecialidad.Decimales = PrecioEspecialidad.Decimales
  newAmap.DatosCatalogoMO.DatosPrecioEspecialidad.Moneda = PrecioEspecialidad.Moneda
  newAmap.DatosCatalogoMO.DatosPrecioEspecialidad.Simbolo = PrecioEspecialidad.Simbolo

  newAmap.DatosCatalogoMO.DatosUnidadObraMaterial.Codigo = '0'
  newAmap.DatosCatalogoMO.DatosUnidadObraMaterial.Descripcion = 'SIN UO'
  newAmap.DatosCatalogoMO.DatosUnidadObraMaterial.Materiales = []

  return newAmap
}

function GenerarDtoItem(Item: DtoItems, PrecioItem: DtoPrecioMaterial): DtoItem {
  const DataCatalogoItem = GenerarDtoDataCatalogoItem(Item, PrecioItem)
  const newItem = new DtoItem()
  newItem.ID_Item = Item.ID_Item
  newItem.Empresa.Codigo = Item.Empresa.CodigoGlobal
  newItem.Empresa.RazonSocial = Item.Empresa.RazonSocial
  newItem.Codigo = Item.Codigo
  newItem.Nombre = Item.Nombre
  newItem.Descripcion = Item.Descripcion
  newItem.UnidadMedida.ID_UnidadMedida = Item.UnidadMedida[0].ID_UnidadMedida
  newItem.UnidadMedida.Nombre = Item.UnidadMedida[0].Nombre
  newItem.Ind_SB = Item.Ind_SB
  newItem.Valor = PrecioItem.Precio
  newItem.WBE.ID_WBE = 2
  newItem.WBE.CodigoWBE = 'CREATEBYREPLANTEO'
  newItem.WBE.DescripcionWBE = 'CREADO DESDE REPLANTEO PWA'
  newItem.Moneda = PrecioItem.Moneda
  newItem.Simbolo = PrecioItem.Simbolo
  newItem.Decimales = PrecioItem.Decimales
  newItem.DatosCatalogoItem = DataCatalogoItem

  return newItem
}

function GenerarDtoDataCatalogoItem(Item: DtoItems, PrecioItem: DtoPrecioMaterial) {
  const newDataCatalogoItem = new DtoDataCatalogoItem()
  newDataCatalogoItem.DatosPrecioMaterial.Decimales = PrecioItem.Decimales
  newDataCatalogoItem.DatosPrecioMaterial.Moneda = PrecioItem.Moneda
  newDataCatalogoItem.DatosPrecioMaterial.Precio = PrecioItem.Precio
  newDataCatalogoItem.DatosPrecioMaterial.PreciosVigentes = PrecioItem.PreciosVigentes
  newDataCatalogoItem.DatosPrecioMaterial.Simbolo = PrecioItem.Simbolo
  newDataCatalogoItem.DatosPrecioMaterial.Vigencia = PrecioItem.Vigencia

  newDataCatalogoItem.DatosItem.Ind_SB = Item.Ind_SB
  newDataCatalogoItem.DatosItem.Propio = Item.Propio
  newDataCatalogoItem.DatosItem.Tipo = Item.Tipo
  newDataCatalogoItem.DatosItem.Valor = Item.Valor
  return newDataCatalogoItem
}