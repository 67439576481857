import { ElementBreadCrumb } from '../../../shared/Components/ElementBreadCrumb';
import { InputTextSearch } from '../../../shared/Components/ElementInputSearch';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import './Style.scss';
import { DtoTrabajosStock } from '../Domain/DtoTrabajosStock';
import { ElementItemCardGenerico } from '../../../shared/Components/Programados/ElementItemCardGenerico';
import { AdapterConfigure } from '../Infraestructure/AdapterConfigure';
import ButtonFloating from '../../../shared/Components/ButtonFloating/ButtonFloating';

interface PropsViewMain {
  onChange: (name: string, value: any) => void;
  formSearch: {
    values: any;
    touched: any;
    errors: any;
    handleBlur: Function;
  };
  Trabajos: DtoTrabajosStock[];
  selectTrabajosDB: () => void;
};

// const animatedComponents = makeAnimated();
export const ViewMain = (props: PropsViewMain) => {
  const {
    onChange,
    formSearch,
    Trabajos,
    selectTrabajosDB
  } = props;

  const languageTranslate = LanguageTranslate();

  return (

    <div className='ViewProfile'>
      <div className="container-fluid">
        {/** BLOQUE DE BREADCRUMB */}
        <ElementBreadCrumb list={
          [
            { text: languageTranslate.moduloMain.textoLogistica, navigate: true, path: '/main/logistica' },
            { text: languageTranslate.general.Trabajos, navigate: false, path: '' }
          ]
        } />

        <InputTextSearch
          name='textSearch'
          onChange={onChange}
          values={formSearch.values}
        />
        {Trabajos.map((row, index) => (
          <ElementItemCardGenerico
            key={index}
            icono
            text1={languageTranslate.general.Obra}
            data1={row.OrdenTrabajo}

            text2={languageTranslate.general.Estado}
            data2={row.Estado.Nombre}

            linkIcono1={`${AdapterConfigure.REDIRECT_STOCK_PERSONAL}/${row.ID_Trabajo}`}
          ></ElementItemCardGenerico>
        ))}
        <ButtonFloating
          onClick={selectTrabajosDB}
          icono='fa-solid fa-arrows-rotate'
          colorIcono='#2C3E50'
        />
      </div>
    </div >
  )
};