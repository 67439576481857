import { useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { AdapterConfigure } from "./AdapterConfigure";
import { RepositoryImplMain } from "./RepositoryImplMain";
import { RootState } from "../../../../shared/Infraestructure/AdapterStore";
import {
    addLoading,
    hideIconMenu,
    removeLoading,
} from "../../../../shared/Infraestructure/SliceGenerico";
import { AdapterGenerico } from "../../../../shared/Infraestructure/AdapterGenerico";
import { DtoTrabajos } from "../../../../Master/Home/Domain/DtoTrabajos";
import { UseCaseSelectTrabajoLocal } from "../Application/UseCaseSelectTrabajoLocal";

export const Controller = () => {
    const { websocket, dbLocal } = useSelector((state: RootState) => state.generico)
    const { user } = useSelector((state: RootState) => state.auth)

    const dispatch: Dispatch = useDispatch();
    const params = useParams<{ id: string }>();
    const navigate: NavigateFunction = useNavigate();
    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);
    const [trabajo, setTrabajo] = useState<DtoTrabajos>({} as DtoTrabajos);

    const init = async () => {
        try {
            dispatch(hideIconMenu());
            await loadData();
        } catch (error) {
            console.error(error);
            AdapterGenerico.createMessage("Alerta", (error as Error).message, "warning", false);
            navigate(AdapterConfigure.ROUTE_PROGRAMADOS, { replace: true });
        } finally {
            dispatch(removeLoading());
        }
    };

    const loadData = async () => {
        dispatch(addLoading({ textLoading: "Cargando trabajo..." }));
        const trabajo = await new UseCaseSelectTrabajoLocal(repository).exec(user, Number(params.id));
        dispatch(removeLoading());
        setTrabajo(trabajo);
    };

    return {
        init,
        trabajo,
    };
};
