import { DtoTrabajos } from "../../Home/Domain/DtoTrabajos";
import { RepositoryMain } from "../Domain/RepositoryMain";
import { EntityDataUsuario } from "../../../shared/Domain/EntityDataUsuario";

export class UseCaseSelectTrabajosLocal {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(user: EntityDataUsuario): Promise<Array<DtoTrabajos>> {
        return await this._exec(user);
    }

    private async _exec(user: EntityDataUsuario): Promise<Array<DtoTrabajos>> {
        return await this.repository.selectTrabajosLocal(user);
    }
}
