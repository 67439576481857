import './AccordionAdjuntos.scss'
import React, { useEffect, useState } from 'react'
import { Accordion, Button, Card, ListGroup } from 'react-bootstrap'
import { DtoNodoDoc } from '../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { LoadImage } from '../ElementInputFileImage'
import { ElementInputFileAdjunto } from '../Programados/ElementInputFileAdjunto'
import { DtoFilesDoc, DtoFlujoDoc } from '../../../Programados/Trabajos/Trabajos/Domain/DtoFilesDoc'
import { AdapterGenerico } from '../../Infraestructure/AdapterGenerico'
import { AdapterGeolocation } from '../../Infraestructure/AdapterGeolocation'
import ImageViewer from '../ImageViewer/ImageViewer'
import { saveAs } from 'file-saver'
import { useSelector } from 'react-redux'
import { RootState } from '../../Infraestructure/AdapterStore'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { addLoading, removeLoading } from '../../Infraestructure/SliceGenerico'
import { FTPService } from '../../Infraestructure/AdapterFileSystem'
import { AddressDE } from './AddressDE'
import { DtoHPList } from '../../../Programados/TrabajosDE/Trabajos/Domain/DtoTrabajosDE'
import { DtoValueOnSelect } from '../../../Programados/TrabajosDE/Preliquidar/Domain/DtoValueOnSelect'
import { DtoHPListType } from '../../Domain/Dto/DtoHPListType'
import EXIF from 'exif-js'

interface IPropsAdjuntos {
  Nodos: DtoNodoDoc[]
  NodoPrincipal?: DtoNodoDoc[]
  setNodos: React.Dispatch<React.SetStateAction<DtoNodoDoc[]>>
  setNewFiles: React.Dispatch<React.SetStateAction<File[]>>
  newFiles: File[]
  ruta: string
  colorNodos?: boolean
  dataAddress: DtoHPListType[]
  save: boolean
  btnDelete?: boolean
  btnAdjuntar?: boolean
}

export const AccordionAdjuntos = (props: IPropsAdjuntos): React.ReactElement => {

  const {
    Nodos,
    NodoPrincipal,
    setNodos,
    newFiles,
    setNewFiles,
    ruta,
    colorNodos,
    dataAddress,
    save,
    btnDelete = true,
    btnAdjuntar = true,
  } = props

  const dispatch: Dispatch = useDispatch()
  const [showImageViewer, setShowImageViewer] = useState<boolean>(false)
  const [fileImageViewer, setFileImageViewer] = useState<File | null>(null)
  const [archivo, setArchivo] = useState<DtoFilesDoc>()
  const extensionImagen = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'tiff', 'webp', 'svg', 'raw', 'psd', 'eps', 'ico'].map(e => e.toLowerCase().trim())
  const { user } = useSelector((state: RootState) => state.auth)

  const GetLocation = async () => {
    const coodenadas = await AdapterGeolocation.getLocation()
    return { X: String(coodenadas.longitud), Y: String(coodenadas.latitud) }
  }

  useEffect(() => {
    const getLocation = async () => {
      try {
        GetLocation()
      } catch (error) {
        AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false)
      }
    }
    getLocation()
  }, [])

  const onAdd = async (imagenes: LoadImage[], nodo: DtoNodoDoc, imgInHead: boolean = false) => {
    try {
      const newFilesAux: DtoFilesDoc[] = []

      for (const imagen of imagenes) {

        const newFlujoDoc = new DtoFlujoDoc()
        newFlujoDoc.Action = 'Registrar'
        newFlujoDoc.Description = 'Registrar'
        newFlujoDoc.Posicion = '1'
        newFlujoDoc.Icono = 'fa fa-registered'
        newFlujoDoc.ColorIcono = '#1d9d74'
        newFlujoDoc.TagPagina = ['tagGestionMacroObrasNuevo']

        const Extension = imagen.File.name.split('.').pop()?.toLowerCase().trim() || imagen.File.type.split('/')[1].trim().toLowerCase()
        if (nodo.Files.filter(e => e.Estado.ID_Estado === 1).length >= nodo.CantidadMax && nodo.CantidadMax > 0) return AdapterGenerico.createMessage('Error', 'No se permiten más documentos', 'warning', false)
        if (!nodo.Extension.some(e => e.toUpperCase() === 'ALL')) {
          if (!nodo.Extension.some(e => e.toLowerCase().trim() === Extension.toLowerCase().trim())) {
            return AdapterGenerico.createMessage('Error', 'Tipo de Archivo no permitido', 'warning', false)
          }
        }
        const newAdjunto = new DtoFilesDoc()
        newAdjunto.CodigoNodo = nodo.Codigo
        //#region Coordenadas
        dispatch(addLoading({ textLoading: `Obteniendo Ubicación` }))
        if (navigator.onLine) {
          const Coordenadas = await GetLocation()
          newAdjunto.Coordenadas = Coordenadas
        } else {
          const Coordenadas = await verifyLocation(imagen.File)
          if (Coordenadas) newAdjunto.Coordenadas = Coordenadas
        }
        dispatch(removeLoading())
        //#endregion Coordenadas
        newAdjunto.Extension = Extension
        newAdjunto.Fecha_Archivo = new Date(imagen.File.lastModified)
        newAdjunto.Filename = `${ruta}${imagen.File.name}`
        newAdjunto.Flujos = [newFlujoDoc]
        newAdjunto.NameOriginal = imagen.NombreOriginal
        newAdjunto.Observacion = 'Cargado desde PWA'
        newAdjunto.Posicion = crypto.randomUUID()
        newAdjunto.Size.Size = imagen.File.size / 1048576
        newAdjunto.Size.UM = 'MB'
        newAdjunto.Ultimo_Flujo = newFlujoDoc
        newAdjunto.File = imagen.File
        newAdjunto.Ruta = ruta

        //#region Solo para Alemania Caso especial
        if (imgInHead) {
          newAdjunto.Estado.ID_Estado = 2024
          newAdjunto.Estado.Nombre = 'ALEMANIA_CLIENTES_NOCLIENTES_DOCDP'
        }
        //#endregion Solo para Alemania Caso especial

        if (newAdjunto.Estado.ID_Estado === 2024) console.log(newAdjunto)
        else newFilesAux.push(newAdjunto)

        const newFile = new File([imagen.File], `${user.Pais.Nombre}_${user.Delegacion.Codigo}_${ruta}-${newAdjunto.Filename}`, {
          type: imagen.File.type,
          lastModified: imagen.File.lastModified
        })
        newAdjunto.File = newFile
        setNewFiles([...newFiles, newFile])
      }

      if (NodoPrincipal) {
        addFileInNodo(NodoPrincipal, newFilesAux)
        setNodos(NodoPrincipal)
      }

    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false)
    } finally {
      dispatch(removeLoading())
    }
  }

  const verifyLocation = (file: File): Promise<{ X: string, Y: string } | null> => {
    return new Promise((resolve, reject) => {
      if (file.type && file.type.startsWith('image/')) {
        const reader = new FileReader()
        reader.onload = (e) => {
          if (e.target && e.target.result) {
            const img = new Image()
            img.onload = () => {
              // @ts-expect-error
              EXIF.getData(img, () => {
                const lat = EXIF.getTag(img, 'GPSLatitude')
                const lon = EXIF.getTag(img, 'GPSLongitude')
                const latRef = EXIF.getTag(img, 'GPSLatitudeRef') || 'N'
                const lonRef = EXIF.getTag(img, 'GPSLongitudeRef') || 'W'
                if (lat && lon) {
                  const latDecimal = ((lat[0] + lat[1] / 60 + lat[2] / 3600) * (latRef === 'N' ? 1 : -1)).toFixed(6)
                  const lonDecimal = ((lon[0] + lon[1] / 60 + lon[2] / 3600) * (lonRef === 'W' ? -1 : 1)).toFixed(6)
                  resolve({ Y: latDecimal, X: lonDecimal })
                } else {
                  resolve(null)
                }
              })
            }
            img.src = e.target.result as string
          }
        }
        reader.readAsDataURL(file)
      } else {
        resolve(null)
      }
    })
  }

  const onDelete = (file: DtoFilesDoc, nodo: DtoNodoDoc) => {
    try {
      const isNew = newFiles.find(e => e.name === `${user.Pais.Nombre}_${user.Delegacion.Codigo}_${file.Ruta}-${file.Filename}`)
      if (NodoPrincipal) {
        removeFileInNodo(NodoPrincipal, file, nodo, newFiles, !!isNew)
        setNodos(NodoPrincipal)
        setNewFiles((prev) => prev.filter(e => e.name !== `${user.Pais.Nombre}_${user.Delegacion.Codigo}_${file.Ruta}-${file.Filename}`))
      }
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false)
    }
  }

  const onDownloadImage = async (archivo: DtoFilesDoc) => {
    try {
      dispatch(addLoading({ textLoading: 'Consultando adjunto...' }))
      const isNew = newFiles.find(e => e.name === `${user.Pais.Nombre}_${user.Delegacion.Codigo}_${archivo.Ruta}-${archivo.Filename}`)
      let fileAux = isNew

      if (!fileAux) {
        const file = await FTPService.getFileToServer(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_${archivo.Ruta}-${archivo.Filename}`, 2)
        if (typeof file !== 'string' && typeof file.Archivo === 'string') {
          fileAux = AdapterGenerico.base64ToFile(file.Archivo, file.FileName)
        } else {
          throw Error(`¡No se pudo obtener archivo!`)
        }
      }

      if (extensionImagen.includes(archivo.Extension.toLowerCase().trim())) {
        setFileImageViewer(fileAux)
        setShowImageViewer(true)
        setArchivo(archivo)
        return
      }
      saveAs(fileAux, fileAux.name)
      if ('Notification' in window && Notification.permission === 'granted') {
        new Notification('Descarga completada', {
          body: `El archivo ${fileAux.name} se ha descargado correctamente.`,
        })
      }
    } catch (error) {
      AdapterGenerico.createToast((error as Error).message, 'error')
      console.error(error)
    } finally {
      dispatch(removeLoading())
    }
  }

  //#region Solo para Alemania Caso especial
  const onAddAddress = async (data: DtoValueOnSelect<DtoHPList>, nodo: DtoNodoDoc) => {
    try {
      dispatch(addLoading({ textLoading: `Obteniendo Ubicación` }))
      const Coordenadas = await GetLocation()
      dispatch(removeLoading())

      const newAdjunto = new DtoFilesDoc()
      newAdjunto.CodigoNodo = nodo.Codigo
      newAdjunto.Coordenadas = Coordenadas
      newAdjunto.NameOriginal = typeof data.value === 'string' ? data.value : 'pff'
      newAdjunto.Observacion = 'Cargado desde PWA'
      newAdjunto.Posicion = crypto.randomUUID()
      newAdjunto.Estado.ID_Estado = 8888
      newAdjunto.Estado.Nombre = 'ADDRESS'

      if (NodoPrincipal) {
        addAddressInNodo(NodoPrincipal, newAdjunto)
        setNodos(NodoPrincipal)
      }

    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false)
    } finally {
      dispatch(removeLoading())
    }
  }
  //#endregion Solo para Alemania Caso especial

  return (
    <>
      <Accordion className='w-100' >
        {
          Nodos.map((nodo, i) => {
            const color = colorNodos ? definirColor(nodo) : null
            return <Accordion.Item key={i} eventKey={String(i)}>
              <Accordion.Header className={color ? `bk-color-${color}` : ''}>
                <div className='d-flex w-100 justify-content-between'>
                  <div>
                    <span>{nodo.Titulo}</span>
                    {
                      nodo.Children.length ? null :
                        <span style={{ fontSize: '0.7rem', marginLeft: '0.2rem', color: `${colorNodos ? 'blue' : 'red'}` }}>{`Min ${nodo.CantidadMin}${nodo.CantidadMax !== 0 ? ` - Max ${nodo.CantidadMax}` : ``}`}</span>
                    }
                  </div>
                  {
                    (nodo.Children.length || !btnAdjuntar) ? null :
                      <div className='d-flex align-items-center'>

                        {
                          //#region Solo para Alemania Caso Especial
                          ['004'].includes(user.Pais.Codigo) &&
                          <div className='d-flex'>
                            {
                              user.SuperUsuario &&
                              <ElementInputFileAdjunto
                                icon={'fa-solid fa-paperclip'}
                                onChange={(documentos) => onAdd(documentos, nodo, true)}
                                size={17}
                                allowedFormats={nodo.Extension}
                                capture={extensionImagen.some(elemento => nodo.Extension.includes(elemento))}
                              />
                            }
                            {
                              nodo.Files.some(e => e.Estado.ID_Estado === 2024) &&
                              <i
                                onClick={() => {
                                  const file = nodo.Files.find(e => e.Estado.ID_Estado === 2024)
                                  if (file) onDownloadImage(file)
                                }}
                                className='fa-solid fa-eye'
                                style={{ fontSize: 16 }}
                              />
                            }
                          </div>
                          //#region Solo para Alemania Caso Especial
                        }

                        <ElementInputFileAdjunto
                          icon={'fa-solid fa-paperclip'}
                          onChange={(documentos) => onAdd(documentos, nodo)}
                          size={17}
                          allowedFormats={nodo.Extension}
                          capture={extensionImagen.some(elemento => nodo.Extension.includes(elemento))}
                        />
                      </div>
                  }
                </div>
              </Accordion.Header>
              <Accordion.Body className='d-flex flex-wrap justify-content-around'>
                {
                  nodo.Children.length
                    ? <>
                      {
                        //#region Solo para Alemania Caso especial
                        ['Cod110'].some(elemento => nodo.Codigo.includes(elemento)) &&
                        <AddressDE
                          dataAddress={dataAddress}
                          onChange={(name, value) => onAddAddress(value, nodo)}
                          nodo={nodo}
                          save={save}
                        />
                        //#endregion Solo para Alemania Caso especial
                      }
                      <AccordionAdjuntos
                        Nodos={nodo.Children}
                        NodoPrincipal={NodoPrincipal}
                        setNodos={setNodos}
                        ruta={ruta}
                        newFiles={newFiles}
                        setNewFiles={setNewFiles}
                        colorNodos={colorNodos}
                        dataAddress={dataAddress}
                        save={save}
                      />
                    </> : nodo.Files.filter(e => e.Estado.ID_Estado === 1)
                      .map((archivo, i) =>
                        <Card key={i} className='mb-1 d-flex' style={{ width: '9rem' }}>
                          <Card.Body style={{ padding: 6, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <div className='w-100 d-flex justify-content-center'>
                              <i className={`${extensionImagen.includes(archivo.Extension.toLowerCase().trim()) ? 'fa-solid fa-image' : 'fa-solid fa-file'}`} style={{ fontSize: 40 }}></i>
                            </div>
                            <Card.Title style={{ fontSize: 12 }}>{archivo.NameOriginal}</Card.Title>
                            <div>
                              <ListGroup variant='flush'>
                                <ListGroup.Item style={{ padding: 4 }} action variant='info'>
                                  {`${typeof archivo.Size.Size === 'number' ? archivo.Size.Size.toFixed(2) : archivo.Size.Size} ${archivo.Size.UM}`}
                                </ListGroup.Item>
                              </ListGroup>
                            </div>
                            <div className='d-flex w-100 justify-content-between'>
                              <Button variant='primary' onClick={() => onDownloadImage(archivo)}>
                                {
                                  extensionImagen.includes(archivo.Extension.toLowerCase().trim()) ?
                                    <i className='fa-solid fa-eye' style={{ fontSize: 16 }} /> :
                                    <i className='fa-solid fa-download' style={{ fontSize: 16 }} />
                                }
                              </Button>
                              {
                                btnDelete &&
                                <Button variant='danger' onClick={() => onDelete(archivo, nodo)}>
                                  <i className='fa-solid fa-trash' style={{ fontSize: 16 }} />
                                </Button>
                              }
                            </div>
                          </Card.Body>
                        </Card>
                      )
                }
              </Accordion.Body>
            </Accordion.Item>
          })
        }
      </Accordion>
      {
        fileImageViewer &&
        <ImageViewer
          show={showImageViewer}
          file={fileImageViewer}
          setShow={setShowImageViewer}
          setFile={setFileImageViewer}
          archivo={archivo}
        />
      }
    </>
  )
}

type typeColor = 'success' | 'danger' | 'warning'

const definirColor = (nodo: DtoNodoDoc): typeColor => {
  if (!nodo.Children.length) {
    if (nodo.Files.filter(e => e.Estado.ID_Estado === 1).length) return 'success'
    return 'danger'
  }
  const result = nodo.Children.map(e => definirColor(e))
  const todosSonSuccess = result.every(el => el === 'success')
  if (todosSonSuccess) return 'success'
  const alMenosUnoSuccess = result.some(el => el === 'success' || el === 'warning')
  if (alMenosUnoSuccess) return 'warning'
  return 'danger'
}

const removeFileInNodo = (Nodos: DtoNodoDoc[], file: DtoFilesDoc, nodo: DtoNodoDoc, newFiles: File[], isNew: boolean) => {
  for (const nodoAux of Nodos) {
    if (nodoAux.Children.length) removeFileInNodo(nodoAux.Children, file, nodo, newFiles, isNew)
    else {
      if (nodoAux.Codigo === nodo.Codigo) {
        nodoAux.Files = nodoAux.Files = isNew ? [...nodoAux.Files.filter(e => e.Posicion !== file.Posicion)] :
          [...nodoAux.Files.map(fileAux => fileAux.Posicion === file.Posicion ? { ...fileAux, Estado: { ID_Estado: 10, Nombre: 'INACTIVO' } } : fileAux)]
      }
    }
  }
}

const addFileInNodo = (Nodos: DtoNodoDoc[], newFilesAux: DtoFilesDoc[]) => {
  for (const nodo of Nodos) {
    if (nodo.Children.length) addFileInNodo(nodo.Children, newFilesAux)
    else {
      const matchingFiles = newFilesAux.filter((file) => file.CodigoNodo === nodo.Codigo)
      if (matchingFiles.length > 0) {
        nodo.Files = [...nodo.Files, ...matchingFiles]
      }
    }
  }
}

//#region Solo para Alemania Caso especial
const addAddressInNodo = (Nodos: DtoNodoDoc[], newFilesAux: DtoFilesDoc) => {
  for (const nodo of Nodos) {
    if (nodo.Codigo === newFilesAux.CodigoNodo) {
      nodo.Files = [...nodo.Files.filter(e => e.Estado.ID_Estado !== 8888), newFilesAux]
    } else {
      addAddressInNodo(nodo.Children, newFilesAux)
    }
  }
}
//#endregion Solo para Alemania Caso especial