import { DtoUsuarioEdicion } from "../../../../shared/Domain/Dto/DtoUsuarioEdicion";
import { DtoPeriodo } from "./DtoPeriodo";

export class DtoCertificacion {
    Mes: number = 0;
    Anio: number = 0;
    UltimoPeriodo: DtoPeriodo = new DtoPeriodo();
    Periodos: DtoPeriodo[] = [];
    UsuarioEdicion: DtoUsuarioEdicion = new DtoUsuarioEdicion;
    FechaRegistro: Date = new Date();
}