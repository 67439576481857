import { DtoResponseCuadrillas } from '../../../../app/Domain/DtoResponseCuadrillas'
import { EntityDataUsuario } from '../../../shared/Domain/EntityDataUsuario'
import { EntityLoadData } from '../Domain/EntityLoadData'
import { RepositoryMain } from '../Domain/RepositoryMain'

export class UseCaseLoadData {
  private repository: RepositoryMain

  constructor(_repository: RepositoryMain) {
    this.repository = _repository
  }

  async exec(user: EntityDataUsuario): Promise<EntityLoadData> {
    const [
      dataCuadrillas
    ] = await Promise.all([
      this.getCuadrillas(user),
    ])
    return {
      dataCuadrillas
    }
  }

  private async getCuadrillas(user: EntityDataUsuario): Promise<DtoResponseCuadrillas[]> {
    const Cuadrillas: DtoResponseCuadrillas[] = await this.repository.getCuadrillas()
    return Cuadrillas.filter((el) => el.Pais.Codigo === user.Pais.Codigo && el.Delegacion.Codigo === user.Delegacion.Codigo)
  }

}