import { useEffect } from 'react';
import { ElementInputFileImage, LoadImage } from '../../../../shared/Components/ElementInputFileImage';
// import { DtoCoordenadas } from '../../../../shared/Domain/Dto/DtoCoordenadas';
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate';
import './Style.scss';
// import { AdapterGeolocation } from '../../../../shared/Infraestructure/AdapterGeolocation';

interface PropsViewMain {
    onChange: (name: string, value: any) => void;
    imagenes: LoadImage[];
    text: string;
    disabled: boolean;
};

export const UploadImg = (props: PropsViewMain) => {
    const { onChange, imagenes, text, disabled } = props
    // const [Coordenadas, setCoordenadas] = useState<DtoCoordenadas>()
    useEffect(() => {
        // const GetLocation = async () => {
        //     // const coodenadas = await AdapterGeolocation.getLocation()
        //     // setCoordenadas({ X: String(coodenadas.longitud), Y: String(coodenadas.latitud) })
        // }
        // GetLocation()
    }, [])

    const languageTranslate = LanguageTranslate()

    return (
        <div className='contenedor mb-2'>
            {
                imagenes?.map((e, i) =>
                    <div key={i} className='imagenContenedor d-flex flex-column justify-content-center'>
                        <img
                            src={URL.createObjectURL(e.File)}
                            className='img-fluid mb-1'
                            alt='not source'
                            height={400}
                        />
                        <div className='d-flex justify-content-center mb-1'>
                            {
                        disabled && <button style={{ backgroundColor: '#FF2079', color: '#FFFF00' }} onClick={(e) => onChange('deleteImg', i)} className='btn'><i className="fa-solid fa-trash"></i></button>
                            }
                        </div>
                    </div>
                )
            }
            {
                disabled &&
                <div className='image-empty d-flex flex-column justify-content-center align-items-center'>
                    <span className='camera mb-2'>
                        <ElementInputFileImage
                            icon={'fa-solid fa-camera'}
                            // capture
                            disabled={false}
                            onChange={(imagenes) => onChange('imagenes', imagenes)}
                        />
                    </span>
                    <label className='ms-2'>{imagenes.length < 1 ? text : languageTranslate.general.FotoAuxiliar}</label>
                </div >
            }
        </div >
    )
};