import { DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { DtoFilesDoc } from '../../../Trabajos/Trabajos/Domain/DtoFilesDoc'
import { DtoTrabajosDE } from '../../Trabajos/Domain/DtoTrabajosDE'
import { DtoReqUpdateAddressList } from '../../../../shared/Domain/Dto/DtoReqUpdateAddressList'
import { RepositoryMain } from '../Domain/RepositoryMain'
import { typeHPList } from '../Domain/typeHPList'

export class UseCaseUpdateAddressList {
  private repository: RepositoryMain
  private user: EntityDataUsuario
  private userGestor: EntityDataUsuario

  constructor(_repository: RepositoryMain, _user: EntityDataUsuario, gestor: EntityDataUsuario) {
    this.repository = _repository
    this.user = _user
    this.userGestor = gestor
  }

  public async exec(trabajo: DtoTrabajosDE, nodos: DtoNodoDoc[], typeHPList: typeHPList, Home_ID: string, ID_AddressList: number, newFiles: File[]) {
    return await this._exec(trabajo, nodos, typeHPList, Home_ID, ID_AddressList, newFiles)
  }

  private async _exec(trabajo: DtoTrabajosDE, nodos: DtoNodoDoc[], typeHPList: typeHPList, Home_ID: string, ID_AddressList: number, newFiles: File[]) {

    // Buscar AddressList a actualizar
    const updatedAddressList = trabajo.ColeccionAddressList.filter(e => e.ID_AddressList === ID_AddressList)
    if (updatedAddressList.length !== 1) throw Error(`¡No se pudo Obtener AddressList! Comunícate con Soporte`)

    // Determinar tIpo de listas de casa y obtener la casa por su id
    const HPListUpdate = typeHPList === 'Business'
      ? updatedAddressList[0].BusinessHPList.find(e => e.Home_ID === Home_ID)
      : updatedAddressList[0].ResidentialHPList.find(e => e.Home_ID === Home_ID)
    if (!HPListUpdate) throw Error(`¡No se pudo actualizar Address List! Comunícate con Soporte`)

    // Determinar la Asignacion
    const asignacion = HPListUpdate.Asignaciones.find(e => e.Empresa === trabajo.Ultima_asignacion.Empresa.Codigo)
    if (!asignacion) throw Error(`¡No se pudo obtener asignación!`)

    // Obtener los anexos de manera recursiva
    const newAnexos = this.getAnexosHPList(nodos)
    this.eliminarFile(newAnexos)

    // Actualizar los anexos de la casa obtenida 
    asignacion.Anexos = newAnexos

    // Construir Body
    const body: DtoReqUpdateAddressList = {
      Header: { Coleccion: { Codigo: this.user.Delegacion.Codigo, Nombre: this.user.Pais.Nombre } },
      Body: updatedAddressList
    }

    await this.repository.uploadFiles(newFiles)
    const response = await this.repository.updateAddressList(body, newFiles, this.user, trabajo)

    const indexToUpdate = trabajo.ColeccionAddressList.findIndex(al => al.ID_AddressList === response[0].ID_AddressList)
    if (indexToUpdate !== -1) trabajo.ColeccionAddressList.splice(indexToUpdate, 1, response[0])

    return trabajo

  }

  private getAnexosHPList(nodos: DtoNodoDoc[]): DtoFilesDoc[] {
    return [
      ...nodos.flatMap(e => e.Files.filter(e => e.Estado.ID_Estado === 1).map(f => f)),
      ...nodos.flatMap(e => this.getAnexosHPList(e.Children))
    ]
  }

  private eliminarFile(anexos: DtoFilesDoc[]) {
    for (const anexo of anexos) {
      delete anexo.File
      anexo.Ultimo_Flujo.Usuario.Identificacion = this.userGestor.Identificacion
      anexo.Ultimo_Flujo.Usuario.Apellidos = `${this.userGestor.ApellidoPaterno} ${this.userGestor.ApellidoMaterno}`
      anexo.Ultimo_Flujo.Usuario.Nombres = this.userGestor.Nombres
    }
  }

}