import { DtoResponseSelectStockPersonal } from "../../../Master/Home/Domain/DtoResponseSelectStockPersonal";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import './GridDetalleStockPersonal.scss';

interface IGridDetalleStockPersonal {
    stockPersonal: DtoResponseSelectStockPersonal;
}

const GridDetalleStockPersonal = ({ stockPersonal }: IGridDetalleStockPersonal) => {

    const languageTranslate = LanguageTranslate();

    return (
        <div className="GridDetalleStockPersonal mb-3 shadow rounded">
            {Object.keys(stockPersonal).length &&
                <table className="table table-sm table-striped rowColor">
                    <tbody>
                        <tr>
                            <th scope="row">{languageTranslate.moduloLogistica.stockPersonal.detalleStockPersonal.content.textCodigo}</th>
                            <td>{stockPersonal.Item.Codigo}</td>
                        </tr>
                        <tr>
                            <th scope="row">{languageTranslate.moduloLogistica.stockPersonal.detalleStockPersonal.content.textLote}</th>
                            <td>{stockPersonal.Lote}</td>
                        </tr>
                        <tr>
                            <th scope="row">{languageTranslate.moduloLogistica.stockPersonal.detalleStockPersonal.content.textEmpresa}</th>
                            <td>{stockPersonal.Empresa.RazonSocial}</td>
                        </tr>
                        <tr>
                            <th scope="row">{languageTranslate.moduloLogistica.stockPersonal.detalleStockPersonal.content.textObra}</th>
                            <td>{stockPersonal.OrdenTrabajo}</td>
                        </tr>
                        <tr>
                            <th scope="row">{languageTranslate.moduloLogistica.stockPersonal.detalleStockPersonal.content.textPex}</th>
                            <td>{stockPersonal.FlagPex === 1 ? 'SI' : 'NO'}</td>
                        </tr>
                        <tr>
                            <th scope="row">{languageTranslate.moduloLogistica.stockPersonal.detalleStockPersonal.content.textBobina}</th>
                            <td>{stockPersonal.Bobina}</td>
                        </tr>
                        <tr className="">
                            <th scope="row">{languageTranslate.moduloLogistica.stockPersonal.detalleStockPersonal.content.textDescripcion}</th>
                            <td>{stockPersonal.Item.Nombre}</td>
                        </tr>
                        <tr>
                            <th scope="row">{languageTranslate.moduloLogistica.stockPersonal.detalleStockPersonal.content.textUM}</th>
                            <td>{stockPersonal.Item.UnidadMedida.Nombre}</td>
                        </tr>
                        <tr>
                            <th scope="row">{languageTranslate.moduloLogistica.stockPersonal.detalleStockPersonal.content.text$PU}</th>
                            <td>{stockPersonal.Precio}</td>
                        </tr>
                        <tr>
                            <th scope="row">{languageTranslate.moduloLogistica.stockPersonal.detalleStockPersonal.content.textTipo}</th>
                            <td>{stockPersonal.Tipo}</td>
                        </tr>
                        <tr className="">
                            <th scope="row">{languageTranslate.moduloLogistica.stockPersonal.detalleStockPersonal.content.textSB}</th>
                            <td>{stockPersonal.Item.Ind_SB}</td>
                        </tr>
                        <tr className="">
                            <th scope="row">{languageTranslate.moduloLogistica.stockPersonal.detalleStockPersonal.content.textDespacho}</th>
                            <td>{stockPersonal.Despacho}</td>
                        </tr>
                        <tr className="">
                            <th scope="row">{languageTranslate.moduloLogistica.stockPersonal.detalleStockPersonal.content.textDevolucion}</th>
                            <td>{stockPersonal.Devolucion}</td>
                        </tr>
                        <tr>
                            <th scope="row">{languageTranslate.moduloLogistica.stockPersonal.detalleStockPersonal.content.textDesTR}</th>
                            <td>{stockPersonal.DespachoTR}</td>
                        </tr>
                        <tr>
                            <th scope="row">{languageTranslate.moduloLogistica.stockPersonal.detalleStockPersonal.content.textDevTR}</th>
                            <td>{stockPersonal.DevolucionTR}</td>
                        </tr>
                        <tr>
                            <th scope="row">{languageTranslate.moduloLogistica.stockPersonal.detalleStockPersonal.content.textLiquidacion}</th>
                            <td>{stockPersonal.Liquidacion}</td>
                        </tr>
                        <tr>
                            <th scope="row">{languageTranslate.moduloLogistica.stockPersonal.detalleStockPersonal.content.textStock}</th>
                            <td>{stockPersonal.Stock}</td>
                        </tr>
                        <tr>
                            <th scope="row">{languageTranslate.moduloLogistica.stockPersonal.detalleStockPersonal.content.textTipoStock}</th>
                            <td>{stockPersonal.TipoStock.Nombre}</td>
                        </tr>
                        <tr>
                            <th scope="row">{languageTranslate.moduloLogistica.stockPersonal.detalleStockPersonal.content.textEstado}</th>
                            <td>{stockPersonal.Estado.Nombre}</td>
                        </tr>
                    </tbody>
                </table>
            }
        </div>
    )
}

export default GridDetalleStockPersonal;