import { EntityDataUsuario } from '../../../shared/Domain/EntityDataUsuario';
import { RepositoryMain } from '../Domain/RepositoryMain'

export class UseCaseVerifyAutoInventario {
  private repository: RepositoryMain;

  constructor(_repository: RepositoryMain) {
    this.repository = _repository;
  }

  public async exec(user: EntityDataUsuario) {
    return await this._exec(user)
  }

  private async _exec(user: EntityDataUsuario) {
    return await this.repository.verifyAutoInventario(user)
  }
}
