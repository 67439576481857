import { Dispatch } from 'redux'
import { useSelector, useDispatch } from 'react-redux'
import {
  addLoading,
  hideIconMenu,
  removeLoading,
} from '../../../../shared/Infraestructure/SliceGenerico'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { useState } from 'react'
import { RootState } from '../../../../shared/Infraestructure/AdapterStore'
import { UseCaseSelectTrabajoLocal } from '../Application/UseCaseSelectTrabajoLocal'
import { AdapterConfigure } from './AdapterConfigure'
import { RepositoryImplMain } from './RepositoryImplMain'
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom'
import { DtoTrabajosDE } from '../../Trabajos/Domain/DtoTrabajosDE'
import { DtoDocumentacionBaremoPEX, DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { UseCaseLoadData } from '../Application/UseCaseLoadData'
import { UseCaseUpdateAddressList } from '../Application/UseCaseUpdateAddressList'
import { DtoValorizacion } from '../../../Trabajos/Asignacion/Domain/DtoValorizacion'
import { DtoResponseEstadoInterno } from '../../../../../app/Domain/DtoResponseEstadoInterno'
import { UseCaseUpdateAttachedValuations } from '../Application/UseCaseUpdateAttachedValuations'
import { typeHPList } from 'sigo-package/dist/Programados/Dtos/HomePassDto'

export const Controller = () => {

  const dispatch: Dispatch = useDispatch()
  const { id } = useParams<{ id: string, estado: string }>()
  const navigate: NavigateFunction = useNavigate()
  const { websocket, dbLocal } = useSelector((state: RootState) => state.generico)
  const { user, userGestor } = useSelector((state: RootState) => state.auth)
  const repository = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY)
  const [trabajo, setTrabajo] = useState<DtoTrabajosDE | null>(null)
  const [dataDocumentacionBaremoPEX, setDocumentacionBaremoPEX] = useState<DtoDocumentacionBaremoPEX[]>([])
  const [dataEstadoInterno, setDataEstadoInterno] = useState<DtoResponseEstadoInterno[]>([])

  const init = async () => {
    try {
      dispatch(hideIconMenu())
      dispatch(addLoading({ textLoading: 'Buscando Trabajos ...' }))
      const trabajo = await new UseCaseSelectTrabajoLocal(repository).exec(user, Number(id))
      setTrabajo(trabajo)
      const { DocumentacionBaremoPEX, EstadosInternos } = await new UseCaseLoadData(repository).exec(user, trabajo)
      setDocumentacionBaremoPEX(DocumentacionBaremoPEX)
      setDataEstadoInterno(EstadosInternos)
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error').then(() => { navigate(-1) })
    } finally {
      dispatch(removeLoading())
    }
  }

  const onSaveAdjuntosAddress = async (
    nodos: DtoNodoDoc[],
    typeHPList: typeHPList,
    Home_ID: string,
    ID_AddressList: number,
    newFiles: File[],
    valorizacionUpdate: DtoValorizacion
  ): Promise<boolean> => {
    try {
      if (!newFiles.length) throw Error(`¡No hay archivos que guardar!`)
      dispatch(addLoading({ textLoading: 'Actualizando ...' }))
      if (trabajo) {
        await new UseCaseUpdateAddressList(repository, user, dataEstadoInterno, userGestor)
          .exec(trabajo, nodos, typeHPList, Home_ID, ID_AddressList, newFiles, valorizacionUpdate)
        const trabajoAux = await new UseCaseSelectTrabajoLocal(repository).exec(user, Number(id))
        setTrabajo(trabajoAux)
        AdapterGenerico.createMessage('¡Guardado!', '¡Actualizado correctamente!', 'success')
      }
      return true
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error')
      return false
    } finally {
      dispatch(removeLoading())
    }
  }

  const saveFiles = async (nodos: DtoNodoDoc[], selectedValorizacion: DtoValorizacion) => {
    if (!trabajo) return
    try {
      dispatch(addLoading({ textLoading: 'Actualizando ...' }))
      await new UseCaseUpdateAttachedValuations(repository, userGestor)
        .exec(user, trabajo, dataEstadoInterno, nodos, selectedValorizacion)
      const trabajoAux = await new UseCaseSelectTrabajoLocal(repository).exec(user, Number(id))
      setTrabajo(trabajoAux)
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error')
    } finally {
      dispatch(removeLoading())
    }
  }

  return {
    init,
    trabajo,
    dataDocumentacionBaremoPEX,
    onSaveAdjuntosAddress,
    saveFiles
  }
}
