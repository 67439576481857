import { LanguageTranslate } from "../Infraestructure/LanguageTranslate";
import "./ElementModalCostume.scss";

interface IViewModal {
    show: boolean;
    closeModal: () => void;
    height?: string
    title?: string;
    children?: any;
    disabledButtonClose?: boolean;
    btnSave?: boolean
    onSave?: () => void
}

const ElementModalCostume = (props: IViewModal) => {

    const { btnSave, onSave } = props
    const languageTranslate = LanguageTranslate()

    return (
        <>
            {props.show ? (
                <div className="ElementModalCostume">
                    <div className="container-modal" style={{ height: props.height }} >
                        <div className="header d-flex">
                            <h6 className="mb-0"> {props.title} </h6>
                            {
                                !props.disabledButtonClose ?
                                    <p className="mb-0" onClick={props.closeModal}>
                                        <i className="fa-sharp fa-solid fa-circle-xmark" style={{ fontSize: "20px" }} />
                                    </p>
                                    : null
                            }
                        </div>
                        <div className="body">
                            {
                                props.children
                            }
                        </div>
                        {
                            btnSave ?
                                <div className='footer'>
                                    <button className='btn btn-success text-white' onClick={onSave}>
                                        {languageTranslate.btnGuardar}
                                    </button>
                                </div> : null
                        }
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default ElementModalCostume;
