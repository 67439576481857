import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

interface ApiHeaders {
  [header: string]: string;
}

// Crear instancia de axios
function createApiClient(baseURL: string, headers: ApiHeaders): AxiosInstance {
  const apiClient = axios.create({
    baseURL,
    headers,
  });

  // Manejar errores
  apiClient.interceptors.response.use(
    (response: AxiosResponse) => {
      // Si la respuesta es exitosa, retornar directamente la data
      return response.data;
    },
    (error: AxiosError) => {
      // Manejar errores según el código de estado
      if (error.response) {
        const { status, data } = error.response;

        switch (status) {
          case 400:
            console.error('Bad Request:', data);
            break;
          case 401:
            console.error('Unauthorized:', data);
            break;
          case 403:
            console.error('Forbidden:', data);
            break;
          case 404:
            console.error('Not Found:', data);
            break;
          case 406:
            console.error('Not Acceptable:', data);
            break;
          default:
            console.error('Error desconocido:', data);
        }
      } else {
        console.error('Error en la solicitud:', error.message);
      }

      // Propagar el error para ser manejado por el llamador
      return Promise.reject(error);
    }
  );
  return apiClient;
}

export default createApiClient;