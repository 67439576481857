import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ViewMain } from "./Components/View";
import { useParams } from 'react-router-dom';

const ManagerEntity = () => {
    const params = useParams();
    const controller = Controller();

    React.useEffect(() => {
        controller.init();
        return () => { }
        // eslint-disable-next-line
    }, []);

    return (<ViewMain
        salidaAlmacen={controller.salidaAlmacen}
        ID_DespachoAlmacen={params.id || ''}
        onSendFirm={controller.onSendFirm}
        showModal={controller.showModal}
        setModal={controller.setModal}
        openModal={controller.openModal}
        showBtnFirmar={controller.showBtnFirmar}
    />);
};

export default ManagerEntity;