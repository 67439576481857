import { DtoUsuarioEdicion } from '../../../../shared/Domain/Dto/DtoUsuarioEdicion'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { DtoFotos } from '../../../../shared/Domain/Dto/DtoFotos'
import { DtoRequestUpdateTrabajo } from '../Domain/DtoRequestUpdateTrabajo'
import { RepositoryMain } from '../Domain/RepositoryMain'
import { DtoParteDiario } from '../../Trabajos/Domain/DtoParteDiario'
import { DtoPersonal } from '../../../../shared/Domain/Dto/DtoPersonal'
import { DtoEstadoInterno } from '../../Trabajos/Domain/DtoEstadoInterno'
import { LoadImage } from '../../../../shared/Components/ElementInputFileImage'
import { DtoFotosSST } from '../../Trabajos/Domain/DtoFotosSST'
import { DtoFormParteDiario } from '../Domain/DtoFormParteDiario'
import { EntityLoadData } from '../Domain/EntityLoadData'
import { DtoResponsePersonal } from '../../../../../app/Domain/DtoResponsePersonal'
import { DtoUsuario } from '../../../../shared/Domain/Dto/DtoUsuario'
import { DtoTrabajos } from '../../../../Master/Home/Domain/DtoTrabajos'
import { GetCodigoFechaActual } from 'sigo-package'
import { DtoSubEstadoInterno } from '../../Trabajos/Domain/DtoSubEstadoInterno'


export class UseCaseUpdateTrabajo {
  private repository: RepositoryMain
  private user: EntityDataUsuario
  private trabajo: DtoTrabajos
  private formRegistro: DtoFormParteDiario
  private selectInformation: EntityLoadData

  constructor(_repository: RepositoryMain) {
    this.repository = _repository
    this.user = {} as EntityDataUsuario
    this.trabajo = {} as DtoTrabajos
    this.formRegistro = {} as DtoFormParteDiario
    this.selectInformation = {} as EntityLoadData
  }

  public async exec(data: any): Promise<DtoTrabajos[]> {
    this.user = data.user
    this.trabajo = data.trabajo
    this.selectInformation = data.selectInformation
    this.formRegistro = data.formRegistro.values
    return await this._exec()
  }

  private async _exec(): Promise<DtoTrabajos[]> {
    let params: DtoRequestUpdateTrabajo = {
      Header: {
        Coleccion: {
          Codigo: this.user.Delegacion.Codigo,
          Nombre: this.user.Pais.Nombre
        }
      },
      Body: this.trabajo
    }
    const usuarioEdicion = this.generarDtoUsuarioEdicion()
    const estadoInterno = this.generarDtoEstadoInterno(usuarioEdicion)
    const fotos_SST: DtoFotosSST[] = this.generarFotos_SST()
    const lider = this.generarLider()
    const personal = this.generarPersonal()
    const parteDiario = this.generarDtoParteDiario(estadoInterno, fotos_SST, lider, personal)
    const indexParteDiario = this.trabajo.PartesDiarios.findIndex((e) => e.Codigo === GetCodigoFechaActual())
    if (indexParteDiario === -1) {
      this.trabajo.PartesDiarios = [...this.trabajo.PartesDiarios, parteDiario]
    } else {
      parteDiario.Codigo = this.trabajo.PartesDiarios[indexParteDiario].Codigo
      parteDiario.Fecha = this.trabajo.PartesDiarios[indexParteDiario].Fecha
      parteDiario.EstadosInternos = this.trabajo.PartesDiarios[indexParteDiario].EstadosInternos
      parteDiario.UltimoEstadoInterno = this.trabajo.PartesDiarios[indexParteDiario].UltimoEstadoInterno
      parteDiario.UsuarioAprobacion = this.trabajo.PartesDiarios[indexParteDiario].UsuarioAprobacion
      parteDiario.UsuarioEdicion = this.trabajo.PartesDiarios[indexParteDiario].UsuarioEdicion
      parteDiario.Valorizaciones = this.trabajo.PartesDiarios[indexParteDiario].Valorizaciones
      this.trabajo.PartesDiarios[indexParteDiario] = parteDiario
    }
    return await this.repository.updateTrabajos(params, this.user)
  }

  private generarDtoParteDiario(estadoInterno: DtoEstadoInterno, fotos_SST: DtoFotosSST[], lider: DtoPersonal, personal: DtoPersonal[]): DtoParteDiario {
    const parteDiario: DtoParteDiario = {
      Codigo: GetCodigoFechaActual(),
      Fecha: new Date(),
      EstadosInternos: [estadoInterno],
      Fotos_SST: fotos_SST,
      Lider: lider,
      Novedad: this.formRegistro.Novedad,
      Personal: personal,
      UltimoEstadoInterno: estadoInterno,
      UsuarioAprobacion: new DtoUsuario(),
      UsuarioEdicion: new DtoUsuario(),
      Valorizaciones: [],
    }
    return parteDiario
  }

  private generarDtoEstadoInterno(usuarioEdicion: DtoUsuarioEdicion): DtoEstadoInterno {
    const estadoInterno = this.selectInformation.EstadoInterno.find(e => e.Descripcion === 'REGISTRADO')
    if (!estadoInterno) throw Error(`No se puso encontrar EstadoInterno con Descripcion: REGISTRADO`)
    let newEstadoInterno: DtoEstadoInterno = {
      Color: estadoInterno.Color,
      ColorFondo: estadoInterno.ColorFondo,
      Descripcion: estadoInterno.Descripcion,
      Fecha: new Date(),
      Icono: estadoInterno.Icono,
      ID_EstadoInterno: estadoInterno.ID_EstadoInterno,
      Observacion: estadoInterno.Observacion,
      Orden: Number(estadoInterno.Orden),
      Tipo: estadoInterno.Tipo,
      UsuarioEdicion: usuarioEdicion,
      SubEstados: [],
      UltimoSubEstado: new DtoSubEstadoInterno()
    }
    return newEstadoInterno
  }

  private generarDtoUsuarioEdicion(): DtoUsuarioEdicion {
    return {
      Identificacion: this.user.Identificacion,
      Cuadrilla: '',
      User: `${this.user.Nombres} ${this.user.ApellidoPaterno} ${this.user.ApellidoMaterno}`
    }
  }

  private generarLider(): DtoPersonal {
    const cuadrilla = this.selectInformation.Cuadrillas.find(e => e.Encargado.Identificacion === this.user.Identificacion && e.Pais.Codigo === this.user.Pais.Codigo)
    if (!cuadrilla) throw Error(`No se encontró Cuadrilla con Identificacion de Encargado: ${this.user.Identificacion}, intente nuevamente luego de refrescar Cuadrillas en la opción Refrescar datos.`)
    const personal = this.selectInformation.Personal.find(e => e.Identificacion === this.user.Identificacion && e.Pais.Key === this.user.Pais.Codigo)
    if (!personal) throw Error(`No se encontró Personal con Identificacion: ${this.user.Identificacion}, intente nuevamente luego de refrescar Personal en la opción Refrescar datos.`)
    return this.generarDtoPersonal(personal)
  }

  private generarPersonal(): DtoPersonal[] {
    let Personal: DtoPersonal[] = []
    this.formRegistro.Personal.forEach((personal) => {
      Personal = [...Personal, this.generarDtoPersonal(personal.dataComplete)]
    })
    return Personal
  }

  private generarDtoPersonal(personal: DtoResponsePersonal): DtoPersonal {
    return {
      Identificacion: personal.Identificacion,
      Apellidos: `${personal.ApellidoPaterno} ${personal.ApellidoMaterno}`,
      Nombres: `${personal.Nombres}`,
      Cargo: personal.Cargo.Nombre ? personal.Cargo.Nombre : personal.Cargo.Descripcion,
      IdPersonal: personal.IdPersonal,
      PerfilGOM: personal.DatosArea.Area.Name,
    }
  }

  private generarFotos_SST(): DtoFotosSST[] {
    let fotosSST: DtoFotosSST[] = []
    this.formRegistro.imagenes.forEach((imagen, index) => {
      const dtoFotosSST = this.generarDtoFotosSST(imagen)
      dtoFotosSST.Titulo = `Foto Auxiliar ${index}`
      dtoFotosSST.Descripcion = `Foto Auxiliar ${index}`
      if (index === 0) {
        dtoFotosSST.Titulo = 'Foto Principal'
        dtoFotosSST.Descripcion = 'Foto Cuadrilla'
      }
      fotosSST = [...fotosSST, dtoFotosSST]
    })
    for (const parteDiario of this.trabajo.PartesDiarios) {
      if (parteDiario.Codigo === GetCodigoFechaActual()) {
        for (const foto of parteDiario.Fotos_SST) {
          const fotoSearch = this.formRegistro.imagenes.find(e => e.File.name === foto.Foto.img)
          if (!fotoSearch) {
            foto.Foto.Estado.ID_Estado = 10
            foto.Foto.Estado.Nombre = 'INACTIVO'
            fotosSST = [...fotosSST, foto]
          }
        }
      }
    }
    return fotosSST
  }

  private generarDtoFotosSST(data: LoadImage): DtoFotosSST {
    const newFoto: DtoFotos = new DtoFotos()
    newFoto.Ruta = data.File.name.split('-')[0]
    newFoto.img = data.File.name

    const fotosSST: DtoFotosSST = {
      Titulo: '',
      Descripcion: '',
      Foto: newFoto
    }
    return fotosSST
  }

}