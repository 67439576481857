import { EntityParams } from "../../context/shared/Domain/EntityParams";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseInitialService {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: EntityParams): Promise<void> {
        await this._exec(params);
    }

    private async _exec(params: EntityParams): Promise<void> {
        await this.repository.initialService(params);
    }
}