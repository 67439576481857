const SCHEMA: string = process.env.REACT_APP_MAESTRO_SEDE_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MAESTRO_SEDE_ENTITY || '';
const REDIRECT_LOGISTICA: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGISTICA}/${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_STOCK_PERSONAL_TOTAL}` || '';
const SELECT_STOCK_PERSONAL: string = '/SigoPwa/Logistica/Reportes/StockPersonal/Select';

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    REDIRECT_LOGISTICA,
    SELECT_STOCK_PERSONAL,
};