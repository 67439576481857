import { createSlice } from '@reduxjs/toolkit';

interface SliceMainState {
    ruta: string;
    rutaAnterior: string,
};

const initialState: SliceMainState = {
    ruta: '',
    rutaAnterior: '',
};

const MainSlice = createSlice({
    name: 'MainSlice',
    initialState,
    reducers: {
        changeRoute: (state, action) => {
            return {
                ...state,
                rutaAnterior: state.ruta,
                ruta: action.payload,
            };
        },
    },
});


export const {
    changeRoute,
} = MainSlice.actions;

export default MainSlice.reducer;