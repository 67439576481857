import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { DtoResponseSelectStockPersonal } from "../Domain/DtoResponseSelectStockPersonal";
import { DtoRequestSelectStockPersonal } from "../Domain/DtoRequestSelectStockPersonal";
import { AdapterConfigure } from "./AdapterConfigure";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

    public async selectStockPersonal(params: DtoRequestSelectStockPersonal): Promise<DtoResponseSelectStockPersonal[]> {
        let url: string = `${this.urlBase}${AdapterConfigure.SELECT_STOCK_PERSONAL}`;
        let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'json', {});
        response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response;
        return response;
    }
    
}