import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { AdapterStorage } from "../../context/shared/Infraestructure/AdapterStorage";
import { RootState } from "../../context/shared/Infraestructure/AdapterStore";
import { addLoading, removeLoading, changeOnline, changeCountProcess, removeLoadingMaestros, changeAvailableUpdate } from "../../context/shared/Infraestructure/SliceGenerico";
import { EntityParams } from "../../context/shared/Domain/EntityParams";
import { AdapterConfigure } from "./AdapterConfigure";
import { RepositoryImplMain } from "./RepositoryImplMain";
import { UseCaseInitialService } from "../Application/UseCaseInitialService";
import { AdapterGenerico } from "../../context/shared/Infraestructure/AdapterGenerico";
import { EntityInformationDataInitial } from "../../context/shared/Domain/EntityInformationDataInitial";
import { UseCaseExecuteProcess } from "../Application/UseCaseExecuteProcess";

import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';
import { useState } from "react";
import { AdapterPermiss } from "../../context/shared/Infraestructure/AdapterPermiss";
import * as Pipelines from '../../context/shared/Infraestructure/Pipelines';
import { DtoTrabajos } from "../../context/Master/Home/Domain/DtoTrabajos";
import { UseCaseUploadFiles } from "../Application/UseCaseUploadFiles";
import { DtoAddressList, DtoObrasDE } from '../../context/Programados/TrabajosDE/Trabajos/Domain/DtoTrabajosDE';

export const Controller = () => {
  const { user } = useSelector((state: RootState) => state.auth)
  const { websocket, dbLocal, loadingMaestros, availableUpdate } = useSelector((state: RootState) => state.generico)
  const dispatch: Dispatch = useDispatch();

  const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

  const SERVICESCALLED_NAME_FROM_LOCAL_STORAGE = "servicesCalleds";

  let [sw, setSW] = useState<ServiceWorkerRegistration | undefined>();

  let intervalVerifyRDI: NodeJS.Timer;

  const init = async () => {
    await requirePermiss();
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      let service: ServiceWorkerRegistration | undefined = await serviceWorkerRegistration.register({ onUpdate(registration) { dispatch(changeAvailableUpdate(true)); }, });
      setSW(service);
      dispatch(changeAvailableUpdate(!!service?.waiting));
    }
    let { servicesCalleds }: { servicesCalleds: EntityInformationDataInitial | null } = AdapterStorage.get(SERVICESCALLED_NAME_FROM_LOCAL_STORAGE);
    if (servicesCalleds === null) {
      servicesCalleds = {
        Pais: { called: false, count: 0, date: '' },
        EstadoInterno: { called: false, count: 0, date: '' },
        PrecioMaterial: { called: false, count: 0, date: '' },
        ManoObraGlobal: { called: false, count: 0, date: '' },
        PrecioEspecialidad: { called: false, count: 0, date: '' },
        Items: { called: false, count: 0, date: '' },
        ContratoOT: { called: false, count: 0, date: '' },
        DocumentacionBaremoPEX: { called: false, count: 0, date: '' },
        DocumentacionPEX: { called: false, count: 0, date: '' },
        Personal: { called: false, count: 0, date: '' },
        Cuadrillas: { called: false, count: 0, date: '' },
        CHILE_9512_Trabajos: { called: false, count: 0, date: '' },
        CHILE_9512_StockPersonal: { called: false, count: 0, date: '' },
        COLOMBIA_9612_Trabajos: { called: false, count: 0, date: '' },
        COLOMBIA_9612_StockPersonal: { called: false, count: 0, date: '' },
        ALEMANIA_AL02_Trabajos: { called: false, count: 0, date: '' },
        ALEMANIA_AL02_StockPersonal: { called: false, count: 0, date: '' },
        ALEMANIA_AL02_AddressList: { called: false, count: 0, date: '' },
        ALEMANIA_AL02_Obras: { called: false, count: 0, date: '' },
        CHILE_9512_StockAlmacen: { called: false, count: 0, date: '' },
        COLOMBIA_9612_StockAlmacen: { called: false, count: 0, date: '' },
        ALEMANIA_AL02_StockAlmacen: { called: false, count: 0, date: '' },
        ALEMANIA_AL02_ConsumoMaterial: { called: false, count: 0, date: '' },
        CHILE_9512_ConsumoMaterial: { called: false, count: 0, date: '' },
        COLOMBIA_9612_ConsumoMaterial: { called: false, count: 0, date: '' },
      };
      AdapterStorage.set(SERVICESCALLED_NAME_FROM_LOCAL_STORAGE, servicesCalleds);
    }

    window.addEventListener('online', onOnline);
    window.addEventListener('offline', onOffline);

    await callToAllInitialServices();
    dispatch(removeLoadingMaestros());

    intervalVerifyRDI = setInterval(verifyProcess, 60000);
  };

  const requirePermiss = async () => {
    try {
      // let permissMicrophone = await AdapterPermiss.permissMicrophone();
      // if (!permissMicrophone) { throw Error('Debe permitir el uso del microfono'); }

      const permissGeolocation = await AdapterPermiss.permissGeolocation()
      if (!permissGeolocation) throw Error('Debe permitir el uso del GPS')

      let permissNotification = await AdapterPermiss.permissNotification();
      if (!permissNotification) { throw Error('Debe permitir el uso de las notificaciones'); }
    } catch (error) {
      await AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false);
      await requirePermiss();
    }
  };

  const callToAllInitialServices = async () => {
    let params: EntityParams = {
      Pais: Pipelines.FiltroPais,
      Cuadrillas: Pipelines.FiltroCuadrillas,
      Personal: Pipelines.FiltroPersonal,
      EstadoInterno: Pipelines.FiltroEstadoInterno,
      PrecioMaterial: Pipelines.FiltroPrecioMaterial,
      ManoObraGlobal: Pipelines.FiltroManoObraGlobal,
      PrecioEspecialidad: Pipelines.FiltroPrecioEspecialidad,
      Items: Pipelines.FiltroItems,
      ContratoOT: Pipelines.FiltroContratoOT,
      DocumentacionBaremoPEX: Pipelines.FiltroDocumentacionBaremoPEX,
      DocumentacionPEX: Pipelines.FiltroDocumentacionPEX,
      // CHILE_9512_Trabajos: Pipelines.FiltroTrabajos,
      // COLOMBIA_9612_Trabajos: Pipelines.FiltroTrabajos,
      // ALEMANIA_AL02_Trabajos: Pipelines.FiltroTrabajos,
    };

    try {
      dispatch(addLoading({ textLoading: 'Cargando...' }));
      await new UseCaseInitialService(repository).exec(params);
    } catch (error) {
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false);
    } finally {
      dispatch(removeLoading());
    }
  };

  const onOffline = () => {
    AdapterGenerico.createToast('Sin conexión', 'warning');
    dispatch(changeOnline(false));
  };

  const onOnline = async () => {
    websocket.init();

    await verifyProcess(true);

    dispatch(changeOnline(true));
    AdapterGenerico.createToast('Conexión establecida', 'success');
  };

  const verifyProcess = async (force: boolean = false) => {
    let count: number = 0;
    let countTrabajos: number = await verifyProcessTrabajos(force);
    let countAddressList: number = await verifyProcessAddressList(force);
    let countObras: number = await verifyProcessObras(force);
    // let countAutochequeo: number = await verifyProcessAutochequeo(force);
    // let countTermsAccepted: number = await verifyProcessTerms(force);

    // count += countRDI + countInspeccion + countAutochequeo + countTermsAccepted;
    count += countTrabajos + countAddressList + countObras
    dispatch(changeCountProcess(count));
  }

  const verifyProcessTrabajos = async (force: boolean = false): Promise<number> => {
    if (!force && !navigator.onLine) { return 0; }

    const dataTrabajoChile: Array<DtoTrabajos> = await dbLocal.selectAllStore(`CHILE_9512_Trabajos`);
    const dataTrabajoColombia: Array<DtoTrabajos> = await dbLocal.selectAllStore(`COLOMBIA_9612_Trabajos`);
    const dataTrabajoAlemania: Array<DtoTrabajos> = await dbLocal.selectAllStore(`ALEMANIA_AL02_Trabajos`);
    const dataTrabajo = [...dataTrabajoChile, ...dataTrabajoColombia, ...dataTrabajoAlemania].filter(row => row?.StatusOffline?.Estado === 'No Enviado');

    let countProcess: number = dataTrabajo.length;

    for await (let row of dataTrabajo) {
      try {
        let obj: any = await (new UseCaseExecuteProcess<any>(repository)).exec({
          type: 'api',
          body: JSON.stringify(row.dataSend.params),
          method: 'POST',
          typeAuth: 'basic',
          typeRequest: 'json',
          typeResponse: 'json',
          url: '/SigoPwa/Programados/Gestion/Trabajos/UpdateOffLine'
        });
        const trabajo = await dbLocal.selectByIndexStore({ nameStore: `${row.Pais.Nombre}_${row.Delegacion.Codigo}_Trabajos`, value: row.ID_Trabajo });
        if (trabajo) {
          const trabajoUpdated: DtoTrabajos = {
            ColeccionObras: trabajo.ColeccionObras,
            ColeccionReserva: trabajo.ColeccionReserva,
            ColeccionManoObra: trabajo.ColeccionManoObra,
            ...obj.filter((e: any) => e.ID_Trabajo && e.Ultima_PreLiquidacion && e.PartesDiarios)[0]
          };
          await dbLocal.updateByIndexStore({ nameStore: `${row.Pais.Nombre}_${row.Delegacion.Codigo}_Trabajos`, value: trabajoUpdated });
          const stocks = obj.filter((e: any) => e.ID_StockPersonal && e.Reserva);
          for await (const stock of stocks) {
            await dbLocal.updateByIndexStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockPersonal`, value: stock });
          }
        }
        await new UseCaseUploadFiles(repository).exec(row.dataSend.archivos.map(e => e.File));
        countProcess -= 1;
      } catch (error) {
        console.error(error);
      }
    }
    return countProcess;
  };

  const verifyProcessAddressList = async (force: boolean = false) => {
    if (!force && !navigator.onLine) return 0

    const nameStore = `ALEMANIA_AL02_AddressList`

    const dataAlemania: DtoAddressList[] = await dbLocal.selectAllStore(nameStore)
    let countProcess: number = dataAlemania.length

    for await (const item of dataAlemania) {
      try {
        await new UseCaseUploadFiles(repository).exec(item.dataSend.archivos)
        const obj: any[] = await new UseCaseExecuteProcess<any>(repository).exec({
          type: 'api',
          body: JSON.stringify(item.dataSend.params),
          method: 'POST',
          typeAuth: 'basic',
          typeRequest: 'json',
          typeResponse: 'json',
          url: AdapterConfigure.UPDATE_ADDRESSLIST
        })
        await dbLocal.deleteByIndexStore({ nameStore, value: item.ID_AddressList })
        countProcess -= 1
      } catch (error) {
        continue
      }
    }

    return countProcess
  }

  const verifyProcessObras = async (force: boolean = false) => {
    if (!force && !navigator.onLine) return 0

    const nameStore = `ALEMANIA_AL02_Obras`

    const dataAlemania: DtoObrasDE[] = await dbLocal.selectAllStore(nameStore)
    let countProcess: number = dataAlemania.length

    for await (const item of dataAlemania) {
      try {
        await new UseCaseUploadFiles(repository).exec(item.dataSend.archivos)
        const obj: any[] = await new UseCaseExecuteProcess<any>(repository).exec({
          type: 'api',
          body: JSON.stringify(item.dataSend.params),
          method: 'POST',
          typeAuth: 'basic',
          typeRequest: 'json',
          typeResponse: 'json',
          url: AdapterConfigure.UPDATE_OBRA
        })
        await dbLocal.deleteByIndexStore({ nameStore, value: item.ID_incidencia })
        countProcess -= 1
      } catch (error) {
        continue
      }
    }

    return countProcess
  }

  const end = async () => {
    clearInterval(intervalVerifyRDI);
    window.removeEventListener('online', onOnline);
    window.removeEventListener('offline', onOffline);
  };

  const updateApp = async () => {
    if (!sw) { return; }
    if (sw.waiting) {
      sw.waiting.postMessage({ type: "SKIP_WAITING", });
      window.location.reload();
    }
  };

  return {
    init,
    end,

    availableUpdate,
    loadingMaestros,

    updateApp,
    callToAllInitialServices,
  };
}