import { RepositoryMain } from "../Domain/RepositoryMain";
import { DtoRequestRecuperarPassword } from "../Domain/DtoRequestRecuperarPassword";
import { DtoResponseLogin } from "../../Login/Domain/DtoResponseLogin";

export class UseCaseRecuperarPassword {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: DtoRequestRecuperarPassword): Promise<boolean> {
        return await this._exec(params);
    }

    private async _exec(params: DtoRequestRecuperarPassword): Promise<boolean> {
        let result: boolean = await this.repository.recuperarPassword(params);
        return result;
    }
}