import { RepositoryMain } from '../Domain/RepositoryMain';
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario';
import { DtoTrabajos } from '../../../../Master/Home/Domain/DtoTrabajos';

export class UseCaseSelectTrabajoLocal {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(user: EntityDataUsuario, idTrabajo: number): Promise<DtoTrabajos> {
        return await this._exec(user, idTrabajo);
    }

    private async _exec(user: EntityDataUsuario, idTrabajo: number): Promise<DtoTrabajos> {
        return await this.repository.selectTrabajoLocal(user, idTrabajo);
    }
}