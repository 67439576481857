import { DtoCentroCostos } from "./DtoCentroCostos";
import { DtoPeriodo } from "./DtoPeriodo";
import { DtoUsuarioEdicion } from './../../../../shared/Domain/Dto/DtoUsuarioEdicion';

export class DtoProduccion {
    Mes: number = 0;
    Anio: number = 0;
    UltimoPeriodo: DtoPeriodo = new DtoPeriodo();
    Periodos: DtoPeriodo[] = [];  //VERIFICAR EN EL SET VALORES
    CentroCostos: DtoCentroCostos = new DtoCentroCostos();
    UsuarioEdicion: DtoUsuarioEdicion = new DtoUsuarioEdicion();
    FechaRegistro: Date = new Date();
}