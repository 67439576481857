import { Filtros } from "../../Domain/EntityParams";

export const FiltroContratoOT: Filtros = {
  Filtros: [
    {
      $match: {
        'Estado.ID_Estado': 1
      }
    },
    {
      $project: {
        ID_ContratoOT: 1,
        Codigo: 1,
        Descripcion: 1,
        Sistema: 1,
        Pais: 1,
        Delegacion: 1,
        OT: 1,
        Vigencia: 1,
        CheckPrecioMO: 1,
      }
    }]
}